import React, { useState, useContext, useEffect } from 'react';
import UserContext from '../../context/UserContext';
import ControlContext from '../../context/ControlContext';
import { useHistory } from 'react-router-dom';
import ErrorNotice from '../misc/ErrorNotice';

import AdminSettings from '../admin/AdminSettings';
import AdminEmails from '../admin/AdminEmails';
import AdminEditMethod from '../admin/AdminEditMethod';
import AdminEditMaterial from '../admin/AdminEditMaterial';
import AdminEditProcessing from '../admin/AdminEditProcessing';
import AdminEditColor from '../admin/AdminEditColor';
import AdminEditInfill from '../admin/AdminEditInfill';
import AdminEditDelivery from '../admin/AdminEditDelivery';
import AdminEditOrderStatus from '../admin/AdminEditOrderStatus';

import { checkAdmin } from '../account/AccountUtils';

const AdminFormulas = () => {
    const { userData } = useContext(UserContext);
    const { control } = useContext(ControlContext);
    const history = useHistory();
    const [error, setError] = useState();

    const checkLoggedIn = async () => {
        try {
            checkAdmin(userData, history);
        } catch (err) {
            err.response.data.msg && setError(err.response.data.msg);
        }
    };

    useEffect(() => {
        checkLoggedIn();
    }, []);

    return (
        <div>
            {error && (
                <>
                    <ErrorNotice message={error} clearError={() => setError(undefined)} />
                    <br />
                </>
            )}
            <div className="adminPage">
                {control.adminPage === 'settings' ? <AdminSettings setError={setError} /> : null}
                {control.adminPage === 'emails' ? <AdminEmails setError={setError} /> : null}
                {control.adminPage === 'method' ? <AdminEditMethod setError={setError} /> : null}
                {control.adminPage === 'material' ? <AdminEditMaterial setError={setError} /> : null}
                {control.adminPage === 'processing' ? <AdminEditProcessing setError={setError} /> : null}
                {control.adminPage === 'color' ? <AdminEditColor setError={setError} /> : null}
                {control.adminPage === 'infill' ? <AdminEditInfill setError={setError} /> : null}
                {control.adminPage === 'delivery' ? <AdminEditDelivery setError={setError} /> : null}
                {control.adminPage === 'orderstatus' ? <AdminEditOrderStatus setError={setError} /> : null}
            </div>
        </div>
    );
};

export default AdminFormulas;
