import React from 'react';

const AdditionalInfoVO = ({ readOnly, projectID, inquiryID, comment, status, statusDisp, orderID, setOrderID, trackingNumbers }) => {
    return (
        <div id="additional-info-container">
            <div className="additional-info">
                <div id="field-projectID">
                    <label>Projektbezeichnung:</label>
                    <label>{projectID}</label>
                </div>
                <div id="field-userOfferID">
                    <label>Anfragenummer:</label>
                    <label>{inquiryID}</label>
                </div>
                <div id="field-userOfferID">
                    <label>Bestellnummer:</label>
                    <label>{orderID}</label>
                </div>
                {statusDisp && <div id="field-orderStatus">{readOnly ? <h4>Status: {statusDisp}</h4> : null}</div>}
                <br />
            </div>
            {status === 'os_delivery' ? <div className="additional-info">{`Trackingnummern: ${trackingNumbers}`} </div> : null}
        </div>
    );
};

export default AdditionalInfoVO;
