import React, { useState, useContext } from 'react';
import Axios from 'axios';
import ControlContext from '../../context/ControlContext';
import ErrorNotice from '../misc/ErrorNotice';
import ActionCodeItem from '../account/ActionCodeItem';
import Modal from 'react-modal';
import { confirmAlert } from 'react-confirm-alert';
import AddActionCode from '../account/AddActionCode';
import EditActionCode from '../account/EditActionCode';

const ActionCodeList = ({ actionCodes, setActionCodes }) => {
    const { control, setControl } = useContext(ControlContext);
    const [error, setError] = useState();
    const [showAddCode, setShowAddCode] = useState(false);
    const [showEditCode, setShowEditCode] = useState(false);

    const updateCodeList = async () => {
        try {
            let token = localStorage.getItem('auth-token');
            const res = await Axios.get(`${process.env.REACT_APP_SERVER}/admin/settings/`, { headers: { 'x-auth-token': token } });
            if (res) {
                setActionCodes(res.data.actionCodes);
            }
        } catch (err) {
            console.log(err);
        }
    };

    const addCode = () => {
        setShowAddCode(true);
    };

    const modalClose = () => {
        setShowAddCode(false);
    };

    const modalClose2 = () => {
        setShowEditCode(false);
        updateCodeList();
    };

    const handleSubmit = (e) => {
        setShowAddCode(false);
        updateCodeList();
    };

    const deleteCode = (_id) => {
        confirmAlert({
            title: 'Löschen bestätigen',
            message: 'Wollen Sie diesen Aktionscode wirklich löschen?',
            buttons: [
                {
                    label: 'Ja',
                    className: 'alert-button-grey',
                    onClick: async () => {
                        try {
                            let token = localStorage.getItem('auth-token');
                            const payload = { _id };
                            const res = await Axios.post(`${process.env.REACT_APP_SERVER}/admin/deleteActionCode`, payload, {
                                headers: { 'x-auth-token': token },
                            });
                            setActionCodes(res.data.actionCodes);
                        } catch (err) {
                            err.response.data.msg && setError(err.response.data.msg);
                        }
                    },
                },
                {
                    label: 'Nein',
                    className: 'alert-button-grey',
                    onClick: () => {},
                },
            ],
        });
    };

    const editActionCode = async (_id) => {
        setControl({
            ...control,
            editActionCode: _id,
        });
        setShowEditCode(true);
        //history.push('/editAdress');
    };

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '90%',
            maxWidth: '1000px',
            height: '60%',
        },
    };

    return (
        <div >
            {/*
            <div id="info-box">
                <InfoAddress />
                
            </div>*/}
            {error && <ErrorNotice message={error} clearError={() => setError(undefined)} />}
            <div>
                <Modal isOpen={showAddCode} onRequestClose={(e) => modalClose(e)} contentLabel="Actionscode hinzufügen" style={customStyles}>
                    <div className="modalHeader">
                        <div>
                            <h2>Aktionscode hinzufügen</h2>
                        </div>
                        <div>
                            <button id="buttonX" onClick={() => modalClose()}>
                                &#10006;
                            </button>
                        </div>
                    </div>
                    <div className="modalBody">
                        <AddActionCode admin={true} onSubmit={handleSubmit} />
                    </div>
                </Modal>
                <Modal isOpen={showEditCode} onRequestClose={(e) => modalClose2(e)} contentLabel="Aktionscode bearbeiten" style={customStyles}>
                    <div className="modalHeader">
                        <div>
                            <h2>Aktionscode bearbeiten</h2>
                        </div>
                        <div>
                            <button id="buttonX" onClick={() => modalClose2()}>
                                &#10006;
                            </button>
                        </div>
                    </div>
                    <div className="modalBody">
                        <EditActionCode onSubmit={modalClose2} />
                    </div>
                </Modal>
                <ul id="adress-list">
                    {actionCodes &&
                        actionCodes.length > 0 &&
                        actionCodes.map((ac) => (
                            <li key={ac.uuid}>
                                <ActionCodeItem code={ac} deleteCode={deleteCode} editCode={editActionCode} />
                            </li>
                        ))}
                </ul>
                <div id="button-container-left">
                    <button className="page-button-left" onClick={() => addCode()}>
                        Actionscode hinzufügen
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ActionCodeList;
