import React, { useState, useContext, useEffect } from 'react';
import Axios from 'axios';
import date from 'date-and-time';
import UserContext from '../../context/UserContext';
import ControlContext from '../../context/ControlContext';
import FileContext from '../../context/FileContext';
import TaskContext from '../../context/TaskContext';
import { useHistory } from 'react-router-dom';
import AdditionalInfo from '../misc/AdditionalInfo';
import ErrorNotice from '../misc/ErrorNotice';

import FileDetailsOffer from '../file/FileDetailsOffer';
import FileDetailsOfferAdmin from '../file/FileDetailsOfferAdmin';
import FileTaskOffer from '../file/FileTaskOffer';
import FileTaskOfferAdmin from '../file/FileTaskOfferAdmin';

import { confirmAlert } from 'react-confirm-alert';
import Documents from '../admin/Documents';
import InfoViewOffer from '../misc/InfoViewOffer';

const ViewOffer = () => {
    const { userData } = useContext(UserContext);
    const { control } = useContext(ControlContext);
    const { files, setFiles } = useContext(FileContext);
    const { tasks, setTasks } = useContext(TaskContext);

    const history = useHistory();
    const [error, setError] = useState();
    const [offer, setOffer] = useState();
    const [projectID, setProjectID] = useState();
    const [inquiryID, setInquiryID] = useState();
    const [status, setStatus] = useState();

    const [chatMsg, setChatMsg] = useState();
    const [chatMessages, setChatMessages] = useState([]);
    const [changed, setChanged] = useState(false);
    const [showUpload, setShowUpload] = useState(false);
    const [expired, setExpired] = useState(false);
    const [refresh, setRefresh] = useState(0);

    const _id = control.viewOffer;

    const OnBack = () => {
        history.push('/offers');
    };

    const OnFollowUp = () => {
        offer.items.forEach((item) => {
            item.no3D = true;
            item.methods = [];
            item.materials = [];
            item.colors = [];
            item.processings = [];

            item.x = item.boundingBox[0];
            item.y = item.boundingBox[1];
            item.z = item.boundingBox[2];

            item.name = item.fileName;

            let amounts = [];
            item.prices.forEach((pr) => {
                amounts.push(pr.amount);
            });
            item.amounts = amounts;
        });
        setFiles([...offer.items]);
        setTasks([...offer.tasks]);
        history.push('/');
    };

    const CreateOrder = () => {
        history.push('/createorder');
    };

    const DeleteOffer = () => {
        confirmAlert({
            title: 'Löschen bestätigen',
            message: 'Wollen Sie dieses Angebot wirklich löschen?.',
            buttons: [
                {
                    label: 'Ja',
                    onClick: () => {
                        try {
                            let token = localStorage.getItem('auth-token');
                            Axios.post(
                                `${process.env.REACT_APP_SERVER}/files/deleteoffer`,
                                { _id: offer._id },
                                { headers: { 'x-auth-token': token } }
                            );
                            history.push('/offers');
                        } catch (err) {}
                    },
                },
                {
                    label: 'Nein',
                    onClick: () => {},
                },
            ],
        });
    };

    const SendMsg = async () => {
        const now = new Date();
        const timeString = date.format(now, 'DD.MM.YYYY HH:mm:ss');

        const chatName = userData.user.chatName || `${userData.user.firstName} ${userData.user.lastName}`;

        const cm = {
            msg: chatMsg,
            time: timeString,
            user: chatName,
        };

        let token = localStorage.getItem('auth-token');
        await Axios.post(`${process.env.REACT_APP_SERVER}/files/addoffermsg`, { _id: offer._id, msg: cm }, { headers: { 'x-auth-token': token } });

        setChatMessages([...chatMessages, cm]);
        console.log(chatMessages);
        setChatMsg('');
    };

    const updateIDs = async () => {
        let token = localStorage.getItem('auth-token');
        await Axios.post(
            `${process.env.REACT_APP_SERVER}/files/updateofferids`,
            { _id: offer._id, IDS: offer.IDS, projectID: projectID, inquiryID: inquiryID },
            { headers: { 'x-auth-token': token } }
        );
    };

    const sendEmail = async () => {
        let token = localStorage.getItem('auth-token');
        await Axios.post(`${process.env.REACT_APP_SERVER}/admin/emailOfferUpdate`, { _id: offer._id }, { headers: { 'x-auth-token': token } });
    };

    const saveChanges = async () => {
        let token = localStorage.getItem('auth-token');
        try {
            for (const item of offer.items) {
                await Axios.post(
                    `${process.env.REACT_APP_SERVER}/admin/updateprices`,
                    { _id: item._id, prices: item.prices, tasks: item.tasks, approved: item.approved },
                    { headers: { 'x-auth-token': token } }
                );
            }

            await Axios.post(
                `${process.env.REACT_APP_SERVER}/admin/updateTasks`,
                { _id: offer._id, tasks: offer.tasks },
                { headers: { 'x-auth-token': token } }
            );

            setChanged(false);
        } catch (err) {
            console.log(err);
        }
    };

    const updateTask = (task) => {
        let newTasks = [...offer.tasks];
        newTasks.forEach(function (item, i) {
            if (item.uuid === task.uuid) newTasks[i] = task;
        });
        setOffer({ ...offer, tasks: newTasks });
        setChanged(true);
    };

    useEffect(() => {
        if (offer) {
            let date1 = new Date(offer.expires);
            let date2 = new Date();
            const diffTime = date1 - date2;
            if (diffTime < 0) {
                setStatus('abgelaufen');
                setExpired(true);
            } else if (diffTime < 3600000) {
                const diffHours = Math.ceil(diffTime / (1000 * 60 * 60));
                setStatus(`läuft in ${diffHours.toString()} Stunden ab`);
            } else {
                const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
                setStatus(`läuft in ${diffDays.toString()} Tagen ab`);
            }
        }
    }, [offer]);

    useEffect(() => {
        if (control.maintenance) {
            history.push('/maintenance');
        }
        const checkLoggedIn = async () => {
            let token = localStorage.getItem('auth-token');
            if (token === null) {
                localStorage.setItem('auth-token', '');
                token = '';
            }

            const tokenRes = await Axios.post(`${process.env.REACT_APP_SERVER}/users/tokenIsValid`, null, { headers: { 'x-auth-token': token } });

            if (tokenRes.data) {
                if (_id) {
                    try {
                        const res = await Axios.post(
                            `${process.env.REACT_APP_SERVER}/files/offer`,
                            { _id: _id },
                            { headers: { 'x-auth-token': token } }
                        );
                        setOffer(res.data);
                        setProjectID(res.data.projectID);
                        setInquiryID(res.data.inquiryID);
                        setChatMessages(res.data.messages);
                    } catch (err) {
                        console.log(err);
                        setError(`${err.message}`);
                    }
                }
            } else {
                history.push('/login');
            }
        };
        checkLoggedIn();
    }, [history, setOffer, _id, refresh]);

    return (
        <div className="container">
            
                <div id="info-area">
                    <div className="page-title">
                        <h2>Angebot: {offer?.IDS}</h2>
                        <hr className="solid"></hr>
                    </div>
                    <InfoViewOffer />
                    {error && <ErrorNotice message={error} clearError={() => setError(undefined)} />}
                </div>
            
            {/* 
            <Modal isOpen={showUpload} onRequestClose={(e) => modalClose(e)} contentLabel="Dokumente" style={customStyles}>
                <Documents setError={setError} order={offer} setShowUpload={setShowUpload} mode="offer" />
            </Modal>*/}
            {offer ? (
                <AdditionalInfo
                    status={status}
                    readOnly={true}
                    projectID={projectID}
                    setProjectID={setProjectID}
                    inquiryID={inquiryID}
                    setInquiryID={setInquiryID}
                    updateIDs={updateIDs}
                />
            ) : null}

            {userData?.rights?.includes('admin') ? (
                <div className="item-column" id="file-list">
                    {offer &&
                        offer.items?.length > 0 &&
                        offer.items.map((item, pos) => <FileDetailsOfferAdmin key={item._id} item={item} setChanged={setChanged} position={pos} />)}
                    {offer &&
                        offer.tasks?.length > 0 &&
                        offer.tasks.map((task, pos) => (
                            <FileTaskOfferAdmin key={task.uuid} position={offer.items.length + pos + 1} task={task} updateTask={updateTask} />
                        ))}
                </div>
            ) : (
                <div className="item-column" id="file-list">
                    {offer &&
                        offer.items?.length > 0 &&
                        offer.items.map((item, pos) => <FileDetailsOffer key={item._id} item={item} setChanged={setChanged} position={pos} />)}
                    {offer &&
                        offer.tasks?.length > 0 &&
                        offer.tasks.map((task, pos) => (
                            <FileTaskOffer key={task.uuid} position={offer.items.length + pos + 1} task={task} updateTask={updateTask} />
                        ))}
                </div>
            )}
            <div className="item-column" id="file-list">
                <div id="footer-inner-container">
                    <Documents setError={setError} order={offer} setShowUpload={setShowUpload} mode="offer" setRefresh={setRefresh} />
                </div>
            </div>
            <div id="footer-container">
                <div id="footer-inner-container">
                    <div id="chat-offer-footer">
                        <div id="chat-column-1">
                            <label className="label-height-2-white">Nachrichten zum Auftrag:</label>
                            <input
                                id="chat-input"
                                type="text"
                                value={chatMsg}
                                onChange={(e) => setChatMsg(e.currentTarget.value)}
                                className="chat-input"
                                placeholder="Schreiben Sie uns eine Nachricht"
                            ></input>
                            <br />
                            <div id="button-container">
                                <button className="chat-button-white" onClick={SendMsg}>
                                    Senden
                                </button>
                                {/*
                                        <button className="page-button-white" onClick={onDocuments}>
                                            Meine Dokumente
                                        </button>*/}
                            </div>
                        </div>
                        <div id="chat-column-2">
                            <div className="chat-column">
                                <label className="label-height-2">Nachrichtenverlauf:</label>
                                {offer ? (
                                    <>
                                        <div className="chat-messages">
                                            {chatMessages.length > 0 &&
                                                chatMessages.map((msg) => (
                                                    <>
                                                        <label className="chat-message-time">
                                                            &nbsp;
                                                            {msg.time}
                                                        </label>
                                                        <label className="chat-message-user">
                                                            &nbsp;
                                                            {msg.user}
                                                        </label>
                                                        :
                                                        <label className="chat-message-msg">
                                                            &nbsp;
                                                            {msg.msg}
                                                        </label>
                                                        <br />
                                                    </>
                                                ))}
                                        </div>
                                    </>
                                ) : null}
                            </div>
                        </div>
                    </div>
                    <div id="button-container">
                        {/*
                        {userData?.rights?.includes('admin') && (
                            <button className="page-button-white" onClick={OnFollowUp}>
                                Folgeangebot (experimental) only admin
                            </button>
                        )}*/}
                        <button className="page-button-white" onClick={OnBack}>
                            Zurück
                        </button>
                        {offer?.orders?.length > 0 ? (
                            <></>
                        ) : (
                            <button className="page-button-white" onClick={DeleteOffer}>
                                Angebot löschen
                            </button>
                        )}
                        {/* 
                            <button className="page-button-white" onClick={onDocuments}>
                                Meine Dokumente
                            </button>*/}
                        {userData?.rights?.includes('admin') && (
                            <>
                                <button className="page-button-white" onClick={sendEmail}>
                                    Email senden
                                </button>
                                <button className="page-button-white" onClick={saveChanges}>
                                    Save changes
                                </button>
                            </>
                        )}
                        {!expired && (
                            <button className="page-button-action" onClick={CreateOrder}>
                                Auftrag erstellen
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ViewOffer;
