import React, { useState, useEffect } from 'react';
import Axios from 'axios';

import AdminEditFormula from '../admin/AdminEditFormula';

const AdminEditDelivery = ({ setError }) => {
    const [select, setSelect] = useState('main');
    const [name, setName] = useState();
    const [displayName, setDisplayName] = useState();
    const [newName, setNewName] = useState();
    const [deliveries, setDeliveries] = useState([]);
    const [editObject, setEditObject] = useState();
    const [formula, setFormula] = useState();
    const [minDays, setMinDays] = useState();
    const [maxDays, setMaxDays] = useState();
    const [allowedCountries, setAllowedCountries] = useState([]);
    const [countries, setCountries] = useState([]);
    const [productID, setProductID] = useState('V01PAK');
    const [isExpress, setIsExpress] = useState(false);

    const [showEditFormula, setShowEditFormula] = useState(false);
    const [inputVariables, setInputVariables] = useState({});

    const AddDelivery = async () => {
        if (newName) {
            let token = localStorage.getItem('auth-token');
            const payload = {
                name: newName,
            };
            try {
                const res = await Axios.post(`${process.env.REACT_APP_SERVER}/admin/adddelivery`, payload, { headers: { 'x-auth-token': token } });
                if (res.data && res.data.name) {
                    setDeliveries([...deliveries, res.data]);
                    setEditObject(res.data);
                    setName(res.data.name);
                }
            } catch (err) {
                err.response.data.msg && setError(err.response.data.msg);
            }
        }
    };

    const saveChanges = async () => {
        editObject.formula = formula;
        editObject.minDays = minDays;
        editObject.maxDays = maxDays;
        editObject.name = name;
        editObject.displayName = displayName;
        editObject.countries = allowedCountries;
        editObject.productID = productID;
        editObject.isExpress = isExpress;
        let token = localStorage.getItem('auth-token');
        try {
            await Axios.post(`${process.env.REACT_APP_SERVER}/admin/savedelivery`, editObject, { headers: { 'x-auth-token': token } });
        } catch (err) {
            err.response.data && err.response.data.msg && setError(err.response.data.msg);
        }
    };

    const deleteEntry = async () => {
        let token = localStorage.getItem('auth-token');
        try {
            await Axios.post(`${process.env.REACT_APP_SERVER}/admin/deletedelivery`, editObject, { headers: { 'x-auth-token': token } });
            const res2 = await Axios.get(`${process.env.REACT_APP_SERVER}/admin/deliveries/`, { headers: { 'x-auth-token': token } });
            if (res2) {
                setDeliveries(res2.data.deliveries);
                setEditObject(undefined);
            }
        } catch (err) {
            err.response.data && err.response.data.msg && setError(err.response.data.msg);
        }
    };

    const changeCountrySelect = (checked, colorID) => {
        if (checked) {
            if (!allowedCountries.some((e) => e._id === colorID)) {
                setAllowedCountries([...allowedCountries, { _id: colorID }]);
            }
        } else {
            setAllowedCountries(
                allowedCountries.filter(function (obj) {
                    return obj._id !== colorID;
                })
            );
        }
    };

    useEffect(() => {
        const getDeliveries = async () => {
            try {
                let token = localStorage.getItem('auth-token');
                const cres = await Axios.get(`${process.env.REACT_APP_SERVER}/admin/countries/`, { headers: { 'x-auth-token': token } });
                if (cres) {
                    setCountries(cres.data.countries);
                }
                const res = await Axios.get(`${process.env.REACT_APP_SERVER}/admin/deliveries/`, { headers: { 'x-auth-token': token } });
                if (res) {
                    setDeliveries(res.data.deliveries);
                    setEditObject(undefined);
                }
            } catch (err) {
                err.response.data.msg && setError(err.response.data.msg);
            }
        };

        getDeliveries();
    }, [setError]);

    const editPrintFormula = () => {
        setInputVariables({
            x: 100,
            y: 100,
            z: 100,
            weight: 100,
            amount: 1,
            totalWeight: 100,
            totalVolume: 1000,
            Preis: 0,
            netto: 0,
        });
        setShowEditFormula(true);
    };

    return (
        <>
            <AdminEditFormula
                showModal={showEditFormula}
                setShowModal={setShowEditFormula}
                inputVariables={inputVariables}
                setInputVariables={setInputVariables}
                formula={formula}
                setFormula={setFormula}
            />
            <h1>Lieferarten anlegen</h1>
            <div className="admin-editor-page">
                <div className="admin-edit-column">
                    <ul>
                        {deliveries &&
                            deliveries.length > 0 &&
                            deliveries.map((del) => (
                                <li key={del._id}>
                                    <button
                                        className={select === del.name ? 'admin-nav-button-sel' : 'admin-nav-button'}
                                        onClick={() => {
                                            setEditObject(del);
                                            setMinDays(del.minDays || '');
                                            setMaxDays(del.maxDays || '');
                                            setFormula(del.formula || '');
                                            setSelect(del.name);
                                            setName(del.name);
                                            setDisplayName(del.displayName || '');
                                            setAllowedCountries(del.countries);
                                            setProductID(del.productID || '');
                                            setIsExpress(del.isExpress || false);
                                        }}
                                    >
                                        {del.name}
                                    </button>
                                </li>
                            ))}
                    </ul>
                    <input type="text" value={newName} onChange={(e) => setNewName(e.target.value)} />
                    <br />
                    <button className="page-button-action" onClick={AddDelivery}>
                        Lieferart hinzufügen
                    </button>
                </div>

                {editObject ? (
                    <>
                        <div className="admin-edit-column">
                            <input type="text" value={name} onChange={(e) => setName(e.target.value)} />
                            <h4>Anzeigename</h4>
                            <input type="text" value={displayName} onChange={(e) => setDisplayName(e.target.value)} />
                            <h4>min Tage</h4>
                            <input type="text" placeholder="Min Tage" value={minDays} onChange={(e) => setMinDays(e.target.value)} />
                            <h4>max Tage</h4>
                            <input type="text" placeholder="Max Tage" value={maxDays} onChange={(e) => setMaxDays(e.target.value)} />
                            <h4>Product ID</h4>
                            <input type="text" placeholder="dhl product" value={productID} onChange={(e) => setProductID(e.target.value)} />
                            <h4>Is Express</h4>
                            <label htmlFor="cbisexpress">
                                Express
                                <input
                                    type="checkbox"
                                    id="cbisexpress"
                                    checked={isExpress}
                                    onChange={(e) => {
                                        setIsExpress(e.target.checked);
                                    }}
                                />
                            </label>
                        </div>
                        <div className="admin-edit-column">
                            <h4>Mögliche Länder</h4>
                            {countries &&
                                countries.length > 0 &&
                                countries.map((country) => (
                                    <div key={country._id}>
                                        <label htmlFor={country.name}>
                                            {country.name}
                                            <input
                                                type="checkbox"
                                                id={country.name}
                                                checked={allowedCountries.some((e) => e._id === country._id)}
                                                onChange={(e) => {
                                                    changeCountrySelect(e.target.checked, country._id);
                                                }}
                                            />
                                        </label>
                                        <br />
                                    </div>
                                ))}
                        </div>
                        <div className="admin-edit-column">
                            <h4>Formeln</h4>
                            <button className="page-button-action" onClick={() => editPrintFormula()}>
                                Edit
                            </button>
                            <br />
                            <button className="page-button-action" onClick={() => saveChanges()}>
                                Änderungen speichern
                            </button>
                            <button className="admin-delete-button" onClick={() => deleteEntry()}>
                                Löschen
                            </button>
                        </div>
                        <div className="admin-edit-column">
                            <h4>Variablen</h4>
                            <h5>Von Auswahl</h5>
                            <label>x (Boundingbox)</label>
                            <br />
                            <label>y (Boundingbox)</label>
                            <br />
                            <label>z (Boundingbox)</label>
                            <br />
                            <label>weight</label>
                            <br />
                            <label>amount</label>
                            <br />
                            <h5>Zwischengespeicherte Variablen</h5>
                            <label>totalWeight</label>
                            <br />
                            <label>totalVolume</label>
                            <br />
                            <h5>Zu schreiben</h5>
                            <label>Preis</label>
                            <br />
                        </div>
                    </>
                ) : null}
            </div>
        </>
    );
};

export default AdminEditDelivery;
