import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import Axios from 'axios';
import Select from 'react-select';
import FileDropzone from '../misc/FileDropzone';
import AdditionalInfo from '../misc/AdditionalInfo';
import ErrorNotice from '../misc/ErrorNotice';
import FileList from '../misc/FileList';
import Info1 from '../misc/Info1';
import { useHistory } from 'react-router-dom';
import FileContext from '../../context/FileContext';
import TaskContext from '../../context/TaskContext';
import UserContext from '../../context/UserContext';
import ControlContext from '../../context/ControlContext';
import { customStyleLarge, formatOptionLabelAdress } from '../misc/SelectOptions';
import { checkUser } from '../account/AccountUtils';
import { v4 as uuidv4 } from 'uuid';

export default function Home({ setShowLogin, setShowRegister }) {
    const history = useHistory();
    const { userData } = useContext(UserContext);
    const { control, setControl } = useContext(ControlContext);
    const { files, setFiles } = useContext(FileContext);
    const { tasks, setTasks } = useContext(TaskContext);
    const [allPrintable, setAllPrintable] = useState(false);

    const [deliveryAdresses, setDeliveryAdresses] = useState(userData.adresses);
    const [deliveryAdress, setDeliveryAdress] = useState();
    const [error, setError] = useState();

    const [delOptions, setDelOptions] = useState([]);

    const [discount, setDiscount] = useState(0);
    const [requestPreview, setRequestPreview] = useState();

    useEffect(() => {
        if (userData.user) {
            setDeliveryAdresses(userData.adresses);
        }
    }, [userData]);

    const sleep = (milliseconds) => {
        return new Promise((resolve) => setTimeout(resolve, milliseconds));
    };

    const createThumbnail = async (file) => {
        setRequestPreview(file.uuid);
        while (!file.preview) {
            await sleep(100);
        }
    };

    const saveOffer = async (createOrder) => {
        try {
            console.log('saveOffer');
            let offer = {};
            offer.projectID = control.projectID || '';
            offer.inquiryID = control.inquiryID || '';
            offer.comment = control.comment || '';
            if (!control.deliveryAdress) {
                offer.deliveryAdress = userData?.adresses?.[0]?._id;
            } else {
                offer.deliveryAdress = control.deliveryAdress;
            }
            offer.files = [];
            offer.additionalFiles = [];
            offer.tasks = tasks;

            for (var file of files) {
                if (file.type === '') {
                    if(file.fileType === 'stl')
                    {
                        await createThumbnail(file);
                    }
                    offer.files.push(file);
                } else {
                    offer.additionalFiles.push(file.params);
                }
            }

            let token = localStorage.getItem('auth-token');
            const res = await Axios.post(`${process.env.REACT_APP_SERVER}/files/saveOffer`, offer, { headers: { 'x-auth-token': token } });
            if (res.data.offerID) {
                control.projectID = '';
                control.inquiryID = '';
                control.comment = '';
                setControl({
                    ...control,
                    viewOffer: res.data.offerID,
                });
                setFiles([]);
                if (createOrder) {
                    history.push('/createorder');
                } else {
                    history.push('/viewoffer');
                }
            }
        } catch (err) {
            if (err.response.status === 500) {
                console.log('Error 500');
            } else {
                console.log(err.response.data.msg);
            }
        }
    };

    const ToOrderPage = () => {
        saveOffer(true);
    };

    const OnSaveOffer = () => {
        saveOffer(false);
    };

    const OnNew = () => {
        setFiles([]);
        setTasks([]);
    };

    const onExtraTask = () => {
        const task = {
            name: `Zusätzliche Leistung zum Auftrag`,
            uuid: uuidv4(),
            prices: [
                {
                    amount: 1,
                    price: 0,
                    time: 1,
                    selected: true,
                },
            ],
            references: 'Order',
            description: ' ',
            type: 'global',
            approved: false,
        };

        setTasks([...tasks, task]);
    };

    const onAdress1Change = (adr) => {
        setDeliveryAdress(adr);
        setControl({
            ...control,
            deliveryAdress: adr.value,
        });
    };

    useEffect(() => {
        let options = [];
        deliveryAdresses?.forEach((adr) => {
            const opt = {
                value: `${adr._id}`,
                country: `${adr.country}`,
                label: adr.faName,
                adr: adr,
            };
            options.push(opt);
        });
        setDelOptions(options);
        if (options.length > 0) setDeliveryAdress(options[0]);
    }, [deliveryAdresses]);

    useEffect(() => {
        if (control.maintenance) {
            history.push('/maintenance');
        }
        const init = async () => {
            const valid = await checkUser();
            if (!valid) {
                //setUserData({});
            }
            setDiscount(userData?.user?.rebateLevel || 0);
        };

        init();
    }, [userData.user]);

    return (
        <div id="wrap-div">
            {/*<div id="home-banner">
                <div id="banner-row">
                    <div id="banner-text">
                        <h1>3D Preiskalkulator</h1>
                    </div>
                </div>
            </div>*/}
            <div id="info-box-background">
                <div id="upload-box" className="flex">
                    {userData?.rights?.includes('admin') ? (
                        <div className="page-title">
                            <h2><span className='h2_big'>Upload</span><br />der Daten</h2>                        
                        </div>
                    ) : (
                        <Info1 />
                    )}
                    {error && <ErrorNotice message={error} clearError={() => setError(undefined)} />}
                    <FileDropzone setError={setError} />
                    
                </div>
            </div> 

            {userData?.rights?.includes('admin') ? (
                    <div id="admin-set-discount-level" align="center">
                        Admin set discount level:
                        <input className="amount-input" type="number" value={discount} onChange={(e) => setDiscount(e.currentTarget.value)} />
                    </div>
                ) : null}

            
            {files.length > 0 ? (
                <>
                    <AdditionalInfo />
                    <FileList
                        setAllPrintable={setAllPrintable}
                        discount={discount}
                        requestPreview={requestPreview}
                        setRequestPreview={setRequestPreview}
                    />{' '}
                </>
            ) : null}

            {files.length > 0 && userData.user ? (
                <>
                    <div className="wrapper_1500 center">
                        <button className="page-button-white " onClick={() => onExtraTask()}>
                           Zusatzleistungen zum Auftrag
                        </button>
                    </div>
                </>
            ) : null}

            <div id="error-box">
                {files && files.length > 0 && !allPrintable ? (
                    <div className="user-hint">
                        Mindestens eines Ihrer Modelle ist nicht druckbar. Bitte entfernen Sie diese/dieses aus der Liste.
                    </div>
                ) : null}
            </div>

            <div id="footer-container">
                
                {userData?.user && files && files.length > 0 && allPrintable ? (
                    <div id="offer-footer">
                        <div id="offer-footer-left">
                            <label className="label-height-2-white">Angebotsadresse</label>
                            <Select
                                styles={customStyleLarge}
                                value={deliveryAdress}
                                onChange={onAdress1Change}
                                options={delOptions}
                                menuPlacement="top"
                                formatOptionLabel={formatOptionLabelAdress}
                                isSearchable={false}
                            />
                        </div>
                        <div id="offer-footer-right">
                            <p>Alle Preise ohne Mehrwertsteuer. Bei Bestellung fallen zusätzlich Versandkosten an.</p>

                            <div id="offer-footer-buttons">
                                <button className="page-button-white" onClick={OnNew}>
                                    Angebot leeren
                                </button>
                                <button className="page-button-white" onClick={OnSaveOffer}>
                                    Angebot speichern
                                </button>
                                <button className="page-button-action" onClick={ToOrderPage}>
                                    Auftrag planen
                                </button>
                            </div>
                        </div>
                    </div>
                ) : null}
            </div>

            {userData?.user && (
                    <div id="minordervalue-box">
                        Bitte beachten Sie unseren Mindestbestellwert von {userData.user.minOrderValue},- Euro Netto pro Auftrag. Für Auftr&auml;ge unter
                        diesem Betrag erheben wir einen Mindermengenzuschlag.
                    </div>
                )}

            {!userData.user ? (
                <div className="user-hint">
                    Zur Bestellung und Verwaltung Ihrer Aufträge bitte&nbsp;
                    <Link style={{ textDecoration: 'underline' }} onClick={() => setShowRegister(true)}>
                        Registrieren
                    </Link>
                    &nbsp;bzw.&nbsp;
                    <Link style={{ textDecoration: 'underline' }} onClick={() => setShowLogin(true)}>
                        Einloggen
                    </Link>
                    .
                </div>
            ) : null}
        </div>
    );
}
