import React, { useState, useContext, useEffect } from 'react';
import Axios from 'axios';
import Modal from 'react-modal';
import UserContext from '../../context/UserContext';
import { useHistory } from 'react-router-dom';
import ErrorNotice from '../misc/ErrorNotice';
import AdressList from '../account/AdressList';

export default function EditAccount({ showModal, setShowModal }) {
    const [email, setEmail] = useState();
    const [rebateLevel, setRebateLevel] = useState('0');
    const [firstName, setFirstName] = useState();
    const [lastName, setLastName] = useState();
    const [chatName, setChatName] = useState();
    const [salut, setSalut] = useState('Herr');
    const [newsletter, setNewsletter] = useState(false);

    const [password, setPassword] = useState();
    const [passwordCheck, setPasswordCheck] = useState();

    const { userData, setUserData } = useContext(UserContext);
    const history = useHistory();
    const [error, setError] = useState();

    const [success, setSuccess] = useState();

    const modalClose = () => {
        setShowModal(false);
    };

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            position: 'absolute',
            transform: 'translate(-50%, -50%)',
            width: '90%',
            maxWidth: '1000px',
            height: '60%',
        },
    };

    useEffect(() => {
        const checkLoggedIn = async () => {
            //console.log('check login');
            try {
                setEmail(userData.user.email);
                setFirstName(userData.user.firstName);
                setLastName(userData.user.lastName);
                setChatName(userData.user.chatName);
                setSalut(userData.user.salutation);
                setNewsletter(userData.user.newsletter);

                if (userData.user.rebateLevel !== undefined) {
                    setRebateLevel(userData.user.rebateLevel);
                } else {
                    setRebateLevel('0');
                }
            } catch (err) {
                //history.push('/');
            }
        };
        checkLoggedIn();
    }, [userData.user]);

    const submit = async (e) => {
        e.preventDefault();
        try {
            let token = localStorage.getItem('auth-token');
            const payload = {
                email,
                firstName,
                lastName,
                chatName,
                salut,
                newsletter,
            };
            await Axios.post(`${process.env.REACT_APP_SERVER}/users/updateAccount`, payload, { headers: { 'x-auth-token': token } });
        } catch (err) {
            err.response.data.msg && setError(err.response.data.msg);
        }
    };

    const submit2 = async (e) => {
        e.preventDefault();
        try {
            setSuccess(undefined);
            if (password.length < 8) {
                setError('Bitte wählen Sie ein Passwort mit mindestens 8 Zeichen');
                return;
            }
            if (password === passwordCheck) {
                const payload = { password: password };
                let token = localStorage.getItem('auth-token');
                const res = await Axios.post(`${process.env.REACT_APP_SERVER}/users/changepw`, payload, { headers: { 'x-auth-token': token } });
                if (res && res.data && res.data.msg) {
                    setSuccess(res.data.msg);
                }
                setUserData({ ...userData, pwID: undefined });
            } else {
                setError('Eingaben verschieden');
            }
            //history.push('/login');
        } catch (err) {
            if (err.response && err.response.data) {
                err.response.data.msg && setError(err.response.data.msg);
            } else {
                setError('Error');
            }
        }
    };

    return (
        <Modal isOpen={showModal} onRequestClose={(e) => modalClose(e)} contentLabel={'modalLabel'} style={customStyles}>
            <div className="modalHeader">
                <div>
                    <h2>Account</h2>
                </div>
                <div>
                    <button id="buttonX" onClick={() => modalClose()}>
                        &#10006;
                    </button>
                </div>
                
            </div>
            <hr></hr> 
            <div className="modalBody">
                {error && <ErrorNotice message={error} clearError={() => setError(undefined)} />}
                <form className="form" onSubmit={submit}> 
                                      
                    <div className="form-div50">
                        <label>Gesch&auml;ftspartnernummer: {userData?.user?.internalNumber}</label>
                    </div>
                    <div className="form-div50">
                        <label>Punkte: {rebateLevel}</label>
                    </div>
                    
                    <hr></hr>
                    
                    <div className="form-div50">
                        <label htmlFor="login-email">Email</label>                        
                        <input id="login-email" type="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
                    </div>
                    <div className="form-div50">
                        <label htmlFor="account-salut">Anrede</label>
                        <input id="adr8" type="text" value={salut} onChange={(e) => setSalut(e.target.value)} required />
                    </div>

                    <div className="form-div50">
                        <label htmlFor="account-name">Vorname</label>
                        <input id="account-name" type="text" value={firstName} onChange={(e) => setFirstName(e.target.value)} required />
                    </div>
                    <div className="form-div50">
                        <label htmlFor="account-lastname">Name</label>
                        <input id="account-lastname" type="text" value={lastName} onChange={(e) => setLastName(e.target.value)} required />
                    </div>

                    <div className="form-div50">
                        <label htmlFor="cbNewsletter">
                            Newsletter abonnieren&nbsp;
                            <input
                                type="checkbox"
                                id="cbNewsletter"
                                checked={newsletter}
                                onChange={(e) => {
                                    setNewsletter(e.target.checked);
                                }}
                            />
                        </label>
                    </div>
                    <div className="form-div50"></div>
                    {userData?.user?.rights?.includes('admin') && (
                        <>
                            <div className="form-div100">
                                <label htmlFor="account-lastname">Chat Name</label>
                                <br />
                                <input id="account-chatname" type="text" value={chatName} onChange={(e) => setChatName(e.target.value)} required />
                            </div>
                            <br />
                        </>
                    )}

                    <button className="page-button-left" type="submit">
                        Speichern
                    </button>
                </form>
                <hr></hr>
                <form className="form" onSubmit={submit2}>
                    <label htmlFor="register-password">
                        <strong>Password ändern</strong>
                    </label>
                    <input id="register-password" placeholder="Password" type="password" onChange={(e) => setPassword(e.target.value)} required />
                    <input type="password" placeholder="Verify password" onChange={(e) => setPasswordCheck(e.target.value)} required />
                    <button className="page-button-left" type="submit">
                        Speichern
                    </button>
                </form>
                <hr></hr>
                <h2>Adressen</h2>
                <AdressList />
                {success && <h3>{success}</h3>}
            </div>
        </Modal>
    );
}
