import React, { useState, useEffect, useContext } from 'react';
import Axios from 'axios';
import ErrorNotice from '../misc/ErrorNotice';

import UserContext from '../../context/UserContext';

export default function AddActionCode({ onSubmit, admin }) {
    const [name, setName] = useState();
    const [type, setType] = useState();
    const [points, setPoints] = useState();
    const [expires, setExpires] = useState();
    

    const [error, setError] = useState();
    
    const submit = async (e) => {
        e.preventDefault();
        try {
            let token = localStorage.getItem('auth-token');
            const payload = {
                name: name,
                type: type,
                points: points,
                expires: expires,
            };
            if (admin) {
                await Axios.post(`${process.env.REACT_APP_SERVER}/admin/addActionCode`, payload, { headers: { 'x-auth-token': token } });
            } 
            onSubmit();
        } catch (err) {
            err.response?.data?.msg && setError(err.response?.data?.msg);
        }
    };

    return (
        <div className="page-form">
            {error && <ErrorNotice message={error} clearError={() => setError(undefined)} />}
            <form className="form" onSubmit={submit}>
                <div className="form-div100">
                    <label className="adressLabel">Name</label>
                    <br />
                    <input id="adr1" type="text" value={name} onChange={(e) => setName(e.target.value)} placeholder="Name" />
                </div>
                
                <div className="form-div100">
                    <label className="adressLabel">Typ (register, checkout)</label>
                    <br />
                    <input id="adr2" type="text" value={type} onChange={(e) => setType(e.target.value)} placeholder="Typ" />
                </div>
            
                <div className="form-div100">
                    <label className="adressLabel">Punkte (10.0)</label>
                    <br />
                    <input id="adr2a" type="text" value={points} onChange={(e) => setPoints(e.target.value)} placeholder="10.0" required />
                </div>
                <div className="form-div50">
                    <label className="adressLabel">Ablaufdatum (1.10.2034)</label>
                    <br />
                    <input id="adr4" type="text" value={expires} onChange={(e) => setExpires(e.target.value)} placeholder="31.12.2023" required />
                    <button className="page-button-green" type="submit">
                        Speichern
                    </button>
                </div>
                
                    
                
            </form>
        </div>
    );
}
