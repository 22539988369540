import React, { useState, useEffect, useContext } from 'react';
import Axios from 'axios';
import { useHistory } from 'react-router-dom';
import ErrorNotice from '../misc/ErrorNotice';
import { AgGridReact } from 'ag-grid-react';
import { BtnCellRenderer, BtnViewCellRenderer } from '../admin/BtnCellRenderer';
import ParamsCellRenderer from '../admin/ParamsCellRenderer';
import { columnTypes, defaultColDef, defaultColGroupDef, dateTimeFormatter } from '../misc/ColumnTypes';
import AdminTicket from '../admin/AdminTicket';
import AutoButton from '../misc/AutoButton';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

const AdminTickets = () => {
    const [error, setError] = useState();
    const history = useHistory();
    const [tickets, setTickets] = useState();
    const [ticketAssignees, setTicketAssignees] = useState([]);
    const [ticket, setTicket] = useState();
    const [showTicket, setShowTicket] = useState(false);

    const frameworkComponents = {
        btnCellRenderer: BtnCellRenderer,
        paramsCellRenderer: ParamsCellRenderer,
        btnViewCellRenderer: BtnViewCellRenderer,
    };

    const columnDefs = [
        {
            field: '_id',
            headerName: 'View',
            cellRenderer: 'btnViewCellRenderer',
            cellRendererParams: {
                clicked: function (field) {
                    viewTicket(field);
                },
                label: 'View',
            },
            type: 'iconColumn',
            editable: false,
            filter: false,
        },
        {
            field: 'ID',
            headerName: '#',
            sortable: true,
            filter: true,
            suppressMenu: true,
            editable: false,
            type: 'iconColumn',
        },
        {
            field: 'title',
            headerName: 'Betreff',
            type: 'paramsColumn',
            filter: false,
            editable: false,
        },
        {
            field: 'status',
            headerName: 'Status',
            sortable: true,
            filter: true,
            suppressMenu: true,
            editable: false,
        },
        {
            field: 'priority',
            headerName: 'Priorität',
            sortable: true,
            filter: true,
            suppressMenu: true,
            editable: false,
        },
        {
            field: 'assigned.email',
            headerName: 'Zugewiesen an',
            sortable: true,
            filter: true,
            suppressMenu: true,
            editable: false,
            type: 'accountColumn',
        },

        {
            field: 'lastModified',
            headerName: 'letzte Änderung',
            sortable: true,
            filter: true,
            type: 'dateColumn',
            valueFormatter: dateTimeFormatter,
            sort: 'desc',
            suppressMenu: true,
            editable: false,
        },

        {
            field: 'creator.email',
            headerName: 'Ersteller',
            sortable: true,
            filter: true,
            suppressMenu: true,
            editable: false,
            type: 'accountColumn',
        },
        {
            field: 'created',
            headerName: 'Erstellt am',
            sortable: true,
            filter: true,
            type: 'dateColumn',
            valueFormatter: dateTimeFormatter,
            sort: 'desc',
            suppressMenu: true,
            editable: false,
        },
    ];

    const refresh = async () => {
        let token = localStorage.getItem('auth-token');
        try {
            const res = await Axios.get(`${process.env.REACT_APP_SERVER}/admin/tickets/`, { headers: { 'x-auth-token': token } });
            setTickets(res.data.tickets);

            const res2 = await Axios.get(`${process.env.REACT_APP_SERVER}/admin/ticketassignees/`, { headers: { 'x-auth-token': token } });
            setTicketAssignees(res2.data.ticketassignees);
        } catch (err) {
            console.log(err);
        }
    };

    const viewTicket = async (data) => {
        let token = localStorage.getItem('auth-token');
        try {
            const res = await Axios.get(`${process.env.REACT_APP_SERVER}/admin/ticket/`, {
                headers: { 'x-auth-token': token },
                params: { _id: data._id },
            });
            setTicket(res.data.ticket);
            setShowTicket(true);
        } catch (err) {
            console.log(err);
        }
    };

    const onAddTicket = () => {
        try {
            setTicket({
                title: 'neues Ticket',
            });
            setShowTicket(true);
        } catch (err) {
            console.log(err);
        }
    };

    const saveTicket = async (tick) => {
        let token = localStorage.getItem('auth-token');
        try {
            if (tick._id) {
                await Axios.post(`${process.env.REACT_APP_SERVER}/admin/saveticket`, tick, { headers: { 'x-auth-token': token } });
            } else {
                const res = await Axios.post(`${process.env.REACT_APP_SERVER}/admin/addticket`, tick, { headers: { 'x-auth-token': token } });

                if (res) {
                    setTicket(res.data.ticket);
                }
            }
            refresh();
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        refresh();
    }, []);

    return (
        <div className="overview">
            <AdminTicket
                showModal={showTicket}
                setShowModal={setShowTicket}
                ticket={ticket}
                saveTicket={saveTicket}
                ticketAssignees={ticketAssignees}
            />
            <h2>Admin - Tickets</h2>
            {error && <ErrorNotice message={error} clearError={() => setError(undefined)} />}

            <div className="ag-theme-alpine">
                <AgGridReact
                    rowData={tickets}
                    columnDefs={columnDefs}
                    frameworkComponents={frameworkComponents}
                    columnTypes={columnTypes}
                    defaultColDef={defaultColDef}
                    defaultColGroupDef={defaultColGroupDef}
                    pagination={true}
                    paginationAutoPageSize={true}
                ></AgGridReact>
            </div>
            <br />
            <AutoButton className="page-button-white" onClick={refresh} text="Aktualisieren" count={60} />
            <button className="page-button-white" onClick={onAddTicket}>
                Neues Ticket
            </button>
        </div>
    );
};

export default AdminTickets;
