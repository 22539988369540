import React, { useState, useContext, useEffect } from 'react';
import Axios from 'axios';
import UserContext from '../../context/UserContext';
import ControlContext from '../../context/ControlContext';
import { useHistory } from 'react-router-dom';
import ErrorNotice from '../misc/ErrorNotice';

import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

import { AgGridReact } from 'ag-grid-react';
import { BtnCellRenderer } from '../admin/BtnCellRenderer';
import EditCellRenderer from '../admin/EditCellRenderer';
import { CheckboxCellRenderer } from '../admin/CheckboxCellRenderer';
import { columnTypes, defaultColDef, defaultColGroupDef } from '../misc/ColumnTypes';

import AdminEditUser from '../admin/AdminEditUser';
import AdminAddUser from '../admin/AdminAddUser';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

import { checkAdmin } from '../account/AccountUtils';

const AdminAccounts = () => {
    const { userData } = useContext(UserContext);
    const { control, setControl } = useContext(ControlContext);
    const history = useHistory();
    const [error, setError] = useState();
    const [users, setUsers] = useState();
    const [showEditUser, setShowEditUser] = useState(false);
    const [showAddUser, setShowAddUser] = useState(false);

    const frameworkComponents = {
        btnCellRenderer: BtnCellRenderer,
        editCellRenderer: EditCellRenderer,
        cbCellRenderer: CheckboxCellRenderer,
    };

    const deleteAccount = async (data) => {
        confirmAlert({
            title: 'Löschen bestätigen',
            message: 'Wollen Sie diesen Account wirklich löschen?.',
            buttons: [
                {
                    label: 'Ja',
                    onClick: () => {},
                },
                {
                    label: 'Nein',
                    onClick: () => {},
                },
            ],
        });
    };

    const editAccount = async (data) => {
        setControl({ ...control, editAccount: data._id });
        setShowEditUser(true);
        //history.push('/adminedituser');
    };

    const saveChanges = async (data) => {
        //console.log(data);
        try {
            let token = localStorage.getItem('auth-token');
            let rights = [];

            const payload = {
                _id: data._id,
                rebate: data.rebateLevel,
                internalNumber: data.internalNumber,
                disabled: data.disabled,
                mustPayBefore: data.mustPayBefore,
                rights: rights,
                minOrderValue: data.minOrderValue,
            };
            await Axios.post(`${process.env.REACT_APP_SERVER}/admin/updateAccount`, payload, { headers: { 'x-auth-token': token } });
        } catch (err) {
            err.response.data.msg && setError(err.response.data.msg);
            err.response.data.msg && console.log(err.response.data.msg);
        }
    };

    const columnDefs = [
        {
            field: 'email',
            headerName: 'Email',
            sortable: true,
            filter: true,
        },
        {
            field: 'internalNumber',
            headerName: 'Kundennummer',
            sortable: true,
            filter: true,
            editable: true,
        },
        {
            field: 'lastName',
            headerName: 'Name',
            sortable: true,
            filter: true,
        },
        {
            field: 'firstName',
            headerName: 'Vorname',
            sortable: true,
            filter: true,
        },
        {
            field: 'rebateLevel',
            headerName: 'Rabatt',
            sortable: true,
            filter: true,
            type: 'numberColumn',
            editable: true,
        },
        {
            field: 'minOrderValue',
            headerName: 'Mindestbestellwert',
            sortable: true,
            filter: true,
            type: 'numberColumn',
            editable: true,
        },
        {
            headerName: 'Disabled',
            field: 'disabled',
            cellRenderer: 'cbCellRenderer',
        },
        {
            headerName: 'Vorkassepflichtig',
            field: 'mustPayBefore',
            cellRenderer: 'cbCellRenderer',
        },
        {
            field: '_id',
            headerName: 'Speichern',
            cellRenderer: 'btnCellRenderer',
            cellRendererParams: {
                clicked: function (field) {
                    saveChanges(field);
                },
                label: 'Save',
            },

            type: 'buttonColumn',
        },
        {
            field: '_id',
            headerName: 'Löschen',
            cellRenderer: 'btnCellRenderer',
            cellRendererParams: {
                clicked: function (field) {
                    deleteAccount(field);
                },
                label: 'Löschen',
            },

            type: 'buttonColumn',
        },
        {
            field: '_id',
            headerName: 'Bearbeiten',
            cellRenderer: 'btnCellRenderer',
            cellRendererParams: {
                clicked: function (field) {
                    editAccount(field);
                },
                label: 'Bearbeiten',
            },

            type: 'buttonColumn',
        },
    ];

    const onCellValueChanged = function (event) {
        console.log('onCellValueChanged: ' + event.colDef.field + ' = ' + event.newValue);
        /*
        if (event.colDef.field === 'rebateLevel') {
            const newUsers = users.map((u) =>
                u.email === event.data.email
                    ? { ...u, rebateLevel: event.newValue }
                    : u
            );
            setUsers(newUsers);
        }
        */
    };

    const addUser = () => {
        history.push('/adminadduser');
    };

    /*
    useEffect(() => {
        console.log(users);
    }, [users]);
*/
    const init = async () => {
        let token = localStorage.getItem('auth-token');
        const res = await Axios.get(`${process.env.REACT_APP_SERVER}/admin/accounts/`, { headers: { 'x-auth-token': token } });
        res.data.users.forEach((user) => {
            user.admin = user.rights.includes('admin');
        });

        setUsers(res.data.users);
    };

    const checkLoggedIn = async () => {
        try {
            checkAdmin(userData, history);
        } catch (err) {
            err.response.data.msg && setError(err.response.data.msg);
        }
    };

    useEffect(() => {
        checkLoggedIn();
        init();
    }, []);

    return (
        <div className="overview">
            <AdminEditUser showModal={showEditUser} setShowModal={setShowEditUser} />
            <AdminAddUser showModal={showAddUser} setShowModal={setShowAddUser} />
            <div className="page">
                <h2>Admin - Accountverwaltung</h2>
                {error && <ErrorNotice message={error} clearError={() => setError(undefined)} />}
            </div>

            <div className="ag-theme-alpine">
                <AgGridReact
                    rowData={users}
                    columnDefs={columnDefs}
                    frameworkComponents={frameworkComponents}
                    columnTypes={columnTypes}
                    defaultColDef={defaultColDef}
                    defaultColGroupDef={defaultColGroupDef}
                    pagination={true}
                    paginationAutoPageSize={true}
                    onCellValueChanged={onCellValueChanged}
                ></AgGridReact>
            </div>
            <div>
                <button className="page-button-action" onClick={() => setShowAddUser(true)}>
                    Nutzer hinzufügen
                </button>
            </div>
        </div>
    );
};

export default AdminAccounts;
