import React, { useState, useContext, useEffect } from 'react';
import Axios from 'axios';
import UserContext from '../../context/UserContext';
import ControlContext from '../../context/ControlContext';
import { useHistory } from 'react-router-dom';
import ErrorNotice from '../misc/ErrorNotice';
import AdressItem from '../account/AdressItem';
import Modal from 'react-modal';
import { confirmAlert } from 'react-confirm-alert';
import AddAdress from '../account/AddAdress';
import EditAdress from '../account/EditAdress';

const AdressList = () => {
    const { userData, setUserData } = useContext(UserContext);
    const { control, setControl } = useContext(ControlContext);
    const history = useHistory();
    const [error, setError] = useState();
    const [showAdress, setShowAdress] = useState(false);
    const [showEditAdress, setShowEditAdress] = useState(false);
    const [adresses, setAdresses] = useState();

    useEffect(() => {
        const checkLoggedIn = async () => {
            updateAdressList();
        };
        checkLoggedIn();
    }, [history, setUserData]);

    const updateAdressList = async () => {
        let token = localStorage.getItem('auth-token');
        if (token === null) {
            localStorage.setItem('auth-token', '');
            token = '';
        }

        const tokenRes = await Axios.post(`${process.env.REACT_APP_SERVER}/users/tokenIsValid`, null, { headers: { 'x-auth-token': token } });

        if (tokenRes.data) {
            const userRes = await Axios.get(`${process.env.REACT_APP_SERVER}/users/adresses/`, { headers: { 'x-auth-token': token } });
            setAdresses(userRes.data.adresses);
            setUserData({
                ...userData,
                adresses: userRes.data.adresses,
            });
        } else {
            history.push('/login');
        }
    };

    const addAdress = () => {
        setShowAdress(true);
    };

    const modalClose = () => {
        setShowAdress(false);
    };

    const modalClose2 = () => {
        setShowEditAdress(false);
        updateAdressList();
    };

    const handleSubmit = (e) => {
        setShowAdress(false);
        updateAdressList();
    };

    const deleteAdress = (_id) => {
        confirmAlert({
            title: 'Löschen bestätigen',
            message: 'Wollen Sie diese Adresse wirklich löschen?',
            buttons: [
                {
                    label: 'Ja',
                    className: 'alert-button-grey',
                    onClick: async () => {
                        try {
                            let token = localStorage.getItem('auth-token');
                            const payload = { _id };
                            const res = await Axios.post(`${process.env.REACT_APP_SERVER}/users/deleteAdress`, payload, {
                                headers: { 'x-auth-token': token },
                            });
                            setAdresses(res.data.adresses);
                            setUserData({
                                ...userData,
                                adresses: res.data.adresses,
                            });
                        } catch (err) {
                            err.response.data.msg && setError(err.response.data.msg);
                        }
                    },
                },
                {
                    label: 'Nein',
                    className: 'alert-button-grey',
                    onClick: () => {},
                },
            ],
        });
    };

    const makeMain = (_id) => {
        confirmAlert({
            title: 'Als Hauptadresse markieren',
            message: 'Diese Adresse als Hauptadresse wählen?',
            buttons: [
                {
                    label: 'Ja',
                    className: 'alert-button-grey',
                    onClick: async () => {
                        try {
                            let token = localStorage.getItem('auth-token');
                            const payload = { _id };
                            await Axios.post(`${process.env.REACT_APP_SERVER}/users/makeMainAdress`, payload, {
                                headers: { 'x-auth-token': token },
                            });

                            updateAdressList();
                        } catch (err) {
                            err.response.data.msg && setError(err.response.data.msg);
                        }
                    },
                },
                {
                    label: 'Nein',
                    className: 'alert-button-grey',
                    onClick: () => {},
                },
            ],
        });
    };

    const editAdress = async (_id) => {
        setControl({
            ...control,
            editAdress: _id,
        });
        setShowEditAdress(true);
        //history.push('/editAdress');
    };

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '90%',
            maxWidth: '1000px',
            height: '60%',
        },
    };

    return (
        <div>
            {/*
            <div id="info-box">
                <InfoAddress />
                
            </div>*/}
            {error && <ErrorNotice message={error} clearError={() => setError(undefined)} />}
            <div>
                <Modal isOpen={showAdress} onRequestClose={(e) => modalClose(e)} contentLabel="Adresse hinzufügen" style={customStyles}>
                    <div className="modalHeader">
                        <div>
                            <h2>Adresse hinzufügen</h2>
                        </div>
                        <div>
                            <button id="buttonX" onClick={() => modalClose()}>
                                &#10006;
                            </button>
                        </div>
                    </div>
                    <div className="modalBody">
                        <AddAdress onSubmit={handleSubmit} />
                    </div>
                </Modal>
                <Modal isOpen={showEditAdress} onRequestClose={(e) => modalClose2(e)} contentLabel="Adresse bearbeiten" style={customStyles}>
                    <div className="modalHeader">
                        <div>
                            <h2>Adresse bearbeiten</h2>
                        </div>
                        <div>
                            <button id="buttonX" onClick={() => modalClose2()}>
                                &#10006;
                            </button>
                        </div>
                    </div>
                    <div className="modalBody">
                        <EditAdress onSubmit={modalClose2} />
                    </div>
                </Modal>
                <ul id="adress-list">
                    {adresses &&
                        adresses.length > 0 &&
                        adresses.map((adress) => (
                            <li key={adress.uuid}>
                                <AdressItem adress={adress} deleteAdress={deleteAdress} editAdress={editAdress} makeMain={makeMain} />
                            </li>
                        ))}
                </ul>
                <div id="button-container-left">
                    <button className="page-button-left" onClick={addAdress}>
                        Adresse hinzufügen
                    </button>
                </div>
            </div>
        </div>
    );
};

export default AdressList;
