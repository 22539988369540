export const AdminMenuItems = [
    {
        title: 'Logs',
        path: '/adminlogs',
        cName: 'dropdown-link',
        page: 'adminlogs',
    },
    {
        title: 'Dashboard',
        path: '/admin',
        cName: 'dropdown-link',
        page: 'dashboard',
    },
];

export const SuperAdminMenuItems = [
    {
        title: 'Einstellungen',
        path: '/adminformulas',
        cName: 'dropdown-link',
        page: 'settings',
    },
    {
        title: 'Dashboard',
        path: '/admin',
        cName: 'dropdown-link',
        page: 'dashboard',
    },
    {
        title: 'Rechtevergabe',
        path: '/adminaccountrights',
        cName: 'dropdown-link',
        page: 'adminaccountrights',
    },
    {
        title: 'Emails',
        path: '/adminformulas',
        cName: 'dropdown-link',
        page: 'emails',
    },
    {
        title: 'Verfahren',
        path: '/adminformulas',
        cName: 'dropdown-link',
        page: 'method',
    },
    {
        title: 'Materials',
        path: '/adminformulas',
        cName: 'dropdown-link',
        page: 'material',
    },
    {
        title: 'Nachbearbeitung',
        path: '/adminformulas',
        cName: 'dropdown-link',
        page: 'processing',
    },
    {
        title: 'Färbung',
        path: '/adminformulas',
        cName: 'dropdown-link',
        page: 'color',
    },
    {
        title: 'Infill',
        path: '/adminformulas',
        cName: 'dropdown-link',
        page: 'infill',
    },
    {
        title: 'Lieferarten',
        path: '/adminformulas',
        cName: 'dropdown-link',
        page: 'delivery',
    },
    {
        title: 'Zahlungsarten',
        path: '/admineditpayments',
        cName: 'dropdown-link',
        page: 'payments',
    },
    {
        title: 'Länder',
        path: '/admineditcountry',
        cName: 'dropdown-link',
        page: 'countries',
    },
    {
        title: 'Order Stati',
        path: '/admineditorderstatus',
        cName: 'dropdown-link',
        page: 'orderstatus',
    },
    {
        title: 'Logs',
        path: '/adminlogs',
        cName: 'dropdown-link',
        page: 'adminlogs',
    },
];
