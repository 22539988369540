import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import ErrorNotice from '../misc/ErrorNotice';

import { AgGridReact } from 'ag-grid-react';
import { BtnCellRenderer } from '../admin/BtnCellRenderer';
import EditCellRenderer from '../admin/EditCellRenderer';
import { CheckboxCellRenderer } from '../admin/CheckboxCellRenderer';

import { columnTypes, defaultColDef, defaultColGroupDef } from '../misc/ColumnTypes';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

const AdminEditCountry = () => {
    const [error, setError] = useState();
    const [countries, setCountries] = useState([]);

    const frameworkComponents = {
        btnCellRenderer: BtnCellRenderer,
        editCellRenderer: EditCellRenderer,
        cbCellRenderer: CheckboxCellRenderer,
    };

    const columnDefs = [
        {
            field: 'name',
            headerName: 'Name',
            sortable: true,
            filter: true,
        },
        {
            field: 'identifier',
            headerName: 'Kennung',
            sortable: true,
            filter: true,
        },
        {
            field: 'isoCode',
            headerName: 'ISO Code',
            sortable: true,
            filter: true,
        },

        {
            field: 'isEU',
            headerName: 'EU',
            sortable: true,
            filter: true,
            sort: 'desc',
            cellRenderer: 'cbCellRenderer',
        },
        {
            field: 'vat',
            headerName: 'MwSt.',
            sortable: true,
            filter: true,
            type: 'numberColumn',
        },
        {
            field: 'vatText1',
            headerName: 'MwSt. Text 1',
            sortable: true,
            filter: true,
        },
        {
            field: 'vatText2',
            headerName: 'MwSt. Text 2',
            sortable: true,
            filter: true,
        },
        {
            field: 'isHidden',
            headerName: 'Hidden',
            sortable: true,
            filter: true,
            sort: 'desc',
            cellRenderer: 'cbCellRenderer',
        },
        {
            field: '_id',
            headerName: 'Speichern',
            cellRenderer: 'btnCellRenderer',
            cellRendererParams: {
                clicked: function (field) {
                    saveChanges(field);
                },
                label: 'Save',
            },

            type: 'buttonColumn',
        },
    ];

    const AddCountry = async () => {
        let token = localStorage.getItem('auth-token');
        const payload = {
            name: 'Neuland',
        };
        try {
            const res = await Axios.post(`${process.env.REACT_APP_SERVER}/admin/addcountry`, payload, { headers: { 'x-auth-token': token } });
            if (res.data && res.data.name) {
                setCountries([...countries, res.data]);
            }
        } catch (err) {
            err.response.data && err.response.data.msg && setError(err.response.data.msg);
        }
    };

    const saveChanges = async (data) => {
        console.log(data);
        try {
            let token = localStorage.getItem('auth-token');

            const payload = {
                _id: data._id,
                name: data.name,
                isEU: data.isEU,
                isHidden: data.isHidden,
                vat: data.vat,
                vatText1: data.vatText1,
                vatText2: data.vatText2,
                identifier: data.identifier,
                isoCode: data.isoCode,
            };
            await Axios.post(`${process.env.REACT_APP_SERVER}/admin/savecountry`, payload, { headers: { 'x-auth-token': token } });
        } catch (err) {
            err.response.data.msg && setError(err.response.data.msg);
            err.response.data.msg && console.log(err.response.data.msg);
        }
    };

    useEffect(() => {
        const getOrderStatuss = async () => {
            try {
                let token = localStorage.getItem('auth-token');
                const res = await Axios.get(`${process.env.REACT_APP_SERVER}/admin/countries/`, { headers: { 'x-auth-token': token } });
                if (res) {
                    setCountries(res.data.countries);
                }
            } catch (err) {
                err.response.data.msg && setError(err.response.data.msg);
            }
        };

        getOrderStatuss();
    }, [setError]);

    return (
        <div className="overview">
            <div className="page">
                <h2>Admin - Länder</h2>
                {error && <ErrorNotice message={error} clearError={() => setError(undefined)} />}
            </div>
            <div>
                <button className="page-button-white center" onClick={AddCountry}>
                    Land hinzufügen
                </button>
            </div>
            <br />
            <div className="ag-theme-alpine">
                <AgGridReact
                    rowData={countries}
                    columnDefs={columnDefs}
                    frameworkComponents={frameworkComponents}
                    columnTypes={columnTypes}
                    defaultColDef={defaultColDef}
                    defaultColGroupDef={defaultColGroupDef}
                    pagination={true}
                    paginationAutoPageSize={true}
                ></AgGridReact>
            </div>
        </div>
    );
};

export default AdminEditCountry;
