import React, { useState, useContext, useEffect } from 'react';
import Axios from 'axios';
import UserContext from '../../context/UserContext';
import ControlContext from '../../context/ControlContext';
import { useHistory } from 'react-router-dom';
import Modal from 'react-modal';
import download from 'downloadjs';
import ErrorNotice from '../misc/ErrorNotice';
import { AgGridReact } from 'ag-grid-react';
import { BtnCellRenderer } from './BtnCellRenderer';
import { CheckboxCellRenderer, CheckboxCellRendererRO } from '../admin/CheckboxCellRenderer';
import { columnTypes, defaultColDef, defaultColGroupDef } from '../misc/ColumnTypes';
import InvoiceUpload from '../admin/InvoiceUpload';

import { checkAdmin } from '../account/AccountUtils';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

const AdminAccounting = () => {
    const { userData } = useContext(UserContext);
    const { control, setControl } = useContext(ControlContext);
    const history = useHistory();
    const [error, setError] = useState();
    const [orders, setOrders] = useState();
    const [showUpload, setShowUpload] = useState(false);
    const [order, setOrder] = useState({});

    const [marked, setMarked] = useState([]);
    const [markedNames, setMarkedNames] = useState([]);

    const frameworkComponents = {
        btnCellRenderer: BtnCellRenderer,
        cbCellRenderer: CheckboxCellRenderer,
        cbCellRendererRO: CheckboxCellRendererRO,
    };

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
        },
    };

    const columnDefs = [
        {
            headerName: 'Markieren',
            field: 'marked',
            cellRenderer: 'cbCellRenderer',
            onCellClicked: (params) => {
                changeSelection(params.data);
            },
        },
        {
            field: 'IDS',
            headerName: 'Bestellnummer',
            sortable: true,
            filter: true,
            onCellClicked: (params) => {
                viewOrder(params.data._id);
            },
            sort: 'desc',
            suppressMenu: true,
            editable: false,
        },
        {
            field: 'statusDisplayName',
            headerName: 'Status',
            sortable: true,
            filter: true,
            suppressMenu: true,
            editable: false,
        },
        {
            field: 'customerID.email',
            headerName: 'Account',
            sortable: true,
            filter: true,
            onCellClicked: (params) => {
                viewAccount(params.data.customerID?._id);
            },
            editable: false,
        },
        {
            field: 'invoiceAdress.faName',
            headerName: 'Firma',
            sortable: true,
            filter: true,
            editable: false,
        },
        {
            headerName: 'Alles verpackt',
            field: 'allPacked',
            cellRenderer: 'cbCellRendererRO',
            editable: false,
        },
        {
            headerName: 'Rechnung hochgeladen',
            field: 'invoiceUploaded',
            cellRenderer: 'cbCellRendererRO',
            editable: false,
        },
        {
            headerName: 'Bezahlt',
            field: 'paid',
            cellRenderer: 'cbCellRenderer',
            editable: false,
        },
        {
            field: '_id',
            headerName: 'Speichern',
            cellRenderer: 'btnCellRenderer',
            cellRendererParams: {
                clicked: function (field) {
                    saveChanges(field);
                },
                label: 'Save',
            },
            editable: false,
            filter: false,

            type: 'buttonColumn',
        },
        {
            field: '_id',
            headerName: 'Rechnungen',
            cellRenderer: 'btnCellRenderer',
            cellRendererParams: {
                clicked: function (field) {
                    uploadInvoice(field);
                },
                label: 'Upload',
            },
            editable: false,
            filter: false,

            type: 'buttonColumn',
        },
    ];

    const uploadInvoice = async (data) => {
        console.log(data);
        setOrder(data);
        setShowUpload(true);
    };

    const modalClose = () => {
        setShowUpload(false);
    };

    const onDownloadXML = async () => {
        try {
            let token = localStorage.getItem('auth-token');
            const config = {
                responseType: 'blob',
                headers: { 'x-auth-token': token, 'Content-type': 'text/xml; charset=utf-8' },
                params: { selected: marked },
            };
            const fileName = `lieferschein_${markedNames.join('_')}.xml`;

            Axios.get(`${process.env.REACT_APP_SERVER}/admin/deliverynotexml`, config).then((res) => {
                download(res.data, fileName);
            });
        } catch (err) {
            console.log(err);
            err.response && err.response.data && err.response.data.msg && setError(err.response.data.msg);
        }
    };

    const changeSelection = (data) => {
        if (data.marked && data.marked === true) {
            if (marked.indexOf(data._id) === -1) {
                marked.push(data._id);
                markedNames.push(data.IDS);
            }
        } else {
            const index = marked.indexOf(data._id);
            if (index > -1) {
                marked.splice(index, 1);
                markedNames.splice(index, 1);
            }
        }
        setMarked(marked);
        setMarkedNames([...markedNames]);
    };

    const saveChanges = async (data) => {
        console.log(data);
        try {
            let token = localStorage.getItem('auth-token');

            const payload = {
                _id: data._id,
                paid: data.paid,
            };
            await Axios.post(`${process.env.REACT_APP_SERVER}/admin/updateorderpaid`, payload, { headers: { 'x-auth-token': token } });
        } catch (err) {
            err.response.data.msg && setError(err.response.data.msg);
            err.response.data.msg && console.log(err.response.data.msg);
        }
    };

    const viewOrder = async (_id) => {
        setControl({
            ...control,
            viewOrder: _id,
        });

        history.push('/viewOrder');
    };

    const viewAccount = async (_id) => {
        setControl({ ...control, editAccount: _id });

        history.push('/adminedituser');
    };

    const init = async () => {
        let token = localStorage.getItem('auth-token');

        const res = await Axios.get(`${process.env.REACT_APP_SERVER}/admin/orders/`, { headers: { 'x-auth-token': token } });
        setOrders(res.data.orders);
    };

    const checkLoggedIn = async () => {
        try {
            checkAdmin(userData, history);
        } catch (err) {
            err.response.data.msg && setError(err.response.data.msg);
        }
    };

    useEffect(() => {
        checkLoggedIn();
        init();
    }, []);

    return (
        <>
            <div className="overview">
                <Modal isOpen={showUpload} onRequestClose={(e) => modalClose(e)} contentLabel="Rechnungen" style={customStyles}>
                    <InvoiceUpload setError={setError} order={order} updateList={init} setShowUpload={setShowUpload} mode="order" />
                </Modal>
                <h2>Admin - Buchhaltung</h2>
                {error && <ErrorNotice message={error} clearError={() => setError(undefined)} />}

                <div className="ag-theme-alpine">
                    <AgGridReact
                        rowData={orders}
                        columnDefs={columnDefs}
                        frameworkComponents={frameworkComponents}
                        columnTypes={columnTypes}
                        defaultColDef={defaultColDef}
                        defaultColGroupDef={defaultColGroupDef}
                        pagination={true}
                        paginationAutoPageSize={true}
                    ></AgGridReact>
                </div>
                <button className="page-button-white" onClick={() => init()}>
                    Aktualisieren
                </button>
                <button className="page-button-white" onClick={() => onDownloadXML()}>
                    Lieferschein XML
                </button>
            </div>
        </>
    );
};

export default AdminAccounting;
