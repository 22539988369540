import React, { useState, useCallback, useContext } from 'react';
import { useDropzone } from 'react-dropzone';
import { v4 as uuidv4 } from 'uuid';
import FileContext from '../../context/FileContext';

import '../../style/dropzone.css';

const FileDropzone = ({ setError }) => {
    const maxSize = 104857600;
    const maxSizeText = '100 MB';

    const { files, setFiles } = useContext(FileContext);
    const [fileState, setFileState] = useState();
    const [unit, setUnit] = useState('mm');

    const onDrop = useCallback(
        (acceptedFiles, rejectedFiles) => {
            rejectedFiles.forEach((file) => {
                console.log(file);
                if (file.file.size > maxSize) {
                    console.log('file too large');
                    setError(`Datei ${file.file.name} mit ${file.file.size} Bytes ist zu groß. Maximal erlaubte Größe: ${maxSizeText}`);
                } else {
                    setError(`Datei ${file.file.name} hat falschen Dateityp.`);
                }
            });
            acceptedFiles.forEach((file) => {
                /*
                if (file.type === 'application/pdf') {
                    console.log('PDF detected');
                }

                if (file.type === 'image/jpeg') {
                    console.log('Image jpg detected');
                }

                if (file.type === 'image/tiff') {
                    console.log('Image tiff detected');
                }

                if (file.type === '') {
                    console.log('no file type detected');
                }*/
                file.uuid = uuidv4();
                file.unit = unit;
                
                file.tasks = [];
                file.prices = [
                    {
                        amount: '1',
                        price: 0.0,
                        colorPrice: 0.0,
                        procPrice: 0.0,
                        selected: false,
                    },
                ];
            });

            setFiles([...files, ...acceptedFiles]);
            setFileState('file selected');
        },
        [files, setFiles, unit]
    );
    const { getRootProps, getInputProps, rejectedFiles } = useDropzone({
        onDrop,
        accept: {
            'file/stl':['.stl'], 
            'file/step':['.step', '.stp'], 
            'file/pdf':['.pdf'], 
            'image/jpeg':['.jpg', '.jpeg'], 
            'image/tiff':['.tiff']
        },
        minSize: 0,
        maxSize,
    });

    //const isFileTooLarge = rejectedFiles && rejectedFiles.length > 0 && rejectedFiles[0].size > maxSize;

    const onRadioChange = (e) => {
        setUnit(e.target.value);
        console.log(unit);
    };

    return (
        <>
            <div id="dropzone-outer">
                <div id="dropzone-radios">
                    <div>
                        <table>
                            <tbody onChange={onRadioChange}>
                                <tr>
                                    <td>In welcher Einheit haben Sie Ihre Daten konstruiert?</td>

                                    <td>
                                        <label>
                                            <input
                                                type="radio"
                                                value="mm"
                                                name="measurement"
                                                onChange={() => {}}
                                                checked={unit === 'mm' ? 'checked' : null}
                                            />
                                            mm
                                        </label>
                                    </td>
                                    <td>
                                        <label>
                                            <input
                                                type="radio"
                                                value="cm"
                                                name="measurement"
                                                onChange={() => {}}
                                                checked={unit === 'cm' ? 'checked' : null}
                                            />
                                            cm
                                        </label>
                                    </td>
                                    <td>
                                        <label>
                                            <input
                                                type="radio"
                                                value="zoll"
                                                name="measurement"
                                                onChange={() => {}}
                                                checked={unit === 'zoll' ? 'checked' : null}
                                            />
                                            zoll
                                        </label>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div id="drop-zone" {...getRootProps()}>
                    <input {...getInputProps()} />
                    <p>
                        3D-Daten im STL- oder STEP-Format in diese Fl&auml;che ziehen
                        <br />
                        (Datenmenge pro Datei bis 64 MB)
                    </p>
                    <div id="drop-zone-button">Datei auswählen</div>
                    <p>
                        Zeichnungen der Bauteile bitte als PDF oder JPEG
                        
                    </p>
                </div>
            </div>
        </>
    );
};

export default FileDropzone;
