import React, { useState, useContext, useEffect } from 'react';
import Axios from 'axios';
//import Modal from 'react-modal';
import date from 'date-and-time';
import UserContext from '../../context/UserContext';
import ControlContext from '../../context/ControlContext';
import { useHistory } from 'react-router-dom';
import ErrorNotice from '../misc/ErrorNotice';
import AdditionalInfoVO from '../misc/AdditionalInfoVO';
import FileDetailsViewOrder from '../file/FileDetailsViewOrder';
import FileDetailsViewOrderAdmin from '../file/FileDetailsViewOrderAdmin';
import FileTaskViewOrder from '../file/FileTaskViewOrder';
import OrderSummaryReadOnly from '../misc/OrderSummaryReadOnly';
import DeliveryPage from '../admin/DeliveryPage';

import AdminOrderTools from '../admin/AdminOrderTools';
import Documents from '../admin/Documents';
import InfoViewOrder from '../misc/InfoViewOrder';

const ViewOrder = () => {
    const { userData } = useContext(UserContext);
    const { control, setControl } = useContext(ControlContext);
    const history = useHistory();
    const [error, setError] = useState();
    const [order, setOrder] = useState();
    const [chatMsg, setChatMsg] = useState();
    const [chatMessages, setChatMessages] = useState([]);
    const [projectID, setProjectID] = useState();
    const [inquiryID, setInquiryID] = useState();
    const [orderID, setOrderID] = useState();
    const [showUpload, setShowUpload] = useState(false);
    const [refresh, setRefresh] = useState(0);
    const [showDelivery, setShowDelivery] = useState(false);

    const [_id] = useState(control.viewOrder);

    const [orderStatus, setOrderStatus] = useState('not set');
    const [orderStatusDisp, setOrderStatusDisp] = useState('not set');
    const [trackingNumbers, setTrackingNumbers] = useState('');

    const SendMsg = async () => {
        const now = new Date();
        const timeString = date.format(now, 'DD.MM.YYYY HH:mm:ss');

        const chatName = userData.user.chatName || `${userData.user.firstName} ${userData.user.lastName}`;

        const cm = {
            msg: chatMsg,
            time: timeString,
            user: chatName,
        };

        let token = localStorage.getItem('auth-token');
        await Axios.post(`${process.env.REACT_APP_SERVER}/files/addordermsg`, { _id: order._id, msg: cm }, { headers: { 'x-auth-token': token } });

        setChatMessages([...chatMessages, cm]);
        console.log(chatMessages);
        setChatMsg('');
    };

    const updateIDs = async () => {
        let token = localStorage.getItem('auth-token');
        await Axios.post(
            `${process.env.REACT_APP_SERVER}/files/updateorderids`,
            { _id: order._id, IDS: order.IDS, projectID: projectID, inquiryID: inquiryID },
            { headers: { 'x-auth-token': token } }
        );
    };

    const getOrder = async () => {
        try {
            let token = localStorage.getItem('auth-token');
            let res = await Axios.post(`${process.env.REACT_APP_SERVER}/files/order`, { _id: _id }, { headers: { 'x-auth-token': token } });

            setInquiryID(res.data.inquiryID);
            setProjectID(res.data.projectID);
            setOrderID(res.data.user_orderID);
            setChatMessages(res.data.messages);
            setOrderStatus(res.data.status);
            setOrderStatusDisp(res.data.statusDisplayName);
            setChatMessages(res.data.messages);
            setOrder(res.data);

            const pres = await Axios.post(
                `${process.env.REACT_APP_SERVER}/files/packages`,
                { orderID: _id },
                {
                    headers: { 'x-auth-token': token },
                }
            );
            let tn = '';

            if (pres.data.msg === 'OK' && pres.data.packages?.length > 0) {
                pres.data.packages.forEach((pack, index) => {
                    tn += pack.dhlTrackingNumber;
                    if (index < pres.data.packages?.length - 1) {
                        tn += ', ';
                    }
                });
            }

            setTrackingNumbers(tn);
        } catch (err) {
            setError(err.message);
        }
    };

    useEffect(() => {
        if (control.maintenance) {
            history.push('/maintenance');
        }
        const checkLoggedIn = async () => {
            let token = localStorage.getItem('auth-token');
            if (token === null) {
                localStorage.setItem('auth-token', '');
                token = '';
            }

            const tokenRes = await Axios.post(`${process.env.REACT_APP_SERVER}/users/tokenIsValid`, null, { headers: { 'x-auth-token': token } });

            if (tokenRes.data) {
                await getOrder();
            } else {
                history.push('/login');
            }
        };
        checkLoggedIn();
    }, [history, _id, refresh]);

    const onDeliveryButton = () => {
        console.log('huhu');
        setControl({
            ...control,
            viewOrder: order._id,
        });
        setShowDelivery(true);
        //history.push('/delivery');
    };

    return (
        <>
            <DeliveryPage showModal={showDelivery} setShowModal={setShowDelivery} />
            <div className="container">
                
                    <div id="info-area">
                        <div className="page-title">
                            <h2>Bestellung: {order?.IDS}</h2>
                            <hr className="solid"></hr>
                        </div>
                        <InfoViewOrder />
                        {error && <ErrorNotice message={error} clearError={() => setError(undefined)} />}
                    </div>

                {/*
            <Modal isOpen={showUpload} onRequestClose={(e) => modalClose(e)} contentLabel="Dokumente" style={customStyles}>
                <Documents setError={setError} order={order} setShowUpload={setShowUpload} mode="order" />
            </Modal>*/}
            <div id="file-list">
                {order ? (
                    <AdditionalInfoVO
                        readOnly={true}
                        projectID={projectID}
                        inquiryID={inquiryID}
                        orderID={orderID}
                        status={orderStatus}
                        statusDisp={orderStatusDisp}
                        updateIDs={updateIDs}
                        isOrder={true}
                        trackingNumbers={trackingNumbers}
                    />
                ) : null}
                {order && userData.rights.includes('admin') ? (
                    <>
                        <div id="footer-container">
                            <div id="footer-inner-container">
                                <AdminOrderTools
                                    order={order}
                                    setError={setError}
                                    orderStatus={orderStatus}
                                    setOrderStatus={setOrderStatus}
                                    setOrderStatusDisp={setOrderStatusDisp}
                                    items={order.items}
                                    onDeliveryButton={onDeliveryButton}
                                />
                            </div>
                        </div>

                        <div className="item-column">
                            {order &&
                                order.items.length > 0 &&
                                order.items.map((item, pos) => (
                                    <FileDetailsViewOrderAdmin key={item._id} item={item} isOrder={true} position={pos} tasks={item.tasks} />
                                ))}
                        </div>
                    </>
                ) : (
                    <div className="item-column">
                        {order &&
                            order.items?.length > 0 &&
                            order.items?.map((item, pos) => <FileDetailsViewOrder key={item._id} item={item} isOrder={true} position={pos} />)}
                    </div>
                )}
                <div className="item-column">
                    {order &&
                        order.tasks.length > 0 &&
                        order.tasks.map((task, pos) => <FileTaskViewOrder key={task.uuid} position={order.items.length + pos + 1} task={task} />)}
                </div>
                <div className="item-column">
                    <div id="footer-inner-container">
                        <Documents setError={setError} order={order} setShowUpload={setShowUpload} mode="order" setRefresh={setRefresh} />
                    </div>
                </div>

                <div id="footer-container">
                    <div id="footer-inner-container">
                        {order ? (
                            <OrderSummaryReadOnly
                                summary={order.summary}
                                deliveryAdress={order.deliveryAdress}
                                invoiceAdress={order.invoiceAdress}
                                deliveryMethod={order.deliveryMethod}
                                paymentMethod={order.paymentMethod}
                            />
                        ) : null}
                        <div id="chat-order-footer">
                            <div id="chat-column-1">
                                <label className="label-height-2-white">Nachrichten zum Auftrag:</label>
                                <input
                                    id="chat-input"
                                    type="text"
                                    value={chatMsg}
                                    onChange={(e) => setChatMsg(e.currentTarget.value)}
                                    className="chat-input"
                                    placeholder="Schreiben Sie uns eine Nachricht"
                                ></input>
                                <br />
                                <div id="button-container">
                                    <button className="chat-button-white" onClick={SendMsg}>
                                        Senden
                                    </button>
                                    {/*
                                        <button className="page-button-white" onClick={onDocuments}>
                                            Meine Dokumente
                                        </button>*/}
                                </div>
                            </div>
                            <div id="chat-column-2">
                                <div className="chat-column">
                                    <label className="label-height-2">Nachrichtenverlauf:</label>
                                    {order ? (
                                        <>
                                            <div className="chat-messages">
                                                {chatMessages.length > 0 &&
                                                    chatMessages.map((msg) => (
                                                        <>
                                                            <label className="chat-message-time">
                                                                &nbsp;
                                                                {msg.time}
                                                            </label>
                                                            <label className="chat-message-user">
                                                                &nbsp;
                                                                {msg.user}
                                                            </label>
                                                            :
                                                            <label className="chat-message-msg">
                                                                &nbsp;
                                                                {msg.msg}
                                                            </label>
                                                            <br />
                                                        </>
                                                    ))}
                                            </div>
                                        </>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </>
    );
};

export default ViewOrder;
