import { NumericFormat } from 'react-number-format';;

const OrderSummaryReadOnly = ({ summary, deliveryAdress, invoiceAdress, deliveryMethod, paymentMethod }) => {
    return (
        <>
            <div className="order-summary">
                <div className="order-summary-column-ro">
                    {deliveryAdress ? (
                        <>
                            <label className="label-height-2">
                                <strong>Lieferadresse</strong>
                            </label>
                            {deliveryAdress.faName}
                            <br />
                            {deliveryAdress.dpt}
                            <br />
                            {deliveryAdress.firstName} {deliveryAdress.lastName}
                            <br />
                            {deliveryAdress.street}
                            <br />
                            {deliveryAdress.zip} {deliveryAdress.city}
                            <br />
                        </>
                    ) : null}
                </div>
                <div className="order-summary-column-ro">
                    {invoiceAdress ? (
                        <>
                            <label className="label-height-2">
                                <strong>Rechnungsadresse</strong>
                            </label>
                            {invoiceAdress.faName}
                            <br />
                            {invoiceAdress.dpt}
                            <br />
                            {invoiceAdress.firstName} {invoiceAdress.lastName}
                            <br />
                            {invoiceAdress.street}
                            <br />
                            {invoiceAdress.zip} {invoiceAdress.city}
                            <br />
                        </>
                    ) : null}
                </div>
                <div className="order-summary-column-ro">
                    {deliveryMethod ? (
                        <>
                            <label className="label-height-2">
                                <strong>Versandart:</strong>
                            </label>
                            {deliveryMethod.name}
                            <label className="label-height-2">
                                <strong>Zahlungsart:</strong>
                            </label>
                            {paymentMethod.displayName}
                        </>
                    ) : null}
                </div>
                <div className="order-summary-column-ro">
                    <label className="label-height-2">
                        <strong>Kosten</strong>
                    </label>
                    <table id="order-summary-table-ro">
                        <tbody>
                            <tr>
                                <td>Netto:</td>
                                <td>
                                    <NumericFormat
                                        value={summary.netto}
                                        displayType={'text'}
                                        suffix={' €'}
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        thousandSeparator={'.'}
                                        decimalSeparator={','}
                                    />
                                </td>
                            </tr>
                            {summary.surcharge > 0 ? (
                                <tr>
                                    <td>Zuschlag:</td>
                                    <td>
                                        <NumericFormat
                                            value={summary.surcharge}
                                            displayType={'text'}
                                            suffix={' €'}
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                            thousandSeparator={'.'}
                                            decimalSeparator={','}
                                        />
                                    </td>
                                </tr>
                            ) : null}
                            <tr>
                                <td>Versandkosten:</td>
                                <td>
                                    <NumericFormat
                                        value={summary.delivery}
                                        displayType={'text'}
                                        suffix={' €'}
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        thousandSeparator={'.'}
                                        decimalSeparator={','}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>MwSt:</td>
                                <td>
                                    <NumericFormat
                                        value={summary.mwst}
                                        displayType={'text'}
                                        suffix={' €'}
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        thousandSeparator={'.'}
                                        decimalSeparator={','}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>Brutto gesamt:</td>
                                <td>
                                    <NumericFormat
                                        value={summary.brutto}
                                        displayType={'text'}
                                        suffix={' €'}
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        thousandSeparator={'.'}
                                        decimalSeparator={','}
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <label className="label-height-2-white">Vorraussichtliche Produktionszeit: {summary.totalTime} Tage</label>
            <br />
            <label style={{ color: 'white' }}>Vorraussichtlicher Versandtermin: {summary.sendDate}</label>
            <br />
        </>
    );
};

export default OrderSummaryReadOnly;
