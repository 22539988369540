import React, { useState, useEffect, useContext } from 'react';
import Axios from 'axios';
import Modal from 'react-modal';
import { useHistory } from 'react-router-dom';
import ControlContext from '../../context/ControlContext';
import ErrorNotice from '../misc/ErrorNotice';

export default function AdminEditUser({ showModal, setShowModal }) {
    const { control, setControl } = useContext(ControlContext);

    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [passwordCheck, setPasswordCheck] = useState();
    const [firstName, setFirstName] = useState();
    const [lastName, setLastName] = useState();
    const [salut, setSalut] = useState('Herr');
    const [saluts] = useState(['Herr', 'Frau']);
    const [error, setError] = useState();
    const [internalNumber, setInternalNumber] = useState();

    const [disabled, setDisabled] = useState(false);
    const [disabledText, setDisabledText] = useState();

    const [mustPayBefore, setMustPayBefore] = useState(false);
    const [useSkonto, setUseSkonto] = useState(false);
    const [skontoLevel, setSkontoLevel] = useState(0.0);
    const [paymentDays, setPaymentDays] = useState(12);
    const [paymentDaysSkonto, setPaymentDaysSkonto] = useState(12);
    const [rebateLevel, setRebateLevel] = useState(0.0);

    const [faName, setFaName] = useState();
    const [street, setStreet] = useState();
    const [street2, setStreet2] = useState();
    const [additionalInfo, setAdditionalInfo] = useState();
    const [zip, setZip] = useState();
    const [city, setCity] = useState();
    const [country, setCountry] = useState();
    const [countries, setCountries] = useState([]);
    const [mainAdrID, setMainAdrID] = useState();

    const [methods, setMethods] = useState([]);
    const [allowedMethods, setAllowedMethods] = useState([]);

    const modalClose = () => {
        setShowModal(false);
        setControl({ ...control, editAccount: 0 });
    };

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            position: 'absolute',
            transform: 'translate(-50%, -50%)',
            width: '90%',
            maxWidth: '1000px',
            height: '60%',
        },
    };

    const onCountryChange = (_country) => {
        setCountry(_country);
    };

    const history = useHistory();

    const submit = async (e) => {
        e.preventDefault();
        try {
            const user = {
                _id: control.editAccount,
                email,
                internalNumber,
                disabled,
                disabledText,
            };
            let token = localStorage.getItem('auth-token');
            await Axios.post(`${process.env.REACT_APP_SERVER}/admin/updateuser`, user, { headers: { 'x-auth-token': token } });

            //history.push('/adminaccounts');
        } catch (err) {
            err.response.data.msg && setError(err.response.data.msg);
        }
    };

    const submitAdress = async (e) => {
        e.preventDefault();
        try {
            const user = {
                _id: mainAdrID,
                faName,
                firstName,
                lastName,
                salut,
                street,
                street2,
                additionalInfo,
                zip,
                city,
                country,
            };
            let token = localStorage.getItem('auth-token');
            await Axios.post(`${process.env.REACT_APP_SERVER}/admin/updatemainadr`, user, { headers: { 'x-auth-token': token } });

            //history.push('/adminaccounts');
        } catch (err) {
            err.response.data.msg && setError(err.response.data.msg);
        }
    };

    const submitPW = async (e) => {
        e.preventDefault();
        try {
            const user = {
                _id: control.editAccount,
                password,
                passwordCheck,
            };
            let token = localStorage.getItem('auth-token');
            await Axios.post(`${process.env.REACT_APP_SERVER}/admin/updateuserpw`, user, { headers: { 'x-auth-token': token } });

            //history.push('/adminaccounts');
        } catch (err) {
            err.response.data.msg && setError(err.response.data.msg);
        }
    };

    const submitPayment = async (e) => {
        e.preventDefault();
        try {
            const user = {
                _id: control.editAccount,
                mustPayBefore,
                useSkonto,
                skontoLevel,
                paymentDays,
                paymentDaysSkonto,
                rebateLevel,
            };
            let token = localStorage.getItem('auth-token');
            await Axios.post(`${process.env.REACT_APP_SERVER}/admin/updatepayment`, user, { headers: { 'x-auth-token': token } });

            //history.push('/adminaccounts');
        } catch (err) {
            err.response.data.msg && setError(err.response.data.msg);
        }
    };

    const submitMethods = async (e) => {
        e.preventDefault();
        try {
            const user = {
                _id: control.editAccount,
                allowedMethods: allowedMethods,
            };
            let token = localStorage.getItem('auth-token');
            await Axios.post(`${process.env.REACT_APP_SERVER}/admin/updateusermethods`, user, { headers: { 'x-auth-token': token } });

            //history.push('/adminaccounts');
        } catch (err) {
            err.response.data.msg && setError(err.response.data.msg);
        }
    };

    useEffect(() => {
        const getCountries = async () => {
            try {
                let token = localStorage.getItem('auth-token');
                if (token === null) {
                    localStorage.setItem('auth-token', '');
                    token = '';
                }

                const tokenRes = await Axios.post(`${process.env.REACT_APP_SERVER}/users/tokenIsValid`, null, { headers: { 'x-auth-token': token } });

                if (tokenRes.data) {
                    //console.log(control.editAccount);
                    const res = await Axios.post(
                        `${process.env.REACT_APP_SERVER}/admin/account`,
                        { edituser: control.editAccount },
                        { headers: { 'x-auth-token': token } }
                    );
                    if (res.data) {
                        setEmail(res.data.email);
                        setInternalNumber(res.data.internalNumber);
                        setDisabled(res.data.disabled);
                        setDisabledText(res.data.disabledText);
                        setAllowedMethods(res.data.allowedMethods);

                        setMustPayBefore(res.data.mustPayBefore);
                        setUseSkonto(res.data.useSkonto);
                        setSkontoLevel(res.data.skontoLevel);
                        setPaymentDays(res.data.paymentDays);
                        setPaymentDaysSkonto(res.data.paymentDaysSkonto);
                        setRebateLevel(res.data.rebateLevel);

                        const mainAdr = res.data.adresses?.find((o) => o.isMainAdr === true);
                        setMainAdrID(mainAdr._id);
                        setFirstName(mainAdr.firstName);
                        setLastName(mainAdr.lastName);
                        setSalut(mainAdr.salutation);
                        setFaName(mainAdr.faName);
                        setStreet(mainAdr.street);
                        setStreet2(mainAdr.street2);
                        setZip(mainAdr.zip);
                        setCity(mainAdr.city);
                        setAdditionalInfo(mainAdr.additionalInfo);
                        setCountry(mainAdr.country);
                    } else {
                        history.push('/adminaccounts');
                    }

                    const methodRes = await Axios.get(`${process.env.REACT_APP_SERVER}/files/methods`);
                    if (methodRes) {
                        setMethods(methodRes.data.methods);
                    }
                } else {
                    history.push('/login');
                }

                const cres = await Axios.get(`${process.env.REACT_APP_SERVER}/admin/countries/`, { headers: { 'x-auth-token': token } });
                if (cres) {
                    //console.log(cres.data.countries);
                    setCountries(cres.data.countries);
                    setCountry(cres.data.countries?.[0]?._id);
                }
            } catch (err) {
                console.log(err);
            }
        };
        getCountries();
    }, [control.editAccount]);

    const changeAllowedMethod = (checked, methodID) => {
        if (checked) {
            if (!allowedMethods.some((e) => e === methodID)) {
                setAllowedMethods([...allowedMethods, methodID]);
            }
        } else {
            setAllowedMethods(
                allowedMethods.filter(function (obj) {
                    return obj !== methodID;
                })
            );
        }
    };

    return (
        <Modal isOpen={showModal} onRequestClose={(e) => modalClose(e)} contentLabel={'modalLabel'} style={customStyles}>
            <div className="modalHeader">
                <div>
                    <h2>Admin - Nutzer bearbeiten</h2>
                </div>
                <div>
                    <button id="buttonX" onClick={() => modalClose()}>
                        &#10006;
                    </button>
                </div>
            </div>
            <div className="modalBody">
                {error && <ErrorNotice message={error} clearError={() => setError(undefined)} />}
                <form className="form" onSubmit={submit}>
                    <div className="form-div100">
                        <label htmlFor="register-email">Email</label>
                        <br />
                        <input id="register-email" type="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
                    </div>
                    <div className="form-div100">
                        <label htmlFor="register-inumber">Gesch&auml;ftspartnernummer</label>
                        <br />
                        <input id="register-inumber" type="text" value={internalNumber} onChange={(e) => setInternalNumber(e.target.value)} />
                    </div>
                    <div className="form-div100">
                        <br />
                        <label htmlFor="register-dt">
                            Account gesperrt
                            <input
                                type="checkbox"
                                id="registerdt"
                                name="register-dt"
                                checked={disabled}
                                onChange={(e) => setDisabled(e.target.checked)}
                            />
                        </label>
                        <br />
                        <br />
                        <label htmlFor="register-disabledtext">Account gesperrt - Nachricht</label>
                        <br />
                        <input id="register-disabledtext" type="text" value={disabledText} onChange={(e) => setDisabledText(e.target.value)} />
                    </div>
                    <input type="submit" value="Speichern" />
                </form>
                <br />
                <h3>Password &auml;ndern</h3>
                <form className="form" onSubmit={submitPW}>
                    <div className="form-div100">
                        <label htmlFor="register-password">Password</label>
                        <br />
                        <input id="register-password" type="password" onChange={(e) => setPassword(e.target.value)} />
                    </div>
                    <div className="form-div100">
                        <label htmlFor="verify-password">Verify Password</label>
                        <input
                            type="password"
                            id="verify-password"
                            placeholder="Verify password"
                            onChange={(e) => setPasswordCheck(e.target.value)}
                        />
                    </div>
                    <input type="submit" value="Speichern" />
                </form>
                <br />

                <h3>Zahlungsmodalit&auml;ten</h3>
                <form className="form" onSubmit={submitPayment}>
                    <div className="form-div100">
                        <label htmlFor="payment1">
                            Vorkassepflichtig
                            <input
                                type="checkbox"
                                id="payment1"
                                name="payment1"
                                checked={mustPayBefore}
                                onChange={(e) => setMustPayBefore(e.target.checked)}
                            />
                        </label>
                    </div>
                    <div className="form-div100">
                        <label htmlFor="payment2">
                            Skonto
                            <input
                                type="checkbox"
                                id="payment2"
                                name="payment2"
                                checked={useSkonto}
                                onChange={(e) => setUseSkonto(e.target.checked)}
                            />
                        </label>
                    </div>
                    <div className="form-div20">
                        <label className="adressLabel">Skontolevel</label>
                        <br />
                        <input
                            id="payment3"
                            type="text"
                            value={skontoLevel}
                            onChange={(e) => setSkontoLevel(e.target.value)}
                            placeholder="Skontolevel"
                        />
                    </div>
                    <div className="form-div20">
                        <label className="adressLabel">Zahlungsziel Skonto</label>
                        <br />
                        <input
                            id="payment4"
                            type="text"
                            value={paymentDaysSkonto}
                            onChange={(e) => setPaymentDaysSkonto(e.target.value)}
                            placeholder="Zahlungsziel Skonto"
                        />
                    </div>
                    <div className="form-div20">
                        <label className="adressLabel">Zahlungsziel</label>
                        <br />
                        <input
                            id="payment5"
                            type="text"
                            value={paymentDays}
                            onChange={(e) => setPaymentDays(e.target.value)}
                            placeholder="Zahlungsziel"
                        />
                    </div>
                    <div className="form-div20">
                        <label className="adressLabel">Rabattlevel</label>
                        <br />
                        <input
                            id="payment6"
                            type="text"
                            value={rebateLevel}
                            onChange={(e) => setRebateLevel(e.target.value)}
                            placeholder="Zahlungsziel"
                        />
                    </div>
                    <div className="form-div100">
                        <input type="submit" value="Speichern" />
                    </div>
                </form>
                <br />

                <h3>Hauptadresse</h3>
                <form className="form" onSubmit={submitAdress}>
                    <div className="form-div100">
                        <label className="adressLabel">Firmenname</label>
                        <br />
                        <input id="adr1" type="text" value={faName} onChange={(e) => setFaName(e.target.value)} placeholder="Firmenname" />
                    </div>

                    <div className="form-div100">
                        <label htmlFor="account-salut">Anrede</label>
                        <br />
                        <select id="adr8" className="country-select" onChange={(e) => setSalut(e.currentTarget.value)} value={salut}>
                            {saluts.map((item) => (
                                <option key={item} value={item}>
                                    {item}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="form-div50">
                        <label htmlFor="account-name">Vorname</label>
                        <br />
                        <input id="account-name" type="text" value={firstName} onChange={(e) => setFirstName(e.target.value)} required />
                    </div>
                    <div className="form-div50">
                        <label htmlFor="account-lastname">Name</label>
                        <br />
                        <input id="account-lastname" type="text" value={lastName} onChange={(e) => setLastName(e.target.value)} required />
                    </div>
                    <div className="form-div80">
                        <label className="adressLabel">Straße</label>
                        <br />
                        <input id="adr5" type="text" value={street} onChange={(e) => setStreet(e.target.value)} placeholder="Straße" required />
                    </div>
                    <div className="form-div20">
                        <label className="adressLabel">Hausnummer</label>
                        <br />
                        <input
                            id="adr5b"
                            type="text"
                            value={street2}
                            onChange={(e) => setStreet2(e.target.value)}
                            placeholder="Hausnummer"
                            required
                        />
                    </div>
                    <div className="form-div100">
                        <label className="adressLabel">Adresszusatz</label>
                        <br />
                        <input
                            id="adr5a"
                            type="text"
                            value={additionalInfo}
                            onChange={(e) => setAdditionalInfo(e.target.value)}
                            placeholder="Adresszusatz"
                        />
                    </div>
                    <div className="form-div20">
                        <label className="adressLabel">Postleitzahl</label>
                        <br />
                        <input id="adr6" type="text" value={zip} onChange={(e) => setZip(e.target.value)} placeholder="Postleitzahl" required />
                    </div>
                    <div className="form-div80">
                        <label className="adressLabel">Ort</label>
                        <br />
                        <input id="adr7" type="text" value={city} onChange={(e) => setCity(e.target.value)} placeholder="Ort" required />
                    </div>
                    <div className="form-div100">
                        <label className="adressLabel">Land</label>
                        <br />
                        <select id="adr8" className="country-select" onChange={(e) => onCountryChange(e.currentTarget.value)} value={country}>
                            {countries.map((item) => (
                                <option key={item._id} value={item._id}>
                                    {item.name}
                                </option>
                            ))}
                        </select>
                    </div>

                    <input type="submit" value="Speichern" />
                </form>

                <br />
                <h3>Erlaubte Druckverfahren</h3>
                <form className="form" onSubmit={submitMethods}>
                    <div className="form-div100">
                        {methods &&
                            methods.length > 0 &&
                            methods.map((method) => (
                                <>
                                    <label htmlFor={method.ID}>
                                        <input
                                            type="checkbox"
                                            id={method.ID}
                                            checked={allowedMethods.some((e) => e === method._id)}
                                            onChange={(e) => {
                                                changeAllowedMethod(e.target.checked, method._id);
                                            }}
                                        />
                                        {method.displayName} ({method.name})
                                    </label>
                                    <br />
                                </>
                            ))}
                    </div>
                    <br />
                    <input type="submit" value="Speichern" />
                </form>
            </div>
        </Modal>
    );
}
