import React, { useContext, useEffect, useState } from 'react';
import Axios from 'axios';
import Chart from 'react-google-charts';

import UserContext from '../../context/UserContext';
import AutoButton from '../misc/AutoButton';

export default function Admin() {
    const { userData } = useContext(UserContext);
    const [data, setData] = useState();

    const [openOfferData, setOpenOfferData] = useState([]);
    const [materialUseData, setMaterialUseData] = useState([]);

    const refresh = async () => {
        let token = localStorage.getItem('auth-token');
        try {
            const res = await Axios.get(`${process.env.REACT_APP_SERVER}/admin/dashboard/`, { headers: { 'x-auth-token': token } });
            //console.log(res.data);
            setData(res.data);

            let openOffers = [];
            openOffers.push(['Status', 'Anzahl']);
            openOffers.push(['Ausgelöst', res.data.countAllOffers - res.data.countOpenOffers]);
            openOffers.push(['Offen', res.data.countOpenOffers]);
            setOpenOfferData(openOffers);

            console.log(res.data.materialData);

            let materialData = [];
            materialData.push(['Name', 'Anzahl']);
            for (const [key, value] of Object.entries(res.data.materialData)) {
                console.log(`${key}: ${value}`);
                materialData.push([key, value]);
            }
            setMaterialUseData(materialData);
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        refresh();
    }, []);

    return (
        <>
            {userData.user && data ? (
                <div className="wrapper_1500">
                    <h2> Hallo {userData.user.firstName} </h2>
                    <p> Offene Auftr&auml;ge: {data.countOpen}</p>
                    <p> In Pr&uuml;fung: {data.countValidation}</p>
                    <br />
                    <p> In Produktion: {data.countProduction}</p>
                    <p> Ausgeliefert: {data.countDelivery}</p>
                    <p> Pausiert: {data.countPaused}</p>
                    <br />
                    <p>Offene Tickets: {data.assignedTickets}</p>
                    <p>Unbeantwortete Nachrichten: {data.countUnanswered}</p>
                    <br />
                    <br />
                    Nicht ausgelöste Angebote: {data.countOpenOffers}
                    <br />
                    Nicht ausgelöste Angebote, die in weniger als 3 Tagen ablaufen: {data.countOpenOffers3}
                    <br />
                    <Chart
                        width={'500px'}
                        height={'300px'}
                        chartType="PieChart"
                        loader={<div>Loading Chart</div>}
                        data={openOfferData}
                        options={{
                            title: 'Angebote letzte 14 Tage',
                        }}
                        rootProps={{ 'data-testid': '1' }}
                    />
                    <br />
                    <Chart
                        width={'500px'}
                        height={'300px'}
                        chartType="PieChart"
                        loader={<div>Loading Chart</div>}
                        data={materialUseData}
                        options={{
                            title: 'Aufträge letzte 30 Tage',
                        }}
                        rootProps={{ 'data-testid': '1' }}
                    />
                    <br />
                    <AutoButton className="page-button-white" onClick={refresh} text="Aktualisieren" count={300} />
                </div>
            ) : null}
        </>
    );
}
