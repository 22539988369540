import React, { useState, useEffect } from 'react';
import FileTaskCreateOrder from '../file/FileTaskCreateOrder';
import { NumericFormat } from 'react-number-format';;

const FileDetailsCreateOrder = ({ item, paramsComplete, deleteFile, position }) => {
    const [tasks, setTasks] = useState(item.tasks);
    const [incomplete, setIncomplete] = useState(false);

    const onPriceSelect = (e, index) => {
        let newPrices = [...item.prices];
        for (var i = 0; i < newPrices.length; ++i) {
            if (i === index) {
                newPrices[i].selected = true;
                item.selectedPrice = newPrices[i];
                item.orderParamsComplete = true;
            } else {
                newPrices[i].selected = false;
            }
        }
        item.prices = [...newPrices];
        setIncomplete(false);
        paramsComplete();
    };

    const onDelete = async () => {
        deleteFile(item._id);
    };

    function getSafe(fn, defaultVal) {
        try {
            return fn();
        } catch (e) {
            return defaultVal;
        }
    }

    const onCommentChange = (text) => {
        item.comment = text;
    };

    useEffect(() => {
        item.tasks = tasks;
        setIncomplete(false);
        paramsComplete();
    }, [item, tasks]);

    const deleteTask = (task) => {
        let newTasks = tasks.filter(function (obj) {
            return obj.uuid !== task.uuid;
        });
        setTasks(newTasks);
    };

    const updateTask = (task) => {
        let newTasks = [...tasks];
        newTasks.forEach(function (item, i) {
            if (item.uuid === task.uuid) newTasks[i] = task;
        });
        setTasks(newTasks);
    };

    return (
        <>
            <div id="item-element-wrapper">
                <div className="item-container">
                    <div className="item-preview-details-wrapper">
                        {item ? (
                            <>
                                <div className="item-position-number">{position + 1}</div>

                                <div className="item-details-row">
                                    <div className="item-preview">
                                        <img src={item.preview} width="200" height="200" alt="" />
                                    </div>
                                    <div className="item-params">
                                        <div className="item-name">{item.fileName}</div>

                                        <div className="item-params-row">
                                            <div className="item-params-value">
                                                <NumericFormat
                                                    value={item.boundingBox[0]}
                                                    displayType={'text'}
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    thousandSeparator={'.'}
                                                    decimalSeparator={','}
                                                />
                                                <span> x </span>
                                                <NumericFormat
                                                    value={item.boundingBox[1]}
                                                    displayType={'text'}
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    thousandSeparator={'.'}
                                                    decimalSeparator={','}
                                                />
                                                <span> x </span>
                                                <NumericFormat
                                                    value={item.boundingBox[2]}
                                                    displayType={'text'}
                                                    suffix={' mm'}
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    thousandSeparator={'.'}
                                                    decimalSeparator={','}
                                                />
                                            </div>
                                        </div>

                                        <div className="item-comment-textbox">
                                            <p className="paragraph">Bemerkungen:</p>
                                            <textarea
                                                id="file-comment"
                                                name="filecomment"
                                                rows="7"
                                                cols="25"
                                                onChange={(e) => onCommentChange(e.currentTarget.value)}
                                                value={item.comment}
                                            ></textarea>
                                        </div>
                                    </div>

                                    <div className="item-selections">
                                        <div className="item-selections-row">
                                            <div className="item-selections-name">
                                                <label className="fileDetailLabel">Verfahren:</label>
                                            </div>
                                            <div>
                                                <label>{item.method.displayName}</label>
                                            </div>
                                        </div>
                                        <div className="item-selections-row">
                                            <div className="item-selections-name">
                                                <label className="fileDetailLabel">Material:</label>
                                            </div>
                                            <div>
                                                <label>{item.material.displayName}</label>
                                            </div>
                                        </div>
                                        {item.infill && item.infill !== 'noinfill' ? (
                                            <>
                                                <div className="item-selections-row">
                                                    <div className="item-selections-name">
                                                        <label className="fileDetailLabel">Infill:</label>
                                                    </div>
                                                    <div>
                                                        <label>{item.infill.displayName}</label>
                                                    </div>
                                                </div>
                                            </>
                                        ) : null}
                                        <div className="item-selections-row">
                                            <div className="item-selections-name">
                                                <label className="fileDetailLabel">Nachbearbeitung:</label>
                                            </div>
                                            <div>
                                                <label>{getSafe(() => item.processing.displayName, 'keine Glättung')}</label>
                                            </div>
                                        </div>
                                        <div className="item-selections-row">
                                            <div className="item-selections-name">
                                                <label className="fileDetailLabel">Farbe:</label>
                                            </div>
                                            <div>
                                                <label>{getSafe(() => item.color.displayName, 'keine Färbung')}</label>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="item-prices ">
                                        <p>
                                            Stückpreise
                                            <br />
                                        </p>
                                        {incomplete ? (
                                            <>
                                                <label className="item-prices-incomplete">Bitte wählen Sie eine Stückzahl</label>
                                            </>
                                        ) : null}
                                        {item.approved ? (
                                            <table id="item-prices-table">
                                                <tbody>
                                                    {item.prices
                                                        ? item.prices.map((pr, index) => (
                                                              <tr key={index}>
                                                                  <td>
                                                                      {pr.amount}
                                                                      &nbsp;Stück:
                                                                  </td>
                                                                  <td>
                                                                      <NumericFormat
                                                                          value={parseFloat(pr.price)}
                                                                          displayType={'text'}
                                                                          suffix={' €'}
                                                                          decimalScale={2}
                                                                          fixedDecimalScale={true}
                                                                          thousandSeparator={'.'}
                                                                          decimalSeparator={','}
                                                                      />
                                                                  </td>
                                                                  <td>
                                                                      <input
                                                                          className="amount-radio"
                                                                          type="radio"
                                                                          name={pr.uuid}
                                                                          checked={pr.selected ? true : false}
                                                                          onChange={(e) => onPriceSelect(e, index)}
                                                                      />
                                                                  </td>
                                                              </tr>
                                                          ))
                                                        : null}
                                                </tbody>
                                            </table>
                                        ) : (
                                            <>
                                                <p className="paragraph">
                                                    Diese Position wurde noch nicht durch einen Mitarbeiter bearbeitet und f&uuml;r
                                                    <br />
                                                    die Bestellung frei gegeben. Entfernen Sie diese Position, wenn Sie trotzdem
                                                    <br /> eine Bestellung ausl&ouml;sen wollen.
                                                </p>
                                            </>
                                        )}
                                    </div>
                                </div>

                                <div className="remove-button-box">
                                    <div>
                                        {' '}
                                        <button className="delete-item-button" onClick={onDelete}>
                                            🗙
                                        </button>
                                    </div>
                                </div>
                            </>
                        ) : null}
                    </div>
                </div>

                <div className="item-actions">
                    <div className="item-actions-wrapper">
                        <div className="item-actions-options">
                            {item.keepOrient ? (
                                <>
                                    <label className="labelGreen">Ausrichtung beibehalten</label>
                                </>
                            ) : (
                                <>
                                    <label className="labelRed">Ausrichtung flexibel</label>
                                </>
                            )}
                            {item.allowHollow ? (
                                <>
                                    <label className="labelGreen">Aushöhlen erlaubt</label>
                                </>
                            ) : (
                                <>
                                    <label className="labelRed">Aushöhlen nicht erlaubt</label>
                                </>
                            )}
                            {item.planSeries ? (
                                <>
                                    <label className="labelGreen">Serienfertigung geplant</label>
                                </>
                            ) : (
                                <>
                                    <label className="labelRed">Keine Serienfertigung geplant</label>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            {tasks &&
                tasks.length > 0 &&
                tasks.map((task, index) => (
                    <>
                        <FileTaskCreateOrder
                            key={task.uuid}
                            position={`${position + 1}.${index + 1}`}
                            task={task}
                            updateTask={updateTask}
                            deleteTask={deleteTask}
                            paramsComplete={paramsComplete}
                        />
                    </>
                ))}
        </>
    );
};

export default FileDetailsCreateOrder;
