import React, { useState, useEffect, useContext, useCallback } from 'react';
import Axios from 'axios';
import download from 'downloadjs';
import { useHistory } from 'react-router-dom';
import MethodContext from '../../context/MethodContext';
import { confirmAlert } from 'react-confirm-alert';

const AdminOrderTools = ({ order, setError, orderStatus, setOrderStatus, setOrderStatusDisp, items, onDeliveryButton }) => {
    const history = useHistory();
    const { methodDefs } = useContext(MethodContext);
    const [stati, setStati] = useState([]);
    const [status, setStatus] = useState(order.status);
    const [confirmStatus, setConfirmStatus] = useState('');

    const changeStatus = useCallback(
        async (s) => {
            const ostatus = methodDefs.orderStati.find((element) => element.name == s);
            let token = localStorage.getItem('auth-token');
            try {
                await Axios.post(
                    `${process.env.REACT_APP_SERVER}/admin/updateorderstatus`,
                    { orderID: order._id, IDS: order.IDS, statusID: s },
                    { headers: { 'x-auth-token': token } }
                );

                let allowed = [];

                for (const os of methodDefs.orderStati) {
                    if (ostatus.followedBy.includes(os._id) || ostatus._id == os._id) {
                        allowed.push(os);
                    }
                }

                setStati(allowed);

                setStatus(s);
                setConfirmStatus('');
                setOrderStatus(ostatus.displayName);
            } catch (err) {
                err.response.data && err.response.data.msg && setError(err.response.data.msg);
            }
        },
        [methodDefs.orderStati, order, setError, setOrderStatus]
    );

    const onStatusChange = (s) => {
        const ostatus = methodDefs.orderStati.find((element) => element.name == s);
        if (s === 'os_validated') {
            let problem = false;
            if (items) {
                items.forEach((item) => {
                    problem |= item.checkStatus !== 1;
                });
            }
            if (problem) {
                confirmAlert({
                    title: 'Problem!',
                    message: 'Nicht alle Items geprüft oder druckbar.',
                    buttons: [
                        {
                            label: 'OK',
                        },
                    ],
                });
                return;
            }
        }
        if (ostatus.requiresConfirm) {
            setConfirmStatus(s);
        } else {
            changeStatus(s);
        }
    };

    useEffect(() => {
        if (confirmStatus !== '') {
            confirmAlert({
                title: 'Status bestätigen',
                message: `Wollen Sie den Status wirklich auf ${confirmStatus} setzen?`,
                buttons: [
                    {
                        label: 'Ja',
                        onClick: () => {
                            changeStatus(confirmStatus);
                        },
                    },
                    {
                        label: 'Nein',
                        onClick: () => {},
                    },
                ],
            });
        }
    }, [confirmStatus, changeStatus]);

    useEffect(() => {
        let allowed = [];

        const status = methodDefs.orderStati.find((element) => element.name === order.status);

        if (status) {
            for (const os of methodDefs.orderStati) {
                if (status.followedBy.includes(os._id) || status._id == os._id) {
                    allowed.push(os);
                }
            }

            setStati(allowed);
            setStatus(status.name);
            setOrderStatus(status.name);
            setOrderStatusDisp(status.displayName);
        }
    }, [methodDefs.orderStati, order.status, setOrderStatus]);

    const onDownload = async () => {
        let token = localStorage.getItem('auth-token');
        const config = {
            responseType: 'blob',
            headers: { 'x-auth-token': token },
            params: { orderID: order._id },
        };

        Axios.get(`${process.env.REACT_APP_SERVER}/admin/downloadorderzip`, config).then((res) => {
            download(res.data, `Auftrag_${order.IDS}.zip`);
        });
    };

    return (
        <div id="admin-order-tools">
            <>
                <select className="param-select" onChange={(e) => onStatusChange(e.currentTarget.value)} value={status}>
                    {stati &&
                        stati.map((item) => (
                            <option key={item.name} value={item.name}>
                                {item.displayName}
                            </option>
                        ))}
                </select>

                <button className="page-button-white" onClick={onDownload}>
                    Download
                </button>

                <button className="page-button-white" onClick={() => onDeliveryButton()}>
                    Lieferschein erstellen
                </button>
            </>
        </div>
    );
};

export default AdminOrderTools;
