import React, { useState, useEffect } from 'react';
import { Router, Switch, Route } from 'react-router-dom';
import Axios from 'axios';

import CreateOrder from './components/pages/CreateOrder';
import Header from './components/layout/Header';
import Home from './components/pages/Home';
import Admin from './components/pages/Admin';
import Maintenance from './components/pages/Maintenance';
import Login from './components/account/Login';
import Register from './components/account/Register';
import Registered from './components/account/registered';
import RequestPW from './components/account/RequestPW';
import ResetPW from './components/account/ResetPW';
import EditAccount from './components/account/EditAccount';
import Offers from './components/account/Offers';
import ViewOffer from './components/pages/ViewOffer';
import ViewOrder from './components/pages/ViewOrder';
import Orders from './components/account/Orders';
import Thanks from './components/account/thanks';
import Verify from './components/account/Verify';

import Footer1 from './components/misc/Footer1';

import AdminOffers from './components/admin/AdminOffers';
import AdminOrders from './components/admin/AdminOrders';
import AdminAccounts from './components/admin/AdminAccounts';
import AdminAccountRights from './components/admin/AdminAccountRights';
import AdminAccounting from './components/admin/AdminAccounting';
import AdminFormulas from './components/admin/AdminFormulas';
import DeliveryPage from './components/admin/DeliveryPage';
import AdminAddUser from './components/admin/AdminAddUser';
import AdminEditUser from './components/admin/AdminEditUser';
import AdminEditCountry from './components/admin/AdminEditCountry';
import AdminEditPayments from './components/admin/AdminEditPayments';
import AdminEditSettings from './components/admin/AdminEditSettings';
import AdminEditOrderStatus from './components/admin/AdminEditOrderStatus';
import AdminLogs from './components/admin/AdminLogs';
import AdminTickets from './components/admin/AdminTickets';

import ControlContext from './context/ControlContext';
import UserContext from './context/UserContext';
import FileContext from './context/FileContext';
import TaskContext from './context/TaskContext';
import MethodContext from './context/MethodContext';

import history from './components/history';

import './style/style.css';
import './style/item.css';
import './style/admin.css';


export default function App() {
    const [userData, setUserData] = useState({
        token: undefined,
        user: undefined,
        adresses: [],
        rights: [],
    });

    const [control, setControl] = useState({
        viewOffer: 0,
        viewOrder: 0,
        deliveryMethod: undefined,
    });

    const [files, setFiles] = useState([]);
    const [tasks, setTasks] = useState([]);
    const [methodDefs, setMethodDefs] = useState({});
    const [urlParams, setUrlParams] = useState();

    const [showAccount, setShowAccount] = useState(false);
    const [showLogin, setShowLogin] = useState(false);
    const [showSettings, setShowSettings] = useState(false);
    const [showRegister, setShowRegister] = useState(false);
    const [showRequestPW, setShowRequestPW] = useState(false);

    const init = () => {
        console.log(`API Server: ${process.env.REACT_APP_SERVER}`);

        document.title = 'Dick & Dick | 3D-Druck Service online';

        const checkLoggedIn = async () => {
            let token = localStorage.getItem('auth-token');
            if (token === null) {
                localStorage.setItem('auth-token', '');
                token = '';
            }

            const tokenRes = await Axios.post(`${process.env.REACT_APP_SERVER}/users/tokenIsValid`, null, { headers: { 'x-auth-token': token } });

            if (tokenRes.data) {
                const userRes = await Axios.get(`${process.env.REACT_APP_SERVER}/users/`, { headers: { 'x-auth-token': token } });
                const adresses = [...userRes.data.adresses];
                const rights = [...userRes.data.rights];
                delete userRes.data.adresses;
                delete userRes.data.rights;
                setUserData({
                    token,
                    user: userRes.data,
                    adresses: adresses,
                    rights: rights,
                });
            } else {
                localStorage.setItem('auth-token', '');
            }
        };

        const getMethodData = async () => {
            const methodRes = await Axios.get(`${process.env.REACT_APP_SERVER}/files/context`);
            setMethodDefs(methodRes.data);
        };

        const getMaintenance = async () => {
            const maintRes = await Axios.get(`${process.env.REACT_APP_SERVER}/files/maintenance`);
            setControl({ ...control, maintenance: maintRes.data.maintenance });
            if (maintRes.data.maintenance) {
                history.push('/maintenance');
            }
        };

        getMaintenance();

        let search = window.location.search;
        let params = new URLSearchParams(search);

        if (params && params.get('verify')) {
            setUrlParams({ verify: params.get('verify') });
        } else if (params && params.get('resetpw')) {
            setUrlParams({ resetpw: params.get('resetpw') });
        } else if (params && params.get('viewOffer')) {
            checkLoggedIn();
            getMethodData();
            setUrlParams({ viewOffer: params.get('viewOffer') });
        } else if (params && params.get('viewOrder')) {
            checkLoggedIn();
            getMethodData();
            setUrlParams({ viewOrder: params.get('viewOrder') });
        } else {
            checkLoggedIn();
            getMethodData();
        }
    };

    useEffect(() => {
        init();
    }, []);

    const checkViewOffer = async (ID) => {
        try {
            let token = localStorage.getItem('auth-token');
            const res = await Axios.post(`${process.env.REACT_APP_SERVER}/files/offerbyid`, { ID: ID }, { headers: { 'x-auth-token': token } });
            if (res) {
                setControl({ ...control, viewOffer: res.data._id });

                history.push('/viewoffer');
            }
        } catch (err) {
            console.log(err);
        }
    };

    const checkViewOrder = async (ID) => {
        try {
            let token = localStorage.getItem('auth-token');
            const res = await Axios.post(`${process.env.REACT_APP_SERVER}/files/orderbyid`, { ID: ID }, { headers: { 'x-auth-token': token } });
            if (res) {
                setControl({ ...control, viewOrder: res.data._id });
                history.push('/vieworder');
            }
        } catch (err) {
            console.log(err);
        }
    };

    const checkUrlParams = (urlParams) => {
        if (urlParams) {
            if (urlParams.verify) {
                setUserData({ ...userData, verifyID: urlParams.verify });
                history.push('/verify');
            } else if (urlParams.resetpw) {
                setUserData({ ...userData, pwID: urlParams.resetpw });
                history.push('/resetpw');
            } else if (urlParams.viewOffer) {
                checkViewOffer(urlParams.viewOffer);
            } else if (urlParams.viewOrder) {
                checkViewOrder(urlParams.viewOrder);
            }
        }
    };

    useEffect(() => {
        checkUrlParams(urlParams);
    }, [urlParams]);

    return (
        <>
            <Router history={history}>
                <UserContext.Provider value={{ userData, setUserData }}>
                    <FileContext.Provider value={{ files, setFiles }}>
                        <TaskContext.Provider value={{ tasks, setTasks }}>
                            <MethodContext.Provider value={{ methodDefs, setMethodDefs }}>
                                <ControlContext.Provider value={{ control, setControl }}>
                                    <Header
                                        setShowAccount={setShowAccount}
                                        setShowLogin={setShowLogin}
                                        setShowRegister={setShowRegister}
                                        setShowSettings={setShowSettings}
                                    />

                                    <div className="container">
                                        <AdminEditSettings showModal={showSettings} setShowModal={setShowSettings} />
                                        <EditAccount showModal={showAccount} setShowModal={setShowAccount} />
                                        <Login
                                            showModal={showLogin}
                                            setShowModal={setShowLogin}
                                            setShowRegister={setShowRegister}
                                            setShowRequestPW={setShowRequestPW}
                                        />
                                        <Register showModal={showRegister} setShowModal={setShowRegister} setShowLogin={setShowLogin} />
                                        <RequestPW showModal={showRequestPW} setShowModal={setShowRequestPW} />
                                        <Switch>
                                            <Route
                                                exact
                                                path="/"
                                                render={() => <Home setShowLogin={setShowLogin} setShowRegister={setShowRegister} />}
                                            />
                                            <Route path="/admin" component={Admin} />
                                            <Route path="/registered" component={Registered} />
                                            <Route path="/resetpw" component={ResetPW} />
                                            <Route path="/editaccount" component={EditAccount} />
                                            <Route path="/offers" component={Offers} />
                                            <Route path="/orders" component={Orders} />
                                            <Route path="/viewoffer" component={ViewOffer} />
                                            <Route path="/createOrder" component={CreateOrder} />
                                            <Route path="/viewOrder" component={ViewOrder} />
                                            <Route path="/delivery" component={DeliveryPage} />
                                            <Route path="/thanks" component={Thanks} />
                                            <Route path="/verify" component={Verify} />
                                            <Route path="/resetpw" component={ResetPW} />
                                            <Route path="/adminoffers" component={AdminOffers} />
                                            <Route path="/adminorders" component={AdminOrders} />
                                            <Route path="/adminaccountrights" component={AdminAccountRights} />
                                            <Route path="/adminaccounts" component={AdminAccounts} />
                                            <Route path="/adminaccounting" component={AdminAccounting} />
                                            <Route path="/adminformulas" component={AdminFormulas} />
                                            <Route path="/adminadduser" component={AdminAddUser} />
                                            <Route path="/adminedituser" component={AdminEditUser} />
                                            <Route path="/admineditcountry" component={AdminEditCountry} />
                                            <Route path="/admineditpayments" component={AdminEditPayments} />
                                            <Route path="/admineditorderstatus" component={AdminEditOrderStatus} />
                                            <Route path="/adminlogs" component={AdminLogs} />
                                            <Route path="/admintickets" component={AdminTickets} />
                                            <Route path="/maintenance" component={Maintenance} />
                                        </Switch>
                                        <Footer1/>
                                    </div>
                                </ControlContext.Provider>
                            </MethodContext.Provider>
                        </TaskContext.Provider>
                    </FileContext.Provider>
                </UserContext.Provider>
            </Router>
        </>
    );
}
