import React, { useState, useContext, useEffect } from 'react';
import Axios from 'axios';
import UserContext from '../../context/UserContext';
import ControlContext from '../../context/ControlContext';
import { useHistory } from 'react-router-dom';
import ErrorNotice from '../misc/ErrorNotice';

const EditAdress = ({ onSubmit }) => {
    const [faName, setFaName] = useState();
    const [dpt, setDpt] = useState();
    const [salut, setSalut] = useState();
    const [lastName, setLastName] = useState();
    const [firstName, setFirstName] = useState();
    const [street, setStreet] = useState();
    const [street2, setStreet2] = useState();
    const [additionalInfo, setAdditionalInfo] = useState();
    const [zip, setZip] = useState();
    const [city, setCity] = useState();
    const [country, setCountry] = useState();
    const [tel, setTel] = useState();
    const [vatID, setVatID] = useState();
    const [email, setEmail] = useState();
    const [emailInvoice, setEmailInvoice] = useState();
    const [emailTracking, setEmailTracking] = useState();
    const [isInvoiceAdr, setIsInvoiceAdr] = useState(false);
    const [isDeliveryAdr, setIsDeliveryAdr] = useState(false);

    const { userData, setUserData } = useContext(UserContext);
    const { control } = useContext(ControlContext);
    const history = useHistory();
    const [error, setError] = useState();

    const _id = control.editAdress;

    const [countries, setCountries] = useState([]);

    const onCountryChange = (_country) => {
        setCountry(_country);
    };

    useEffect(() => {
        const checkLoggedIn = async () => {
            try {
                let token = localStorage.getItem('auth-token');
                if (token === null) {
                    localStorage.setItem('auth-token', '');
                    token = '';
                }

                const tokenRes = await Axios.post(`${process.env.REACT_APP_SERVER}/users/tokenIsValid`, null, { headers: { 'x-auth-token': token } });

                const cres = await Axios.get(`${process.env.REACT_APP_SERVER}/admin/countries/`, { headers: { 'x-auth-token': token } });
                if (cres) {
                    cres.data.countries.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
                    setCountries(cres.data.countries);
                }

                if (tokenRes.data) {
                    const res = await Axios.post(
                        `${process.env.REACT_APP_SERVER}/users/adress/`,
                        { _id: _id },
                        { headers: { 'x-auth-token': token } }
                    );
                    setFaName(res.data.faName);
                    setDpt(res.data.dpt);
                    setSalut(res.data.salutation);
                    setLastName(res.data.lastName);
                    setFirstName(res.data.firstName);
                    setStreet(res.data.street);
                    setStreet2(res.data.street2);
                    setAdditionalInfo(res.data.additionalInfo);
                    setZip(res.data.zip);
                    setCity(res.data.city);
                    setCountry(res.data.country);
                    setTel(res.data.tel);
                    setVatID(res.data.vatID);
                    setEmail(res.data.email);
                    setEmailInvoice(res.data.emailInvoice);
                    setEmailTracking(res.data.emailTracking);
                    if (res.data.isInvoiceAdr) setIsInvoiceAdr(res.data.isInvoiceAdr);
                    else setIsInvoiceAdr(false);
                    if (res.data.isDeliveryAdr) setIsDeliveryAdr(res.data.isDeliveryAdr);
                    else setIsDeliveryAdr(false);
                } else {
                    history.push('/login');
                }
            } catch (err) {
                console.log(err);
            }
        };
        checkLoggedIn();
    }, [setUserData, _id]);

    const submit = async (e) => {
        e.preventDefault();
        try {
            let token = localStorage.getItem('auth-token');
            const payload = {
                _id: control.editAdress,
                faName: faName,
                dpt: dpt,
                salutation: salut,
                lastName: lastName,
                firstName: firstName,
                street: street,
                street2: street2,
                additionalInfo: additionalInfo,
                zip: zip,
                city: city,
                country: country,
                tel: tel,
                vatID: vatID,
                email: email,
                emailInvoice: emailInvoice,
                emailTracking: emailTracking,
                isInvoiceAdr: isInvoiceAdr,
                isDeliveryAdr: isDeliveryAdr,
            };
            const updateRes = await Axios.post(`${process.env.REACT_APP_SERVER}/users/updateAdress`, payload, { headers: { 'x-auth-token': token } });
            if (updateRes) {
                onSubmit();
            }
        } catch (err) {
            console.log(err);
            err.response?.data?.msg && setError(err.response.data.msg);
        }
    };

    return (
        <div className="page-form">
            {error && <ErrorNotice message={error} clearError={() => setError(undefined)} />}
            <form className="form" onSubmit={submit}>
                <div className="form-div100">
                    <label className="adressLabel">Firmenname</label>
                    <br />
                    <input id="adr1" type="text" value={faName} onChange={(e) => setFaName(e.target.value)} placeholder="Firmenname" />
                </div>
                {faName?.length > 0 ? (
                    <div className="form-div100">
                        <label className="adressLabel">Abteilung</label>
                        <br />
                        <input id="adr2" type="text" value={dpt} onChange={(e) => setDpt(e.target.value)} placeholder="Abteilung" />
                    </div>
                ) : null}
                <div className="form-div100">
                    <label className="adressLabel">Anrede</label>
                    <br />
                    <input id="adr2a" type="text" value={salut} onChange={(e) => setSalut(e.target.value)} placeholder="Anrede" required />
                </div>
                <div className="form-div50">
                    <label className="adressLabel">Vorname</label>
                    <br />
                    <input id="adr4" type="text" value={firstName} onChange={(e) => setFirstName(e.target.value)} placeholder="Vorname" required />
                </div>
                <div className="form-div50">
                    <label className="adressLabel">Name</label>
                    <br />
                    <input id="adr3" type="text" value={lastName} onChange={(e) => setLastName(e.target.value)} placeholder="Name" required />
                </div>

                <div className="form-div80">
                    <label className="adressLabel">Straße</label>
                    <br />
                    <input id="adr5" type="text" value={street} onChange={(e) => setStreet(e.target.value)} placeholder="Straße" required />
                </div>
                <div className="form-div20">
                    <label className="adressLabel">Hausnummer</label>
                    <br />
                    <input id="adr5b" type="text" value={street2} onChange={(e) => setStreet2(e.target.value)} placeholder="Hausnummer" required />
                </div>
                <div className="form-div100">
                    <label className="adressLabel">Adresszusatz</label>
                    <br />
                    <input
                        id="adr5a"
                        type="text"
                        value={additionalInfo}
                        onChange={(e) => setAdditionalInfo(e.target.value)}
                        placeholder="Adresszusatz"
                    />
                </div>
                <div className="form-div20">
                    <label className="adressLabel">Postleitzahl</label>
                    <br />
                    <input id="adr6" type="text" value={zip} onChange={(e) => setZip(e.target.value)} placeholder="Postleitzahl" required />
                </div>
                <div className="form-div80">
                    <label className="adressLabel">Ort</label>
                    <br />
                    <input id="adr7" type="text" value={city} onChange={(e) => setCity(e.target.value)} placeholder="Ort" required />
                </div>
                <div className="form-div100">
                    <label className="adressLabel">Land</label>
                    <br />
                    <select id="adr8" className="country-select" onChange={(e) => onCountryChange(e.currentTarget.value)} value={country}>
                        {countries.map((item) => (
                            <option key={item._id} value={item._id}>
                                {item.name}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="form-div100">
                    <label className="adressLabel">Telefon</label>
                    <br />
                    <input id="adr9" type="text" value={tel} onChange={(e) => setTel(e.target.value)} placeholder="Telefon" />
                </div>
                {faName?.length > 0 ? (
                    <div className="form-div80">
                        <label className="adressLabel">USt. ID</label>
                        <br />
                        <input id="adr10" type="text" value={vatID} onChange={(e) => setVatID(e.target.value)} placeholder="USt. ID" required />
                    </div>
                ) : null}
                <div className="form-div80">
                    <label className="adressLabel">Email</label>
                    <br />
                    <input id="adr11" type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email" required />
                </div>
                <div className="form-div80">
                    <label className="adressLabel">Email für Rechnungen</label>
                    <br />
                    <input
                        id="adr12"
                        type="email"
                        value={emailInvoice}
                        onChange={(e) => setEmailInvoice(e.target.value)}
                        placeholder="Email für Rechnungen"
                    />
                </div>
                <div className="form-div80">
                    <label className="adressLabel">Email für Tracking</label>
                    <br />
                    <input
                        id="adr13"
                        type="email"
                        value={emailTracking}
                        onChange={(e) => setEmailTracking(e.target.value)}
                        placeholder="Email für Tracking"
                    />
                </div>
                <div className="address-select-container">
                    <div id="address-select-box">
                        <div id="address-select-row">
                            <div id="address-select-label">
                                <input
                                    type="checkbox"
                                    id="adr14"
                                    name="adr-14"
                                    checked={isInvoiceAdr}
                                    onChange={(e) => setIsInvoiceAdr(e.target.checked)}
                                />
                                &nbsp;<label htmlFor="adr-14">Rechnungsadresse</label>
                            </div>
                            <div id="address-select-label">
                                <input
                                    type="checkbox"
                                    id="adr15"
                                    name="adr-15"
                                    checked={isDeliveryAdr}
                                    onChange={(e) => setIsDeliveryAdr(e.target.checked)}
                                />
                                &nbsp;<label htmlFor="adr-14">Lieferadresse</label>
                            </div>
                        </div>
                    </div>
                    <button className="page-button-green" type="submit">
                        Speichern
                    </button>
                </div>
            </form>
        </div>
    );
};

export default EditAdress;
