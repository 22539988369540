import React, { useContext, useEffect, useState } from 'react';
import Axios from 'axios';

import UserContext from '../../context/UserContext';
import AutoButton from '../misc/AutoButton';

export default function Admin() {
    const refresh = async () => {
        let token = localStorage.getItem('auth-token');
        try {
            //const res = await Axios.get(`${process.env.REACT_APP_SERVER}/admin/dashboard/`, { headers: { 'x-auth-token': token } });
            //console.log(res.data);
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        refresh();
    }, []);

    return (
        <>
            <h1>Wartungsarbeiten, bitte versuchen Sie es später erneut.</h1>
            {/*<AutoButton className="page-button-white" onClick={refresh} text="Aktualisieren" count={300} />*/}
        </>
    );
}
