import React, { useState, useContext, useEffect } from 'react';
import Axios from 'axios';
import download from 'downloadjs';
import ControlContext from '../../context/ControlContext';
import InfoOffers from '../misc/InfoOffers';
import { useHistory } from 'react-router-dom';
import ErrorNotice from '../misc/ErrorNotice';
import { AgGridReact } from 'ag-grid-react';
import { BtnCellRenderer } from '../admin/BtnCellRenderer';
import OrderArrayRenderer from '../misc/OrderArrayRenderer';
import { columnTypes, defaultColDef, defaultColGroupDef, dateTimeFormatter, expiryFormatter } from '../misc/ColumnTypes';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

const Offers = () => {
    const { control, setControl } = useContext(ControlContext);
    const history = useHistory();
    const [error, setError] = useState();
    const [offers, setOffers] = useState();

    const frameworkComponents = {
        btnCellRenderer: BtnCellRenderer,
        orderArrayRenderer: OrderArrayRenderer,
    };

    const columnDefs = [
        {
            field: 'IDS',
            headerName: 'Angebotsnummer',
            sortable: true,
            filter: true,
            editable: false,
        },
        {
            field: 'created',
            headerName: 'Erstellt am',
            sortable: true,
            filter: false,
            type: 'dateColumn',
            valueFormatter: dateTimeFormatter,
            sort: 'desc',
            editable: false,
        },
        {
            field: 'expires',
            headerName: 'Läuft ab',
            sortable: true,
            filter: false,
            valueFormatter: expiryFormatter,
            editable: false,
        },
        {
            field: 'projectID',
            headerName: 'Projektbezeichnung',
            sortable: true,
            filter: true,
            editable: false,
        },
        {
            field: 'inquiryID',
            headerName: 'Anfragenummer',
            sortable: true,
            filter: true,
            editable: false,
        },
        {
            field: 'orders',
            headerName: 'Ausgelöst',
            sortable: true,
            filter: true,
            cellRenderer: 'orderArrayRenderer',
            cellRendererParams: {
                clicked: function (field) {
                    viewOrder(field);
                },
            },
            editable: false,
        },
        {
            field: '_id',
            headerName: 'View',
            cellRenderer: 'btnCellRenderer',
            cellRendererParams: {
                clicked: function (field) {
                    viewOffer(field._id);
                },
                label: 'View',
            },
            type: 'buttonColumn',
            filter: false,
            editable: false,
        },
        {
            field: '_id',
            headerName: 'PDF',
            cellRenderer: 'btnCellRenderer',
            cellRendererParams: {
                clicked: function (field) {
                    createPDF(field);
                },
                label: 'Download',
            },
            type: 'buttonColumn',
            filter: false,
            editable: false,
        },
    ];

    useEffect(() => {
        if (control.maintenance) {
            history.push('/maintenance');
        }
        const checkLoggedIn = async () => {
            let token = localStorage.getItem('auth-token');
            if (token === null) {
                localStorage.setItem('auth-token', '');
                token = '';
            }

            const tokenRes = await Axios.post(`${process.env.REACT_APP_SERVER}/users/tokenIsValid`, null, { headers: { 'x-auth-token': token } });

            if (tokenRes.data) {
                const res = await Axios.get(`${process.env.REACT_APP_SERVER}/files/offers/`, { headers: { 'x-auth-token': token } });
                setOffers(res.data.offers);

                setControl({
                    ...control,
                    viewOffer: 0,
                });
            } else {
                history.push('/login');
            }
        };
        checkLoggedIn();
    }, [history, setOffers]);

    const viewOffer = async (_id) => {
        setControl({
            ...control,
            viewOffer: _id,
        });

        history.push('/viewOffer');
    };

    const viewOrder = async (_id) => {
        setControl({
            ...control,
            viewOrder: _id,
        });

        history.push('/viewOrder');
    };

    /*
    const deleteOffer = async (_id) => {
        try {
            let token = localStorage.getItem('auth-token');
            await Axios.post(
                `${process.env.REACT_APP_SERVER}/files/deleteoffer`,
                { _id: _id },
                { headers: { 'x-auth-token': token } }
            );
            const ores = await Axios.get(
                `${process.env.REACT_APP_SERVER}/files/offers/`,
                { headers: { 'x-auth-token': token } }
            );
            setOffers(ores.data.offers);
        } catch (err) {
            console.log(err.message);
        }
    };*/

    const createPDF = async (offer) => {
        console.log(offer);
        try {
            let token = localStorage.getItem('auth-token');
            const config = {
                responseType: 'blob',
                headers: { 'x-auth-token': token },
                params: { offerID: offer._id },
            };

            Axios.get(`${process.env.REACT_APP_SERVER}/files/downloadofferpdf`, config).then((res) => {
                download(res.data, `Angebot_${offer.IDS}.pdf`);
            });
        } catch (err) {
            console.log(err);
            err.response && err.response.data && err.response.data.msg && setError(err.response.data.msg);
        }
    };

    return (
        <div>
            <div id="info-box">
                <InfoOffers />
                {error && <ErrorNotice message={error} clearError={() => setError(undefined)} />}
            </div>

            <div className="overview">
                <div className="ag-theme-alpine">
                    <AgGridReact
                        rowData={offers}
                        columnDefs={columnDefs}
                        frameworkComponents={frameworkComponents}
                        columnTypes={columnTypes}
                        defaultColDef={defaultColDef}
                        defaultColGroupDef={defaultColGroupDef}
                        pagination={true}
                        paginationAutoPageSize={true}
                    ></AgGridReact>
                </div>
            </div>
        </div>
    );
};

export default Offers;
