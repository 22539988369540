import React, { useState, useContext, useEffect } from 'react';
import Axios from 'axios';
import Modal from 'react-modal';
import UserContext from '../../context/UserContext';
import { Link, useHistory } from 'react-router-dom';
import ErrorNotice from '../misc/ErrorNotice';

export default function Login({ showModal, setShowModal, setShowRegister, setShowRequestPW }) {
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const { setUserData } = useContext(UserContext);
    const history = useHistory(); 
    const [error, setError] = useState();

    const modalClose = () => {
        setShowModal(false);
    };

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            position: 'absolute',
            transform: 'translate(-50%, -50%)',
            width: '90%',
            maxWidth: '700px',
            height: '400px',
        },
    };

    const submit = async (e) => {
        e.preventDefault();
        try {
            console.log(`react_app_server: ${process.env.REACT_APP_SERVER}/users/login`);
            console.log(`server: ${process.env.SERVER}/users/login`);

            const loginUser = { email, password };
            const loginRes = await Axios.post(`${process.env.REACT_APP_SERVER}/users/login`, loginUser);

            const adresses = [...loginRes.data.user.adresses];
            const rights = [...loginRes.data.user.rights];
            delete loginRes.data.user.adresses;
            delete loginRes.data.user.rights;

            setUserData({
                token: loginRes.data.token,
                user: loginRes.data.user,
                adresses: adresses,
                rights: rights,
            });
            localStorage.setItem('auth-token', loginRes.data.token);
            history.push('/');
            setShowModal(false);
            /*
            if (loginRes.data.user.rights.includes('admin')) {
                history.push('/admin');
            } else {
                history.push('/');
            }
            */
        } catch (err) {
            if (err.response && err.response.data) {
                err.response.data.msg && setError(err.response.data.msg);
            } else {
                console.log(err);
            }
        }
    };

    useEffect(() => {
        document.body.style.backgroundColor = 'grey';
    }, []);

    return (
        <Modal isOpen={showModal} onRequestClose={(e) => modalClose(e)} contentLabel={'modalLabel'} style={customStyles}>
            <div className="modalHeader">
                <div>
                    <h2>Login</h2>
                </div>
                <div>
                    <button id="buttonX" onClick={() => modalClose()}>
                        &#10006;
                    </button>
                </div>
            </div>
            <div className="modalBody">
                {error && <ErrorNotice message={error} clearError={() => setError(undefined)} />}
                <form className="form" onSubmit={submit}>
                    <div className="form-div100">
                        <label htmlFor="login-email">Email</label>
                        <br />
                        <input id="login-email" type="email" onChange={(e) => setEmail(e.target.value)} />
                    </div>
                    <div className="form-div100">
                        <label htmlFor="login-password">Password</label>
                        <br />
                        <input id="login-password" type="password" onChange={(e) => setPassword(e.target.value)} />
                    </div>
                    <button className="page-button-grey" type="submit">
                        Anmelden
                    </button>
                    <br />
                </form>
                <div id="register-requestpw">
                    <br />
                    Sie haben noch keinen Account?&nbsp;
                    <Link
                        onClick={() => {
                            setShowModal(false);
                            setShowRegister(true);
                        }}
                        style={{ textDecoration: 'underline' }}
                    >
                        Hier
                    </Link>
                    &nbsp;klicken.
                    <br />
                    Sie haben Ihr Passwort vergessen?&nbsp;
                    <Link
                        onClick={() => {
                            setShowModal(false);
                            setShowRequestPW(true);
                        }}
                        style={{ textDecoration: 'underline' }}
                    >
                        Hier
                    </Link>
                    &nbsp;klicken.
                </div>
            </div>
        </Modal>
    );
}
