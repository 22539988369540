import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import Modal from 'react-modal';
import { useHistory } from 'react-router-dom';
import ErrorNotice from '../misc/ErrorNotice';

export default function AdminAddUser({ showModal, setShowModal }) {
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [passwordCheck, setPasswordCheck] = useState();
    const [firstName, setFirstName] = useState();
    const [lastName, setLastName] = useState();
    const [salut, setSalut] = useState('Herr');
    const [saluts] = useState(['Herr', 'Frau']);
    const [error, setError] = useState();

    const [faName, setFaName] = useState();
    const [vatID, setVatID] = useState();
    const [street, setStreet] = useState();
    const [street2, setStreet2] = useState();
    const [additionalInfo, setAdditionalInfo] = useState();
    const [zip, setZip] = useState();
    const [city, setCity] = useState();
    const [country, setCountry] = useState();
    const [countries, setCountries] = useState([]);
    const [internalNumber, setInternalNumber] = useState();

    const modalClose = () => {
        setShowModal(false);
    };

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            position: 'absolute',
            transform: 'translate(-50%, -50%)',
            width: '90%',
            maxWidth: '1000px',
            height: '60%',
        },
    };

    const onCountryChange = (_country) => {
        setCountry(_country);
    };

    const history = useHistory();

    const submit = async (e) => {
        e.preventDefault();
        if (password !== passwordCheck) {
            setError('Passwörter sind ungleich');
            return;
        }
        try {
            let token = localStorage.getItem('auth-token');
            let payload = {
                email,
                password,
                passwordCheck,
                faName,
                vatID,
                firstName,
                lastName,
                salut,
                street,
                street2,
                additionalInfo,
                zip,
                city,
                country,
                internalNumber,
            };
            await Axios.post(`${process.env.REACT_APP_SERVER}/admin/adduser`, payload, { headers: { 'x-auth-token': token } });

            history.push('/adminaccounts');
        } catch (err) {
            err.response.data.msg && setError(err.response.data.msg);
        }
    };

    useEffect(() => {
        const getCountries = async () => {
            try {
                let token = localStorage.getItem('auth-token');

                const cres = await Axios.get(`${process.env.REACT_APP_SERVER}/admin/countries/`, { headers: { 'x-auth-token': token } });
                if (cres) {
                    console.log(cres.data.countries);
                    setCountries(cres.data.countries);
                    setCountry(cres.data.countries?.[0]?._id);
                }
            } catch (err) {
                console.log(err);
            }
        };
        getCountries();
    }, []);

    return (
        <Modal isOpen={showModal} onRequestClose={(e) => modalClose(e)} contentLabel={'modalLabel'} style={customStyles}>
            <div className="modalHeader">
                <div>
                    <h2>Admin - Nutzer hinzuf&uuml;gen</h2>
                </div>
                <div>
                    <button id="buttonX" onClick={() => modalClose()}>
                        &#10006;
                    </button>
                </div>
            </div>
            <div className="modalBody">
                {' '}
                {error && <ErrorNotice message={error} clearError={() => setError(undefined)} />}
                <form className="form" onSubmit={submit}>
                    <div className="form-div100">
                        <label htmlFor="register-email">Email</label>
                        <br />
                        <input id="register-email" type="email" onChange={(e) => setEmail(e.target.value)} required />
                    </div>
                    <div className="form-div100">
                        <label htmlFor="register-password">Password</label>
                        <br />
                        <input id="register-password" type="password" onChange={(e) => setPassword(e.target.value)} required />
                    </div>
                    <div className="form-div100">
                        <label htmlFor="verify-password">Verify Password</label>
                        <input
                            type="password"
                            id="verify-password"
                            placeholder="Verify password"
                            onChange={(e) => setPasswordCheck(e.target.value)}
                            required
                        />
                    </div>
                    <div className="form-div100">
                        <label className="adressLabel">Firmenname</label>
                        <br />
                        <input id="adr1" type="text" value={faName} onChange={(e) => setFaName(e.target.value)} placeholder="Firmenname" />
                    </div>
                    <div className="form-div100">
                        <label className="adressLabel">USt. ID</label>
                        <br />
                        <input id="adr1" type="text" value={vatID} onChange={(e) => setVatID(e.target.value)} placeholder="Umsatzsteuer-ID" />
                    </div>

                    <div className="form-div100">
                        <label htmlFor="account-salut">Anrede</label>
                        <br />
                        <select id="adr8" className="country-select" onChange={(e) => setSalut(e.currentTarget.value)} value={salut}>
                            {saluts.map((item) => (
                                <option key={item} value={item}>
                                    {item}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="form-div50">
                        <label htmlFor="account-name">Vorname</label>
                        <br />
                        <input id="account-name" type="text" value={firstName} onChange={(e) => setFirstName(e.target.value)} required />
                    </div>
                    <div className="form-div50">
                        <label htmlFor="account-lastname">Name</label>
                        <br />
                        <input id="account-lastname" type="text" value={lastName} onChange={(e) => setLastName(e.target.value)} required />
                    </div>
                    <div className="form-div80">
                        <label className="adressLabel">Straße</label>
                        <br />
                        <input id="adr5" type="text" value={street} onChange={(e) => setStreet(e.target.value)} placeholder="Straße" required />
                    </div>
                    <div className="form-div20">
                        <label className="adressLabel">Hausnummer</label>
                        <br />
                        <input
                            id="adr5b"
                            type="text"
                            value={street2}
                            onChange={(e) => setStreet2(e.target.value)}
                            placeholder="Hausnummer"
                            required
                        />
                    </div>
                    <div className="form-div100">
                        <label className="adressLabel">Adresszusatz</label>
                        <br />
                        <input
                            id="adr5a"
                            type="text"
                            value={additionalInfo}
                            onChange={(e) => setAdditionalInfo(e.target.value)}
                            placeholder="Adresszusatz"
                        />
                    </div>
                    <div className="form-div20">
                        <label className="adressLabel">Postleitzahl</label>
                        <br />
                        <input id="adr6" type="text" value={zip} onChange={(e) => setZip(e.target.value)} placeholder="Postleitzahl" required />
                    </div>
                    <div className="form-div80">
                        <label className="adressLabel">Ort</label>
                        <br />
                        <input id="adr7" type="text" value={city} onChange={(e) => setCity(e.target.value)} placeholder="Ort" required />
                    </div>
                    <div className="form-div100">
                        <label className="adressLabel">Land</label>
                        <br />
                        <select id="adr8" className="country-select" onChange={(e) => onCountryChange(e.currentTarget.value)} value={country}>
                            {countries.map((item) => (
                                <option key={item._id} value={item._id}>
                                    {item.name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="form-div100">
                        <label className="adressLabel">Gesch&auml;ftspartnernummber</label>
                        <br />
                        <input
                            id="adr9"
                            type="text"
                            value={internalNumber}
                            onChange={(e) => setInternalNumber(e.target.value)}
                            placeholder="Gesch&auml;ftspartnernummber"
                        />
                    </div>

                    <input type="submit" value="Nutzer anlegen" />
                </form>
            </div>
        </Modal>
    );
}
