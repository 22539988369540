import React, { useState, useContext, useEffect } from 'react';
import Axios from 'axios';
import ControlContext from '../../context/ControlContext';
import { useHistory } from 'react-router-dom';
import Modal from 'react-modal';

import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

import ErrorNotice from '../misc/ErrorNotice';
import FileDetailsDelivery from '../file/FileDetailsDelivery';
import FileExtraTaskDelivery from '../file/FileExtraTaskDelivery';
import { NumericFormat } from 'react-number-format';
import download from 'downloadjs';

import Select from 'react-select';
import { customStyleLarge, formatOptionLabelAdress2 } from '../misc/SelectOptions';

const DeliveryPage = ({ showModal, setShowModal }) => {
    const { control } = useContext(ControlContext);
    const history = useHistory();
    const [error, setError] = useState();
    const [order, setOrder] = useState();
    const [packages, setPackages] = useState([]);
    const [items, setItems] = useState([]);

    const [senderAdresses, setSenderAdresses] = useState([]);
    const [senderAdressOptions, setSenderAdressOptions] = useState([]);

    const [senderAdress, setSenderAdress] = useState();
    const [deliveryAdress, setDeliveryAdress] = useState();

    const modalClose = () => {
        setShowModal(false);
    };

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            position: 'absolute',
            transform: 'translate(-50%, -50%)',
            width: '90%',
            maxWidth: '1500px',
            height: '70%',
        },
    };

    const allPacked = () => {
        let result = true;
        items.forEach((it, index) => {
            let alreadyPacked = 0;
            packages.forEach((pa, pindex) => {
                alreadyPacked += parseInt(pa.items[index].packAmount);
            });

            if (alreadyPacked < parseInt(it.total)) {
                result = false;
            }
        });
        return result;
    };

    const onAddPackage = () => {
        let pack = {};
        pack.items = [];
        let weight = 0;

        items.forEach((it, index) => {
            let pi = {};
            pi.item = it;
            pi.total = items[index].total;

            let alreadyPacked = 0;
            packages.forEach((pa, pindex) => {
                alreadyPacked += parseInt(pa.items[index].packAmount);
                //console.log(`item ${index} in pack ${pindex} : ${pa.items[index].packAmount} :  total ${alreadyPacked}`);
            });

            pi.alreadyPacked = alreadyPacked;
            pi.packAmount = items[index].total - alreadyPacked;
            pack.items.push(pi);

            if (it._id) {
                weight += (pi.packAmount * it.density * it.volume) / 1000;
            }
        });

        if (!allPacked()) {
            pack.weight = weight;
            setPackages([...packages, pack]);
        } else {
            confirmAlert({
                title: 'Alles verpackt!',
                message: 'Es ist nichts mehr zu verpacken. Wollen Sie die Bestellung abschließen?',
                buttons: [
                    {
                        label: 'Ja',
                        onClick: async () => {
                            try {
                                let token = localStorage.getItem('auth-token');
                                const res = await Axios.post(
                                    `${process.env.REACT_APP_SERVER}/admin/finalizeorder`,
                                    { _id: order._id, IDS: order.IDS },
                                    { headers: { 'x-auth-token': token } }
                                );
                                order.allPacked = true;
                            } catch (err) {
                                console.log(err);
                            }
                        },
                    },
                    {
                        label: 'Nein',
                        onClick: () => {},
                    },
                ],
            });
        }
    };

    const onSetAmount = (pindex, iindex, amount) => {
        let packs = [...packages];
        packs[pindex].items[iindex].packAmount = parseInt(amount);

        let weight = 0;
        packs[pindex].items.forEach((it) => {
            weight += (it.packAmount * it.item.density * it.item.volume) / 1000;
        });
        packs[pindex].weight = weight;

        setPackages(packs);
    };

    const onSetAmountTask = (pindex, iindex, tindex, amount) => {
        let packs = [...packages];
        packs[pindex].items[iindex].packAmount = parseInt(amount);

        let weight = 0;
        packs[pindex].items.forEach((it) => {
            weight += (it.packAmount * it.item.density * it.item.volume) / 1000;
        });
        packs[pindex].weight = weight;

        setPackages(packs);
    };

    const onDeletePackage = (pindex) => {
        confirmAlert({
            title: 'Löschen bestätigen',
            message: 'Wollen Sie dieses Paket wirklich löschen?.',
            buttons: [
                {
                    label: 'Ja',
                    onClick: async () => {
                        try {
                            let token = localStorage.getItem('auth-token');
                            let pack = JSON.parse(JSON.stringify(packages[pindex]));

                            if (pack._id) {
                                const res = await Axios.post(
                                    `${process.env.REACT_APP_SERVER}/admin/deletepackage`,
                                    { _id: pack._id },
                                    { headers: { 'x-auth-token': token } }
                                );
                                if (res && res.data.msg === 'OK') {
                                    await updatePackages(order._id, items);
                                }
                            } else {
                                let newPacks = [...packages];
                                newPacks.pop();
                                setPackages([...newPacks]);
                            }
                        } catch (err) {
                            console.log(err);
                        }
                    },
                },
                {
                    label: 'Nein',
                    onClick: () => {},
                },
            ],
        });
    };

    const onSave = async (pindex) => {
        if (pindex < packages.length) {
            //console.log(packages[pindex]);

            let pack = JSON.parse(JSON.stringify(packages[pindex]));

            pack.items.forEach((it) => {
                it._id = it.item._id;
                if (it._id) {
                    delete it.item;
                } else {
                    delete it.item.prices;
                }
            });

            try {
                let token = localStorage.getItem('auth-token');

                const res = await Axios.post(
                    `${process.env.REACT_APP_SERVER}/admin/savepackage`,
                    { _id: pack._id, order: order._id, weight: pack.weight, package: pack },
                    { headers: { 'x-auth-token': token } }
                );
                if (res && res.data.msg === 'OK') {
                    let packs = [...packages];
                    packs[pindex]._id = res.data._id;
                    packs[pindex].IDS = res.data.IDS;
                    setPackages(packs);
                }

                if (allPacked()) {
                    confirmAlert({
                        title: 'Alles verpackt!',
                        message: 'Es ist nichts mehr zu verpacken. Wollen Sie die Bestellung abschließen?',
                        buttons: [
                            {
                                label: 'Ja',
                                onClick: async () => {
                                    try {
                                        let token = localStorage.getItem('auth-token');
                                        const res = await Axios.post(
                                            `${process.env.REACT_APP_SERVER}/admin/finalizeorder`,
                                            { _id: order._id, IDS: order.IDS },
                                            { headers: { 'x-auth-token': token } }
                                        );
                                        order.allPacked = true;
                                    } catch (err) {
                                        console.log(err);
                                    }
                                },
                            },
                            {
                                label: 'Nein',
                                onClick: () => {},
                            },
                        ],
                    });
                }
            } catch (err) {
                setError(err.message);
                console.log(err);
            }
        }
    };

    const onCreate = async (pindex) => {
        if (pindex < packages.length) {
            try {
                let token = localStorage.getItem('auth-token');
                const config = {
                    responseType: 'blob',
                    headers: { 'x-auth-token': token },
                    params: { orderID: order._id, packageID: packages[pindex]._id },
                };

                Axios.get(`${process.env.REACT_APP_SERVER}/admin/lieferschein`, config).then((res) => {
                    download(res.data, `lieferschein_${packages[pindex].IDS}.pdf`);
                });
            } catch (err) {
                console.log(err);
                err.response && err.response.data && err.response.data.msg && setError(err.response.data.msg);
            }
        }
    };

    const onDHLCreate = async (pindex) => {
        if (pindex < packages.length) {
            try {
                let token = localStorage.getItem('auth-token');
                const payload = {
                    orderID: order._id,
                    packageID: packages[pindex]._id,
                    senderAdress: senderAdress.adr,
                };
                const res = await Axios.post(`${process.env.REACT_APP_SERVER}/admin/dhlcreate`, payload, { headers: { 'x-auth-token': token } });

                if (res.data.msg === 'OK') {
                    await updatePackages(order._id, items);
                } else if (res.data.msg === 'DHL_ERROR') {
                    console.log(res.data.info);

                    setError(`${res.data.info?.faultcode} || ${res.data.info?.faultstring}`);
                    let packs = [...packages];
                    packs[pindex].error = res.data.info?.LabelData?.Status?.statusText;
                    packs[pindex].error2 = res.data.info?.LabelData?.Status?.statusMessage.join();
                    setPackages([...packs]);
                }
            } catch (err) {
                err.response && err.response.data && err.response.data.msg && setError(err.response.data.msg);
            }
        }
    };

    const onDHLCancel = async (pindex) => {};

    const onDHLSendMail = async (pindex) => {
        if (pindex < packages.length) {
            try {
                let token = localStorage.getItem('auth-token');

                await Axios.post(
                    `${process.env.REACT_APP_SERVER}/admin/dhlsendmail`,
                    { orderID: order._id, packageID: packages[pindex]._id },
                    { headers: { 'x-auth-token': token } }
                );
            } catch (err) {
                console.log(err);
                err.response && err.response.data && err.response.data.msg && setError(err.response.data.msg);
            }
        }
    };

    const onDHLDownloadLabel = async (pindex) => {
        if (pindex < packages.length) {
            try {
                let token = localStorage.getItem('auth-token');
                const config = {
                    responseType: 'blob',
                    headers: { 'x-auth-token': token },
                    params: { orderID: order._id, packageID: packages[pindex]._id },
                };

                Axios.get(`${process.env.REACT_APP_SERVER}/admin/packagelabel`, config).then((res) => {
                    download(res.data, `versandlabel_${order.IDS}_${packages[pindex].IDS}.pdf`);
                });
            } catch (err) {
                console.log(err);
                err.response && err.response.data && err.response.data.msg && setError(err.response.data.msg);
            }
        }
    };

    const updatePackages = async (orderID, itms) => {
        let token = localStorage.getItem('auth-token');
        const config = {
            headers: { 'x-auth-token': token },
            params: { orderID: orderID },
        };

        const pres = await Axios.get(`${process.env.REACT_APP_SERVER}/admin/packages`, config);

        if (pres && pres.data.packages?.length > 0) {
            let packs = JSON.parse(JSON.stringify(pres.data.packages));
            packs.forEach((pa) => {
                pa.items.forEach((it, iindex) => {
                    it.item = JSON.parse(JSON.stringify(itms[iindex]));
                });
            });

            setPackages([...packs]);
        } else {
            setPackages([]);
        }
    };

    useEffect(() => {
        const getOrder = async () => {
            try {
                if (control.viewOrder && control.viewOrder !== 0) {
                    let token = localStorage.getItem('auth-token');
                    const res = await Axios.post(
                        `${process.env.REACT_APP_SERVER}/files/order`,
                        { _id: control.viewOrder },
                        { headers: { 'x-auth-token': token } }
                    );
                    setOrder(res.data);
                    setDeliveryAdress(res.data.deliveryAdress);

                    let itms = [];
                    res.data.items.forEach((item) => {
                        let name1 = item.fileName || '';
                        let name2 = name1.replace('.stl', '');
                        let name = name2.replace('.STL', '');
                        item.name = name;
                        item.total = item.selectedPrice.amount;
                        item.type = 'item';
                        itms.push(item);
                        let artSuffix = 3;

                        if (item.processing) {
                            itms.push({
                                name: `${item?.processing?.displayName} ${name}`,
                                type: 'processing',
                                total: item.total,
                                references: item.fileName,
                                IDS: `${item.IDS}_1`,
                                selectedPrice: {
                                    amount: item.selectedPrice.amount,
                                    price: item.selectedPrice.procPrice,
                                },
                            });
                        }

                        if (item.color) {
                            itms.push({
                                name: `Färbung ${name} ${item.color.displayName}`,
                                type: 'color',
                                total: item.total,
                                references: item.fileName,
                                IDS: `${item.IDS}_2`,
                                selectedPrice: {
                                    amount: item.selectedPrice.amount,
                                    price: item.selectedPrice.colorPrice,
                                },
                            });
                        }

                        item.tasks.forEach((task) => {
                            task.total = item.selectedPrice.amount;
                            task.type = 'task';
                            task.IDS = `${item.IDS}_${artSuffix++}`;
                            itms.push(task);
                        });
                    });
                    res.data.tasks.forEach((item) => {
                        item.total = item.selectedPrice.amount;
                        item.type = 'globaltask';
                        itms.push(item);
                    });

                    if (res.data.summary.surcharge > 0) {
                        itms.push({
                            name: 'Mindermengenzuschlag',
                            IDS: res.data.items[0]?.IDS + '_S',
                            type: 'surcharge',
                            references: '',
                            total: 1,
                            selectedPrice: {
                                amount: 1,
                                price: res.data.summary.surcharge,
                            },
                        });
                    }

                    if (res.data.summary.delivery > 0) {
                        itms.push({
                            name: res.data.deliveryMethod.displayName,
                            type: 'delivery',
                            IDS: res.data.items[0]?.IDS + '_D',
                            references: '',
                            total: 1,
                            selectedPrice: {
                                amount: 1,
                                price: res.data.summary.delivery,
                            },
                        });
                    }

                    setItems(itms);

                    await updatePackages(res.data._id, itms);
                    console.log(res.data.deliveryMethod);

                    const res2 = await Axios.get(`${process.env.REACT_APP_SERVER}/admin/settings/`, { headers: { 'x-auth-token': token } });
                    if (res2) {
                        setSenderAdresses(res2.data.adresses);
                    }
                }
            } catch (err) {
                setError(err.message);
            }
        };
        getOrder();
    }, [control.viewOrder]);

    useEffect(() => {
        let options = [];
        senderAdresses.forEach((adr) => {
            const opt = {
                value: `${adr._id}`,
                label: adr.faName,
                adr: adr,
            };
            options.push(opt);
        });
        setSenderAdressOptions(options);
        if (options.length > 0) setSenderAdress(options[0]);
    }, [senderAdresses]);

    useEffect(() => {
        const checkLoggedIn = async () => {
            let token = localStorage.getItem('auth-token');
            if (token === null) {
                localStorage.setItem('auth-token', '');
                token = '';
                setShowModal(false);
                history.push('/login');
            }

            const tokenRes = await Axios.post(`${process.env.REACT_APP_SERVER}/users/tokenIsValid`, null, { headers: { 'x-auth-token': token } });

            if (!tokenRes.data) {
                setShowModal(false);
                history.push('/login');
            }
        };
        checkLoggedIn();
    }, []);

    const onSenderChange = (adr) => {
        setSenderAdress(adr);
    };

    return (
        <>
            <Modal isOpen={showModal} onRequestClose={(e) => modalClose(e)} contentLabel={'modalLabel'} style={customStyles}>
                <div className="modalHeader">
                    <div>
                        <h2>Admin - Lieferschein</h2>
                    </div>
                    <div>
                        <button id="buttonX" onClick={() => modalClose()}>
                            &#10006;
                        </button>
                    </div>
                </div>
                <div className="modalBody">
                    {error && <ErrorNotice message={error} clearError={() => setError(undefined)} />}
                    <div className="order-summary">
                        <div className="order-summary-column">
                            {senderAdress ? (
                                <>
                                    <b>Absender:</b>
                                    <Select
                                        styles={customStyleLarge}
                                        value={senderAdress}
                                        onChange={onSenderChange}
                                        options={senderAdressOptions}
                                        formatOptionLabel={formatOptionLabelAdress2}
                                        isSearchable={false}
                                    />
                                </>
                            ) : null}
                        </div>
                        <div className="order-summary-column">
                            {deliveryAdress ? (
                                <>
                                    <b>Empfänger:</b>
                                    <br />
                                    {deliveryAdress.faName}
                                    <br />
                                    {deliveryAdress.dpt}
                                    <br />
                                    {deliveryAdress.firstName} {deliveryAdress.lastName}
                                    <br />
                                    {deliveryAdress.street} {deliveryAdress.street2}
                                    <br />
                                    {deliveryAdress.zip} {deliveryAdress.city}
                                    <br />
                                </>
                            ) : null}
                        </div>
                    </div>
                    {order?.deliveryMethod?.isExpress && (
                        <div className="deliveryExpressWarning">
                            <p>EXPRESS !!!</p>
                        </div>
                    )}

                    {order &&
                        packages.length > 0 &&
                        packages.map((pack, pindex) => (
                            <>
                                <h2>Paket {pindex + 1}</h2>

                                <div className="item-column">
                                    {pack.items.map((it, iindex) => (
                                        <>
                                            {it.item.type === 'item' ? (
                                                <FileDetailsDelivery
                                                    key={it.item._id}
                                                    item={it.item}
                                                    total={it.total}
                                                    alreadyPacked={it.alreadyPacked}
                                                    inHere={it.packAmount}
                                                    pindex={pindex}
                                                    iindex={iindex}
                                                    readOnly={false}
                                                    onSetAmount={onSetAmount}
                                                />
                                            ) : (
                                                <FileExtraTaskDelivery
                                                    key={`fdtask${iindex}`}
                                                    position={`${iindex + 1}`}
                                                    task={it.item}
                                                    pindex={pindex}
                                                    iindex={iindex}
                                                    onSetAmount={onSetAmount}
                                                    readOnly={false}
                                                    total={it.total}
                                                    alreadyPacked={it.alreadyPacked}
                                                    inHere={it.packAmount}
                                                />
                                            )}
                                        </>
                                    ))}
                                </div>
                                <NumericFormat
                                    value={pack.weight}
                                    displayType={'text'}
                                    prefix={'Gewicht: ca. '}
                                    suffix={' kg'}
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    thousandSeparator={'.'}
                                    decimalSeparator={','}
                                />
                                <button className="page-button" onClick={() => onSave(pindex)}>
                                    Speichern
                                </button>
                                {pack._id && (
                                    <>
                                        <button className="page-button" onClick={() => onCreate(pindex)}>
                                            Lieferschein
                                        </button>

                                        {pack.dhlTrackingNumber ? (
                                            <>
                                                <button className="page-button" onClick={() => onDHLDownloadLabel(pindex)}>
                                                    Versandlabel
                                                </button>
                                                Trackingnummer: {pack.dhlTrackingNumber}
                                                {/*}
                                                <button className="page-button" onClick={() => onDHLCancel(pindex)}>
                                                    DHL cancel
                                        </button>*/}
                                                <button className="page-button" onClick={() => onDHLSendMail(pindex)}>
                                                    Mail versenden
                                                </button>
                                            </>
                                        ) : (
                                            <>
                                                {order?.deliveryMethod?.isExpress ? (
                                                    <p>Express - Manuelle Anmeldung</p>
                                                ) : (
                                                    <button className="page-button" onClick={() => onDHLCreate(pindex)}>
                                                        DHL anmelden
                                                    </button>
                                                )}
                                            </>
                                        )}
                                        {/*
                                        <button className="page-button" onClick={() => onDHLCreate(pindex)}>
                                            DHL Test
                                        </button>*/}
                                    </>
                                )}
                                {pindex === packages.length - 1 ? (
                                    <button className="page-button" onClick={() => onDeletePackage(pindex)}>
                                        Löschen
                                    </button>
                                ) : null}
                                <br />
                                {pack.error && (
                                    <>
                                        <label>{pack.error}</label>
                                        <br />
                                        <label>{pack.error2}</label>
                                    </>
                                )}
                            </>
                        ))}
                    <div id="offer-footer-right">
                        <div id="offer-footer-buttons">
                            {!allPacked() ? (
                                <button className="page-button" onClick={() => onAddPackage()}>
                                    Paket hinzufügen
                                </button>
                            ) : null}
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default DeliveryPage;
