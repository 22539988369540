import React, { useState, useEffect, useContext } from 'react';
import Axios from 'axios';
import Modal from 'react-modal';
import { useHistory } from 'react-router-dom';
import ControlContext from '../../context/ControlContext';
import ErrorNotice from '../misc/ErrorNotice';

import Select from 'react-select';
import { customStyleLarge, customStyleSmall, formatOptionLabelAdress } from '../misc/SelectOptions';

export default function AdminTicket({ showModal, setShowModal, ticket, saveTicket, ticketAssignees }) {
    const { control, setControl } = useContext(ControlContext);
    const [error, setError] = useState();
    const [id, setId] = useState();
    const [number, setNumber] = useState();
    const [title, setTitle] = useState();
    const [text, setText] = useState();
    const [prio, setPrio] = useState();
    const [prios] = useState([
        {
            value: 0,
            label: 'sehr niedrig',
        },
        {
            value: 1,
            label: 'niedrig',
        },
        {
            value: 2,
            label: 'normal',
        },
        {
            value: 3,
            label: 'hoch',
        },
        {
            value: 4,
            label: 'sehr hoch',
        },
    ]);
    const [status, setStatus] = useState();
    const [stati] = useState([
        {
            value: 'new',
            label: 'neu',
        },
        {
            value: 'open',
            label: 'offen',
        },
        {
            value: 'closed',
            label: 'geschlossen',
        },
    ]);
    const [assigned, setAssigned] = useState();
    const [assignees, setAssignees] = useState();

    useEffect(() => {
        if (ticket) {
            setId(ticket._id);
            setTitle(ticket.title);

            if (ticket._id) {
                setNumber(ticket.ID);
                setText(ticket.text);
                setPrio(prios[ticket.priority]);
                setStatus(stati.find((st) => st.value === ticket.status));
                setAssigned(assignees.find((st) => st.value === ticket.assigned._id));
            }
        }
    }, [ticket, prios]);

    useEffect(() => {
        if (ticketAssignees && ticketAssignees.length > 0) {
            let asses = [];
            ticketAssignees.forEach((element) => {
                asses.push({
                    value: element._id,
                    label: `${element.firstName} ${element.lastName}`,
                });
            });
            setAssignees(asses);
        }
    }, [ticketAssignees]);

    const modalClose = () => {
        setShowModal(false);
        setControl({ ...control, editAccount: 0 });
    };

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            position: 'absolute',
            transform: 'translate(-50%, -50%)',
            width: '90%',
            maxWidth: '1000px',
            height: '60%',
        },
    };

    const submit = async (e) => {
        e.preventDefault();

        saveTicket({ _id: id, title: title, text: text, priority: prio.value, assigned: assigned.value, status: status.value });
    };

    const onPrioChange = (param) => {
        setPrio(prios[param.value]);
    };

    const onStatusChange = (param) => {
        setStatus(stati.find((st) => st.value === param.value));
    };

    const onAssignChange = (param) => {
        setAssigned(assignees.find((st) => st.value === param.value));
    };

    return (
        <Modal isOpen={showModal} onRequestClose={(e) => modalClose(e)} contentLabel={'modalLabel'} style={customStyles}>
            <div className="modalHeader">
                <div>
                    <h2>Admin - Ticket</h2>
                </div>
                <div>
                    <button id="buttonX" onClick={() => modalClose()}>
                        &#10006;
                    </button>
                </div>
            </div>
            <div className="modalBody">
                {error && <ErrorNotice message={error} clearError={() => setError(undefined)} />}
                <form className="form" onSubmit={submit}>
                    <div className="form-div100">
                        <label>Ticket:{number}</label>
                    </div>
                    <div className="form-div50">
                        Priorität:
                        <Select value={prio} onChange={onPrioChange} options={prios} styles={customStyleSmall} isSearchable={false} />
                    </div>
                    <div className="form-div50">
                        Bearbeiter:
                        <Select value={assigned} onChange={onAssignChange} options={assignees} styles={customStyleSmall} isSearchable={false} />
                    </div>
                    <div className="form-div50">
                        Status:
                        <Select value={status} onChange={onStatusChange} options={stati} styles={customStyleSmall} isSearchable={false} />
                    </div>
                    <div className="form-div100">
                        <label htmlFor="title">Betreff</label>
                        <br />
                        <input id="title" type="text" value={title} onChange={(e) => setTitle(e.target.value)} required />
                    </div>
                    <div className="form-div100">
                        <label htmlFor="title">Text</label>
                        <br />

                        <textarea rows="10" cols="120" onChange={(e) => setText(e.currentTarget.value)} value={text}></textarea>
                    </div>
                    <input type="submit" value="Speichern" />
                </form>
            </div>
        </Modal>
    );
}
