import React, { Component } from 'react';

import pdf from '../../img/pdf.png';
import view from '../../img/view.png';
import download from '../../img/download.png';
import attention from '../../img/attention.png';
import attention2 from '../../img/attention2.png';
import comment from '../../img/comment.png';
import delivery from '../../img/delivery.png';

class BtnCellRenderer extends Component {
    constructor(props) {
        super(props);
        this.btnClickedHandler = this.btnClickedHandler.bind(this);
    }
    btnClickedHandler() {
        this.props.clicked(this.props.data);
    }
    render() {
        return (
            <button className="gridButton" onClick={this.btnClickedHandler}>
                {this.props.label}
            </button>
        );
    }
}

class BtnPDFCellRenderer extends Component {
    constructor(props) {
        super(props);
        this.btnClickedHandler = this.btnClickedHandler.bind(this);
    }
    btnClickedHandler() {
        this.props.clicked(this.props.data);
    }
    render() {
        return (
            <button onClick={this.btnClickedHandler}>
                <img src={pdf} width={32} height="auto" alt="PDF" />
            </button>
        );
    }
}

class BtnDownloadCellRenderer extends Component {
    constructor(props) {
        super(props);
        this.btnClickedHandler = this.btnClickedHandler.bind(this);
    }
    btnClickedHandler() {
        this.props.clicked(this.props.data);
    }
    render() {
        return (
            <button onClick={this.btnClickedHandler}>
                <img src={download} width={32} height="auto" alt="Download" />
            </button>
        );
    }
}

class BtnViewCellRenderer extends Component {
    constructor(props) {
        super(props);
        this.btnClickedHandler = this.btnClickedHandler.bind(this);
    }
    btnClickedHandler() {
        this.props.clicked(this.props.data);
    }
    render() {
        return (
            <button onClick={this.btnClickedHandler}>
                <img src={view} width={32} height="auto" alt="View" />
            </button>
        );
    }
}

class BtnAttentionCellRenderer extends Component {
    constructor(props) {
        super(props);
        this.btnClickedHandler = this.btnClickedHandler.bind(this);
    }
    btnClickedHandler() {
        this.props.clicked(this.props.data);
    }
    render() {
        return (
            <>
                {this.props.data.needsAdminAttention === 2 ? (
                    <button onClick={this.btnClickedHandler}>
                        <img src={attention} width={32} height="auto" alt="Attention" />{' '}
                    </button>
                ) : null}
                {this.props.data.needsAdminAttention === 1 ? (
                    <button onClick={this.btnClickedHandler}>
                        <img src={attention2} width={32} height="auto" alt="Attention2" />{' '}
                    </button>
                ) : null}
            </>
        );
    }
}

class BtnCommentCellRenderer extends Component {
    constructor(props) {
        super(props);
        this.btnClickedHandler = this.btnClickedHandler.bind(this);
    }
    btnClickedHandler() {
        this.props.clicked(this.props.data);
    }
    render() {
        return (
            <>
                {this.props.data.unansweredMessage ? (
                    <button onClick={this.btnClickedHandler}>
                        <img src={comment} width={32} height="auto" alt="Comment" />
                    </button>
                ) : null}
            </>
        );
    }
}

class BtnDeliveryCellRenderer extends Component {
    constructor(props) {
        super(props);
        this.btnClickedHandler = this.btnClickedHandler.bind(this);
    }
    btnClickedHandler() {
        this.props.clicked(this.props.data);
    }
    render() {
        return (
            <button onClick={this.btnClickedHandler}>
                <img src={delivery} width={32} height="auto" alt="Delivery" />
            </button>
        );
    }
}

export {
    BtnCellRenderer,
    BtnPDFCellRenderer,
    BtnDownloadCellRenderer,
    BtnViewCellRenderer,
    BtnAttentionCellRenderer,
    BtnCommentCellRenderer,
    BtnDeliveryCellRenderer,
};
