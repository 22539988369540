import React from 'react';

const InfoOffers = () => {
    return (
        <div className="info-area">
            <div className="page-title">
                <h2>Angebote</h2>
                <hr className="solid"></hr>
            </div>
            <div className="paragraph_30"></div>
        </div>
    );
};

export default InfoOffers;
