import React, { useState, useContext, useEffect } from 'react';
import Axios from 'axios';
import UserContext from '../../context/UserContext';
import ControlContext from '../../context/ControlContext';
import { useHistory } from 'react-router-dom';
import ErrorNotice from '../misc/ErrorNotice';
import download from 'downloadjs';
import { AgGridReact } from 'ag-grid-react';
import {
    BtnCellRenderer,
    BtnPDFCellRenderer,
    BtnDownloadCellRenderer,
    BtnViewCellRenderer,
    BtnAttentionCellRenderer,
    BtnCommentCellRenderer,
} from '../admin/BtnCellRenderer';
import OrderArrayRenderer from '../misc/OrderArrayRenderer';
import { columnTypes, defaultColDef, defaultColGroupDef, dateTimeFormatter, expiryFormatter } from '../misc/ColumnTypes';
import AutoButton from '../misc/AutoButton';
import { checkAdmin } from '../account/AccountUtils';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

const AdminOffers = () => {
    const { userData } = useContext(UserContext);
    const { control, setControl } = useContext(ControlContext);
    const history = useHistory();
    const [error, setError] = useState();
    const [offers, setOffers] = useState();

    const frameworkComponents = {
        btnCellRenderer: BtnCellRenderer,
        btnPDFCellRenderer: BtnPDFCellRenderer,
        btnDownloadCellRenderer: BtnDownloadCellRenderer,
        btnViewCellRenderer: BtnViewCellRenderer,
        btnAttentionCellRenderer: BtnAttentionCellRenderer,
        btnCommentCellRenderer: BtnCommentCellRenderer,
        orderArrayRenderer: OrderArrayRenderer,
    };

    const columnDefs = [
        {
            field: 'IDS',
            headerName: 'Angebotsnummer',
            sortable: true,
            filter: true,
            editable: false,
            onCellClicked: (params) => {
                viewOffer(params.data._id);
            },
        },
        {
            field: 'customerID.email',
            headerName: 'Account',
            sortable: true,
            filter: true,
            type: 'accountColumn',
            editable: false,
        },
        {
            field: 'created',
            headerName: 'Erstellt am',
            sortable: true,
            filter: true,
            type: 'dateColumn',
            valueFormatter: dateTimeFormatter,
            sort: 'desc',
            editable: false,
        },
        {
            field: 'expires',
            headerName: 'Läuft ab',
            sortable: true,
            filter: true,
            valueFormatter: expiryFormatter,
            editable: false,
        },
        {
            field: 'projectID',
            headerName: 'Projektbezeichnung',
            sortable: true,
            filter: true,
            editable: false,
        },
        {
            field: 'inquiryID',
            headerName: 'Anfragenummer',
            sortable: true,
            filter: true,
            editable: false,
        },
        {
            field: 'orders',
            headerName: 'Ausgelöst',
            sortable: true,
            filter: true,
            cellRenderer: 'orderArrayRenderer',
            cellRendererParams: {
                clicked: function (field) {
                    viewOrder(field);
                },
            },
            editable: false,
        },

        {
            field: '_id',
            headerName: 'View',
            cellRenderer: 'btnViewCellRenderer',
            cellRendererParams: {
                clicked: function (field) {
                    viewOffer(field);
                },
                label: 'View',
            },
            type: 'iconColumn',
            editable: false,
            filter: false,
        },
        {
            field: '_id',
            headerName: 'Files',
            cellRenderer: 'btnDownloadCellRenderer',
            cellRendererParams: {
                clicked: function (field) {
                    downloadFiles(field);
                },
                label: 'Download',
            },
            type: 'iconColumn',
            editable: false,
            filter: false,
        },
        {
            field: '_id',
            headerName: 'PDF',
            cellRenderer: 'btnPDFCellRenderer',
            cellRendererParams: {
                clicked: function (field) {
                    downloadPDF(field);
                },
                label: 'Download',
            },
            type: 'iconColumn',
            editable: false,
            filter: false,
        },
        {
            field: 'needsAdminAttention',
            headerName: 'Alert',
            cellRenderer: 'btnAttentionCellRenderer',
            cellRendererParams: {
                clicked: function (field) {
                    viewOffer(field);
                },
                label: 'View',
            },

            type: 'iconColumn',
            editable: false,
            filter: false,
        },
        {
            field: 'unansweredMessage',
            headerName: 'Message',
            cellRenderer: 'btnCommentCellRenderer',
            cellRendererParams: {
                clicked: function (field) {
                    viewOffer(field);
                },
                label: 'View',
            },

            type: 'iconColumn',
            editable: false,
            filter: false,
        },
    ];

    const init = async () => {
        let token = localStorage.getItem('auth-token');
        const res = await Axios.get(`${process.env.REACT_APP_SERVER}/admin/offers/`, { headers: { 'x-auth-token': token } });
        setOffers(res.data.offers);
        setControl({
            ...control,
            viewOffer: 0,
        });
    };

    const checkLoggedIn = async () => {
        try {
            checkAdmin(userData, history);
        } catch (err) {
            err.response.data.msg && setError(err.response.data.msg);
        }
    };

    useEffect(() => {
        checkLoggedIn();
        init();
    }, []);

    const downloadPDF = async (offer) => {
        try {
            let token = localStorage.getItem('auth-token');
            const config = {
                responseType: 'blob',
                headers: { 'x-auth-token': token },
                params: { offerID: offer._id },
            };

            Axios.get(`${process.env.REACT_APP_SERVER}/admin/downloadofferpdf`, config).then((res) => {
                download(res.data, `Angebot_${offer.IDS}.pdf`);
            });
        } catch (err) {
            console.log(err);
            err.response && err.response.data && err.response.data.msg && setError(err.response.data.msg);
        }
    };

    const downloadFiles = async (offer) => {
        try {
            let token = localStorage.getItem('auth-token');
            const config = {
                responseType: 'blob',
                headers: { 'x-auth-token': token },
                params: { offerID: offer._id },
            };

            Axios.get(`${process.env.REACT_APP_SERVER}/admin/downloadofferzip`, config).then((res) => {
                download(res.data, `Angebot_${offer.IDS}.zip`);
            });
        } catch (err) {
            console.log(err);
            err.response && err.response.data && err.response.data.msg && setError(err.response.data.msg);
        }
    };

    const viewOffer = (_id) => {
        setControl({
            ...control,
            viewOffer: _id,
        });

        history.push('/viewOffer');
    };

    const viewOrder = (_id) => {
        setControl({
            ...control,
            viewOrder: _id,
        });

        history.push('/viewOrder');
    };

    return (
        <div className="overview">
            <div className="page">
                <h2>Admin - Alle Angebote</h2>
                {error && <ErrorNotice message={error} clearError={() => setError(undefined)} />}
            </div>
            <div className="ag-theme-alpine">
                <AgGridReact
                    rowData={offers}
                    columnDefs={columnDefs}
                    frameworkComponents={frameworkComponents}
                    columnTypes={columnTypes}
                    defaultColDef={defaultColDef}
                    defaultColGroupDef={defaultColGroupDef}
                    pagination={true}
                    paginationAutoPageSize={true}
                ></AgGridReact>
            </div>
           
            <AutoButton className="page-button-white" onClick={init} text="Aktualisieren" count={300} />
        </div>
    );
};

export default AdminOffers;
