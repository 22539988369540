import React from 'react';

const AdditionalInfoCO = ({ readOnly, projectID, inquiryID, comment, status, orderID, setOrderID }) => {
    return (
        <div id="additional-info-container">
            <div className="additional-info">
                <div id="field-projectID">
                    <label>Projektbezeichnung:</label>
                    <label>{projectID}</label>
                </div>
                <div id="field-userOfferID">
                    <label>Anfragenummer:</label>
                    <label>{inquiryID}</label>
                </div>
                <div id="field-userOfferID">
                    <label>Bestellnummer:</label>
                    <input className="input-userOfferID" type="text" value={orderID} onChange={(e) => setOrderID(e.currentTarget.value)} />
                </div>
                {status && <div id="field-orderStatus">{readOnly ? <h4>Status: {status}</h4> : null}</div>}
                <br />
            </div>
        </div>
    );
};

export default AdditionalInfoCO;
