import React, { useEffect, useState } from 'react';
import { NumericFormat } from 'react-number-format';;

const FileTaskViewOrder = ({ task, position }) => {
    const [params] = useState(task);
    useEffect(() => {}, []);

    return (
        <div>
            <div className={`item-container`}>
                <div className="row-item-name">
                    <div>
                        <p className="round-border">{position + 1}</p>
                    </div>
                    <div>
                        <h4>{params.name}</h4>
                    </div>
                </div>

                <div className="item-comment-textbox">
                    <p className="paragraph">Beschreibung:</p>
                    <textarea id="file-comment" name="filecomment" rows="15" cols="100" disabled value={params.description}></textarea>
                    <p className="paragraph">
                        Bitte beschreiben Sie hier die gew&uuml;nschte Leistung. Sie k&ouml;nnen auch zus&auml;tzliche technische Dokumente im
                        PDF-Format hochladen.
                        <br /> Die Preise f&uuml;r diese Leistung k&ouml;nnen nicht automatisch ermittelt werden. Sie werden per Email benachrichtigt,
                        <br />
                        wenn ein Mitarbeiter Ihre Anfrage bearbeitet hat und k&ouml;nnen Ihr Angebot hier einsehen.
                    </p>
                </div>
                <div className="vertical-divider"></div>
                <div>
                    <table id="item-prices-table">
                        <tbody>
                            <tr>
                                <th />
                                <th>Anzahl</th>
                                <th>Einzelpreis</th>
                                <th>Gesamtpreis</th>
                            </tr>
                            <tr>
                                <td />
                                <td>
                                    <NumericFormat
                                        value={parseInt(params.selectedPrice.amount)}
                                        displayType={'text'}
                                        prefix={''}
                                        suffix={''}
                                        decimalScale={0}
                                        fixedDecimalScale={true}
                                        thousandSeparator={'.'}
                                        decimalSeparator={','}
                                    />
                                </td>
                                <td>
                                    <NumericFormat
                                        value={parseFloat(params.selectedPrice.price)}
                                        displayType={'text'}
                                        prefix={''}
                                        suffix={' €'}
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        thousandSeparator={'.'}
                                        decimalSeparator={','}
                                    />
                                </td>
                                <td>
                                    <NumericFormat
                                        value={parseInt(params.selectedPrice.amount) * parseFloat(params.selectedPrice.price)}
                                        displayType={'text'}
                                        prefix={''}
                                        suffix={' €'}
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        thousandSeparator={'.'}
                                        decimalSeparator={','}
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default FileTaskViewOrder;
