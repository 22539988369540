import React, { useState } from 'react';
import ErrorNotice from '../misc/ErrorNotice';

const Registered = () => {
    const [error, setError] = useState();

    return (
        <>
            <div className="page-form">
                <h2>
                    Eine Mail zur Verifizierung Ihrer Emailadresse wurde
                    versandt.
                </h2>
                {error && (
                    <ErrorNotice
                        message={error}
                        clearError={() => setError(undefined)}
                    />
                )}
            </div>
        </>
    );
};

export default Registered;
