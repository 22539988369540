import React, { useState, useEffect, useContext } from 'react';
import Axios from 'axios';
import ControlContext from '../../context/ControlContext';
import { useHistory } from 'react-router-dom';
import ErrorNotice from '../misc/ErrorNotice';
import { AgGridReact } from 'ag-grid-react';
import { BtnCellRenderer } from '../admin/BtnCellRenderer';
import ParamsCellRenderer from '../admin/ParamsCellRenderer';
import { columnTypes, defaultColDef, defaultColGroupDef, dateTimeFormatter } from '../misc/ColumnTypes';
import DeliveryPage from './DeliveryPage';
import AutoButton from '../misc/AutoButton';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

const AdminLogs = () => {
    const { control, setControl } = useContext(ControlContext);
    const [error, setError] = useState();
    const history = useHistory();
    const [logs, setLogs] = useState();
    const [showDelivery, setShowDelivery] = useState(false);

    const frameworkComponents = {
        btnCellRenderer: BtnCellRenderer,
        paramsCellRenderer: ParamsCellRenderer,
    };

    const columnDefs = [
        {
            field: 'user.email',
            headerName: 'Nutzer',
            sortable: true,
            filter: true,
            suppressMenu: true,
            editable: false,
            type: 'accountColumn',
        },
        {
            field: 'time',
            headerName: 'Erstellt am',
            sortable: true,
            filter: true,
            type: 'dateColumn',
            valueFormatter: dateTimeFormatter,
            sort: 'desc',
            suppressMenu: true,
            editable: false,
        },
        {
            field: 'category',
            headerName: 'Category',
            sortable: true,
            filter: true,
            suppressMenu: true,
            editable: false,
        },

        {
            field: 'action',
            headerName: 'Action',
            sortable: true,
            filter: true,
            suppressMenu: true,
            editable: false,
        },
        {
            field: 'params',
            headerName: 'Params',
            cellRenderer: 'paramsCellRenderer',
            cellRendererParams: {
                clicked: function (field) {
                    handleClick(field.params);
                },
                label: 'View',
            },
            type: 'paramsColumn',
            filter: false,
            editable: false,
        },
    ];

    const refresh = async () => {
        console.log('refresh');
        let token = localStorage.getItem('auth-token');
        try {
            const res = await Axios.get(`${process.env.REACT_APP_SERVER}/admin/logs/`, { headers: { 'x-auth-token': token } });
            setLogs(res.data.logs);
        } catch (err) {
            console.log(err);
        }
    };

    const viewOffer = (_id) => {
        setControl({
            ...control,
            viewOffer: _id,
        });

        history.push('/viewOffer');
    };

    const viewOrder = (_id) => {
        setControl({
            ...control,
            viewOrder: _id,
        });

        history.push('/viewOrder');
    };

    const handleClick = (params) => {
        console.log(params);
        if (params.offerID) {
            viewOffer(params.offerID);
        }
        if (params.orderID) {
            viewOrder(params.orderID);
        }
    };

    useEffect(() => {
        refresh();
    }, []);

    return (
        <div className="overview">
            <DeliveryPage showModal={showDelivery} setShowModal={setShowDelivery} />
            <h2>Admin - Logs</h2>
            {error && <ErrorNotice message={error} clearError={() => setError(undefined)} />}

            <div className="ag-theme-alpine">
                <AgGridReact
                    rowData={logs}
                    columnDefs={columnDefs}
                    frameworkComponents={frameworkComponents}
                    columnTypes={columnTypes}
                    defaultColDef={defaultColDef}
                    defaultColGroupDef={defaultColGroupDef}
                    pagination={true}
                    paginationAutoPageSize={true}
                ></AgGridReact>
            </div>
            <br />
            <AutoButton className="page-button-white" onClick={refresh} text="Aktualisieren" count={60} />
        </div>
    );
};

export default AdminLogs;
