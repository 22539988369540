const addWeekDays = (date, dd) => {
    var wks = Math.floor(dd / 5);
    var dys = dd % 5;
    var dy = date.getDay();
    if (dy === 6 && dys > -1) {
        if (dys === 0) {
            dys -= 2;
            dy += 2;
        }
        dys++;
        dy -= 6;
    }
    if (dy === 0 && dys < 1) {
        if (dys === 0) {
            dys += 2;
            dy -= 2;
        }
        dys--;
        dy += 6;
    }
    if (dy + dys > 5) dys += 2;
    if (dy + dys < 1) dys -= 2;
    date.setDate(date.getDate() + wks * 7 + dys);
};

module.exports = { addWeekDays };
