export const customStyleLarge = {
    option: (provided, state) => ({
        ...provided,
        height: 'auto',
        borderRadius: 0,
        color: '#333',
    }),
    control: (provided) => ({
        ...provided,
        height: '10em',
        width: 300,
        borderRadius: 0,
    }),
    singleValue: (provided, state) => ({
        ...provided,
        /*height: 100,*/
        borderRadius: 0,
    }),
    valueContainer: (provided, state) => ({
        ...provided,
        height: '10em',
        borderRadius: 0,
    }),
};

export const customStyleSmall = {
    option: (provided, state) => ({
        ...provided,
        borderRadius: 0,
    }),
    control: (provided) => ({
        ...provided,
        width: 300,
        borderRadius: 0,
    }),
    singleValue: (provided, state) => ({
        ...provided,
        borderRadius: 0,
    }),
    valueContainer: (provided, state) => ({
        ...provided,
        borderRadius: 0,
    }),
};

export const formatOptionLabelAdress = ({ value, label, adr }) => (
    <>
        {adr.faName ? (
            <div style={{ display: 'flex' }}>
                <div style={{ marginLeft: '5px' }}>
                    <b>{adr.faName}</b>
                    <br />
                    {adr.dpt ? (
                        <>
                            {adr.dpt}
                            <br />
                        </>
                    ) : null}
                    {adr.firstName}&nbsp;{adr.lastName}
                    <br />
                    {adr.street}&nbsp;{adr.street2}
                    <br />
                    {adr.additionalInfo && adr.additionalInfo !== '' ? (
                        <>
                            {adr.additionalInfo}
                            <br />
                        </>
                    ) : null}
                    {adr.zip}&nbsp;{adr.city}
                    <br />
                    {adr.country.name}
                </div>
            </div>
        ) : (
            <div style={{ display: 'flex' }}>
                <div style={{ marginLeft: '5px' }}>
                    {adr.firstName}&nbsp;{adr.lastName}
                    <br />
                    {adr.street}&nbsp;{adr.street2}
                    <br />
                    {adr.additionalInfo && adr.additionalInfo !== '' ? (
                        <>
                            {adr.additionalInfo}
                            <br />
                        </>
                    ) : null}
                    {adr.zip}&nbsp;{adr.city}
                    <br />
                    {adr.country.name}
                </div>
            </div>
        )}
    </>
);

export const formatOptionLabelAdress2 = ({ value, label, adr }) => (
    <>
        {adr.faName ? (
            <div style={{ display: 'flex' }}>
                <div style={{ marginLeft: '5px' }}>
                    <b>{adr.faName}</b>
                    <br />
                    {adr.street}&nbsp;{adr.street2}
                    <br />
                    {adr.zip}&nbsp;{adr.city}
                </div>
            </div>
        ) : (
            <div style={{ display: 'flex' }}>
                <div style={{ marginLeft: '5px' }}>
                    {adr.firstName}&nbsp;{adr.lastName}
                    <br />
                    {adr.street}&nbsp;{adr.street2}
                    <br />
                    {adr.zip}&nbsp;{adr.city}
                </div>
            </div>
        )}
    </>
);
