import React, { useState, useContext, useEffect } from 'react';
import Axios from 'axios';
import UserContext from '../../context/UserContext';
import ControlContext from '../../context/ControlContext';
import { useHistory } from 'react-router-dom';
import ErrorNotice from '../misc/ErrorNotice';

import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

import { AgGridReact } from 'ag-grid-react';
import { BtnCellRenderer } from '../admin/BtnCellRenderer';
import EditCellRenderer from '../admin/EditCellRenderer';
import { CheckboxCellRenderer } from '../admin/CheckboxCellRenderer';
import { columnTypes, defaultColDef, defaultColGroupDef } from '../misc/ColumnTypes';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

import { checkAdmin } from '../account/AccountUtils';

const AdminAccountRights = () => {
    const { userData } = useContext(UserContext);
    const { control, setControl } = useContext(ControlContext);
    const history = useHistory();
    const [error, setError] = useState();
    const [users, setUsers] = useState();
    const [showEditUser, setShowEditUser] = useState(false);
    const [showAddUser, setShowAddUser] = useState(false);

    const frameworkComponents = {
        btnCellRenderer: BtnCellRenderer,
        editCellRenderer: EditCellRenderer,
        cbCellRenderer: CheckboxCellRenderer,
    };

    const saveChanges = async (data) => {
        //console.log(data);
        try {
            let token = localStorage.getItem('auth-token');
            let rights = [];

            if (data.admin) {
                rights.push('admin');
            }
            if (data.offers) {
                rights.push('offers');
            }
            if (data.orders) {
                rights.push('orders');
            }
            if (data.accounting) {
                rights.push('accounting');
            }
            if (data.accounts) {
                rights.push('accounts');
            }
            if (data.delivery) {
                rights.push('delivery');
            }
            if (data.tickets) {
                rights.push('tickets');
            }

            const payload = {
                _id: data._id,
                rights: rights,
            };
            await Axios.post(`${process.env.REACT_APP_SERVER}/admin/updateRights`, payload, { headers: { 'x-auth-token': token } });
        } catch (err) {
            err.response.data.msg && setError(err.response.data.msg);
            err.response.data.msg && console.log(err.response.data.msg);
        }
    };

    const columnDefs = [
        {
            field: 'email',
            headerName: 'Account',
            sortable: true,
            filter: true,
            editable: false,
            type: 'accountColumn',
        },
        {
            headerName: 'Admin',
            field: 'admin',
            cellRenderer: 'cbCellRenderer',
            editable: false,
            type: 'cbColumn',
        },
        {
            headerName: 'Accounts',
            field: 'accounts',
            cellRenderer: 'cbCellRenderer',
            editable: false,
        },
        {
            headerName: 'Angebote',
            field: 'offers',
            cellRenderer: 'cbCellRenderer',
            editable: false,
        },
        {
            headerName: 'Bestellungen',
            field: 'orders',
            cellRenderer: 'cbCellRenderer',
            editable: false,
        },
        {
            headerName: 'Buchhaltung',
            field: 'accounting',
            cellRenderer: 'cbCellRenderer',
            editable: false,
        },

        {
            headerName: 'Versand',
            field: 'delivery',
            cellRenderer: 'cbCellRenderer',
            editable: false,
        },
        {
            headerName: 'Tickets',
            field: 'tickets',
            cellRenderer: 'cbCellRenderer',
            editable: false,
        },
        {
            field: '_id',
            headerName: 'Speichern',
            cellRenderer: 'btnCellRenderer',
            cellRendererParams: {
                clicked: function (field) {
                    saveChanges(field);
                },
                label: 'Save',
            },
            editable: false,
            type: 'buttonColumn',
        },
    ];

    const init = async () => {
        let token = localStorage.getItem('auth-token');
        const res = await Axios.get(`${process.env.REACT_APP_SERVER}/admin/accounts/`, { headers: { 'x-auth-token': token } });
        res.data.users.forEach((user) => {
            user.admin = user.rights.includes('admin');
            user.accounts = user.rights.includes('accounts');
            user.offers = user.rights.includes('offers');
            user.orders = user.rights.includes('orders');
            user.accounting = user.rights.includes('accounting');
            user.delivery = user.rights.includes('delivery');
            user.tickets = user.rights.includes('tickets');
            //user. = user.rights.includes('');
        });

        setUsers(res.data.users);
    };

    const checkLoggedIn = async () => {
        try {
            checkAdmin(userData, history);
        } catch (err) {
            err.response.data.msg && setError(err.response.data.msg);
        }
    };

    const onCellValueChanged = function (event) {
        console.log('onCellValueChanged: ' + event.colDef.field + ' = ' + event.newValue);
    };

    useEffect(() => {
        checkLoggedIn();
        init();
    }, []);

    return (
        <div className="overview">
            <div className="page">
                <h2>Admin - Rechtevergabe</h2>
                {error && <ErrorNotice message={error} clearError={() => setError(undefined)} />}
            </div>

            <div className="ag-theme-alpine">
                <AgGridReact
                    rowData={users}
                    columnDefs={columnDefs}
                    frameworkComponents={frameworkComponents}
                    columnTypes={columnTypes}
                    defaultColDef={defaultColDef}
                    defaultColGroupDef={defaultColGroupDef}
                    pagination={true}
                    paginationAutoPageSize={true}
                    onCellValueChanged={onCellValueChanged}
                ></AgGridReact>
            </div>
        </div>
    );
};

export default AdminAccountRights;
