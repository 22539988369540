import React, { useState, useContext, useEffect } from 'react';
import UserContext from '../../context/UserContext';
import ControlContext from '../../context/ControlContext';
import MethodContext from '../../context/MethodContext';
import Modal from 'react-modal';
import AddAdress from '../account/AddAdress';
import Select from 'react-select';
import { customStyleLarge, customStyleSmall, formatOptionLabelAdress } from '../misc/SelectOptions';
import { NumericFormat } from 'react-number-format';;

const OrderSummary = ({ summary, deliverySelected, setDeliverySelected, setDelivery, setVat, payment, setPayment, paymentMethods }) => {
    const { userData } = useContext(UserContext);
    const { methodDefs } = useContext(MethodContext);
    const { control, setControl } = useContext(ControlContext);

    const [deliveryAdresses, setDeliveryAdresses] = useState([]);
    const [invoiceAdresses, setInvoiceAdresses] = useState([]);
    const [deliveries, setDeliveries] = useState([]);
    const [deliveryAdress, setDeliveryAdress] = useState();
    const [invoiceAdress, setInvoiceAdress] = useState();
    const [selectedDelivery, setSelectedDelivery] = useState();
    const [selectedPayment, setSelectedPayment] = useState();

    const [showAdress, setShowAdress] = useState(false);
    const [incomplete, setIncomplete] = useState(true);

    const [delOptions, setDelOptions] = useState([]);
    const [invOptions, setInvOptions] = useState([]);
    const [methodOptions, setMethodOptions] = useState([]);
    const [paymentOptions, setPaymentOptions] = useState([]);

    const getVat = (country, isCompany) => {
        let vat = 0;
        if (country.identifier === 'D') {
            vat = parseFloat(country.vat) / 100;
        } else {
            if (country.isEU) {
                if (isCompany === true || isCompany === 'true') {
                    //text = country.vatText1;
                } else {
                    vat = parseFloat(country.vat) / 100;
                    //text = country.vatText2;
                }
            } else {
                //console.log('not EU');
            }
        }
        setVat(vat);
    };

    const onAdress1Change = (adr) => {
        setDeliveryAdress(adr);
        setControl({ ...control, deliveryAdress: adr.value });

        let delivs = methodDefs.deliveries.filter(function (el) {
            return el.countries.includes(adr.adr.country._id);
        });

        setDeliveries(delivs);
        setDeliverySelected(false);
        const vatID = adr.adr.vatID;
        const isCompany = !(vatID === undefined || vatID === '');
        getVat(adr.adr.country, isCompany);
    };

    const onAdress2Change = (adr) => {
        setInvoiceAdress(adr);
        setControl({ ...control, invoiceAdress: adr.value });
    };

    const onDeliveryChange = (obj) => {
        setDelivery(obj.value);
        setSelectedDelivery(obj);
        setControl({
            ...control,
            deliveryMethod: obj.value,
        });
        if (parseInt(obj.value) === -1) {
            setIncomplete(true);
            setDeliverySelected(false);
        } else {
            setIncomplete(false);
            setDeliverySelected(true);
        }
    };

    const onPaymentChange = (obj) => {
        setPayment(obj.value);
        setSelectedPayment(obj);
        if (parseInt(obj.value) === -1) {
            setIncomplete(true);
        } else {
            setIncomplete(false);
        }
    };

    const modalClose = () => {
        setShowAdress(false);
    };

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '90%',
            maxWidth: '700px',
            height: '400px',
        },
    };

    const handleSubmit = async (e) => {
        setShowAdress(false);
    };

    useEffect(() => {
        let delAdr = userData.adresses.filter(function (el) {
            return el.isDeliveryAdr === true && el.hidden === false;
        });
        setDeliveryAdresses(delAdr);

        if (!deliveryAdress && delAdr && delAdr.length) {
            setDeliveryAdress(delAdr[0]._id);
        }
        let invAdr = userData.adresses.filter(function (el) {
            return el.isInvoiceAdr === true && el.hidden === false;
        });
        setInvoiceAdresses(invAdr);

        if (!invoiceAdress && invAdr && invAdr.length) {
            setInvoiceAdress(invAdr[0]._id);
        }
    }, [userData.adresses]);

    useEffect(() => {
        let options = [];
        deliveryAdresses.forEach((adr) => {
            const opt = {
                value: `${adr._id}`,
                country: `${adr.country}`,
                label: adr.faName,
                adr: adr,
            };
            options.push(opt);
        });
        setDelOptions(options);
        if (options.length > 0) setDeliveryAdress(options[0]);
    }, [deliveryAdresses]);

    useEffect(() => {
        let options = [];
        invoiceAdresses.forEach((adr) => {
            const opt = {
                value: `${adr._id}`,
                label: adr.faName,
                adr: adr,
            };
            options.push(opt);
        });
        setInvOptions(options);
        if (options.length > 0) setInvoiceAdress(options[0]);
    }, [invoiceAdresses]);

    useEffect(() => {
        let options = [];
        deliveries.forEach((del) => {
            const opt = {
                value: `${del._id}`,
                label: `${del.displayName}\n${del.minDays}-${del.maxDays} Werktage`,
            };
            options.push(opt);
        });
        setMethodOptions(options);
    }, [deliveries]);

    useEffect(() => {
        //const createPaymentOptions = () => {
        let options = [];
        paymentMethods.forEach((pay) => {
            const opt = {
                value: `${pay._id}`,
                label: `${pay.displayName}`,
            };
            options.push(opt);
        });
        setPaymentOptions(options);
        //};
    }, [paymentMethods]);

    useEffect(() => {
        paymentOptions.forEach((meth) => {
            if (payment === meth.value) {
                setSelectedPayment(meth);
            }
        });
    }, [payment]);

    useEffect(() => {
        const getAdresses = async () => {
            const workFunc = async () => {
                try {
                    let delAdr = userData.adresses.filter(function (el) {
                        return el.isDeliveryAdr === true && el.hidden === false;
                    });
                    setDeliveryAdresses(delAdr);

                    if (!deliveryAdress && delAdr && delAdr.length) {
                        control.deliveryAdress = delAdr[0]._id;
                        const vatID = delAdr[0].vatID;
                        const isCompany = !(vatID === undefined || vatID === '');
                        getVat(delAdr[0].country, isCompany);
                    }

                    let invAdr = userData.adresses.filter(function (el) {
                        return el.isInvoiceAdr === true && el.hidden === false;
                    });
                    setInvoiceAdresses(invAdr);

                    if (!invoiceAdress && invAdr && invAdr.length) {
                        control.invoiceAdress = invAdr[0]._id;
                    }

                    let delivs = methodDefs.deliveries.filter(function (el) {
                        return el.countries.includes(delAdr[0].country._id);
                    });
                    setDeliveries(delivs);
                } catch (err) {
                    console.log(err);
                }
            };
            await workFunc();
        };
        getAdresses();

        setDeliveryAdress(control.deliveryAdress || 0);
    }, []);

    return (
        <>
            <div className="order-summary">
                <Modal isOpen={showAdress} onRequestClose={(e) => modalClose(e)} contentLabel="Adresse hinzufügen" style={customStyles}>
                    <div className="modalHeader">
                        <div>
                            <h2>Adresse hinzufügen</h2>
                        </div>
                        <div>
                            <button id="buttonX" onClick={() => modalClose()}>
                                &#10006;
                            </button>
                        </div>
                    </div>
                    <div className="modalBody">
                        <AddAdress onSubmit={handleSubmit} />
                    </div>
                </Modal>
                <div className="order-summary-column">
                    <label className="label-height-2-white">Lieferadresse</label>
                    <Select
                        styles={customStyleLarge}
                        value={deliveryAdress}
                        onChange={onAdress1Change}
                        options={delOptions}
                        formatOptionLabel={formatOptionLabelAdress}
                        menuPlacement="top"
                        isSearchable={false}
                    />

                    <br />
                    <button
                        id="add-address-button"
                        onClick={() => {
                            setShowAdress(true);
                        }}
                    >
                        Neue Adresse anlegen
                    </button>
                </div>
                <div className="order-summary-column">
                    <label className="label-height-2-white">Rechnungsadresse</label>
                    <Select
                        styles={customStyleLarge}
                        value={invoiceAdress}
                        onChange={onAdress2Change}
                        options={invOptions}
                        formatOptionLabel={formatOptionLabelAdress}
                        menuPlacement="top"
                        isSearchable={false}
                    />
                </div>
                <div className="order-summary-column">
                    {deliverySelected ? (
                        <label className="label-height-2-white">Versandart</label>
                    ) : (
                        <label className="label-height-2-red">Versandart</label>
                    )}
                    <Select
                        value={selectedDelivery}
                        onChange={onDeliveryChange}
                        options={methodOptions}
                        styles={customStyleSmall}
                        isSearchable={false}
                    />
                    {selectedPayment ? (
                        <label className="label-payment-select">Zahlungsart</label>
                    ) : (
                        <label className="label-payment-select-red">Zahlungsart</label>
                    )}
                    <Select
                        value={selectedPayment}
                        onChange={onPaymentChange}
                        options={paymentOptions}
                        styles={customStyleSmall}
                        isSearchable={false}
                    />
                </div>
                <div id="order-summary-column">
                    <label className="label-height-2-white">Kosten</label>
                    <table id="order-summary-table">
                        <tbody>
                            <tr>
                                <td>Netto:</td>
                                <td>
                                    <NumericFormat
                                        value={summary.netto}
                                        displayType={'text'}
                                        suffix={' €'}
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        thousandSeparator={'.'}
                                        decimalSeparator={','}
                                    />
                                </td>
                            </tr>
                            {summary.savings > 0 ? (
                                <tr>
                                    <td>inkl. Rabatt:</td>
                                    <td>
                                        <NumericFormat
                                            value={summary.savings}
                                            displayType={'text'}
                                            suffix={' €'}
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                            thousandSeparator={'.'}
                                            decimalSeparator={','}
                                        />
                                    </td>
                                </tr>
                            ) : null}
                            {summary.surcharge > 0 ? (
                                <tr>
                                    <td>zzgl. Zuschlag*:</td>
                                    <td>
                                        <NumericFormat
                                            value={summary.surcharge}
                                            displayType={'text'}
                                            suffix={' €'}
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                            thousandSeparator={'.'}
                                            decimalSeparator={','}
                                        />
                                    </td>
                                </tr>
                            ) : null}
                            <tr>
                                <td>zzgl. Versandkosten:</td>
                                <td>
                                    <NumericFormat
                                        value={summary.delivery}
                                        displayType={'text'}
                                        suffix={' €'}
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        thousandSeparator={'.'}
                                        decimalSeparator={','}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>zzgl. MwSt:</td>
                                <td>
                                    <NumericFormat
                                        value={summary.mwst}
                                        displayType={'text'}
                                        suffix={' €'}
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        thousandSeparator={'.'}
                                        decimalSeparator={','}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>Brutto gesamt:</td>
                                <td>
                                    <NumericFormat
                                        value={summary.brutto}
                                        displayType={'text'}
                                        suffix={' €'}
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        thousandSeparator={'.'}
                                        decimalSeparator={','}
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div className="wrapper_1500">
                    <p>
                        <label className="label-delivery-time" style={{ color: 'white' }}>
                            Vorraussichtliche Produktionszeit: <strong>{summary.totalTime} Tage</strong>
                        </label>
                        <br />
                        <label className="label-delivery-time" style={{ color: 'white' }}>
                            Vorraussichtlicher Versandtermin: <strong>{summary.sendDate} </strong>
                        </label>
                        <br />
                        <label className="label-delivery-time" style={{ color: 'white' }}>
                            <strong>{summary.numPackages || '0'}</strong> Pakete mit ca. <strong>{summary.totalWeight?.toFixed(2)} kg</strong>{' '}
                            Nettogewicht.
                        </label>
                    </p>
                </div>
            </div>
        </>
    );
};

export default OrderSummary;
