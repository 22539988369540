import Axios from 'axios';

export const checkUser = async () => {
    let token = localStorage.getItem('auth-token');
    if (token === null || token === '') {
        localStorage.setItem('auth-token', '');
        token = '';
        return false;
    }
    try {
        const tokenRes = await Axios.post(`${process.env.REACT_APP_SERVER}/users/tokenIsValid`, null, { headers: { 'x-auth-token': token } });
        if (tokenRes.data) {
            const userRes = await Axios.get(`${process.env.REACT_APP_SERVER}/users/`, { headers: { 'x-auth-token': token } });

            if (userRes) {
                return true;
            }
        }
    } catch (err) {
        console.log(err);
    }
    localStorage.setItem('auth-token', '');
    token = '';
    return false;
};

export const checkAdmin = async (userData, history) => {
    let token = localStorage.getItem('auth-token');
    if (token === null) {
        localStorage.setItem('auth-token', '');
        token = '';
        history.push('/');
    }

    try {
        const tokenRes = await Axios.post(`${process.env.REACT_APP_SERVER}/users/tokenIsValid`, null, { headers: { 'x-auth-token': token } });

        if (tokenRes.data) {
            if (!userData?.rights?.includes('admin')) {
                history.push('/');
            }
        }
        return true;
    } catch (err) {
        console.log(err);
    }
    return false;
};

/*
export const checkAdmin = async (userData, setUserData) => {
    let token = localStorage.getItem('auth-token');
    if (token === null) {
        localStorage.setItem('auth-token', '');
        token = '';
    }

    const tokenRes = await Axios.post(
        `${process.env.REACT_APP_SERVER}/users/tokenIsValid`,
        null,
        { headers: { 'x-auth-token': token } }
    );

    if (tokenRes.data) {
        const userRes = await Axios.get(
            `${process.env.REACT_APP_SERVER}/users/`,
            { headers: { 'x-auth-token': token } }
        );
        console.log(userRes.data);
        setUserData({
            token,
            user: userRes.data,
        });

        return userRes?.data?.rights?.includes('admin');
    }

    return false;
};
*/

//module.exports = { checkToken, hasAdmin };
