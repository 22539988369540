import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import Modal from 'react-modal';
import { useHistory } from 'react-router-dom';
import ErrorNotice from '../misc/ErrorNotice';

export default function ConfirmCopyValues({ showModal, setShowModal, onConfirmApply }) {
    const history = useHistory();
    const [error, setError] = useState();

    const [includeMethod, setIncludeMethod] = useState(true);
    const [includeMaterial, setIncludeMaterial] = useState(true);
    const [includeColor, setIncludeColor] = useState(true);
    const [includeProc, setIncludeProc] = useState(true);
    const [includeConstraints, setIncludeConstraints] = useState(true);
    const [includePrices, setIncludePrices] = useState(true);

    const modalClose = () => {
        setShowModal(false);
    };

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            position: 'absolute',
            transform: 'translate(-50%, -50%)',
            width: '90%',
            maxWidth: '700px',
            height: '400px',
        },
    };

    const submit = async (e) => {
        e.preventDefault();
        const filter = {
            includeMethod: includeMethod,
            includeMaterial: includeMaterial,
            includeColor: includeColor,
            includeProc: includeProc,
            includeConstraints: includeConstraints,
            includePrices: includePrices,
        };
        onConfirmApply(filter);
        setShowModal(false);
        try {
        } catch (err) {
            if (err.response && err.response.data) {
                err.response.data.msg && setError(err.response.data.msg);
            } else {
                console.log(err);
            }
        }
    };

    useEffect(() => {
        document.body.style.backgroundColor = 'grey';
    }, []);

    return (
        <Modal isOpen={showModal} onRequestClose={(e) => modalClose(e)} contentLabel={'modalLabel'} style={customStyles}>
            <div className="modalHeader">
                <div>
                    <h2>Einstellungen übertragen</h2>
                </div>
                <div>
                    <button id="buttonX" onClick={() => modalClose()}>
                        &#10006;
                    </button>
                </div>
            </div>
            <div className="modalBody">
                {error && <ErrorNotice message={error} clearError={() => setError(undefined)} />}
                <form className="form" onSubmit={submit}>
                    <div className="form-div100">
                        <input
                            id="cbi1"
                            type="checkbox"
                            checked={includeMethod}
                            onChange={(e) => setIncludeMethod(e.target.checked)}
                            placeholder="Include Method"
                        />
                        <label htmlFor="cbi1" className="adressLabel">
                            Verfahren
                        </label>
                        
                    </div>

                    <div className="form-div100">
                        
                        <input
                            id="cbi2"
                            type="checkbox"
                            checked={includeMaterial}
                            onChange={(e) => setIncludeMaterial(e.target.checked)}
                            placeholder="Include Method"
                        />
                        <label htmlFor="cbi2" className="adressLabel">
                            Material
                        </label>
                    </div>
                    <div className="form-div100">
                        
                        <input
                            id="cbi3"
                            type="checkbox"
                            checked={includeColor}
                            onChange={(e) => setIncludeColor(e.target.checked)}
                            placeholder="Include Method"
                        />
                        <label htmlFor="cbi3" className="adressLabel">
                            F&auml;rbung
                        </label>
                    </div>
                    <div className="form-div100">
                        
                        <input
                            id="cbi4"
                            type="checkbox"
                            checked={includeProc}
                            onChange={(e) => setIncludeProc(e.target.checked)}
                            placeholder="Include Method"
                        />
                        <label htmlFor="cbi4" className="adressLabel">
                            Nachbearbeitung
                        </label>
                    </div>
                    <div className="form-div100">
                        
                        <input
                            id="cbi5"
                            type="checkbox"
                            checked={includeConstraints}
                            onChange={(e) => setIncludeConstraints(e.target.checked)}
                            placeholder="Include Method"
                        />
                        <label htmlFor="cbi5" className="adressLabel">
                            Bauteilinfos
                        </label>
                    </div>
                    <div className="form-div100">
                        
                        <input
                            id="cbi6"
                            type="checkbox"
                            checked={includePrices}
                            onChange={(e) => setIncludePrices(e.target.checked)}
                            placeholder="Include Method"
                        />
                        <label htmlFor="cbi6" className="adressLabel">
                            Rabattstaffel
                        </label>
                    </div>

                    <br />
                    <button className="page-button-grey" onClick={() => modalClose()}>
                        Abbrechen
                    </button>
                    <button className="page-button-grey" type="submit">
                        Best&auml;tigen
                    </button>
                    <br />
                </form>
            </div>
        </Modal>
    );
}
