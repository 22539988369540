import React from 'react';

const Info1 = () => {
    return (
        
            

            <div className="page-title">
                <h2><span className='h2_big'>Upload</span><br />der Daten</h2> 
                <span className='welcome-text'>
                Laden Sie hier Ihre CAD-Dateien für ein Sofortangebot hoch. <br />Sie können mehrere Dateien gleichzeitig hochladen und falls gewünscht
                Staffelpreise anfragen.<br /> Für eine Bestellung ist eine Registrierung notwendig.
            </span>                              
            </div>
            
             
            
        
    );
};

export default Info1;
