import React, { useState, useCallback, useContext } from 'react';
import UserContext from '../../context/UserContext';
import Axios from 'axios';
import download from 'downloadjs';
import { v4 as uuidv4 } from 'uuid';
import { useDropzone } from 'react-dropzone';

const InvoiceUpload = ({ setError, order, updateList, setShowUpload, mode, setRefresh }) => {
    const { userData } = useContext(UserContext);
    const [files, setFiles] = useState([]);

    const maxSize = 33554432;
    const maxSizeText = '32 MB';

    const onDrop = useCallback(
        (acceptedFiles, rejectedFiles) => {
            rejectedFiles.forEach((file) => {
                console.log(file);
                if (file.file.size > maxSize) {
                    console.log('file too large');
                    setError(`Datei ${file.file.name} mit ${file.file.size} Bytes ist zu groß. Maximal erlaubte Größe: ${maxSizeText}`);
                }
            });
            acceptedFiles.forEach((file) => {
                console.log(file);
            });
            setFiles([...files, ...acceptedFiles]);
        },
        [setError, files]
    );
    const { getRootProps, getInputProps, rejectedFiles } = useDropzone({
        onDrop,
        accept: '.pdf',
        minSize: 0,
        maxSize,
    });

    const isFileTooLarge = rejectedFiles && rejectedFiles.length > 0 && rejectedFiles[0].size > maxSize;

    const onUpload = async () => {
        let token = localStorage.getItem('auth-token');
        for (const file of files) {
            try {
                const formData = new FormData();
                formData.append('file', file);
                formData.append('uuid', uuidv4());
                formData.append('IDS', order.IDS);
                formData.append('order_id', order._id);
                formData.append('mode', mode);

                const res = await Axios.post(`${process.env.REACT_APP_SERVER}/admin/uploadinvoice`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'x-auth-token': token,
                    },
                });
                if (res) {
                    setShowUpload(false);
                    updateList();
                }
            } catch (err) {
                if (err?.response?.status === 500) {
                    console.log('Error 500');
                } else {
                    console.log(err?.response?.data?.msg);
                }
            }
        }
        setFiles([]);
        //setRefresh(Math.random());
    };

    const onDownload = async (fileName) => {
        try {
            let token = localStorage.getItem('auth-token');
            const config = {
                responseType: 'blob',
                headers: { 'x-auth-token': token },
                params: {
                    orderID: order._id,
                    IDS: order.IDS,
                    fileName: fileName,
                },
            };

            Axios.get(`${process.env.REACT_APP_SERVER}/files/downloadorderdocument`, config).then((res) => {
                download(res.data, fileName);
            });
        } catch (err) {
            console.log(err);
            err.response && err.response.data && err.response.data.msg && setError(err.response.data.msg);
        }
    };

    const onDelete = async (fileName) => {};

    return (
        <>
            {order ? (
                <>
                    <h2>Rechnungen</h2>
                    <div className="document-list">
                        <table>
                            <tbody>
                                {order.documents ? (
                                    <>
                                        {order.documents.length > 0 ? (
                                            <>
                                                {order.documents.map((doc) => (
                                                    <>
                                                        {doc.type === 'invoice' ? (
                                                            <tr>
                                                                <td>{doc.fileName}</td>
                                                                <td>
                                                                    <button className="page-button" onClick={() => onDownload(doc.fileName)}>
                                                                        download
                                                                    </button>
                                                                </td>
                                                                {userData?.user?.rights?.includes('admin') && (
                                                                    <td>
                                                                        <button className="page-button" onClick={() => onDelete(doc.fileName)}>
                                                                            L&ouml;schen
                                                                        </button>
                                                                    </td>
                                                                )}
                                                            </tr>
                                                        ) : null}
                                                    </>
                                                ))}
                                            </>
                                        ) : (
                                            <tr>
                                                <td>Keine Dateien vorhanden.</td>
                                            </tr>
                                        )}
                                    </>
                                ) : (
                                    <tr>
                                        <td>Keine Dateien vorhanden.</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                    {userData.rights.includes('admin') || mode === 'offer' ? (
                        <>
                            <div id="drop-zone" {...getRootProps()}>
                                <input {...getInputProps()} />
                                <p>
                                    Bitte Datei(en) per Drag and Drop einfügen bzw.
                                    <br />
                                    Klicken für Dateiauswahl
                                </p>
                                {isFileTooLarge && <div className="text-danger mt-2">File is too large.</div>}
                            </div>
                            <div>
                                <table>
                                    <tbody>
                                        {files &&
                                            files.length > 0 &&
                                            files.map((file) => (
                                                <tr>
                                                    <td>{file.name}</td>
                                                </tr>
                                            ))}
                                    </tbody>
                                </table>
                            </div>

                            <div>
                                {files.length > 0 ? (
                                    <button className="page-button" onClick={onUpload}>
                                        Upload
                                    </button>
                                ) : null}
                            </div>
                        </>
                    ) : null}
                </>
            ) : null}
        </>
    );
};

export default InvoiceUpload;
