import React from 'react';
import FileTaskViewOrder from '../file/FileTaskViewOrder';
import { NumericFormat } from 'react-number-format';;

const FileDetailsViewOrder = ({ item, position }) => {
    function getSafe(fn, defaultVal) {
        try {
            return fn();
        } catch (e) {
            return defaultVal;
        }
    }

    return (
        <>
            <div id="item-element-wrapper">
                <div className="item-container">
                    {item ? (
                        <>
                            <div className="item-preview-details-wrapper">
                                <div className="item-position-number"> {position + 1} </div>

                                <div className="item-details-row">
                                    <div className="item-preview">
                                        <img src={item.preview} width="200" height="200" alt="" />
                                    </div>

                                    <div className="item-params">
                                        <div className="item-name"> {item.fileName} </div>

                                        <div className="item-params-value">
                                            <NumericFormat
                                                value={item.boundingBox[0]}
                                                displayType={'text'}
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                thousandSeparator={'.'}
                                                decimalSeparator={','}
                                            />
                                            <span> x </span>
                                            <NumericFormat
                                                value={item.boundingBox[1]}
                                                displayType={'text'}
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                thousandSeparator={'.'}
                                                decimalSeparator={','}
                                            />
                                            <span> x </span>
                                            <NumericFormat
                                                value={item.boundingBox[2]}
                                                displayType={'text'}
                                                suffix={' mm'}
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                thousandSeparator={'.'}
                                                decimalSeparator={','}
                                            />
                                        </div>
                                        <div className="item-comment-textbox">
                                            Bemerkungen
                                            <textarea
                                                id="file-comment"
                                                name="filecomment"
                                                rows="5"
                                                cols="30"
                                                disabled
                                                value={item.comment || ''}
                                            ></textarea>
                                        </div>
                                    </div>

                                    <div className="item-selections">
                                        <div className="item-selections-row">
                                            <div className="item-selections-name">
                                                <label className="fileDetailLabel">Verfahren:</label>
                                            </div>
                                            <div>
                                                <label>{item.method.displayName}</label>
                                            </div>
                                        </div>
                                        <div className="item-selections-row">
                                            <div className="item-selections-name">
                                                <label className="fileDetailLabel">Material:</label>
                                            </div>
                                            <div>
                                                <label>{item.material.displayName}</label>
                                            </div>
                                        </div>
                                        {item.infill ? (
                                            <div className="item-selections-row">
                                                <div className="item-selections-name">
                                                    <label className="fileDetailLabel">Infill:</label>
                                                </div>
                                                <div>
                                                    <label>{getSafe(() => item.infill.displayName, '100% Infill')}</label>
                                                </div>
                                            </div>
                                        ) : null}
                                        <div className="item-selections-row">
                                            <div className="item-selections-name">
                                                <label className="fileDetailLabel">Nachbearbeitung:</label>
                                            </div>
                                            <div>
                                                <label>{getSafe(() => item.processing.displayName, 'keine Glättung')}</label>
                                            </div>
                                        </div>
                                        <div className="item-selections-row">
                                            <div className="item-selections-name">
                                                <label className="fileDetailLabel">Farbe:</label>
                                            </div>
                                            <div>
                                                <label>{getSafe(() => item.color.displayName, 'keine Färbung')}</label>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="item-prices ">
                                        <p>
                                            Stückpreise
                                            <br />
                                        </p>
                                        <table id="item-prices-table">
                                            <tbody>
                                                <tr key={873487}>
                                                    <td>
                                                        {item.selectedPrice?.amount}
                                                        &nbsp;Stück:
                                                    </td>
                                                    <td className="amount-label">{parseFloat(item.selectedPrice?.price).toFixed(2)}€</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>

                            <div className="item-actions">
                                <div className="item-actions-wrapper">
                                    <div className="item-actions-options">
                                        {item.keepOrient ? (
                                            <>
                                                <label className="labelGreen">Ausrichtung beibehalten</label>
                                            </>
                                        ) : (
                                            <>
                                                <label className="labelRed">Ausrichtung nicht beibehalten</label>
                                            </>
                                        )}
                                        {item.allowHollow ? (
                                            <>
                                                <label className="labelGreen">Aushöhlen erlaubt</label>
                                            </>
                                        ) : (
                                            <>
                                                <label className="labelRed">Aushöhlen nicht erlaubt</label>
                                            </>
                                        )}
                                        {item.planSeries ? (
                                            <>
                                                <label className="labelGreen">Serienfertigung geplant</label>
                                            </>
                                        ) : (
                                            <>
                                                <label className="labelRed">Serienfertigung nicht geplant</label>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : null}
                </div>
            </div>
            {item.tasks &&
                item.tasks.length > 0 &&
                item.tasks.map((task, index) => (
                    <>
                        <FileTaskViewOrder key={task.uuid} position={`${position + 1}.${index + 1}`} task={task} />
                    </>
                ))}
        </>
    );
};

export default FileDetailsViewOrder;
