import React, { Component } from 'react';

class EditCellRenderer extends Component {
    constructor(props) {
        super(props);
        this.changeHandler = this.changeHandler.bind(this);
    }
    changeHandler(e) {
        console.log(e);
    }
    render() {
        return (
            <input
                type="text"
                value={this.props.amount}
                onChange={this.changeHandler}
            />
        );
    }
}

export default EditCellRenderer;
