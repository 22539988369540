import React from 'react';

const InfoOrders = () => {
    return (
        <div className="info-area">
            <div className="page-title">
                <h2>Bestellungen</h2>
                <hr className="solid"></hr>
            </div>
            <div className="paragraph_30"></div>
        </div>
    );
};

export default InfoOrders;
