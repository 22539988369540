import React, { useEffect, useState, useContext, useCallback } from 'react';
import FileExtraTask from '../file/FileExtraTask';
import Axios from 'axios';
import PreviewWindow from '../file/PreviewWindow';
import MethodContext from '../../context/MethodContext';
import UserContext from '../../context/UserContext';
import ReactTooltip from 'react-tooltip';
import uploadGif from '../../img/upload-file.gif';
import { NumericFormat } from 'react-number-format';
import { v4 as uuidv4 } from 'uuid';

const FileDetails = ({ file, position, deleteFile, copyFile, applySettingsForAll, notifyChange, discount, requestPreview, setRequestPreview }) => {
    const key = Math.random();

    const [tasks, setTasks] = useState(file.tasks);
    const { methodDefs } = useContext(MethodContext);
    const { userData } = useContext(UserContext);

    const [methods, setMethods] = useState([]);
    const [materials, setMaterials] = useState([]);
    const [processings, setProcessings] = useState([]);
    const [colors, setColors] = useState([]);
    const [infills, setInfills] = useState([]);

    const [method, setMethod] = useState('nomethod');
    const [material, setMaterial] = useState('nomaterial');
    const [processing, setProcessing] = useState('noprocessing');
    const [color, setColor] = useState('nocolor');
    const [infill, setInfill] = useState('noinfill');

    const [canHollow, setCanHollow] = useState(false);
    const [canOrient, setCanOrient] = useState(false);
    const [canSeries, setCanSeries] = useState(false);

    const [keepOrient, setKeepOrient] = useState(false);
    const [allowHollow, setAllowHollow] = useState(true);
    const [planSeries, setPlanSeries] = useState(false);
    const [comment, setComment] = useState(file.comment);

    const [amounts, setAmounts] = useState([1]);
    const [prices, setPrices] = useState([]);

    const [renderColor, setRenderColor] = useState(0xffffff);

    const [printable, setPrintable] = useState(true);
    const [checkPrintError, setCheckPrintError] = useState('Nicht druckbar');

    const [tooltipMethod, setTooltipMethod] = useState('Verfahren');
    const [tooltipMaterial, setTooltipMaterial] = useState('Material');
    const [tooltipColor, setTooltipColor] = useState('Farbgebung der Modelle auswählen');
    const [tooltipInfill, setTooltipInfill] = useState('Infill der Modelle auswählen');
    const [tooltipProcessing, setTooltipProcessing] = useState('Auswahl der Oberflächenbearbeitung');
    const [tooltipManualApproval] = useState(
        'Die Auswahl überschreitet die Grenzen für die automatische Preisermittlung. <br /> Das Angebot muss durch einen Mitarbeiter frei gegeben werden'
    );

    const checkBB = (mi, ma) => {
        if (!file?.boundingBox) return false;

        let x = 0;
        let y = 0;
        let z = 0;

        if(keepOrient === true) {
            x = file.x;
            y = file.y;
            z = file.z;
        } else {
            x = file.x_obb;
            y = file.y_obb;
            z = file.z_obb;
        }

        if (mi[0] <= x && mi[1] <= y && mi[2] <= z && x <= ma[0] && y <= ma[1] && z <= ma[2]) return true;
        
        if (mi[0] > x || mi[1] > y || mi[2] > z) {
            setCheckPrintError('Nicht druckbar. Mindestabmessungen unterschritten');
        }
        if (x > ma[0] && y > ma[1] && z > ma[2]) {
            setCheckPrintError('Nicht druckbar. Maximalabmessungen &uuml;berschritten');
        }
    
        if (mi[1] <= x && mi[0] <= y && mi[2] <= z && x <= ma[1] && y <= ma[0] && z <= ma[2]) return true;
        if (mi[2] <= x && mi[1] <= y && mi[0] <= z && x <= ma[2] && y <= ma[1] && z <= ma[0]) return true;
        if (mi[0] <= x && mi[2] <= y && mi[1] <= z && x <= ma[0] && y <= ma[2] && z <= ma[1]) return true;

        setCheckPrintError('Nicht druckbar. Modell verletzt Anforderungen an Mindest- oder Maximumabmessungen');

        return false;
    };

    const checkMaterials = (mater) => {
        const result = mater.map((mat) =>
            Object.assign(mat, {
                disabled: !checkBB([mat.minx, mat.miny, mat.minz], [mat.maxx, mat.maxy, mat.maxz]),
            })
        );
        //file.printable = result.some((e) => e.disabled === false) && file.params.isWatertight === true && file.params.isVolume === true;
        file.printable = result.some((e) => e.disabled === false);

        setPrintable(file.printable);
        setMaterials(result);

        var i;
        for (i = 0; i < result?.length; ++i) {
            const mat = result?.[i];
            if (!mat.disabled) {
                setMaterial(mat._id);
                file.density = mat.density; //###
                checkProcessings(mat.processings);
                checkColors(mat.colors);
                setTooltipMaterial(mat.tooltip);
                setCanHollow(mat.canHollow);
                setCanOrient(mat.canOrient);
                setCanSeries(mat.canSeries);

                setProcessing('noprocessing');
                setColor('nocolor');

                setInfills(mat.infills);

                if (mat.infills.length > 0) {
                    setInfill(mat.infills[0]);
                } else {
                    setInfill('noinfill');
                }
                setRenderColor(parseInt('ffffff', 16));
                setTooltipColor('Farbgebung der Modelle auswählen');
                setTooltipProcessing('Auswahl der Oberflächenbearbeitung');

                break;
            }
        }
    };

    const checkProcessings = (procs) => {
        const result = procs.map((mat) =>
            Object.assign(mat, {
                disabled: !checkBB([mat.minx, mat.miny, mat.minz], [mat.maxx, mat.maxy, mat.maxz]),
            })
        );
        setProcessings([{ displayName: 'Keine Nachbearbeitung', _id: 'noprocessing' }, ...result]);
    };

    const checkColors = (cols) => {
        const result = cols.map((mat) =>
            Object.assign(mat, {
                disabled: !checkBB([mat.minx, mat.miny, mat.minz], [mat.maxx, mat.maxy, mat.maxz]),
            })
        );
        setColors([{ displayName: 'nicht relevant', _id: 'nocolor' }, ...result]);
        if (result.length === 0) {
            setRenderColor(parseInt('ffffff', 16));
        }
    };

    const onMethodChange = (_method) => {
        setMethod(_method);
        const meth = methodDefs.methods.find((metho) => metho._id === _method);
        if (meth) {
            setTooltipMethod(meth.tooltip);
            checkMaterials(meth.materials);
        }
    };

    const onMaterialChange = (_material) => {
        setMaterial(_material);
        const meth = methodDefs.methods.find((metho) => metho._id === method);
        if (meth) {
            const mat = meth.materials.find((material) => material._id === _material);
            if (mat) {
                file.density = mat.density; //###
                checkProcessings(mat.processings);
                checkColors(mat.colors);
                setInfills(mat.infills);
                setTooltipMaterial(mat.tooltip);
                setCanHollow(mat.canHollow);
                setCanOrient(mat.canOrient);
                setCanSeries(mat.canSeries);
                if (mat.infills.length > 0) {
                    setInfill(mat.infills[0]);
                } else {
                    setInfill('noinfill');
                }
            }
        }

        setProcessing('noprocessing');
        setColor('nocolor');
        setRenderColor(parseInt('ffffff', 16));
        setTooltipColor('Farbgebung der Modelle auswählen');
        setTooltipProcessing('Auswahl der Oberflächenbearbeitung');
    };

    const onProcessingChange = (_processing) => {
        setProcessing(_processing);
        if (_processing !== 'noprocessing') {
            const meth = methodDefs.methods.find((metho) => metho._id === method);
            if (meth) {
                const mat = meth.materials.find((mater) => mater._id === material);
                if (mat) {
                    const pro = mat.processings.find((proc) => proc._id === _processing);
                    if (pro) {
                        setTooltipProcessing(pro.tooltip);
                    }
                }
            }
        } else {
            setTooltipProcessing('Auswahl der Oberflächenbearbeitung');
        }
    };

    const onColorChange = (_color) => {
        setColor(_color);
        if (_color !== 'nocolor') {
            const meth = methodDefs.methods.find((metho) => metho._id === method);
            if (meth) {
                const mat = meth.materials.find((mater) => mater._id === material);
                if (mat) {
                    const col = mat.colors.find((colo) => colo._id === _color);
                    if (col) {
                        let colStr = col.rgb;
                        setRenderColor(parseInt(colStr.substr(1), 16));
                        file.previewColor = parseInt(colStr.substr(1), 16);
                        setTooltipColor(col.tooltip);
                    } else {
                        setRenderColor(parseInt('ffffff', 16));
                    }
                }
            }
        } else {
            setTooltipColor('Farbgebung der Modelle auswählen');
            setRenderColor(parseInt('ffffff', 16));
        }
    };

    const onInfillChange = (_infill) => {
        setInfill(_infill);
        if (_infill !== 'noinfill') {
            const meth = methodDefs.methods.find((metho) => metho._id === method);
            if (meth) {
                const mat = meth.materials.find((mater) => mater._id === material);
                if (mat) {
                    const inf = mat.infills.find((infi) => infi._id === _infill);
                    if (inf) {
                        setTooltipInfill(inf.tooltip);
                    }
                }
            }
        } else {
            setTooltipInfill('Infill der Modelle auswählen');
        }
    };

    const onCommentChange = (text) => {
        setComment(text);
        file.comment = text;
    };

    const onAmountChange = (e, index) => {
        let pr = prices[index];
        if (pr.amount !== e.currentTarget.value) {
            pr.amount = e.currentTarget.value;
            setPrices([...prices.slice(0, index), pr, ...prices.slice(index + 1)]);
        }

        let am = amounts[index];
        if (am !== e.currentTarget.value) {
            am = e.currentTarget.value;
            setAmounts([...amounts.slice(0, index), am, ...amounts.slice(index + 1)]);
        }
    };

    const onAmountDelete = (e, index) => {
        if (prices.length > 1) {
            setPrices([...prices.slice(0, index), ...prices.slice(index + 1)]);
        }
        if (amounts.length > 1) {
            setAmounts([...amounts.slice(0, index), ...amounts.slice(index + 1)]);
        }
    };

    const onAmountAdd = (e) => {
        setAmounts([...amounts, 1]);
        setPrices([...prices, { amount: 1, price: undefined }]);
    };

    const restoreDropdowns = () => {
        console.log('restore dropdowns');
        const newmat = file.material;
        setInfills(file.infills);
        setColors(file.colors);
        setProcessings(file.processings);
        setMaterials(file.materials);
        setMethods(methodDefs.methods);

        setMethod(file.method);
        const meth = methodDefs.methods.find((metho) => metho._id === file.method);
        if (meth) {
            setTooltipMethod(meth.tooltip);
            onMaterialChange(newmat);
            //setMaterial(newmat);
            const mat = meth.materials.find((material) => material._id === newmat);
            if (mat) {
                setTooltipMaterial(mat.tooltip);
                setColor(file.color);
                if (file.color !== 'nocolor' && file.color !== undefined) {
                    const col = mat.colors.find((colo) => colo._id === file.color);
                    if (col) {
                        let colStr = col.rgb;
                        setRenderColor(parseInt(colStr.substr(1), 16));
                        setTooltipColor(col.tooltip);
                    } else {
                        setRenderColor(parseInt('ffffff', 16));
                    }
                }
                setProcessing(file.processing);
                if (file.processing !== 'noprocessing' && file.processing !== undefined) {
                    const pro = mat.processings.find((proc) => proc._id === file.processing);
                    if (pro) {
                        setTooltipProcessing(pro.tooltip);
                    }
                }
                setInfill(file.infill);
                if (file.infill !== 'noinfill' && file.infill !== undefined) {
                    const inf = mat.infills.find((infi) => infi._id === file.infill);
                    if (inf) {
                        setTooltipInfill(inf.tooltip);
                    }
                }
                setKeepOrient(file.keepOrient);
                setAllowHollow(file.allowHollow);
                setPlanSeries(file.planSeries);
            } else {
                console.log('missed mat');
            }
        } else {
            console.log('missed meth');
        }
        file.isCopy = false;
    };

    useEffect(() => {
        const onUpload = async () => {
            const formData = new FormData();
            formData.append('file', file);
            formData.append('uuid', file.uuid);
            formData.append('unit', file.unit);
            try {
                const res = await Axios.post(`${process.env.REACT_APP_SERVER}/files/upload`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
                console.log(res.data);
                file.fileName = res.data.fileName;
                file.filePath = res.data.filePath;
                file.fileType = res.data.fileType;
                file.volume = res.data.volume;
                file.area = res.data.area;
                file.x = res.data.x;
                file.y = res.data.y;
                file.z = res.data.z;
                file.w = res.data.w;
                file.h = res.data.h;
                file.d = res.data.d;
                file.x_obb = res.data.x_obb;
                file.y_obb = res.data.y_obb;
                file.z_obb = res.data.z_obb;
                file.w_obb = res.data.x_obb;
                file.h_obb = res.data.y_obb;
                file.d_obb = res.data.z_obb;
                file.boundingBox = res.data.boundingBox;
                file.boundingBoxObb = res.data.boundingBoxObb;
                file.params = res.data;

                file.tasks = [];
                file.amounts = [1];
                setAmounts([1]);
                setPrices([]);

                file.density = methodDefs?.methods?.[0]?.materials?.[0]?.density; //###
                checkMaterials(methodDefs?.methods?.[0]?.materials);
                //checkProcessings(methodDefs?.methods?.[0]?.materials?.[0]?.processings);
                //checkColors(methodDefs?.methods?.[0]?.materials?.[0]?.colors);
            } catch (err) {
                if (err?.response?.status === 500) {
                    console.log('Error 500');
                } else {
                    console.log(err?.response?.data?.msg);
                }
            }
        };

        const initDropdowns = () => {
            setMethods(methodDefs.methods);
            setMethod(methodDefs?.methods?.[0]?._id);
            setTooltipMethod(methodDefs?.methods?.[0]?.tooltip);
            checkMaterials(methodDefs?.methods?.[0]?.materials);
            //checkProcessings(methodDefs?.methods?.[0]?.materials?.[0]?.processings);
            //checkColors(methodDefs?.methods?.[0]?.materials?.[0]?.colors);
        };

        if (file.fileName) {
            setAmounts(file.amounts);
            setPrices(file.prices || []);
            restoreDropdowns();
        } else {
            onUpload();
            initDropdowns();
        }
    }, [file, file.uuid, methodDefs]);

    useEffect(() => {
        const meth = methodDefs.methods.find((metho) => metho._id === method);
        if (meth) {
            checkMaterials(meth.materials);
        }
    }, [keepOrient]);

    useEffect(() => {
        file.amounts = amounts;
    }, [file, amounts]);

    useEffect(() => {
        file.prices = prices;
    }, [file, prices]);

    useEffect(() => {
        file.tasks = tasks;
    }, [file, tasks]);

    const updateCalc = useCallback(async () => {
        if (method && method !== 'nomethod' && material && material !== 'nomaterial' && file.volume) {
            try {
                const testuuid = uuidv4();
                file.params.uuid = testuuid;
                const calcRes = await Axios.post(`${process.env.REACT_APP_SERVER}/files/calc`, {
                    settings: {
                        method: method,
                        material: material,
                        processing: processing,
                        color: color,
                        infill: infill,
                        keepOrient: keepOrient,
                        allowHollow: allowHollow,
                        planSeries: planSeries,
                        amounts: amounts,
                        discount: discount,
                    },
                    params: file.params,
                });
                if (!calcRes.data.prices) {
                    calcRes.data.prices = [];
                }
                setPrices(calcRes.data.prices);
                notifyChange();
            } catch (err) {
                if (err?.response?.status === 500) {
                    console.log('Error 500');
                } else {
                    console.log(err);
                }
            }
        }
    }, [method, material, processing, color, infill, amounts, allowHollow, keepOrient, planSeries, discount, file]);

    useEffect(() => {
        updateCalc();
    }, [material, processing, color, infill, amounts, allowHollow, keepOrient, planSeries, discount]);
    //}, [material, processing, color, amounts, allowHollow, keepOrient, planSeries, discount, updateCalc]);

    useEffect(() => {
        file.method = method;
        file.material = material;
        file.processing = processing;
        file.color = color;
        file.infill = infill;
        file.allowHollow = allowHollow;
        file.keepOrient = keepOrient;
        file.planSeries = planSeries;
        file.discount = discount;

        file.materials = materials;
        file.colors = colors;
        file.processings = processings;
    }, [method, material, processing, color, infill, amounts, allowHollow, keepOrient, planSeries, discount, materials, colors, processings, file]);

    const onDelete = () => {
        deleteFile(file.uuid);
    };

    const onCopy = () => {
        copyFile(file);
    };

    const onSettingsForAll = () => {
        applySettingsForAll({
            uuid: file.uuid,
            method: method,
            material: material,
            processing: processing,
            color: color,
            infill: infill,
            methods: methods,
            materials: materials,
            processings: processings,
            colors: colors,
            infills: infills,
            prices: prices,
            amounts: amounts,
            allowHollow: allowHollow,
            keepOrient: keepOrient,
            planSeries: planSeries,
        });
    };

    const onExtraTask = () => {
        const task = {
            name: `Zusätzliche Leistung ${file.fileName}`,
            uuid: uuidv4(),
            prices: [
                {
                    amount: 1,
                    price: 0,
                    time: 1,
                    selected: true,
                },
            ],
            references: file.fileName,
            description: ' ',
            type: 'item',
            approved: false,
        };

        setTasks([...tasks, task]);
    };

    const deleteTask = (task) => {
        let newTasks = tasks.filter(function (obj) {
            return obj.uuid !== task.uuid;
        });
        setTasks(newTasks);
    };

    const updateTask = (task) => {
        let newTasks = [...tasks];
        newTasks.forEach(function (item, i) {
            if (item.uuid === task.uuid) newTasks[i] = task;
        });
        setTasks(newTasks);
    };

    useEffect(() => {
        if (file.oldRerender !== file.rerender) {
            file.oldRerender = file.rerender;

            restoreDropdowns();
            setAmounts(file.amounts);
        }
    }, [file.rerender]);

    return (
        <>
            <div id="item-element-wrapper" key={`fdtop${key}`}>
                <div className={`item-container ${printable ? '' : 'item-container-notPrintable'}`} key={`fd2${key}`}>
                    <div className="item-preview-details-wrapper" key={`fd3${key}`}>
                        {file.fileName ? (
                            <>
                                <div className="item-position-number">{position + 1}</div>

                                <div className="item-details-row">
                                    {file.no3D ? (
                                        <img src={file.preview} width="200" height="200" alt="" />
                                    ) : (
                                        <PreviewWindow
                                            file={file}
                                            renderColor={renderColor}
                                            requestPreview={requestPreview}
                                            setRequestPreview={setRequestPreview}
                                            useAABB={keepOrient}
                                        />
                                    )}

                                    <div className="item-params">
                                        <div className="item-name">{file.name}</div>

                                        <div className="item-params-row">
                                            <div className="item-params-value">
                                                <NumericFormat
                                                    value={file.keepOrient ? file.x : file.x_obb}
                                                    displayType={'text'}
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    thousandSeparator={'.'}
                                                    decimalSeparator={','}
                                                />
                                                <span> x </span>
                                                <NumericFormat
                                                    value={file.keepOrient ? file.y : file.y_obb}
                                                    displayType={'text'}
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    thousandSeparator={'.'}
                                                    decimalSeparator={','}
                                                />
                                                <span> x </span>
                                                <NumericFormat
                                                    value={file.keepOrient ? file.z : file.z_obb}
                                                    displayType={'text'}
                                                    suffix={' mm'}
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    thousandSeparator={'.'}
                                                    decimalSeparator={','}
                                                />
                                            </div>
                                        </div>

                                        <div className="item-comment-textbox">
                                            <p className="paragraph">Bemerkungen:</p>
                                            <textarea
                                                id="file-comment"
                                                name="filecomment"
                                                rows="7"
                                                cols="25"
                                                onChange={(e) => onCommentChange(e.currentTarget.value)}
                                                value={comment}
                                            ></textarea>
                                        </div>
                                    </div>

                                    <div className="item-selections">
                                        <div className="item-selections-row">
                                            <div className="item-selections-name">
                                                <label className="fileDetailLabel">Verfahren</label>
                                            </div>
                                            <div className="item-selections-dropdown">
                                                {userData.user ? (
                                                    
                                                    <select
                                                        key={`fdsel1${position}`}
                                                        className="param-select"
                                                        onChange={(e) => onMethodChange(e.currentTarget.value)}
                                                        value={method}
                                                    >
                                                        {methods?.map((meth, oindex) => (
                                                            <>
                                                                {userData.user.allowedMethods.includes(meth._id) && (
                                                                    <option key={`fdopt1${position}${oindex}`} value={meth._id}>
                                                                        {meth.displayName}
                                                                    </option>
                                                                )}
                                                            </>
                                                        ))}
                                                    </select>
                                                    
                                                ) : (
                                                    <select
                                                        key={`fdsel1${position}`}
                                                        className="param-select"
                                                        onChange={(e) => onMethodChange(e.currentTarget.value)}
                                                        value={method}
                                                    >
                                                        {methods?.map((meth, oindex) => (
                                                            <>
                                                                {meth.isDefault === true && (
                                                                    <option key={`fdopt1${position}${oindex}`} value={meth._id}>
                                                                        {meth.displayName}
                                                                    </option>
                                                                )}
                                                            </>
                                                        ))}
                                                    </select>
                                                    
                                                )}
                                            </div>
                                            <div className="item-selections-tooltip">
                                                <button className="info-button" data-tip data-for={'tt4' + key}>
                                                    <strong>?</strong>
                                                </button>
                                                <ReactTooltip id={'tt4' + key} place="top" effect="solid" event="click" html={true}>
                                                    {tooltipMethod}
                                                </ReactTooltip>
                                            </div>
                                        </div>
                                        {printable ? (
                                            <>
                                                <div className="item-selections-row">
                                                    <div className="item-selections-name">
                                                        <label className="fileDetailLabel">Material</label>
                                                    </div>
                                                    <div className="item-selections-dropdown">
                                                        <select
                                                            key={`fdsel2${position}`}
                                                            className="param-select"
                                                            onChange={(e) => onMaterialChange(e.currentTarget.value)}
                                                            value={material}
                                                        >
                                                            {materials?.map((mat, oindex) => (
                                                                <option
                                                                    key={`fdopt2${position}${oindex}`}
                                                                    value={mat._id}
                                                                    disabled={mat.disabled ? true : null}
                                                                >
                                                                    {mat.displayName}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                    <div className="item-selections-tooltip">
                                                        <button className="info-button" data-tip data-for={'tt5' + key}>
                                                            <strong>?</strong>
                                                        </button>
                                                        <ReactTooltip id={'tt5' + key} place="top" effect="solid" event="click" html={true}>
                                                            {tooltipMaterial}
                                                        </ReactTooltip>
                                                    </div>
                                                </div>

                                                {infills?.length > 1 ? (
                                                    <div className="item-selections-row">
                                                        <div className="item-selections-name">
                                                            <label className="fileDetailLabel">Infill</label>
                                                        </div>
                                                        <div className="item-selections-dropdown">
                                                            <select
                                                                key={`fdsel5${position}`}
                                                                className="param-select"
                                                                onChange={(e) => onInfillChange(e.currentTarget.value)}
                                                                value={infill}
                                                            >
                                                                {infills.map((inf, oindex) => (
                                                                    <option
                                                                        key={`fdopt5${position}${oindex}`}
                                                                        value={inf._id}
                                                                        disabled={inf.disabled ? true : null}
                                                                    >
                                                                        {inf.displayName}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                        <div className="item-selections-tooltip">
                                                            <button className="info-button" data-tip data-for={'tt8' + key}>
                                                                <strong>?</strong>
                                                            </button>
                                                            <ReactTooltip id={'tt8' + key} place="top" effect="solid" event="click" html={true}>
                                                                {tooltipInfill}
                                                            </ReactTooltip>
                                                        </div>
                                                    </div>
                                                ) : null}

                                                {processings.length > 1 ? (
                                                    <div className="item-selections-row">
                                                        <div className="item-selections-name">
                                                            <label className="fileDetailLabel">Nachbearbeitung</label>
                                                        </div>
                                                        <div className="item-selections-dropdown">
                                                            <select
                                                                key={`fdsel3${position}`}
                                                                className="param-select"
                                                                onChange={(e) => onProcessingChange(e.currentTarget.value)}
                                                                value={processing}
                                                            >
                                                                {processings.map((item, oindex) => (
                                                                    <option
                                                                        key={`fdopt3${position}${oindex}`}
                                                                        value={item._id}
                                                                        disabled={item.disabled ? true : null}
                                                                    >
                                                                        {item.displayName}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                        <div className="item-selections-tooltip">
                                                            <button className="info-button" data-tip data-for={'tt6' + key}>
                                                                <strong>?</strong>
                                                            </button>
                                                            <ReactTooltip id={'tt6' + key} place="top" effect="solid" event="click" html={true}>
                                                                {tooltipProcessing}
                                                            </ReactTooltip>
                                                        </div>
                                                    </div>
                                                ) : null}
                                                {colors.length > 1 ? (
                                                    <div className="item-selections-row">
                                                        <div className="item-selections-name">
                                                            <label className="fileDetailLabel">Farbe</label>
                                                        </div>
                                                        <div className="item-selections-dropdown">
                                                            <select
                                                                key={`fdsel4${position}`}
                                                                className="param-select"
                                                                onChange={(e) => onColorChange(e.currentTarget.value)}
                                                                value={color}
                                                            >
                                                                {colors.map((col, oindex) => (
                                                                    <option
                                                                        key={`fdopt4${position}${oindex}`}
                                                                        value={col._id}
                                                                        disabled={col.disabled ? true : null}
                                                                    >
                                                                        {col.displayName}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                        <div className="item-selections-tooltip">
                                                            <button className="info-button" data-tip data-for={'tt7' + key}>
                                                                <strong>?</strong>
                                                            </button>
                                                            <ReactTooltip id={'tt7' + key} place="top" effect="solid" event="click" html={true}>
                                                                {tooltipColor}
                                                            </ReactTooltip>
                                                        </div>
                                                    </div>
                                                ) : null}
                                            </>
                                        ) : (
                                            <label>{checkPrintError}</label>
                                        )}
                                    </div>

                                    <div className="item-prices">
                                        {printable ? (
                                            <>
                                                <table className="pricelist" key={`priceTable${key}`}>
                                                    <tbody>
                                                        <tr key={`fdtr${position}1`}>
                                                            <td>1 Stück:</td>

                                                            <td></td>

                                                            <td>
                                                                <NumericFormat
                                                                    value={prices?.[0]?.price}
                                                                    displayType={'text'}
                                                                    suffix={' €'}
                                                                    decimalScale={2}
                                                                    fixedDecimalScale={true}
                                                                    thousandSeparator={'.'}
                                                                    decimalSeparator={','}
                                                                />
                                                            </td>

                                                            <td>
                                                                {prices?.[0]?.needsManualApproval ? (
                                                                    <div className="item-selections-tooltip">
                                                                        <button className="info-button" data-tip data-for={'tt4' + key}>
                                                                            <strong>!</strong>
                                                                        </button>
                                                                        <ReactTooltip
                                                                            id={'tt4' + key}
                                                                            place="top"
                                                                            effect="solid"
                                                                            event="click"
                                                                            html={true}
                                                                        >
                                                                            {tooltipManualApproval}
                                                                        </ReactTooltip>
                                                                    </div>
                                                                ) : null}
                                                            </td>
                                                            <td></td>
                                                        </tr>

                                                        {prices
                                                            ? prices.map((item, index) => (
                                                                  <>
                                                                      {index > 0 ? (
                                                                          <tr key={`fdtr${position}2`}>
                                                                              <td className="first-td">
                                                                                  {prices.length > 1 ? (
                                                                                      <button
                                                                                          className="delete-amount-button"
                                                                                          onClick={(e) => onAmountDelete(e, index)}
                                                                                      >
                                                                                          -
                                                                                      </button>
                                                                                  ) : null}
                                                                              </td>

                                                                              <td>
                                                                                  {amounts && amounts.length > index ? (
                                                                                      <input
                                                                                          className="amount-input"
                                                                                          type="number"
                                                                                          value={amounts[index]}
                                                                                          onChange={(e) => onAmountChange(e, index)}
                                                                                      />
                                                                                  ) : null}
                                                                              </td>

                                                                              <td>
                                                                                  {item.price ? (
                                                                                      <>
                                                                                          <NumericFormat
                                                                                              value={item.price}
                                                                                              displayType={'text'}
                                                                                              suffix={' €'}
                                                                                              decimalScale={2}
                                                                                              fixedDecimalScale={true}
                                                                                              thousandSeparator={'.'}
                                                                                              decimalSeparator={','}
                                                                                          />
                                                                                      </>
                                                                                  ) : (
                                                                                      <label>0.00 € </label>
                                                                                  )}
                                                                              </td>
                                                                              <td>
                                                                                  {item.Time1 ? (
                                                                                      <>
                                                                                          {item.Time}
                                                                                          <label>d </label>
                                                                                      </>
                                                                                  ) : null}
                                                                              </td>
                                                                              <td>
                                                                                  {item.needsManualApproval ? (
                                                                                      <div className="item-selections-tooltip">
                                                                                          <button
                                                                                              className="info-button"
                                                                                              data-tip
                                                                                              data-for={'tt4' + key}
                                                                                          >
                                                                                              <strong>!</strong>
                                                                                          </button>
                                                                                          <ReactTooltip
                                                                                              id={'tt4' + key}
                                                                                              place="top"
                                                                                              effect="solid"
                                                                                              event="click"
                                                                                              html={true}
                                                                                          >
                                                                                              {tooltipManualApproval}
                                                                                          </ReactTooltip>
                                                                                      </div>
                                                                                  ) : null}
                                                                              </td>
                                                                          </tr>
                                                                      ) : null}
                                                                  </>
                                                              ))
                                                            : null}
                                                        <tr>
                                                            <td>
                                                                <button className="add-amount-button" onClick={(e) => onAmountAdd(e)}>
                                                                    +
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </>
                                        ) : null}
                                    </div>
                                </div>

                                <div className="remove-button-box">
                                    <div>
                                        {' '}
                                        <button className="delete-item-button" onClick={onDelete}>
                                            🗙
                                        </button>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <div className="item-waiting">
                                <img src={uploadGif} alt="Uploading file" height={100} width={100} />
                            </div>
                        )}
                    </div>
                </div>

                {file.fileName ? (
                    <div className="item-actions">
                        <div className="item-actions-wrapper">
                            <div className="item-actions-options">
                                {canOrient ? (
                                    <>
                                        <div className="item-comment-row">
                                            <div className="item-comment-label">
                                                <label htmlFor={file.uuid + 'keepOrient'}>Ausrichtung beibehalten</label>
                                            </div>

                                            <div className="item-comment-checkbox">
                                                <input
                                                    type="checkbox"
                                                    id={file.uuid + 'keepOrient'}
                                                    name="keep-orient"
                                                    checked={keepOrient}
                                                    onChange={(e) => setKeepOrient(e.target.checked)}
                                                />
                                            </div>
                                            <div className="item-comment-tooltip">
                                                <button className="info-button" data-tip data-for={'tt1' + key}>
                                                    <strong>?</strong>
                                                </button>
                                                <ReactTooltip id={'tt1' + key} event="click" place="top" effect="solid">
                                                    Ausrichtung während der Fertigung wie
                                                    <br /> in der CAD-Datei angelegt beibehalten.
                                                </ReactTooltip>
                                            </div>
                                        </div>
                                    </>
                                ) : null}
                                {canHollow ? (
                                    <>
                                        <div className="item-comment-row">
                                            <div className="item-comment-label">
                                                <label htmlFor={file.uuid + 'allowHollow'}>Aushöhlen erlauben</label>
                                            </div>
                                            <div className="item-comment-checkbox">
                                                <input
                                                    type="checkbox"
                                                    id={file.uuid + 'allowHollow'}
                                                    name="allow-hollow"
                                                    checked={allowHollow}
                                                    onChange={(e) => setAllowHollow(e.target.checked)}
                                                />
                                            </div>
                                            <div className="item-comment-tooltip">
                                                <button className="info-button" data-tip data-for={'tt2' + key}>
                                                    <strong>?</strong>
                                                </button>
                                                <ReactTooltip id={'tt2' + key} place="top" effect="solid" event="click">
                                                    Sehr massive Wandstärken können ausgehöhlt werden.
                                                </ReactTooltip>
                                            </div>
                                        </div>
                                    </>
                                ) : null}
                                {canSeries ? (
                                    <>
                                        <div className="item-comment-row">
                                            <div className="item-comment-label">
                                                <label htmlFor={file.uuid + 'planSeries'}>Serienfertigung geplant</label>
                                            </div>
                                            <div className="item-comment-checkbox">
                                                <input
                                                    type="checkbox"
                                                    id={file.uuid + 'planSeries'}
                                                    name="plan-series"
                                                    checked={planSeries}
                                                    onChange={(e) => setPlanSeries(e.target.checked)}
                                                />
                                            </div>
                                            <div className="item-comment-tooltip">
                                                <button className="info-button" data-tip data-for={'tt3' + key}>
                                                    <strong>?</strong>
                                                </button>
                                                <ReactTooltip id={'tt3' + key} place="top" effect="solid" event="click">
                                                    Eine Serienfertigung ist geplant, <br />
                                                    gern können Sie uns dazu weitere Informationen
                                                    <br /> im Bemerkungsfeld zukommen lassen.
                                                </ReactTooltip>
                                            </div>
                                        </div>
                                    </>
                                ) : null}
                            </div>

                            <div className="item-actions-actions">
                                <div>
                                    <button className="file-details-button" onClick={onCopy}>
                                        Modell kopieren
                                    </button>
                                </div>
                                <div>
                                    <button
                                        className="file-details-button"
                                        onClick={() => {
                                            onSettingsForAll();
                                        }}
                                    >
                                        Einstellungen übertragen
                                    </button>
                                </div>
                                {userData.user ? (
                                    <div>
                                        <button className="file-details-button" onClick={onExtraTask}>
                                            Zusatzleistungen
                                        </button>
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    </div>
                ) : null}
                {tasks &&
                    tasks.length > 0 && tasks.map((task, index) => (
                        <div key={`fdtask${position}${index}`}>
                            <FileExtraTask
                                position={`${position + 1}.${index + 1}`}
                                task={task}
                                deleteTask={deleteTask}
                                updateTask={updateTask}
                            />
                        </div>
                    ))}
            </div>
        </>
    );
};

export default FileDetails;
