import React from 'react';

const Footer1 = () => {
    return (
        <div className="info-box flex">
                   
            <span className='footer1'>
            <a href="https://www.dick-dick.de/agb.html" target="_blank">AGB</a> <span> - </span> 
            <a href="https://www.dick-dick.de/versand.html" target="_blank">Versand</a> <span> - </span>  
            <a href="https://www.dick-dick.de/impressum-generative-fertigung.html" target="_blank">Impressum</a> <span> - </span>  
            <a href="https://www.dick-dick.de/datenschutzerklaerung_gf.html" target="_blank">Datenschutzerklärung</a> 
            <br/><br/>
            © copyright Dick & Dick Laserschneid- & Systemtechnik GmbH
            </span>   
            
        </div>
    );
};

export default Footer1;