import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import Modal from 'react-modal';

const AdminEmails = ({ setError }) => {
    const [emailRegister, setEmailRegister] = useState();
    const [emailPassword, setEmailPassword] = useState();
    const [emailAccount, setEmailAccount] = useState();
    const [emailSaveOffer, setEmailSaveOffer] = useState();
    const [emailChangeOffer, setEmailChangeOffer] = useState();
    const [emailOrder, setEmailOrder] = useState();
    const [emailOrderStatus, setEmailOrderStatus] = useState();
    const [emailChatMsgOffer, setEmailChatMsgOffer] = useState();
    const [emailChatMsgOrder, setEmailChatMsgOrder] = useState();
    const [emailDelivery, setEmailDelivery] = useState();
    const [emailFileUpload, setEmailFileUpload] = useState();
    const [emailInvoice, setEmailInvoice] = useState();

    const [subjectRegister, setSubjectRegister] = useState();
    const [subjectPassword, setSubjectPassword] = useState();
    const [subjectAccount, setSubjectAccount] = useState();
    const [subjectSaveOffer, setSubjectSaveOffer] = useState();
    const [subjectChangeOffer, setSubjectChangeOffer] = useState();
    const [subjectOrder, setSubjectOrder] = useState();
    const [subjectOrderStatus, setSubjectOrderStatus] = useState();
    const [subjectChatMsgOffer, setSubjectChatMsgOffer] = useState();
    const [subjectChatMsgOrder, setSubjectChatMsgOrder] = useState();
    const [subjectDelivery, setSubjectDelivery] = useState();
    const [subjectFileUpload, setSubjectFileUpload] = useState();
    const [subjectInvoice, setSubjectInvoice] = useState();

    const [cbRegister, setCbRegister] = useState(false);
    const [cbPassword, setCbPassword] = useState(false);
    const [cbAccount, setCbAccount] = useState(false);
    const [cbSaveOffer, setCbSaveOffer] = useState(false);
    const [cbChangeOffer, setCbChangeOffer] = useState(false);
    const [cbOrder, setCbOrder] = useState(false);
    const [cbOrderStatus, setCbOrderStatus] = useState(false);
    const [cbChatMsgOffer, setCbChatMsgOffer] = useState(false);
    const [cbChatMsgOrder, setCbChatMsgOrder] = useState(false);
    const [cbDelivery, setCbDelivery] = useState(false);
    const [cbFileUpload, setCbFileUpload] = useState(false);
    const [cbInvoice, setCbInvoice] = useState(false);

    const [cbRegisterC, setCbRegisterC] = useState(false);
    const [cbPasswordC, setCbPasswordC] = useState(false);
    const [cbAccountC, setCbAccountC] = useState(false);
    const [cbSaveOfferC, setCbSaveOfferC] = useState(false);
    const [cbChangeOfferC, setCbChangeOfferC] = useState(false);
    const [cbOrderC, setCbOrderC] = useState(false);
    const [cbOrderStatusC, setCbOrderStatusC] = useState(false);
    const [cbChatMsgOfferC, setCbChatMsgOfferC] = useState(false);
    const [cbChatMsgOrderC, setCbChatMsgOrderC] = useState(false);
    const [cbDeliveryC, setCbDeliveryC] = useState(false);
    const [cbFileUploadC, setCbFileUploadC] = useState(false);
    const [cbInvoiceC, setCbInvoiceC] = useState(false);

    const [copyEmailRegister, setCopyEmailRegister] = useState();
    const [copyEmailPassword, setCopyEmailPassword] = useState();
    const [copyEmailAccount, setCopyEmailAccount] = useState();
    const [copyEmailSaveOffer, setCopyEmailSaveOffer] = useState();
    const [copyEmailChangeOffer, setCopyEmailChangeOffer] = useState();
    const [copyEmailOrder, setCopyEmailOrder] = useState();
    const [copyEmailOrderStatus, setCopyEmailOrderStatus] = useState();
    const [copyEmailChatMsgOffer, setCopyEmailChatMsgOffer] = useState();
    const [copyEmailChatMsgOrder, setCopyEmailChatMsgOrder] = useState();
    const [copyEmailDelivery, setCopyEmailDelivery] = useState();
    const [copyEmailFileUpload, setCopyEmailFileUpload] = useState();
    const [copyEmailInvoice, setCopyEmailInvoice] = useState();

    const [showModal, setShowModal] = useState(false);
    const [modalText, setModalText] = useState('Lorem Ipsum');

    const modalClose = () => {
        setShowModal(false);
    };

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
        },
    };

    const saveChanges = async () => {
        let editObject = {};
        editObject.emailRegister = emailRegister;
        editObject.emailPassword = emailPassword;
        editObject.emailAccount = emailAccount;
        editObject.emailSaveOffer = emailSaveOffer;
        editObject.emailChangeOffer = emailChangeOffer;
        editObject.emailOrder = emailOrder;
        editObject.emailOrderStatus = emailOrderStatus;
        editObject.emailChatMsgOffer = emailChatMsgOffer;
        editObject.emailChatMsgOrder = emailChatMsgOrder;
        editObject.emailDelivery = emailDelivery;
        editObject.emailFileUpload = emailFileUpload;
        editObject.emailInvoice = emailInvoice;

        editObject.subjectRegister = subjectRegister;
        editObject.subjectPassword = subjectPassword;
        editObject.subjectAccount = subjectAccount;
        editObject.subjectSaveOffer = subjectSaveOffer;
        editObject.subjectChangeOffer = subjectChangeOffer;
        editObject.subjectOrder = subjectOrder;
        editObject.subjectOrderStatus = subjectOrderStatus;
        editObject.subjectChatMsgOffer = subjectChatMsgOffer;
        editObject.subjectChatMsgOrder = subjectChatMsgOrder;
        editObject.subjectDelivery = subjectDelivery;
        editObject.subjectFileUpload = subjectFileUpload;
        editObject.subjectInvoice = subjectInvoice;

        editObject.cbRegister = cbRegister;
        editObject.cbPassword = cbPassword;
        editObject.cbAccount = cbAccount;
        editObject.cbSaveOffer = cbSaveOffer;
        editObject.cbChangeOffer = cbChangeOffer;
        editObject.cbOrder = cbOrder;
        editObject.cbOrderStatus = cbOrderStatus;
        editObject.cbChatMsgOffer = cbChatMsgOffer;
        editObject.cbChatMsgOrder = cbChatMsgOrder;
        editObject.cbDelivery = cbDelivery;
        editObject.cbFileUpload = cbFileUpload;
        editObject.cbInvoice = cbInvoice;

        editObject.cbRegisterC = cbRegisterC;
        editObject.cbPasswordC = cbPasswordC;
        editObject.cbAccountC = cbAccountC;
        editObject.cbSaveOfferC = cbSaveOfferC;
        editObject.cbChangeOfferC = cbChangeOfferC;
        editObject.cbOrderC = cbOrderC;
        editObject.cbOrderStatusC = cbOrderStatusC;
        editObject.cbChatMsgOfferC = cbChatMsgOfferC;
        editObject.cbChatMsgOrderC = cbChatMsgOrderC;
        editObject.cbDeliveryC = cbDeliveryC;
        editObject.cbFileUploadC = cbFileUploadC;
        editObject.cbInvoiceC = cbInvoiceC;

        editObject.copyEmailRegister = copyEmailRegister;
        editObject.copyEmailPassword = copyEmailPassword;
        editObject.copyEmailAccount = copyEmailAccount;
        editObject.copyEmailSaveOffer = copyEmailSaveOffer;
        editObject.copyEmailChangeOffer = copyEmailChangeOffer;
        editObject.copyEmailOrder = copyEmailOrder;
        editObject.copyEmailOrderStatus = copyEmailOrderStatus;
        editObject.copyEmailChatMsgOffer = copyEmailChatMsgOffer;
        editObject.copyEmailChatMsgOrder = copyEmailChatMsgOrder;
        editObject.copyEmailDelivery = copyEmailDelivery;
        editObject.copyEmailFileUpload = copyEmailFileUpload;
        editObject.copyEmailInvoice = copyEmailInvoice;

        let token = localStorage.getItem('auth-token');
        try {
            await Axios.post(`${process.env.REACT_APP_SERVER}/admin/saveemails`, editObject, { headers: { 'x-auth-token': token } });
        } catch (err) {
            err.response.data && err.response.data.msg && setError(err.response.data.msg);
        }
    };

    const parseBool = (val) => {
        return val === true || val === 'true';
    };

    useEffect(() => {
        const initThis = async () => {
            try {
                let token = localStorage.getItem('auth-token');
                const res = await Axios.get(`${process.env.REACT_APP_SERVER}/admin/emails/`, { headers: { 'x-auth-token': token } });
                if (res) {
                    setEmailRegister(res.data.emailRegister);
                    setEmailPassword(res.data.emailPassword);
                    setEmailAccount(res.data.emailAccount);
                    setEmailSaveOffer(res.data.emailSaveOffer);
                    setEmailChangeOffer(res.data.emailChangeOffer);
                    setEmailOrder(res.data.emailOrder);
                    setEmailOrderStatus(res.data.emailOrderStatus);
                    setEmailChatMsgOffer(res.data.emailChatMsgOffer);
                    setEmailChatMsgOrder(res.data.emailChatMsgOrder);
                    setEmailDelivery(res.data.emailDelivery);
                    setEmailFileUpload(res.data.emailFileUpload);
                    setEmailInvoice(res.data.emailInvoice);

                    setSubjectRegister(res.data.subjectRegister);
                    setSubjectPassword(res.data.subjectPassword);
                    setSubjectAccount(res.data.subjectAccount);
                    setSubjectSaveOffer(res.data.subjectSaveOffer);
                    setSubjectChangeOffer(res.data.subjectChangeOffer);
                    setSubjectOrder(res.data.subjectOrder);
                    setSubjectOrderStatus(res.data.subjectOrderStatus);
                    setSubjectChatMsgOffer(res.data.subjectChatMsgOffer);
                    setSubjectChatMsgOrder(res.data.subjectChatMsgOrder);
                    setSubjectDelivery(res.data.subjectDelivery);
                    setSubjectFileUpload(res.data.subjectFileUpload);
                    setSubjectInvoice(res.data.subjectInvoice);

                    setCbRegister(parseBool(res.data.cbRegister));
                    setCbPassword(parseBool(res.data.cbPassword));
                    setCbAccount(parseBool(res.data.cbAccount));
                    setCbSaveOffer(parseBool(res.data.cbSaveOffer));
                    setCbChangeOffer(parseBool(res.data.cbChangeOffer));
                    setCbOrder(parseBool(res.data.cbOrder));
                    setCbOrderStatus(parseBool(res.data.cbOrderStatus));
                    setCbChatMsgOffer(parseBool(res.data.cbChatMsgOffer));
                    setCbChatMsgOrder(parseBool(res.data.cbChatMsgOrder));
                    setCbDelivery(parseBool(res.data.cbDelivery));
                    setCbFileUpload(res.data.cbFileUpload);
                    setCbInvoice(res.data.cbInvoice);

                    setCbRegisterC(parseBool(res.data.cbRegisterC));
                    setCbPasswordC(parseBool(res.data.cbPasswordC));
                    setCbAccountC(parseBool(res.data.cbAccountC));
                    setCbSaveOfferC(parseBool(res.data.cbSaveOfferC));
                    setCbChangeOfferC(parseBool(res.data.cbChangeOfferC));
                    setCbOrderC(parseBool(res.data.cbOrderC));
                    setCbOrderStatusC(parseBool(res.data.cbOrderStatusC));
                    setCbChatMsgOfferC(parseBool(res.data.cbChatMsgOfferC));
                    setCbChatMsgOrderC(parseBool(res.data.cbChatMsgOrderC));
                    setCbDeliveryC(parseBool(res.data.cbDeliveryC));
                    setCbFileUploadC(res.data.cbFileUploadC);
                    setCbInvoiceC(res.data.cbInvoiceC);

                    setCopyEmailRegister(res.data.copyEmailRegister);
                    setCopyEmailPassword(res.data.copyEmailPassword);
                    setCopyEmailAccount(res.data.copyEmailAccount);
                    setCopyEmailSaveOffer(res.data.copyEmailSaveOffer);
                    setCopyEmailChangeOffer(res.data.copyEmailChangeOffer);
                    setCopyEmailOrder(res.data.copyEmailOrder);
                    setCopyEmailOrderStatus(res.data.copyEmailOrderStatus);
                    setCopyEmailChatMsgOffer(res.data.copyEmailChatMsgOffer);
                    setCopyEmailChatMsgOrder(res.data.copyEmailChatMsgOrder);
                    setCopyEmailDelivery(res.data.copyEmailDelivery);
                    setCopyEmailFileUpload(res.data.copyEmailFileUpload);
                    setCopyEmailInvoice(res.data.copyEmailInvoice);
                }
            } catch (err) {
                err.response.data.msg && setError(err.response.data.msg);
            }
        };

        initThis();
    }, [setError]);

    const openPreview = (text) => {
        saveChanges();
        setModalText(text);
        setShowModal(true);
    };

    return (
        <>
            <Modal isOpen={showModal} onRequestClose={(e) => modalClose(e)} contentLabel="Preview" style={customStyles}>
                <div dangerouslySetInnerHTML={{ __html: modalText }}></div>
            </Modal>
            <div className="flex">
                <div className="admin-sidebar">
                    <ul>
                        <li>
                            {' '}
                            <a href="">Dashboard </a>{' '}
                        </li>
                        <li>
                            {' '}
                            <a href="">Emails </a>{' '}
                        </li>
                        <li>
                            {' '}
                            <a href="">Logs </a>{' '}
                        </li>
                    </ul>
                </div>

                <div className="wrapper_1500">
                    <h1>Admin - Einstellungen der Emails</h1>

                    <div className="admin-edit-info">
                        <h2>Variablen</h2>
                        <p>
                            {' '}
                            Folgende Variablen können Sie in Ihren Emailtexten einsetzen:
                            <br />
                            <span>$$link$$</span>
                            <span>$$anrede$$</span>
                            <span>$$vorname$$</span>
                            <span>$$nachname$$</span>
                            <span>$$trackingNumber$$</span>
                            <span>$$orderID$$</span>
                        </p>
                    </div>

                    <div className="grid-wrapper">
                        {/* Verify Email */}
                        <div className="grid-element">
                            <h2>Email - Register Account - Verify Email</h2>
                            <label htmlFor="emailCB1C">
                                <input
                                    className="admin-cb-email"
                                    type="checkbox"
                                    id="emailCB1C"
                                    checked={cbRegisterC}
                                    onChange={(e) => {
                                        setCbRegisterC(e.target.checked);
                                    }}
                                />
                                An Kunden
                            </label>
                            <br />
                            <label htmlFor="emailCB1">
                                <input
                                    className="admin-cb-email"
                                    type="checkbox"
                                    id="emailCB1"
                                    checked={cbRegister}
                                    onChange={(e) => {
                                        setCbRegister(e.target.checked);
                                    }}
                                />
                                An Bearbeiter
                            </label>
                            <br />
                            <input
                                className="admin-le-copyemail"
                                type="text"
                                value={copyEmailRegister}
                                onChange={(e) => setCopyEmailRegister(e.target.value)}
                            />
                        </div>
                        <div className="grid-element">
                            <label>Betreff</label>
                            <input
                                className="admin-le-subject"
                                type="text"
                                value={subjectRegister}
                                onChange={(e) => setSubjectRegister(e.target.value)}
                            />
                            <br />
                            <label>Ihre Nachricht</label>
                            <textarea rows="10" cols="100" onChange={(e) => setEmailRegister(e.currentTarget.value)} value={emailRegister}></textarea>
                            <br />
                            <button
                                className="previewButton"
                                onClick={() => {
                                    openPreview(emailRegister);
                                }}
                            >
                                {' '}
                                Preview
                            </button>
                        </div>

                        {/* Passwort vergessen */}
                        <div className="grid-element">
                            <h2>Email - Passwort vergessen</h2>
                            <label htmlFor="emailCB2C">
                                <input
                                    className="admin-cb-email"
                                    type="checkbox"
                                    id="emailCB2C"
                                    checked={cbPasswordC}
                                    onChange={(e) => {
                                        setCbPasswordC(e.target.checked);
                                    }}
                                />
                                An Kunden
                            </label>
                            <br />
                            <label htmlFor="emailCB2">
                                <input
                                    className="admin-cb-email"
                                    type="checkbox"
                                    id="emailCB2"
                                    checked={cbPassword}
                                    onChange={(e) => {
                                        setCbPassword(e.target.checked);
                                    }}
                                />
                                An Bearbeiter
                            </label>
                            <br />
                            <input
                                className="admin-le-copyemail"
                                type="text"
                                value={copyEmailPassword}
                                onChange={(e) => setCopyEmailPassword(e.target.value)}
                            />
                        </div>
                        <div className="grid-element">
                            <label>Betreff</label>
                            <input
                                className="admin-le-subject"
                                type="text"
                                value={subjectPassword}
                                onChange={(e) => setSubjectPassword(e.target.value)}
                            />
                            <br />
                            <label>Ihre Nachricht</label>
                            <textarea rows="10" cols="100" onChange={(e) => setEmailPassword(e.currentTarget.value)} value={emailPassword}></textarea>
                            <button
                                className="previewButton"
                                onClick={() => {
                                    openPreview(emailPassword);
                                }}
                            >
                                Preview
                            </button>
                        </div>

                        {/* Account freischalten*/}
                        <div className="grid-element">
                            <h2>Email - Account freischalten</h2>

                            <label htmlFor="emailCB3C">
                                <input
                                    className="admin-cb-email"
                                    type="checkbox"
                                    id="emailCB3C"
                                    checked={cbAccountC}
                                    onChange={(e) => {
                                        setCbAccountC(e.target.checked);
                                    }}
                                />
                                An Kunden
                            </label>
                            <br />
                            <label htmlFor="emailCB3">
                                <input
                                    className="admin-cb-email"
                                    type="checkbox"
                                    id="emailCB3"
                                    checked={cbAccount}
                                    onChange={(e) => {
                                        setCbAccount(e.target.checked);
                                    }}
                                />
                                An Bearbeiter
                            </label>
                            <br />
                            <input
                                className="admin-le-copyemail"
                                type="text"
                                value={copyEmailAccount}
                                onChange={(e) => setCopyEmailAccount(e.target.value)}
                            />
                        </div>
                        <div className="grid-element">
                            <label>Betreff</label>
                            <input
                                className="admin-le-subject"
                                type="text"
                                value={subjectAccount}
                                onChange={(e) => setSubjectAccount(e.target.value)}
                            />
                            <br />
                            <label>Ihre Nachricht</label>
                            <textarea rows="10" cols="100" onChange={(e) => setEmailAccount(e.currentTarget.value)} value={emailAccount}></textarea>
                            <button
                                className="previewButton"
                                onClick={() => {
                                    openPreview(emailAccount);
                                }}
                            >
                                Preview
                            </button>
                        </div>

                        {/*Angebot speichern*/}
                        <div className="grid-element">
                            <h2>Email - Angebot speichern</h2>

                            <label htmlFor="emailCB4C">
                                <input
                                    className="admin-cb-email"
                                    type="checkbox"
                                    id="emailCB4C"
                                    checked={cbSaveOfferC}
                                    onChange={(e) => {
                                        setCbSaveOfferC(e.target.checked);
                                    }}
                                />
                                An Kunden
                            </label>
                            <br />
                            <label htmlFor="emailCB4">
                                <input
                                    className="admin-cb-email"
                                    type="checkbox"
                                    id="emailCB4"
                                    checked={cbSaveOffer}
                                    onChange={(e) => {
                                        setCbSaveOffer(e.target.checked);
                                    }}
                                />
                                An Bearbeiter
                            </label>
                            <br />
                            <input
                                className="admin-le-copyemail"
                                type="text"
                                value={copyEmailSaveOffer}
                                onChange={(e) => setCopyEmailSaveOffer(e.target.value)}
                            />
                        </div>
                        <div className="grid-element">
                            <label>Betreff</label>
                            <input
                                className="admin-le-subject"
                                type="text"
                                value={subjectSaveOffer}
                                onChange={(e) => setSubjectSaveOffer(e.target.value)}
                            />
                            <br />
                            <label>Ihre Nachricht</label>
                            <textarea
                                rows="10"
                                cols="100"
                                onChange={(e) => setEmailSaveOffer(e.currentTarget.value)}
                                value={emailSaveOffer}
                            ></textarea>
                            <button
                                className="previewButton"
                                onClick={() => {
                                    openPreview(emailSaveOffer);
                                }}
                            >
                                Preview
                            </button>
                        </div>

                        {/*Angebot wurde geändert*/}
                        <div className="grid-element">
                            <h2>Email - Angebot wurde geändert</h2>

                            <label htmlFor="emailCB5C">
                                <input
                                    className="admin-cb-email"
                                    type="checkbox"
                                    id="emailCB5C"
                                    checked={cbChangeOfferC}
                                    onChange={(e) => {
                                        setCbChangeOfferC(e.target.checked);
                                    }}
                                />
                                An Kunden
                            </label>
                            <br />
                            <label htmlFor="emailCB5">
                                <input
                                    className="admin-cb-email"
                                    type="checkbox"
                                    id="emailCB5"
                                    checked={cbChangeOffer}
                                    onChange={(e) => {
                                        setCbChangeOffer(e.target.checked);
                                    }}
                                />
                                An Bearbeiter
                            </label>
                            <br />
                            <input
                                className="admin-le-copyemail"
                                type="text"
                                value={copyEmailChangeOffer}
                                onChange={(e) => setCopyEmailChangeOffer(e.target.value)}
                            />
                        </div>
                        <div className="grid-element">
                            <label>Betreff</label>
                            <input
                                className="admin-le-subject"
                                type="text"
                                value={subjectChangeOffer}
                                onChange={(e) => setSubjectChangeOffer(e.target.value)}
                            />
                            <br />
                            <label>Ihre Nachricht</label>
                            <textarea
                                rows="10"
                                cols="100"
                                onChange={(e) => setEmailChangeOffer(e.currentTarget.value)}
                                value={emailChangeOffer}
                            ></textarea>
                            <button
                                className="previewButton"
                                onClick={() => {
                                    openPreview(emailChangeOffer);
                                }}
                            >
                                Preview
                            </button>
                        </div>

                        {/*Bestellung*/}
                        <div className="grid-element">
                            <h2>Email - Bestellung</h2>

                            <label htmlFor="emailCB6C">
                                <input
                                    className="admin-cb-email"
                                    type="checkbox"
                                    id="emailCB6C"
                                    checked={cbOrderC}
                                    onChange={(e) => {
                                        setCbOrderC(e.target.checked);
                                    }}
                                />
                                An Kunden
                            </label>
                            <br />
                            <label htmlFor="emailCB6">
                                <input
                                    className="admin-cb-email"
                                    type="checkbox"
                                    id="emailCB6"
                                    checked={cbOrder}
                                    onChange={(e) => {
                                        setCbOrder(e.target.checked);
                                    }}
                                />
                                An Bearbeiter
                            </label>
                            <br />
                            <input
                                className="admin-le-copyemail"
                                type="text"
                                value={copyEmailOrder}
                                onChange={(e) => setCopyEmailOrder(e.target.value)}
                            />
                        </div>
                        <div className="grid-element">
                            <label>Betreff</label>
                            <input className="admin-le-subject" type="text" value={subjectOrder} onChange={(e) => setSubjectOrder(e.target.value)} />
                            <br />
                            <label>Ihre Nachricht</label>
                            <textarea rows="10" cols="100" onChange={(e) => setEmailOrder(e.currentTarget.value)} value={emailOrder}></textarea>
                            <button
                                className="previewButton"
                                onClick={() => {
                                    openPreview(emailOrder);
                                }}
                            >
                                Preview
                            </button>
                        </div>

                        {/*Bestellung Statuswechsel*/}
                        <div className="grid-element">
                            <h2>Email - Bestellung Statuswechsel</h2>

                            <label htmlFor="emailCB7C">
                                <input
                                    className="admin-cb-email"
                                    type="checkbox"
                                    id="emailCB7C"
                                    checked={cbOrderStatusC}
                                    onChange={(e) => {
                                        setCbOrderStatusC(e.target.checked);
                                    }}
                                />
                                An Kunden
                            </label>
                            <br />
                            <label htmlFor="emailCB7">
                                <input
                                    className="admin-cb-email"
                                    type="checkbox"
                                    id="emailCB7"
                                    checked={cbOrderStatus}
                                    onChange={(e) => {
                                        setCbOrderStatus(e.target.checked);
                                    }}
                                />
                                An Bearbeiter
                            </label>
                            <br />
                            <input
                                className="admin-le-copyemail"
                                type="text"
                                value={copyEmailOrderStatus}
                                onChange={(e) => setCopyEmailOrderStatus(e.target.value)}
                            />
                        </div>
                        <div className="grid-element">
                            <label>Betreff</label>
                            <input
                                className="admin-le-subject"
                                type="text"
                                value={subjectOrderStatus}
                                onChange={(e) => setSubjectOrderStatus(e.target.value)}
                            />
                            <br />
                            <label>Ihre Nachricht</label>
                            <textarea
                                rows="10"
                                cols="100"
                                onChange={(e) => setEmailOrderStatus(e.currentTarget.value)}
                                value={emailOrderStatus}
                            ></textarea>
                            <button
                                className="previewButton"
                                onClick={() => {
                                    openPreview(emailOrderStatus);
                                }}
                            >
                                Preview
                            </button>
                        </div>

                        {/*Chatnachricht Angebot */}
                        <div className="grid-element">
                            <h2>Email - Chatnachricht Angebot</h2>

                            <label htmlFor="emailCB8C">
                                <input
                                    className="admin-cb-email"
                                    type="checkbox"
                                    id="emailCB8C"
                                    checked={cbChatMsgOfferC}
                                    onChange={(e) => {
                                        setCbChatMsgOfferC(e.target.checked);
                                    }}
                                />
                                An Kunden
                            </label>
                            <br />
                            <label htmlFor="emailCB8">
                                <input
                                    className="admin-cb-email"
                                    type="checkbox"
                                    id="emailCB8"
                                    checked={cbChatMsgOffer}
                                    onChange={(e) => {
                                        setCbChatMsgOffer(e.target.checked);
                                    }}
                                />
                                An Bearbeiter
                            </label>
                            <br />
                            <input
                                className="admin-le-copyemail"
                                type="text"
                                value={copyEmailChatMsgOffer}
                                onChange={(e) => setCopyEmailChatMsgOffer(e.target.value)}
                            />
                        </div>
                        <div className="grid-element">
                            <label>Betreff</label>
                            <input
                                className="admin-le-subject"
                                type="text"
                                value={subjectChatMsgOffer}
                                onChange={(e) => setSubjectChatMsgOffer(e.target.value)}
                            />
                            <br />
                            <label>Ihre Nachricht</label>
                            <textarea
                                rows="10"
                                cols="100"
                                onChange={(e) => setEmailChatMsgOffer(e.currentTarget.value)}
                                value={emailChatMsgOffer}
                            ></textarea>
                            <button
                                className="previewButton"
                                onClick={() => {
                                    openPreview(emailChatMsgOffer);
                                }}
                            >
                                Preview
                            </button>
                        </div>

                        {/*Chatnachricht Bestellung */}
                        <div className="grid-element">
                            <h2>Email - Chatnachricht Bestellung</h2>

                            <label htmlFor="emailCB9C">
                                <input
                                    className="admin-cb-email"
                                    type="checkbox"
                                    id="emailCB9C"
                                    checked={cbChatMsgOrderC}
                                    onChange={(e) => {
                                        setCbChatMsgOrderC(e.target.checked);
                                    }}
                                />
                                An Kunden
                            </label>
                            <br />
                            <label htmlFor="emailCB9">
                                <input
                                    className="admin-cb-email"
                                    type="checkbox"
                                    id="emailCB9"
                                    checked={cbChatMsgOrder}
                                    onChange={(e) => {
                                        setCbChatMsgOrder(e.target.checked);
                                    }}
                                />
                                An Bearbeiter
                            </label>
                            <br />
                            <input
                                className="admin-le-copyemail"
                                type="text"
                                value={copyEmailChatMsgOrder}
                                onChange={(e) => setCopyEmailChatMsgOrder(e.target.value)}
                            />
                        </div>
                        <div className="grid-element">
                            <label>Betreff</label>
                            <input
                                className="admin-le-subject"
                                type="text"
                                value={subjectChatMsgOrder}
                                onChange={(e) => setSubjectChatMsgOrder(e.target.value)}
                            />
                            <br />
                            <label>Ihre Nachricht</label>
                            <textarea
                                rows="10"
                                cols="100"
                                onChange={(e) => setEmailChatMsgOrder(e.currentTarget.value)}
                                value={emailChatMsgOrder}
                            ></textarea>
                            <button
                                className="previewButton"
                                onClick={() => {
                                    openPreview(emailChatMsgOrder);
                                }}
                            >
                                Preview
                            </button>
                        </div>

                        {/*Versand */}
                        <div className="grid-element">
                            <h2>Email - Versand</h2>

                            <label htmlFor="emailCB10C">
                                <input
                                    className="admin-cb-email"
                                    type="checkbox"
                                    id="emailCB10C"
                                    checked={cbDeliveryC}
                                    onChange={(e) => {
                                        setCbDeliveryC(e.target.checked);
                                    }}
                                />
                                An Kunden
                            </label>
                            <br />
                            <label htmlFor="emailCB10">
                                <input
                                    className="admin-cb-email"
                                    type="checkbox"
                                    id="emailCB10"
                                    checked={cbDelivery}
                                    onChange={(e) => {
                                        setCbDelivery(e.target.checked);
                                    }}
                                />
                                An Bearbeiter
                            </label>
                            <br />
                            <input
                                className="admin-le-copyemail"
                                type="text"
                                value={copyEmailDelivery}
                                onChange={(e) => setCopyEmailDelivery(e.target.value)}
                            />
                        </div>
                        <div className="grid-element">
                            <label>Betreff</label>
                            <input
                                className="admin-le-subject"
                                type="text"
                                value={subjectDelivery}
                                onChange={(e) => setSubjectDelivery(e.target.value)}
                            />
                            <br />
                            <label>Ihre Nachricht</label>
                            <textarea rows="10" cols="100" onChange={(e) => setEmailDelivery(e.currentTarget.value)} value={emailDelivery}></textarea>
                            <button
                                className="previewButton"
                                onClick={() => {
                                    openPreview(emailDelivery);
                                }}
                            >
                                Preview
                            </button>
                        </div>

                        {/*File Upload */}
                        <div className="grid-element">
                            <h2>Email - Daten wurden hochgeladen</h2>

                            <label htmlFor="emailCB11C">
                                <input
                                    className="admin-cb-email"
                                    type="checkbox"
                                    id="emailCB11C"
                                    checked={cbFileUploadC}
                                    onChange={(e) => {
                                        setCbFileUploadC(e.target.checked);
                                    }}
                                />
                                An Kunden
                            </label>
                            <br />
                            <label htmlFor="emailCB11">
                                <input
                                    className="admin-cb-email"
                                    type="checkbox"
                                    id="emailCB11"
                                    checked={cbFileUpload}
                                    onChange={(e) => {
                                        setCbFileUpload(e.target.checked);
                                    }}
                                />
                                An Bearbeiter
                            </label>
                            <br />
                            <input
                                className="admin-le-copyemail"
                                type="text"
                                value={copyEmailDelivery}
                                onChange={(e) => setCopyEmailDelivery(e.target.value)}
                            />
                        </div>
                        <div className="grid-element">
                            <label>Betreff</label>
                            <input
                                className="admin-le-subject"
                                type="text"
                                value={subjectFileUpload}
                                onChange={(e) => setSubjectFileUpload(e.target.value)}
                            />
                            <br />
                            <label>Ihre Nachricht</label>
                            <textarea
                                rows="10"
                                cols="100"
                                onChange={(e) => setEmailFileUpload(e.currentTarget.value)}
                                value={emailFileUpload}
                            ></textarea>
                            <button
                                className="previewButton"
                                onClick={() => {
                                    openPreview(emailFileUpload);
                                }}
                            >
                                Preview
                            </button>
                        </div>

                        {/*Invoice Upload */}
                        <div className="grid-element">
                            <h2>Email - Rechnung wurde hochgeladen</h2>

                            <label htmlFor="emailCB12C">
                                <input
                                    className="admin-cb-email"
                                    type="checkbox"
                                    id="emailCB12C"
                                    checked={cbInvoiceC}
                                    onChange={(e) => {
                                        setCbInvoiceC(e.target.checked);
                                    }}
                                />
                                An Kunden
                            </label>
                            <br />
                            <label htmlFor="emailCB12">
                                <input
                                    className="admin-cb-email"
                                    type="checkbox"
                                    id="emailCB12"
                                    checked={cbInvoice}
                                    onChange={(e) => {
                                        setCbInvoice(e.target.checked);
                                    }}
                                />
                                An Bearbeiter
                            </label>
                            <br />
                            <input
                                className="admin-le-copyemail"
                                type="text"
                                value={copyEmailInvoice}
                                onChange={(e) => setCopyEmailInvoice(e.target.value)}
                            />
                        </div>
                        <div className="grid-element">
                            <label>Betreff</label>
                            <input
                                className="admin-le-subject"
                                type="text"
                                value={subjectInvoice}
                                onChange={(e) => setSubjectInvoice(e.target.value)}
                            />
                            <br />
                            <label>Ihre Nachricht</label>
                            <textarea rows="10" cols="100" onChange={(e) => setEmailInvoice(e.currentTarget.value)} value={emailInvoice}></textarea>
                            <button
                                className="previewButton"
                                onClick={() => {
                                    openPreview(emailInvoice);
                                }}
                            >
                                Preview
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="admin-edit-control">
                <button className="page-button-action" onClick={() => saveChanges()}>
                    Änderungen speichern
                </button>
            </div>
        </>
    );
};

export default AdminEmails;
