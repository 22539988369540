import React, { useState, useEffect } from 'react';
import Axios from 'axios';

const AdminEditMethod = ({ setError }) => {
    const [select, setSelect] = useState();
    const [name, setName] = useState();
    const [displayName, setDisplayName] = useState();
    const [newName, setNewName] = useState();
    const [methods, setMethods] = useState([]);
    const [materials, setMaterials] = useState([]);
    const [editObject, setEditObject] = useState();
    const [allowedMats, setAllowedMats] = useState([]);
    const [formula, setFormula] = useState();
    const [tooltip, setTooltip] = useState();
    const [isDefault, setIsDefault] = useState();

    const AddMethod = async () => {
        if (newName) {
            let token = localStorage.getItem('auth-token');
            const payload = {
                name: newName,
            };
            try {
                const res = await Axios.post(`${process.env.REACT_APP_SERVER}/admin/addmethod`, payload, { headers: { 'x-auth-token': token } });
                if (res.data && res.data.name) {
                    setMethods([...methods, res.data]);
                    setEditObject(res.data);
                    setAllowedMats(res.data.materials);
                    setName(res.data.name);
                }
            } catch (err) {
                err.response.data.msg && setError(err.response.data.msg);
            }
        }
    };

    const changeMethodMaterial = (checked, materialID) => {
        if (checked) {
            if (!allowedMats.some((e) => e._id === materialID)) {
                setAllowedMats([...allowedMats, { _id: materialID }]);
            }
        } else {
            setAllowedMats(
                allowedMats.filter(function (obj) {
                    return obj._id !== materialID;
                })
            );
        }
    };

    const saveChanges = async () => {
        editObject.materials = allowedMats;
        editObject.formula = formula;
        editObject.name = name;
        editObject.displayName = displayName;
        editObject.tooltip = tooltip;
        editObject.isDefault = isDefault;
        let token = localStorage.getItem('auth-token');
        try {
            await Axios.post(`${process.env.REACT_APP_SERVER}/admin/savemethod`, editObject, { headers: { 'x-auth-token': token } });
        } catch (err) {
            err.response.data && err.response.data.msg && setError(err.response.data.msg);
        }
    };

    const deleteEntry = async () => {
        let token = localStorage.getItem('auth-token');
        try {
            await Axios.post(`${process.env.REACT_APP_SERVER}/admin/deletemethod`, editObject, { headers: { 'x-auth-token': token } });
            const res2 = await Axios.get(`${process.env.REACT_APP_SERVER}/admin/methods/`, { headers: { 'x-auth-token': token } });
            if (res2) {
                setMethods(res2.data.methods);
                setEditObject(undefined);
            }
        } catch (err) {
            err.response.data && err.response.data.msg && setError(err.response.data.msg);
        }
    };

    useEffect(() => {
        const getMethods = async () => {
            try {
                let token = localStorage.getItem('auth-token');
                const res = await Axios.get(`${process.env.REACT_APP_SERVER}/admin/methods/`, { headers: { 'x-auth-token': token } });
                if (res) {
                    setMethods(res.data.methods);
                    setEditObject(undefined);
                }
            } catch (err) {
                err.response.data.msg && setError(err.response.data.msg);
            }
        };

        const getMaterials = async () => {
            try {
                let token = localStorage.getItem('auth-token');
                const res = await Axios.get(`${process.env.REACT_APP_SERVER}/admin/materials/`, { headers: { 'x-auth-token': token } });
                if (res) {
                    setMaterials(res.data.materials);
                }
            } catch (err) {
                err.response.data.msg && setError(err.response.data.msg);
            }
        };
        getMethods();
        getMaterials();
    }, [setError]);

    return (
        <>
            <h1>Verfahren anlegen</h1>
            <div className="admin-editor-page">
                <div className="admin-edit-column">
                    <ul>
                        {methods &&
                            methods.length > 0 &&
                            methods.map((method) => (
                                <li key={method._id}>
                                    <button
                                        className={select === method.name ? 'admin-nav-button-sel' : 'admin-nav-button'}
                                        onClick={() => {
                                            setEditObject(method);
                                            setName(method.name);
                                            setDisplayName(method.displayName || '');
                                            setFormula(method.formula || '');
                                            setAllowedMats(method.materials);
                                            setSelect(method.name);
                                            setTooltip(method.tooltip);
                                            setIsDefault(method.isDefault);
                                        }}
                                    >
                                        {method.name}
                                    </button>
                                </li>
                            ))}
                    </ul>
                    <input type="text" value={newName} onChange={(e) => setNewName(e.target.value)} />
                    <br />
                    <button className="page-button-action" onClick={AddMethod}>
                        Verfahren hinzufügen
                    </button>
                </div>
                {editObject ? (
                    <>
                        <div className="admin-edit-column">
                            <input type="text" value={name} onChange={(e) => setName(e.target.value)} />
                            <h4>Anzeigename</h4>
                            <input type="text" value={displayName} onChange={(e) => setDisplayName(e.target.value)} />
                            <br />
                            <label htmlFor="isDefaultVisible">
                                Default
                                <input
                                    type="checkbox"
                                    id="isDefaultVisible"
                                    checked={isDefault}
                                    onChange={(e) => {
                                        setIsDefault(e.target.checked);
                                    }}
                                />
                            </label>
                            <h4>Mögliche Materialien</h4>
                            {materials &&
                                materials.length > 0 &&
                                materials.map((material) => (
                                    <div key={material._id}>
                                        <label htmlFor={material.ID}>
                                            {material.name}
                                            <input
                                                type="checkbox"
                                                id={material.ID}
                                                checked={allowedMats.some((e) => e._id === material._id)}
                                                onChange={(e) => {
                                                    changeMethodMaterial(e.target.checked, material._id);
                                                }}
                                            />
                                        </label>
                                        <br />
                                    </div>
                                ))}
                            <br />
                        </div>
                        <div className="admin-edit-column">
                            {/*
                            <h4>Formeln</h4>
                            <textarea
                                id="formula-input"
                                rows="8"
                                cols="50"
                                onChange={(e) => onFormulaChange(e.currentTarget.value)}
                                value={formula}
                            ></textarea>*/}
                            <h4>Tooltip Text</h4>
                            <textarea
                                id="ttt-input"
                                rows="3"
                                cols="50"
                                onChange={(e) => setTooltip(e.currentTarget.value)}
                                value={tooltip}
                            ></textarea>
                            <br />
                            <button className="page-button-action" onClick={() => saveChanges()}>
                                Änderungen speichern
                            </button>
                            <button className="admin-delete-button" onClick={() => deleteEntry()}>
                                Löschen
                            </button>
                        </div>
                    </>
                ) : null}
            </div>
        </>
    );
};

export default AdminEditMethod;
