import React, { useState, useContext, useEffect } from 'react';
import Axios from 'axios';
import download from 'downloadjs';
import ControlContext from '../../context/ControlContext';
import InfoOrders from '../misc/InfoOrders';
import { useHistory } from 'react-router-dom';
import ErrorNotice from '../misc/ErrorNotice';
import { AgGridReact } from 'ag-grid-react';
import { BtnCellRenderer } from '../admin/BtnCellRenderer';
import { columnTypes, defaultColDef, defaultColGroupDef, dateTimeFormatter, dateFormatter } from '../misc/ColumnTypes';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

const Orders = () => {
    const { control, setControl } = useContext(ControlContext);
    const history = useHistory();
    const [error, setError] = useState();
    const [orders, setOrders] = useState();

    const frameworkComponents = {
        btnCellRenderer: BtnCellRenderer,
    };

    const columnDefs = [
        {
            field: 'IDS',
            headerName: 'Bestellnummer',
            sortable: true,
            filter: true,
            type: 'numberColumn',
            editable: false,
        },

        {
            field: 'created',
            headerName: 'Erstellt am',
            sortable: true,
            filter: false,
            type: 'dateColumn',
            valueFormatter: dateTimeFormatter,
            sort: 'desc',
            editable: false,
        },
        {
            field: 'estDelivery',
            headerName: 'Lieferdatum',
            sortable: true,
            filter: false,
            type: 'dateColumn',
            valueFormatter: dateFormatter,
            editable: false,
        },
        {
            field: 'projectID',
            headerName: 'Projektbezeichnung',
            sortable: true,
            filter: true,
            editable: false,
        },
        {
            field: 'inquiryID',
            headerName: 'Bestellnummer',
            sortable: true,
            filter: true,
            editable: false,
        },
        {
            field: 'statusDisplayName',
            headerName: 'Status',
            sortable: true,
            filter: true,
            editable: false,
        },
        {
            field: 'fromOffer.IDS',
            headerName: 'aus Angebot',
            sortable: true,
            filter: true,
            onCellClicked: (params) => {
                viewOffer(params.data.fromOffer?._id);
            },
            editable: false,
        },

        {
            field: '_id',
            headerName: 'View',
            sortable: true,
            filter: false,
            valueGetter: () => {
                return 'View';
            },
            onCellClicked: (params) => viewOrder(params.data._id),
            editable: false,
        },
        {
            field: '_id',
            headerName: 'PDF',
            sortable: true,
            filter: false,
            valueGetter: () => {
                return 'Download';
            },
            onCellClicked: (params) => createPDF(params.data),
            editable: false,
        },
        {
            field: '_id',
            headerName: 'Rechnungen',
            sortable: true,
            filter: false,
            valueGetter: () => {
                return 'Download';
            },
            onCellClicked: (params) => invoices(params.data),
            editable: false,
        },
    ];

    useEffect(() => {
        if (control.maintenance) {
            history.push('/maintenance');
        }
        const checkLoggedIn = async () => {
            let token = localStorage.getItem('auth-token');
            if (token === null) {
                localStorage.setItem('auth-token', '');
                token = '';
            }

            const tokenRes = await Axios.post(`${process.env.REACT_APP_SERVER}/users/tokenIsValid`, null, { headers: { 'x-auth-token': token } });

            if (tokenRes.data) {
                const res = await Axios.get(`${process.env.REACT_APP_SERVER}/files/orders/`, { headers: { 'x-auth-token': token } });
                setOrders(res.data.orders);
            } else {
                history.push('/login');
            }
        };
        checkLoggedIn();
    }, [history, setOrders]);

    const viewOffer = async (_id) => {
        setControl({
            ...control,
            viewOffer: _id,
        });

        history.push('/viewOffer');
    };

    const viewOrder = async (_id) => {
        setControl({
            ...control,
            viewOrder: _id,
        });

        history.push('/viewOrder');
    };

    const createPDF = async (order) => {
        try {
            let token = localStorage.getItem('auth-token');
            const config = {
                responseType: 'blob',
                headers: { 'x-auth-token': token },
                params: { orderID: order._id },
            };

            Axios.get(`${process.env.REACT_APP_SERVER}/files/downloadorderpdf`, config).then((res) => {
                download(res.data, `Auftrag_${order.IDS}.pdf`);
            });
        } catch (err) {
            console.log(err);
            err.response && err.response.data && err.response.data.msg && setError(err.response.data.msg);
        }
    };

    const invoices = async (order) => {
        try {
            let token = localStorage.getItem('auth-token');

            order.documents.forEach((doc) => {
                if (doc.type === 'invoice') {
                    const config = {
                        responseType: 'blob',
                        headers: { 'x-auth-token': token },
                        params: { orderID: order._id, IDS: order.IDS, fileName: doc.fileName },
                    };

                    Axios.get(`${process.env.REACT_APP_SERVER}/files/downloadorderdocument`, config).then((res) => {
                        download(res.data, doc.fileName);
                    });
                }
            });
        } catch (err) {
            console.log(err);
            err.response && err.response.data && err.response.data.msg && setError(err.response.data.msg);
        }
    };

    return (
        <div>
            <div id="info-box">
                <InfoOrders />
                {error && <ErrorNotice message={error} clearError={() => setError(undefined)} />}
            </div>
            <div className="overview">
                <div className="ag-theme-alpine">
                    <AgGridReact
                        rowData={orders}
                        columnDefs={columnDefs}
                        frameworkComponents={frameworkComponents}
                        columnTypes={columnTypes}
                        defaultColDef={defaultColDef}
                        defaultColGroupDef={defaultColGroupDef}
                        pagination={true}
                        paginationAutoPageSize={true}
                    ></AgGridReact>
                </div>
            </div>
        </div>
    );
};

export default Orders;
