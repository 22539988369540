import React, { Component } from 'react';

class CheckboxCellRenderer extends Component {
    constructor(props) {
        super(props);
        this.checkedHandler = this.checkedHandler.bind(this);
    }
    checkedHandler(event) {
        let checked = event.target.checked;
        let colId = this.props.column.colId;
        this.props.node.setDataValue(colId, checked);
    }
    render() {
        return <input className="gridCheckbox" type="checkbox" readOnly onClick={this.checkedHandler} checked={this.props.value} />;
    }
}

class CheckboxCellRendererRO extends Component {
    constructor(props) {
        super(props);
        this.checkedHandler = this.checkedHandler.bind(this);
    }
    checkedHandler(event) {
        let checked = event.target.checked;
        let colId = this.props.column.colId;
        this.props.node.setDataValue(colId, checked);
    }
    render() {
        return <input className="gridCheckbox" type="checkbox" readOnly onClick={this.checkedHandler} checked={this.props.value} disabled={true} />;
    }
}

export { CheckboxCellRenderer, CheckboxCellRendererRO };
