import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import FileTaskViewOrder from '../file/FileTaskViewOrder';
import { NumericFormat } from 'react-number-format';;

const FileDetailsViewOrderAdmin = ({ item, position, tasks }) => {
    const [checkStatus, setCheckStatus] = useState(parseInt(item.checkStatus));

    function getSafe(fn, defaultVal) {
        try {
            return fn();
        } catch (e) {
            return defaultVal;
        }
    }

    const onSetCheckStatus = async (e) => {
        setCheckStatus(parseInt(e.target.value));
        try {
            let token = localStorage.getItem('auth-token');
            Axios.post(
                `${process.env.REACT_APP_SERVER}/admin/updateCheckStatus`,
                { _id: item._id, checkStatus: parseInt(e.target.value) },
                { headers: { 'x-auth-token': token } }
            );
            item.checkStatus = parseInt(e.target.value);
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        const getCS = async () => {
            try {
                let token = localStorage.getItem('auth-token');

                const config = {
                    headers: { 'x-auth-token': token },
                    params: { itemID: item._id },
                };

                const res = await Axios.get(`${process.env.REACT_APP_SERVER}/admin/checkStatus`, config);

                if (res) {
                    setCheckStatus(parseInt(res.data.checkStatus || 0));
                    item.checkStatus = res.data.checkStatus || 0;
                }
            } catch (err) {
                console.log(err);
            }
        };
        getCS();
    }, [item]);

    return (
        <>
            <div id="item-element-wrapper">
                <div className="item-container">
                    {item ? (
                        <>
                            <div className="item-preview-details-wrapper">
                                <div className="item-position-number">{position + 1}</div>
                                <div className="item-details-row">
                                    <div className="item-preview">
                                        <img src={item.preview} width="200" height="200" alt="" />
                                    </div>
                                    <div className="item-params">
                                        <div className="item-name">{item.fileName}</div>
                                        <div className="item-params-row">
                                            <div className="item-params-value">
                                                <NumericFormat
                                                    value={item.boundingBox[0]}
                                                    displayType={'text'}
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    thousandSeparator={'.'}
                                                    decimalSeparator={','}
                                                />
                                                <span> x </span>
                                                <NumericFormat
                                                    value={item.boundingBox[1]}
                                                    displayType={'text'}
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    thousandSeparator={'.'}
                                                    decimalSeparator={','}
                                                />
                                                <span> x </span>
                                                <NumericFormat
                                                    value={item.boundingBox[2]}
                                                    displayType={'text'}
                                                    suffix={' mm'}
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    thousandSeparator={'.'}
                                                    decimalSeparator={','}
                                                />
                                            </div>
                                        </div>
                                        <br />
                                        <div className="item-params-row">
                                            <div className="item-params-unit">Volumen:</div>
                                            <div className="item-params-value">
                                                <NumericFormat
                                                    value={item.volume}
                                                    displayType={'text'}
                                                    suffix={' cm³'}
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    thousandSeparator={'.'}
                                                    decimalSeparator={','}
                                                />
                                            </div>
                                        </div>
                                        <div className="item-params-row">
                                            <div className="item-params-unit">Oberfläche:</div>
                                            <div className="item-params-value">
                                                <NumericFormat
                                                    value={item.area}
                                                    displayType={'text'}
                                                    suffix={' cm²'}
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    thousandSeparator={'.'}
                                                    decimalSeparator={','}
                                                />
                                            </div>
                                        </div>
                                        <br />
                                        <div className="item-comment-textbox">
                                            Bemerkungen
                                            <textarea
                                                id="file-comment"
                                                name="filecomment"
                                                rows="5"
                                                cols="30"
                                                disabled
                                                value={item.comment || ''}
                                            ></textarea>
                                        </div>
                                    </div>

                                    <div className="item-selections">
                                        <div className="item-selections-row">
                                            <div className="item-selections-name">
                                                <label className="fileDetailLabel">Verfahren:</label>
                                            </div>
                                            <div>
                                                <label>{item.method.displayName}</label>
                                            </div>
                                        </div>
                                        <div className="item-selections-row">
                                            <div className="item-selections-name">
                                                <label className="fileDetailLabel">Material:</label>
                                            </div>
                                            <div>
                                                <label>{item.material.displayName}</label>
                                            </div>
                                        </div>
                                        {item.infill ? (
                                            <div className="item-selections-row">
                                                <div className="item-selections-name">
                                                    <label className="fileDetailLabel">Infill:</label>
                                                </div>
                                                <div>
                                                    <label>{getSafe(() => item.infill.displayName, '100% Infill')}</label>
                                                </div>
                                            </div>
                                        ) : null}
                                        <div className="item-selections-row">
                                            <div className="item-selections-name">
                                                <label className="fileDetailLabel">Nachbearbeitung:</label>
                                            </div>
                                            <div>
                                                <label>{getSafe(() => item.processing.displayName, 'keine Glättung')}</label>
                                            </div>
                                        </div>
                                        <div className="item-selections-row">
                                            <div className="item-selections-name">
                                                <label className="fileDetailLabel">Farbe:</label>
                                            </div>
                                            <div>
                                                <label>{getSafe(() => item.color.displayName, 'keine Färbung')}</label>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="item-prices ">
                                        <p>
                                            Stückpreise
                                            <br />
                                        </p>
                                        <table id="item-prices-table">
                                            <tbody>
                                                <tr key={873487}>
                                                    <td>
                                                        {item.selectedPrice?.amount}
                                                        &nbsp;Stück:
                                                    </td>
                                                    <td className="amount-label">{parseFloat(item.selectedPrice?.price).toFixed(2)}€</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div className="item-actions">
                                <div className="item-actions-wrapper">
                                    <div className="item-actions-options">
                                        {item.keepOrient ? (
                                            <>
                                                <label className="labelGreen">Ausrichtung beibehalten</label>
                                            </>
                                        ) : (
                                            <>
                                                <label className="labelRed">Ausrichtung nicht beibehalten</label>
                                            </>
                                        )}
                                        {item.allowHollow ? (
                                            <>
                                                <label className="labelGreen">Aushöhlen erlaubt</label>
                                            </>
                                        ) : (
                                            <>
                                                <label className="labelRed">Aushöhlen nicht erlaubt</label>
                                            </>
                                        )}
                                        {item.planSeries ? (
                                            <>
                                                <label className="labelGreen">Serienfertigung geplant</label>
                                            </>
                                        ) : (
                                            <>
                                                <label className="labelRed">Serienfertigung nicht geplant</label>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>

                            <div className="item-checkStatus">
                                <input type="radio" id="checkStatus0" value={0} checked={checkStatus === 0} onChange={onSetCheckStatus} />
                                <label htmlFor="checkStatus0">nicht gepr&uuml;ft</label>
                                <br />
                                <input type="radio" id="checkStatus1" value={1} checked={checkStatus === 1} onChange={onSetCheckStatus} />
                                <label htmlFor="checkStatus1">gepr&uuml;ft, druckbar</label>
                                <br />
                                <input type="radio" id="checkStatus2" value={2} checked={checkStatus === 2} onChange={onSetCheckStatus} />
                                <label htmlFor="checkStatus2">gepr&uuml;ft, nicht druckbar</label>
                            </div>
                        </>
                    ) : null}
                </div>
            </div>
            {tasks &&
                tasks.length > 0 &&
                tasks.map((task, index) => (
                    <>
                        <FileTaskViewOrder key={task.uuid} position={`${position + 1}.${index + 1}`} task={task} />
                    </>
                ))}
        </>
    );
};

export default FileDetailsViewOrderAdmin;
