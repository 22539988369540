import React, { Component } from 'react';

class ParamsCellRenderer extends Component {
    constructor(props) {
        //console.log(props);
        super(props);
        this.paramsClickedHandler = this.paramsClickedHandler.bind(this);
    }
    paramsClickedHandler() {
        this.props.clicked(this.props.data);
    }
    render() {
        return (
            <button onClick={this.paramsClickedHandler}>
                {this.props.value?.offerIDS} {this.props.value?.orderIDS} {this.props.value?.msg?.msg}
            </button>
        );
    }
}

export default ParamsCellRenderer;
