import React, { useState, useContext, useEffect } from 'react';
import Axios from 'axios';
import ControlContext from '../../context/ControlContext';
import { useHistory } from 'react-router-dom';
import ErrorNotice from '../misc/ErrorNotice';

const EditActionCode = ({ onSubmit }) => {
    const [name, setName] = useState();
    const [type, setType] = useState();
    const [points, setPoints] = useState();
    const [expires, setExpires] = useState();
    
    const { control } = useContext(ControlContext);
    const history = useHistory();
    const [error, setError] = useState();

    const _id = control.editActionCode;

    useEffect(() => {
        const checkLoggedIn = async () => {
            try {
                let token = localStorage.getItem('auth-token');
                if (token === null) {
                    localStorage.setItem('auth-token', '');
                    token = '';
                }

                const tokenRes = await Axios.post(`${process.env.REACT_APP_SERVER}/users/tokenIsValid`, null, { headers: { 'x-auth-token': token } });

                if (tokenRes.data) {
                    const res = await Axios.post(
                        `${process.env.REACT_APP_SERVER}/admin/actionCode/`,
                        { _id: _id },
                        { headers: { 'x-auth-token': token } }
                    );
                    setName(res.data.name);
                    setType(res.data.type);
                    setPoints(res.data.points);
                    setExpires(res.data.expires);
                    
                } else {
                    history.push('/login');
                }
            } catch (err) {
                console.log(err);
            }
        };
        checkLoggedIn();
    }, [_id]);

    const submit = async (e) => {
        e.preventDefault();
        try {
            let token = localStorage.getItem('auth-token');
            const payload = {
                _id: control.editActionCode,
                name: name,
                type: type,
                points: points,
                expires: expires,
            };
            const updateRes = await Axios.post(`${process.env.REACT_APP_SERVER}/admin/updateActionCode`, payload, { headers: { 'x-auth-token': token } });
            if (updateRes) {
                onSubmit();
            }
        } catch (err) {
            console.log(err);
            err.response?.data?.msg && setError(err.response.data.msg);
        }
    };

    return (
        <div className="page-form">
            {error && <ErrorNotice message={error} clearError={() => setError(undefined)} />}
            <form className="form" onSubmit={submit}>
                
            <div className="form-div100">
                    <label className="adressLabel">Name</label>
                    <br />
                    <input id="adr1" type="text" value={name} onChange={(e) => setName(e.target.value)} placeholder="Name" />
                </div>
                
                <div className="form-div100">
                    <label className="adressLabel">Typ (register, checkout)</label>
                    <br />
                    <input id="adr2" type="text" value={type} onChange={(e) => setType(e.target.value)} placeholder="Typ" />
                </div>
            
                <div className="form-div100">
                    <label className="adressLabel">Punkte (10.0)</label>
                    <br />
                    <input id="adr2a" type="text" value={points} onChange={(e) => setPoints(e.target.value)} placeholder="10.0" required />
                </div>
                <div className="form-div50">
                    <label className="adressLabel">Ablaufdatum (1.10.2034)</label>
                    <br />
                    <input id="adr4" type="text" value={expires} onChange={(e) => setExpires(e.target.value)} placeholder="31.12.2023" required />
                    <button className="page-button-green" type="submit">
                        Speichern
                    </button>
                </div>
            </form>
        </div>
    );
};

export default EditActionCode;
