import React, { useState, useContext } from 'react';
import { AdminMenuItems, SuperAdminMenuItems } from './AdminMenuItems';
import { Link } from 'react-router-dom';
import ControlContext from '../../context/ControlContext';

function Dropdown({ setShowSettings, isSuper }) {
    const { control, setControl } = useContext(ControlContext);
    const [click, setClick] = useState(false);

    const handleClick = () => setClick(!click);

    const onClickLink = (adminPage) => {
        if (adminPage === 'settings') {
            setShowSettings(true);
        } else {
            setClick(false);
            setControl({
                ...control,
                adminPage: adminPage,
            });
        }
    };

    return (
        <>
            <ul onClick={handleClick} className={click ? 'dropdown-menu clicked' : 'dropdown-menu'}>
                {isSuper ? (
                    <>
                        {SuperAdminMenuItems.map((item, index) => {
                            return (
                                <li key={index}>
                                    <Link className={item.cName} to={item.path} onClick={() => onClickLink(item.page)}>
                                        {item.title}
                                    </Link>
                                </li>
                            );
                        })}
                    </>
                ) : (
                    <>
                        {AdminMenuItems.map((item, index) => {
                            return (
                                <li key={index}>
                                    <Link className={item.cName} to={item.path} onClick={() => onClickLink(item.page)}>
                                        {item.title}
                                    </Link>
                                </li>
                            );
                        })}
                    </>
                )}
            </ul>
        </>
    );
}

export default Dropdown;
