import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import UserContext from '../../context/UserContext';
import ControlContext from '../../context/ControlContext';
import CompanyLogo from '../../img/dick-dick_logo_grau.svg';

import Dropdown from '../admin/Dropdown';

import '../../style/header.css';

export default function Header({ setShowAccount, setShowLogin, setShowRegister, setShowSettings }) {
    const { userData, setUserData } = useContext(UserContext);
    const { control, setControl } = useContext(ControlContext);

    const history = useHistory();

    const [click] = useState(false);
    const [dropdown, setDropdown] = useState(false);

    const onMouseEnter = () => {
        if (window.innerWidth < 960) {
            setDropdown(false);
        } else {
            setDropdown(true);
        }
    };

    const onMouseLeave = () => {
        if (window.innerWidth < 960) {
            setDropdown(false);
        } else {
            setDropdown(false);
        }
    };

    const register = () => {
        setShowRegister(true);
        setShowLogin(false);
    };
    const login = () => {
        setShowRegister(false);
        setShowLogin(true);
    };
    const logout = () => {
        setShowRegister(false);
        setShowLogin(false);
        setShowAccount(false);
        localStorage.setItem('auth-token', '');
        setUserData({
            token: undefined,
            user: undefined,
        });
        history.push('/');
    };

    const editAccount = () => setShowAccount(true);

    return (
        <>
            <header id="header">
                <nav className="navbar">
                    <Link to="/">
                        <img className="header-logo" src={CompanyLogo} alt="Dick-Dick Logo" width={200} />
                    </Link>

                    <div id="menuToggle">
                        <input type="checkbox" />

                        <span></span>
                        <span></span>
                        <span></span>

                        {userData.user ? (
                            <ul id="menu" className={click ? 'nav-menu active' : 'nav-menu'}>
                                <>
                                    <li className="nav-item">
                                        <Link to="/">
                                            <label className="nav-links">Upload</label>
                                        </Link>
                                    </li>
                                    {!control.maintenance ? (
                                        <>
                                            {!userData.rights.includes('offers') ? (
                                                <li className="nav-item">
                                                    <Link to="/offers">
                                                        <label className="nav-links">Angebote</label>
                                                    </Link>
                                                </li>
                                            ) : null}
                                            {!userData.rights.includes('orders') ? (
                                                <li className="nav-item">
                                                    <Link to="/orders">
                                                        <label className="nav-links">Bestellungen</label>
                                                    </Link>
                                                </li>
                                            ) : null}
                                        </>
                                    ) : null}
                                </>
                                {!control.maintenance ? (
                                    <>
                                        {userData.rights.includes('offers') ? (
                                            <li className="nav-item">
                                                <Link to="/adminoffers">
                                                    <label className="nav-links">Alle Angebote</label>
                                                </Link>
                                            </li>
                                        ) : null}
                                        {userData.rights.includes('orders') ? (
                                            <li className="nav-item">
                                                <Link to="/adminorders">
                                                    <label className="nav-links">Alle Bestellungen</label>
                                                </Link>
                                            </li>
                                        ) : null}
                                        {userData.rights.includes('accounts') ? (
                                            <li className="nav-item">
                                                <Link to="/adminaccounts">
                                                    <label className="nav-links">Accounts</label>
                                                </Link>
                                            </li>
                                        ) : null}
                                        {userData.rights.includes('accounting') ? (
                                            <li className="nav-item">
                                                <Link to="/adminaccounting">
                                                    <label className="nav-links">Buchhaltung</label>
                                                </Link>
                                            </li>
                                        ) : null}
                                        {userData.rights.includes('tickets') ? (
                                            <li className="nav-item">
                                                <Link to="/admintickets">
                                                    <label className="nav-links">Tickets</label>
                                                </Link>
                                            </li>
                                        ) : null}
                                    </>
                                ) : null}
                                {userData.rights.includes('admin') ? (
                                    <li className="nav-item" onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
                                        <Link to="/admin">
                                            <label className="nav-links">Admin</label>
                                        </Link>
                                        {dropdown && <Dropdown isSuper={userData.rights.includes('superadmin')} setShowSettings={setShowSettings} />}
                                    </li>
                                ) : null}
                            </ul>
                        ) : null}
                    </div>
                </nav>

                <nav className="navbar_account">
                    {userData.user ? (
                        <>
                            <button className="nav-button" onClick={() => editAccount()}>
                                Account
                            </button>
                            <button className="nav-button" onClick={() => logout()}>
                                Log out
                            </button>
                        </>
                    ) : (
                        <>
                            <button className="nav-button" onClick={() => register()}>
                                Register
                            </button>
                            <button className="nav-button" onClick={() => login()}>
                                Log in
                            </button>
                        </>
                    )}
                </nav>
            </header>
        </>
    );
}
