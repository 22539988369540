import React from 'react';
import ReactTooltip from 'react-tooltip';
import UserEdit from '../../img/user-edit.svg';
import UserDelete from '../../img/user-delete.svg';

const ActionCodeItem = ({ code, deleteCode, editCode }) => {
    const onDelete = async () => {
        deleteCode(code._id);
    };

    const onEdit = async () => {
        editCode(code._id);
    };

    return (
        <div className="adress-item">
            <div className="address-item-column-1">
            <label className="address-label"><strong>Name</strong></label>
                <br />
                {code.name}
                <br />
            </div>
            <div className="address-item-column-2">
            <label className="address-label"><strong>Typ</strong></label>
                <br />
                {code.type}
                <br />
                <label className="address-label"><strong>Points</strong></label>
                <br />
                {code.points}
                <br />
            </div>
            <div className="address-item-column-3">
                <label className="address-label"><strong>Expires</strong></label>
                <br />
                {code.expires}
            </div>

            <div id="address-button-column">
                
                <div id="address-button"><img src={UserEdit} alt="Bearbeiten" width={35}
                    onClick={onEdit}
                    data-tip
                    data-for="buttonTip2"
                    ></img>
                <ReactTooltip id="buttonTip2" place="top" effect="solid">
                    Bearbeiten
                </ReactTooltip>
                    </div>
                    <div id="address-button"><img src={UserDelete} alt="Löschen" width={35}
                    onClick={onDelete}
                    data-tip
                    data-for="buttonTip3"
                    ></img>
                <ReactTooltip id="buttonTip3" place="top" effect="solid">
                    L&ouml;schen
                </ReactTooltip>
                    </div>   
            </div>
        </div>
    );
};

export default ActionCodeItem;
