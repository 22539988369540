import React, { useState, useContext, useEffect } from 'react';
import Axios from 'axios';
import Modal from 'react-modal';
import UserContext from '../../context/UserContext';
import ControlContext from '../../context/ControlContext';
import { useHistory } from 'react-router-dom';
import ErrorNotice from '../misc/ErrorNotice';
import AdminAdressList from '../admin/AdminAdressList';
import AdminActionCodeList from '../admin/AdminActionCodeList';
import { confirmAlert } from 'react-confirm-alert';

export default function AdminEditSettings({ showModal, setShowModal }) {
    const { userData } = useContext(UserContext);
    const { control, setControl } = useContext(ControlContext);
    const history = useHistory();
    const [error, setError] = useState();

    const [offerPrefix, setOfferPrefix] = useState();
    const [orderPrefix, setOrderPrefix] = useState();
    const [itemPrefix, setItemPrefix] = useState();
    const [deliveryPrefix, setDeliveryPrefix] = useState();

    const [email, setEmail] = useState();
    const [telefon, setTelefon] = useState();
    const [adr1, setAdr1] = useState();
    const [adr2, setAdr2] = useState();
    const [adr3, setAdr3] = useState();
    const [agb, setAgb] = useState();
    const [datenschutz, setDatenschutz] = useState();
    const [widerruf, setWiderruf] = useState();
    const [rebateEuro, setRebateEuro] = useState();
    const [quoteValidDays, setQuoteValidDays] = useState();
    const [dhlUser, setDhlUser] = useState();
    const [dhlPassword, setDhlPassword] = useState();
    const [dhlAccount, setDhlAccount] = useState();

    const [adresses, setAdresses] = useState([]);
    const [actionCodes, setActionCodes] = useState([]);

    const [success] = useState();

    const modalClose = () => {
        setShowModal(false);
    };

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            position: 'absolute',
            transform: 'translate(-50%, -50%)',
            width: '90%',
            maxWidth: '1000px',
            height: '60%',
        },
    };

    useEffect(() => {
        const checkLoggedIn = async () => {
            //console.log('check login');
            try {
                let token = localStorage.getItem('auth-token');
                const res = await Axios.get(`${process.env.REACT_APP_SERVER}/admin/settings/`, { headers: { 'x-auth-token': token } });
                if (res) {
                    setOfferPrefix(res.data.offerPrefix);
                    setOrderPrefix(res.data.orderPrefix);
                    setItemPrefix(res.data.itemPrefix);
                    setDeliveryPrefix(res.data.deliveryPrefix);
                    setEmail(res.data.email);
                    setTelefon(res.data.telefon);
                    setAdr1(res.data.adr1);
                    setAdr2(res.data.adr2);
                    setAdr3(res.data.adr3);
                    setAgb(res.data.agb);
                    setDatenschutz(res.data.datenschutz);
                    setWiderruf(res.data.widerruf);
                    setRebateEuro(res.data.rebateEuro);
                    setQuoteValidDays(res.data.quoteValidDays);
                    setDhlUser(res.data.dhlUser);
                    setDhlPassword(res.data.dhlPassword);
                    setDhlAccount(res.data.dhlAccount);
                    setAdresses(res.data.adresses);
                    setActionCodes(res.data.actionCodes);
                }
            } catch (err) {
                //history.push('/');
            }
        };
        checkLoggedIn();
    }, [userData.user, history]);

    const saveChanges = async () => {
        try {
            let payload = {};
            payload.offerPrefix = offerPrefix;
            payload.orderPrefix = orderPrefix;
            payload.itemPrefix = itemPrefix;
            payload.deliveryPrefix = deliveryPrefix;
            payload.email = email;
            payload.telefon = telefon;
            payload.adr1 = adr1;
            payload.adr2 = adr2;
            payload.adr3 = adr3;
            payload.agb = agb;
            payload.datenschutz = datenschutz;
            payload.widerruf = widerruf;
            payload.rebateEuro = rebateEuro;
            payload.quoteValidDays = quoteValidDays;
            payload.dhlUser = dhlUser;
            payload.dhlPassword = dhlPassword;
            payload.dhlAccount = dhlAccount;
            let token = localStorage.getItem('auth-token');
            try {
                await Axios.post(`${process.env.REACT_APP_SERVER}/admin/savesettings`, payload, { headers: { 'x-auth-token': token } });
            } catch (err) {
                err.response.data && err.response.data.msg && setError(err.response.data.msg);
            }
        } catch (err) {
            err.response.data.msg && setError(err.response.data.msg);
        }
    };
    const clearDB = async () => {
        confirmAlert({
            title: 'Löschen bestätigen',
            message: 'Klicken Sie nur ja, wenn Sie wissen was Sie tun.',
            buttons: [
                {
                    label: 'Ja',
                    onClick: async () => {
                        let token = localStorage.getItem('auth-token');
                        try {
                            await Axios.post(`${process.env.REACT_APP_SERVER}/admin/cleardb`, {}, { headers: { 'x-auth-token': token } });
                        } catch (err) {
                            err.response.data && err.response.data.msg && setError(err.response.data.msg);
                        }
                    },
                },
                {
                    label: 'Nein',
                    onClick: () => {},
                },
            ],
        });
    };

    const cleanUpload = async () => {
        confirmAlert({
            title: 'Löschen bestätigen',
            message: 'Klicken Sie nur ja, wenn Sie wissen was Sie tun.',
            buttons: [
                {
                    label: 'Ja',
                    onClick: async () => {
                        let token = localStorage.getItem('auth-token');
                        try {
                            await Axios.post(`${process.env.REACT_APP_SERVER}/admin/cleanupload`, {}, { headers: { 'x-auth-token': token } });
                        } catch (err) {
                            err.response.data && err.response.data.msg && setError(err.response.data.msg);
                        }
                    },
                },
                {
                    label: 'Nein',
                    onClick: () => {},
                },
            ],
        });
    };

    const maintFunc = async () => {
        let token = localStorage.getItem('auth-token');
        try {
            Axios.post(
                `${process.env.REACT_APP_SERVER}/admin/setmaintenance`,
                { maintenance: !control.maintenance },
                { headers: { 'x-auth-token': token } }
            );
            setControl({ ...control, maintenance: !control.maintenance });
            modalClose();
            history.push('/');
        } catch (err) {
            err.response.data && err.response.data.msg && setError(err.response.data.msg);
        }
    };

    const toggleMaintenance = async () => {
        confirmAlert({
            title: 'Wartungsarbeiten bestätigen',
            message: 'Klicken Sie nur ja, wenn Sie wissen was Sie tun.',
            buttons: [
                {
                    label: 'Ja',
                    onClick: () => {
                        maintFunc();
                    },
                },
                {
                    label: 'Nein',
                    onClick: () => {},
                },
            ],
        });
    };

    return (
        <Modal isOpen={showModal} onRequestClose={(e) => modalClose(e)} contentLabel={'modalLabel'} style={customStyles}>
            <div className="modalHeader">
                <div>
                    <h2>Einstellungen</h2>
                </div>
                <div>
                    <button id="buttonX" onClick={() => modalClose()}>
                        &#10006;
                    </button>
                </div>
            </div>
            <div className="modalBody">
                {error && <ErrorNotice message={error} clearError={() => setError(undefined)} />}
                <form className="form">
                    <div className="form-div100">
                        <h2>Präfixes</h2>
                    </div>
                    <div className="form-div50">
                        <h6>Prefix Angebot</h6>
                        <input type="text" value={offerPrefix} onChange={(e) => setOfferPrefix(e.target.value)} />
                    </div>
                    <div className="form-div50">
                        <h6>Prefix Bestellung</h6>
                        <input type="text" value={orderPrefix} onChange={(e) => setOrderPrefix(e.target.value)} />
                    </div>
                    <div className="form-div50">
                        <h6>Prefix Item</h6>
                        <input type="text" value={itemPrefix} onChange={(e) => setItemPrefix(e.target.value)} />
                    </div>
                    <div className="form-div50">
                        <h6>Prefix Lieferschein</h6>
                        <input type="text" value={deliveryPrefix} onChange={(e) => setDeliveryPrefix(e.target.value)} />
                    </div>

                    <div className="form-div100">
                        <h2>Diverses</h2>
                    </div>
                    <div className="form-div50">
                        <h6>Rabattpunkt pro x Euro</h6>
                        <input type="text" value={rebateEuro} onChange={(e) => setRebateEuro(e.target.value)} />
                    </div>

                    <div className="form-div50">
                        <h6>Angebotsgültigkeit Tage</h6>
                        <input type="text" value={quoteValidDays} onChange={(e) => setQuoteValidDays(e.target.value)} />
                    </div>
                    <div className="form-div100">
                        <h2>DHL</h2>
                    </div>
                    <div className="form-div50">
                        <h6>DHL User</h6>
                        <input type="text" value={dhlUser} onChange={(e) => setDhlUser(e.target.value)} />
                    </div>
                    <div className="form-div50">
                        <h6>DHL Password</h6>
                        <input type="text" value={dhlPassword} onChange={(e) => setDhlPassword(e.target.value)} />
                    </div>
                    <div className="form-div50">
                        <h6>DHL Account</h6>
                        <input type="text" value={dhlAccount} onChange={(e) => setDhlAccount(e.target.value)} />
                    </div>
                    <div className="form-div50">
                        <br />
                    </div>
                    <div className="form-div100">
                        <h2>Firmendaten - wird durch Adressen ersetzt</h2>
                    </div>

                    <div className="form-div50">
                        <h6>Email</h6>
                        <input type="text" value={email} onChange={(e) => setEmail(e.target.value)} />
                    </div>
                    <div className="form-div50">
                        <h6>Telefon</h6>
                        <input type="text" value={telefon} onChange={(e) => setTelefon(e.target.value)} />
                    </div>

                    <div className="form-div100">
                        <h6>Adresszeile 1</h6>
                        <input type="text" value={adr1} onChange={(e) => setAdr1(e.target.value)} />
                    </div>

                    <div className="form-div50">
                        <h6>Adresszeile 2</h6>
                        <input type="text" value={adr2} onChange={(e) => setAdr2(e.target.value)} />
                    </div>

                    <div className="form-div50">
                        <h6>Adresszeile 3</h6>
                        <input type="text" value={adr3} onChange={(e) => setAdr3(e.target.value)} />
                    </div>
                </form>
                <br />

                <br />
                <div className="form-div100">
                    <h2>Texte</h2>
                </div>
                <div>
                    <h6>AGB</h6>
                    <textarea id="agb-input" rows="10" cols="100" onChange={(e) => setAgb(e.currentTarget.value)} value={agb}></textarea>
                    <h6>Datenschutz</h6>
                    <textarea
                        id="ds-input"
                        rows="10"
                        cols="100"
                        onChange={(e) => setDatenschutz(e.currentTarget.value)}
                        value={datenschutz}
                    ></textarea>
                    <h6>Widerrufsbelehrung</h6>
                    <textarea id="wr-input" rows="10" cols="100" onChange={(e) => setWiderruf(e.currentTarget.value)} value={widerruf}></textarea>
                </div>
                <br />
                <h2>Adressen</h2>
                <AdminAdressList adresses={adresses} setAdresses={setAdresses} />
                {success && <h3>{success}</h3>}
                <br />
                <h2>Aktionscodes</h2>
                <AdminActionCodeList actionCodes={actionCodes} setActionCodes={setActionCodes} />
                {success && <h3>{success}</h3>}
                <br />
                <div className="form-div100">
                    <h2>Admin Debug Tools</h2>
                </div>
                {userData?.rights?.includes('superadmin') ? (
                    <>
                        <div className="form-div100">
                            <button className="page-button-grey" onClick={() => cleanUpload()}>
                                Clean Upload Dir
                            </button>
                            &nbsp;
                            <button className="page-button-grey" onClick={() => clearDB()}>
                                Clear DB
                            </button>
                            <button className="page-button-grey" onClick={() => toggleMaintenance()}>
                                Toggle Maintenance
                            </button>
                        </div>
                    </>
                ) : null}
            </div>
            <div className="modalHeader">
                <div className="form-div100">
                    <br />
                    <button className="page-button-grey" type="submit" onClick={() => saveChanges()}>
                        Änderungen Speichern
                    </button>
                    <br />
                </div>
            </div>
        </Modal>
    );
}
