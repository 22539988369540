import React, { useState } from 'react';
import Axios from 'axios';
import Modal from 'react-modal';
import ErrorNotice from '../misc/ErrorNotice';

export default function RequestPW({ showModal, setShowModal }) {
    const [error, setError] = useState();
    const [email, setEmail] = useState();

    const [success, setSuccess] = useState();

    const modalClose = () => {
        setShowModal(false);
    };

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '90%',
            maxWidth: '700px',
            height: '340px',
        },
    };

    const submit = async (e) => {
        e.preventDefault();
        try {
            const payload = { email };
            await Axios.post(`${process.env.REACT_APP_SERVER}/users/requestpw`, payload);
            setSuccess('Instruktionen wurden versandt. Bitte überprüfen Sie Ihren Posteingang.');
            //history.push('/registered');
        } catch (err) {
            if (err.response && err.response.data) {
                err.response.data.msg && setError(err.response.data.msg);
            } else {
                setError('Error');
            }
        }
    };

    return (
        <Modal isOpen={showModal} onRequestClose={(e) => modalClose(e)} contentLabel={'modalLabel'} style={customStyles}>
            <div className="modalHeader">
                <div>
                    <h2>Passwort vergessen?</h2>
                </div>
                <div>
                    <button id="buttonX" onClick={() => modalClose()}>
                        &#10006;
                    </button>
                </div>
            </div>
            <div className="modalBody">
                <div className="page-form">
                    <p>
                        Bitte geben Sie Ihre E-Mail-Adresse an, mit der Sie sich registriert haben. Sollte ein entsprechender Account vorhanden sein,
                        erhalten Sie in Kürze Instruktionen zum Zurücksetzen Ihres Passworts.
                    </p>
                    <br />
                    {error && <ErrorNotice message={error} clearError={() => setError(undefined)} />}
                    <form className="form" onSubmit={submit}>
                        <div className="form-div100">
                            <label htmlFor="register-email">E-Mail-Adresse</label>
                            <input id="register-email" type="email" onChange={(e) => setEmail(e.target.value)} required />
                        </div>
                        <button className="page-button-grey" type="submit">
                            Anfordern
                        </button>
                        {success && <p id="requestSuccess">{success}</p>}
                    </form>
                </div>
            </div>
        </Modal>
    );
}
