import React, { useState, useContext } from 'react';
import Axios from 'axios';
import FileDetails from '../file/FileDetails';
import FileDocument from '../file/FileDocument';
import FileExtraTask from '../file/FileExtraTask';
import FileContext from '../../context/FileContext';
import TaskContext from '../../context/TaskContext';
import ConfirmCopyValues from '../misc/ConfirmCopyValues';
import { v4 as uuidv4 } from 'uuid';

const FileList = ({ setAllPrintable, discount, addExtraTask, requestPreview, setRequestPreview }) => {
    const { files, setFiles } = useContext(FileContext);
    const { tasks, setTasks } = useContext(TaskContext);
    const [rerender, setRerender] = useState();

    const [showConfirmCopy, setShowConfirmCopy] = useState(false);
    const [settings4All, setSettings4All] = useState();

    const deleteTask = (task) => {
        if (task.type === 'global') {
            let newTasks = tasks.filter(function (obj) {
                return obj.uuid !== task.uuid;
            });
            setTasks(newTasks);
        }
    };

    const updateTask = (task) => {
        let newTasks = [...tasks];
        newTasks.forEach(function (item, i) {
            if (item.uuid === task.uuid) newTasks[i] = task;
        });
        setTasks(newTasks);
    };

    const deleteFile = (uuid) => {
        try {
            let token = localStorage.getItem('auth-token');
            files.forEach((file) => {
                if (file.uuid === uuid) {
                    Axios.post(`${process.env.REACT_APP_SERVER}/files/deletefile`, { path: file.filePath }, { headers: { 'x-auth-token': token } });
                }
            });

            let newFiles = files.filter(function (obj) {
                return obj.uuid !== uuid;
            });
            
            setFiles(newFiles);
            let allPrintable = true;
            let countSTL = 0;
            newFiles.forEach((file) => {
                if (file.type === '') {
                    allPrintable &= file.printable;
                    ++countSTL;
                    file.rerender = Math.random();
                }
            });
            setAllPrintable(allPrintable && countSTL > 0);
            setRerender(Math.random());
        } catch (err) {
            console.log(err);
        }
    };

    const copyFile = (file) => {
        let copiedFile = new File([file], file.name, { type: file.type });
        copiedFile.uuid = uuidv4();
        copiedFile.unit = file.unit;
        copiedFile.prices = [
            {
                amount: '1',
                price: 0.0,
                colorPrice: 0.0,
                procPrice: 0.0,
                selected: false,
            },
        ];
        setFiles([...files, copiedFile]);
    };

    const applySettingsForAll = (settings, prices) => {
        setSettings4All(settings);
        setShowConfirmCopy(true);
        return;
    };

    const onConfirmApply = (filter) => {
        files.forEach((file) => {
            if (file.uuid !== settings4All.uuid) {
                if (filter.includeMethod) {
                    file.methods = settings4All.methods;
                    file.method = settings4All.method;
                }
                if (filter.includeMaterial) {
                    file.materials = settings4All.materials;
                    file.material = settings4All.material;
                }
                if (filter.includeColor) {
                    file.colors = settings4All.colors;
                    file.color = settings4All.color;
                }
                if (filter.includeProc) {
                    file.processings = settings4All.processings;
                    file.processing = settings4All.processing;
                }
                if (filter.includeConstraints) {
                    file.allowHollow = settings4All.allowHollow;
                    file.keepOrient = settings4All.keepOrient;
                    file.planSeries = settings4All.planSeries;
                }
                if (filter.includePrices) {
                    file.amounts = settings4All.amounts;
                }

                file.rerender = Math.random();
            }
        });
        setRerender(Math.random());
    };

    const notifyChange = () => {
        let allPrintable = true;
        let countSTL = 0;
        files.forEach((file) => {
            if (file.type === '') {
                allPrintable &= file.printable;
                ++countSTL;
            }
        });
        setAllPrintable(allPrintable && countSTL > 0);
    };

    return (
        <div id="file-list" key={`filelistDiv`} >
            <ConfirmCopyValues showModal={showConfirmCopy} setShowModal={setShowConfirmCopy} onConfirmApply={onConfirmApply} />
            {files &&
                files.length > 0 &&
                files.map((file, index) => (
                    <div key={`filelist1${index}`}>
                        {file.type === '' || file.type === undefined ? (
                               <FileDetails
                                    key={`filelist${index}`}
                                    position={index}
                                    file={file}
                                    deleteFile={deleteFile}
                                    copyFile={copyFile}
                                    applySettingsForAll={applySettingsForAll}
                                    notifyChange={notifyChange}
                                    rerender={rerender}
                                    discount={discount}
                                    addExtraTask={addExtraTask}
                                    requestPreview={requestPreview}
                                    setRequestPreview={setRequestPreview}
                                />
                        ) : (
                            <FileDocument key={`filelistfd${index}`} position={index} file={file} deleteFile={deleteFile} />
                        )}
                    </div>
                ))}
            {tasks &&
                tasks.length > 0 &&
                tasks.map((task, index) => (
                        <FileExtraTask
                            key={`filelisttask${index}`}
                            position={files.length + index + 1}
                            task={task}
                            deleteTask={deleteTask}
                            updateTask={updateTask}
                        />
                ))}

            
        </div>
    );
};

export default FileList;
