import React, { useState, useContext } from 'react';
import Axios from 'axios';
import UserContext from '../../context/UserContext';
import ErrorNotice from '../misc/ErrorNotice';

export default function ResetPW() {
    const [error, setError] = useState();
    const { userData, setUserData } = useContext(UserContext);

    const [password, setPassword] = useState();
    const [passwordCheck, setPasswordCheck] = useState();

    const [success, setSuccess] = useState();

    const submit = async (e) => {
        e.preventDefault();
        try {
            setSuccess(undefined);
            if (password.length < 8) {
                setError('Bitte wählen Sie ein Passwort mit mindestens 8 Zeichen');
                return;
            }
            if (password === passwordCheck) {
                const payload = { password: password, id: userData.pwID };
                const res = await Axios.post(`${process.env.REACT_APP_SERVER}/users/resetpw`, payload);
                if (res && res.data && res.data.msg) {
                    setSuccess(res.data.msg);
                }
                setUserData({ ...userData, pwID: undefined });
            } else {
                setError('Eingaben verschieden');
            }
            //history.push('/login');
        } catch (err) {
            if (err.response && err.response.data) {
                err.response.data.msg && setError(err.response.data.msg);
            } else {
                setError('Error');
            }
        }
    };

    return (
        <div className="page">
            <label>Reset Password.</label>
            {error && <ErrorNotice message={error} clearError={() => setError(undefined)} />}
            <form className="form" onSubmit={submit}>
                <label htmlFor="register-password">Password</label>
                <input id="register-password" type="password" onChange={(e) => setPassword(e.target.value)} required />
                <input type="password" placeholder="Verify password" onChange={(e) => setPasswordCheck(e.target.value)} required />

                <input type="submit" value="Speichern" />
            </form>
            {success && <h3>{success}</h3>}
        </div>
    );
}
