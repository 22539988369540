import React, { useState, useEffect } from 'react';

import Modal from 'react-modal';

import { create, all } from 'mathjs';

const AdminEditFormula = ({ showModal, setShowModal, inputVariables, setInputVariables, formula, setFormula }) => {
    const [variables, setVariables] = useState([]);
    const [output, setOutput] = useState([]);

    const modalClose = () => {
        setShowModal(false);
    };

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            position: 'absolute',
            transform: 'translate(-50%, -50%)',
            width: '1600px',
            maxWidth: '1600px',
            height: '60%',
        },
    };

    useEffect(() => {
        console.log(inputVariables);

        let vars = [];
        for (var key in inputVariables) {
            if (inputVariables.hasOwnProperty(key)) {
                vars.push({ key: key, value: inputVariables[key] });
            }
        }
        setVariables(vars);
        setOutput([]);
    }, [inputVariables]);

    const changeInputVar = (value, key) => {
        console.log(`${value} ${key}`);
        setInputVariables({
            ...inputVariables,
            [key]: parseFloat(value),
        });
        setOutput([]);
    };

    const testFormula = () => {
        const config = {};
        const math = create(all, config);
        const formulas = formula.split(';');

        let scope = { ...inputVariables };

        try {
            console.log('start');
            formulas.forEach((formula) => {
                console.log(formula);
                console.log(scope);
                math.evaluate(formula, scope);
            });

            let vars = [];
            for (var key in scope) {
                if (!inputVariables.hasOwnProperty(key) || key === 'Preis' || key === 'Druckpreis' || key === 'Time') {
                    if (scope.hasOwnProperty(key)) {
                        vars.push({ key: key, value: scope[key] });
                    }
                }
            }
            setOutput(vars);
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <>
            <Modal isOpen={showModal} onRequestClose={(e) => modalClose(e)} contentLabel={'modalLabel'} style={customStyles}>
                <div className="modalHeader-formula">
                    <div>Formel bearbeiten</div>
                    <div>
                        <button id="buttonX" onClick={() => modalClose()}>
                            &#10006;
                        </button>
                    </div>
                </div>
                <div className="modalBody-formula">
                    <div className="admin-editor-page">
                        <div className="admin-edit-column">
                            {variables.map((variable) => (
                                <>
                                    <input type="text" value={variable.value} onChange={(e) => changeInputVar(e.target.value, variable.key)} /> &nbsp;
                                    <label className="variable-label">{variable.key}</label>
                                    <br />
                                </>
                            ))}

                            <br />
                            <button className="page-button-action" onClick={testFormula}>
                                Formel testen
                            </button>
                        </div>
                        <div className="admin-edit-column">
                            <textarea
                                className="formula-input"
                                id="formula-input"
                                rows="15"
                                cols="80"
                                onChange={(e) => {
                                    setOutput([]);
                                    setFormula(e.currentTarget.value);
                                }}
                                value={formula}
                            ></textarea>
                        </div>
                        <div className="admin-edit-column">
                            {output.map((variable) => (
                                <>
                                    {/*<label className="variable-label">{variable.key}</label>*/}
                                    <h6>{variable.key}</h6>
                                    {variable.value}

                                    <br />
                                </>
                            ))}
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default AdminEditFormula;
