import React, { useState } from 'react';
import { NumericFormat } from 'react-number-format';;

const FileDetailsDelivery = ({ item, total, alreadyPacked, inHere, pindex, iindex, onSetAmount, readOnly }) => {
    const [packAmount, setPackAmount] = useState(inHere);

    function getSafe(fn, defaultVal) {
        try {
            return fn();
        } catch (e) {
            return defaultVal;
        }
    }

    const onSetPackAmount = (amount) => {
        setPackAmount(amount);
        onSetAmount(pindex, iindex, amount);
        /*
        setPackAmount(Math.min(total - alreadyPacked, amount));
        onSetAmount(pindex, iindex, Math.min(total - alreadyPacked, amount));
        */
    };

    return (
        <div className="delivery-item">
            {item ? (
                <>
                    <div className="item-preview">
                        <img src={item.preview} width="200" height="200" alt="" />
                    </div>
                    <div className="item-params">
                        <h4>{item.fileName}</h4>
                        <p>
                            <NumericFormat
                                value={item.volume}
                                displayType={'text'}
                                prefix={'Volumen: '}
                                suffix={' cm³'}
                                decimalScale={2}
                                fixedDecimalScale={true}
                                thousandSeparator={'.'}
                                decimalSeparator={','}
                            />
                            <br />
                            <NumericFormat
                                value={item.area}
                                displayType={'text'}
                                prefix={'Oberfläche: '}
                                suffix={' cm²'}
                                decimalScale={2}
                                fixedDecimalScale={true}
                                thousandSeparator={'.'}
                                decimalSeparator={','}
                            />
                            <br />
                            <NumericFormat
                                value={item.boundingBox[0]}
                                displayType={'text'}
                                prefix={'y: '}
                                suffix={' mm'}
                                decimalScale={2}
                                fixedDecimalScale={true}
                                thousandSeparator={'.'}
                                decimalSeparator={','}
                            />
                            <br />
                            <NumericFormat
                                value={item.boundingBox[1]}
                                displayType={'text'}
                                prefix={'y: '}
                                suffix={' mm'}
                                decimalScale={2}
                                fixedDecimalScale={true}
                                thousandSeparator={'.'}
                                decimalSeparator={','}
                            />
                            <br />
                            <NumericFormat
                                value={item.boundingBox[2]}
                                displayType={'text'}
                                prefix={'z: '}
                                suffix={' mm'}
                                decimalScale={2}
                                fixedDecimalScale={true}
                                thousandSeparator={'.'}
                                decimalSeparator={','}
                            />
                            <br />
                            {item.density && (
                                <>
                                    <NumericFormat
                                        value={item.density * item.volume}
                                        displayType={'text'}
                                        prefix={'Gewicht: ca. '}
                                        suffix={' g'}
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        thousandSeparator={'.'}
                                        decimalSeparator={','}
                                    />
                                </>
                            )}
                        </p>
                    </div>

                    <div className="item-selections">
                        <div>
                            <label>{item.method.displayName}</label>
                        </div>
                        <div>
                            <label>{item.material.displayName}</label>
                        </div>
                        <div>
                            <label>{getSafe(() => item.processing.displayName, 'keine Glättung')}</label>
                        </div>
                        <div>
                            <label>{getSafe(() => item.color.displayName, 'keine Färbung')}</label>
                        </div>
                    </div>
                    <div className="item-selections">
                        <div>
                            {readOnly ? (
                                <b>{packAmount}</b>
                            ) : (
                                <input
                                    className="input-projectID"
                                    type="number"
                                    value={packAmount}
                                    onChange={(e) => onSetPackAmount(e.currentTarget.value)}
                                />
                            )}
                            /{total - alreadyPacked}
                        </div>
                    </div>
                </>
            ) : null}
        </div>
    );
};

export default FileDetailsDelivery;
