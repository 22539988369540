import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import ErrorNotice from '../misc/ErrorNotice';

import { AgGridReact } from 'ag-grid-react';
import { BtnCellRenderer } from '../admin/BtnCellRenderer';
import EditCellRenderer from '../admin/EditCellRenderer';
import { CheckboxCellRenderer } from '../admin/CheckboxCellRenderer';

import { columnTypes, defaultColDef, defaultColGroupDef } from '../misc/ColumnTypes';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

const AdminEditPayments = () => {
    const [error, setError] = useState();
    const [payments, setPayments] = useState([]);

    const frameworkComponents = {
        btnCellRenderer: BtnCellRenderer,
        editCellRenderer: EditCellRenderer,
        cbCellRenderer: CheckboxCellRenderer,
    };

    const columnDefs = [
        {
            field: 'name',
            headerName: 'Name',
            sortable: true,
            filter: true,
        },
        {
            field: 'displayName',
            headerName: 'Anzeigename',
            sortable: true,
            filter: true,
        },
        {
            field: 'isBefore',
            headerName: 'Ist Vorkasse',
            sortable: true,
            filter: false,
            editable: false,
            cellRenderer: 'cbCellRenderer',
        },
        {
            field: 'text1',
            headerName: 'Thanks Text',
            sortable: true,
            filter: true,
        },
        {
            field: 'isHidden',
            headerName: 'Hidden',
            sortable: true,
            filter: false,
            editable: false,
            cellRenderer: 'cbCellRenderer',
        },

        {
            field: '_id',
            headerName: 'Speichern',
            cellRenderer: 'btnCellRenderer',
            cellRendererParams: {
                clicked: function (field) {
                    saveChanges(field);
                },
                label: 'Save',
            },

            type: 'buttonColumn',
        },
    ];

    const AddPayment = async () => {
        let token = localStorage.getItem('auth-token');
        const payload = {
            name: 'Neu',
            displayName: 'Neue Zahlungsart',
        };
        try {
            const res = await Axios.post(`${process.env.REACT_APP_SERVER}/admin/addpayment`, payload, { headers: { 'x-auth-token': token } });
            if (res.data && res.data.name) {
                setPayments([...payments, res.data]);
            }
        } catch (err) {
            err.response.data && err.response.data.msg && setError(err.response.data.msg);
        }
    };

    const saveChanges = async (data) => {
        console.log(data);
        try {
            let token = localStorage.getItem('auth-token');

            const payload = {
                _id: data._id,
                name: data.name,
                displayName: data.displayName,
                isHidden: data.isHidden,
                isBefore: data.isBefore,
                text1: data.text1,
            };
            await Axios.post(`${process.env.REACT_APP_SERVER}/admin/savepayment`, payload, { headers: { 'x-auth-token': token } });
        } catch (err) {
            err.response.data.msg && setError(err.response.data.msg);
            err.response.data.msg && console.log(err.response.data.msg);
        }
    };

    useEffect(() => {
        const getOrderStatuss = async () => {
            try {
                let token = localStorage.getItem('auth-token');
                const res = await Axios.get(`${process.env.REACT_APP_SERVER}/admin/payments/`, { headers: { 'x-auth-token': token } });
                if (res) {
                    setPayments(res.data.payments);
                }
            } catch (err) {
                err.response.data.msg && setError(err.response.data.msg);
            }
        };

        getOrderStatuss();
    }, [setError]);

    return (
        <div className="overview">
            <div className="page">
                <h2>Admin - Zahlungsarten</h2>
                {error && <ErrorNotice message={error} clearError={() => setError(undefined)} />}
            </div>
            <div>
                <button className="page-button-white center" onClick={AddPayment}>
                    Zahlungsart hinzufügen
                </button>
            </div>
            <br />
            <div className="ag-theme-alpine">
                <AgGridReact
                    rowData={payments}
                    columnDefs={columnDefs}
                    frameworkComponents={frameworkComponents}
                    columnTypes={columnTypes}
                    defaultColDef={defaultColDef}
                    defaultColGroupDef={defaultColGroupDef}
                    pagination={true}
                    paginationAutoPageSize={true}
                ></AgGridReact>
            </div>
        </div>
    );
};

export default AdminEditPayments;
