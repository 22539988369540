import React, { useState, useContext, useEffect } from 'react';
import Axios from 'axios';
import UserContext from '../../context/UserContext';
import ErrorNotice from '../misc/ErrorNotice';

export default function Verify() {
    console.log('huhu');

    const [error, setError] = useState();
    const { userData, setUserData } = useContext(UserContext);

    const [success, setSuccess] = useState();

    useEffect(() => {
        const doVerify = async () => {
            try {
                const loginRes = await Axios.post(`${process.env.REACT_APP_SERVER}/users/verify`, { id: userData.verifyID });

                const adresses = [...loginRes.data.user.adresses];
                const rights = [...loginRes.data.user.rights];
                delete loginRes.data.user.adresses;
                delete loginRes.data.user.rights;

                setUserData({
                    token: loginRes.data.token,
                    user: loginRes.data.user,
                    adresses: adresses,
                    rights: rights,
                });

                localStorage.setItem('auth-token', loginRes.data.token);
                setSuccess(
                    'Account email erfolgreich bestätigt. Bitte beachten Sie, dass es ca. einen Tag dauern kann, bis ein Administrator den Account für Bestellungen freigeschaltet hat.'
                );
            } catch (err) {
                if (err.response && err.response.data) {
                    err.response.data.msg && setError(err.response.data.msg);
                } else {
                    setError('Error');
                }
            }
        };
        doVerify();
    }, []);

    return (
        <div className="page">
            {error && <ErrorNotice message={error} clearError={() => setError(undefined)} />}
            {success && <h3>{success}</h3>}
        </div>
    );
}
