import React, { useContext, useState, useEffect } from 'react';
import FileTaskOfferAdmin from '../file/FileTaskOfferAdmin';
import Axios from 'axios';
import UserContext from '../../context/UserContext';
import download from 'downloadjs';
import { NumericFormat } from 'react-number-format';;

const FileDetailsOfferAdmin = ({ item, setChanged, position }) => {
    const { userData } = useContext(UserContext);
    const [tasks, setTasks] = useState(item.tasks);

    const [prices, setPrices] = useState(item.prices);
    const [approved, setApproved] = useState(item.approved);

    const onDownload = async () => {
        let token = localStorage.getItem('auth-token');
        const config = {
            responseType: 'blob',
            headers: { 'x-auth-token': token },
            params: { filePath: item.filePath },
        };

        Axios.get(`${process.env.REACT_APP_SERVER}/admin/downloadfile`, config).then((res) => {
            download(res.data, item.fileName);
        });
    };

    function getSafe(fn, defaultVal) {
        try {
            return fn();
        } catch (e) {
            return defaultVal;
        }
    }

    const onPriceChangePrint = (e, index) => {
        let pr = prices[index];

        pr.printPrice = parseFloat(e.currentTarget.value);
        pr.price = pr.printPrice + pr.procPrice + pr.colorPrice;
        setPrices([...prices.slice(0, index), pr, ...prices.slice(index + 1)]);

        setChanged(true);
    };

    const onPriceChangeProc = (e, index) => {
        let pr = prices[index];

        pr.procPrice = parseFloat(e.currentTarget.value);
        pr.price = pr.printPrice + pr.procPrice + pr.colorPrice;
        setPrices([...prices.slice(0, index), pr, ...prices.slice(index + 1)]);

        setChanged(true);
    };

    const onPriceChangeCol = (e, index) => {
        let pr = prices[index];

        pr.colorPrice = parseFloat(e.currentTarget.value);
        pr.price = pr.printPrice + pr.procPrice + pr.colorPrice;
        setPrices([...prices.slice(0, index), pr, ...prices.slice(index + 1)]);

        setChanged(true);
    };

    const onAmountChange = (e, index) => {
        let pr = prices[index];
        if (pr.amount !== e.currentTarget.value) {
            pr.amount = e.currentTarget.value;
            setPrices([...prices.slice(0, index), pr, ...prices.slice(index + 1)]);
        }
        setChanged(true);
    };

    const onAmountDelete = (e, index) => {
        if (prices.length > 1) {
            setPrices([...prices.slice(0, index), ...prices.slice(index + 1)]);
        }
        setChanged(true);
    };

    const onAmountAdd = (e) => {
        setPrices([...prices, { amount: 1, price: 0 }]);
        setChanged(true);
    };

    const onApprovedChange = (e) => {
        setApproved(e);
        item.approved = e;
        setChanged(true);
    };

    useEffect(() => {
        item.prices = prices;
    }, [item, prices]);

    useEffect(() => {
        item.tasks = tasks;
    }, [item, tasks]);

    const updateTask = (task) => {
        let newTasks = [...tasks];
        newTasks.forEach(function (item, i) {
            if (item.uuid === task.uuid) newTasks[i] = task;
        });
        setTasks(newTasks);
        if (setChanged) setChanged(true);
    };

    return (
        <>
            <div id="item-element-wrapper">
                <div className="item-container">
                    {item ? (
                        <>
                            <div className="item-preview-details-wrapper">
                                <div className="item-position-number">{position + 1}</div>

                                <div className="item-details-row">
                                    <div className="item-preview">
                                        <img src={item.preview} width="200" height="200" alt="" />
                                    </div>

                                    <div className="item-params">
                                        <div className="item-name">
                                            {item.fileName}&nbsp;
                                            <br />({item.IDS})
                                        </div>

                                        <div className="item-params-row">
                                            <div className="item-params-value">
                                                <NumericFormat
                                                    value={item.boundingBox[0]}
                                                    displayType={'text'}
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    thousandSeparator={'.'}
                                                    decimalSeparator={','}
                                                />
                                                <span> x </span>
                                                <NumericFormat
                                                    value={item.boundingBox[1]}
                                                    displayType={'text'}
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    thousandSeparator={'.'}
                                                    decimalSeparator={','}
                                                />
                                                <span> x </span>
                                                <NumericFormat
                                                    value={item.boundingBox[2]}
                                                    displayType={'text'}
                                                    suffix={' mm'}
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    thousandSeparator={'.'}
                                                    decimalSeparator={','}
                                                />
                                            </div>
                                        </div>

                                        <div className="item-comment-textbox">
                                            <p className="paragraph">Bemerkungen:</p>
                                            <textarea
                                                id="file-comment"
                                                name="filecomment"
                                                rows="5"
                                                cols="30"
                                                disabled
                                                value={item.comment || ''}
                                            ></textarea>
                                        </div>
                                    </div>

                                    <div className="item-selections">
                                        <div className="item-selections-row">
                                            <div className="item-selections-name">
                                                <label className="fileDetailLabel">Verfahren:</label>
                                            </div>
                                            <div>
                                                <label>{item.method.displayName}</label>
                                            </div>
                                        </div>
                                        <div className="item-selections-row">
                                            <div className="item-selections-name">
                                                <label className="fileDetailLabel">Material:</label>
                                            </div>
                                            <div>
                                                <label>{item.material.displayName}</label>
                                            </div>
                                        </div>
                                        {item.infill ? (
                                            <div className="item-selections-row">
                                                <div className="item-selections-name">
                                                    <label className="fileDetailLabel">Infill:</label>
                                                </div>
                                                <div>
                                                    <label>{getSafe(() => item.infill.displayName, '100% Infill')}</label>
                                                </div>
                                            </div>
                                        ) : null}
                                        <div className="item-selections-row">
                                            <div className="item-selections-name">
                                                <label className="fileDetailLabel">Nachbearbeitung:</label>
                                            </div>
                                            <div>
                                                <label>{getSafe(() => item.processing.displayName, 'keine Glättung')}</label>
                                            </div>
                                        </div>
                                        <div className="item-selections-row">
                                            <div className="item-selections-name">
                                                <label className="fileDetailLabel">Farbe:</label>
                                            </div>
                                            <div>
                                                <label>{getSafe(() => item.color.displayName, 'keine Färbung')}</label>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="item-prices-admin ">
                                        <table id="item-prices-table">
                                            <tbody>
                                                <tr>
                                                    <td></td>
                                                    <th>Anzahl</th>
                                                    <th>Druck</th>
                                                    <th>Nachb.</th>
                                                    <th>Farbe</th>
                                                </tr>
                                                {prices
                                                    ? prices.map((pr, index) => (
                                                          <tr key={index}>
                                                              <td>
                                                                  {prices.length > 1 && index > 0 ? (
                                                                      <button
                                                                          className="delete-amount-button"
                                                                          onClick={(e) => onAmountDelete(e, index)}
                                                                      >
                                                                          -
                                                                      </button>
                                                                  ) : null}
                                                              </td>

                                                              <td>
                                                                  <input
                                                                      className="amount-input"
                                                                      type="number"
                                                                      value={pr.amount}
                                                                      disabled={index === 0}
                                                                      onChange={(e) => onAmountChange(e, index)}
                                                                  />
                                                              </td>

                                                              <td>
                                                                  <input
                                                                      className="price-input"
                                                                      type="number"
                                                                      value={pr.printPrice}
                                                                      onChange={(e) => onPriceChangePrint(e, index)}
                                                                  />
                                                              </td>
                                                              <td>
                                                                  <input
                                                                      className="price-input"
                                                                      type="number"
                                                                      value={pr.procPrice}
                                                                      onChange={(e) => onPriceChangeProc(e, index)}
                                                                  />
                                                              </td>
                                                              <td>
                                                                  <input
                                                                      className="price-input"
                                                                      type="number"
                                                                      value={pr.colorPrice}
                                                                      onChange={(e) => onPriceChangeCol(e, index)}
                                                                  />
                                                              </td>
                                                          </tr>
                                                      ))
                                                    : null}
                                                <tr>
                                                    <td>
                                                        <button className="add-amount-button" onClick={(e) => onAmountAdd(e)}>
                                                            +
                                                        </button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : null}

                    <div className="item-actions">
                        <div className="item-actions-wrapper">
                            <div className="item-actions-options">
                                {item.keepOrient ? (
                                    <>
                                        <label className="labelGreen">Ausrichtung beibehalten</label>
                                    </>
                                ) : (
                                    <>
                                        <label className="labelRed">Ausrichtung nicht beibehalten</label>
                                    </>
                                )}
                                {item.allowHollow ? (
                                    <>
                                        <label className="labelGreen">Aushöhlen erlaubt</label>
                                    </>
                                ) : (
                                    <>
                                        <label className="labelRed">Aushöhlen nicht erlaubt</label>
                                    </>
                                )}
                                {item.planSeries ? (
                                    <>
                                        <label className="labelGreen">Serienfertigung geplant</label>
                                    </>
                                ) : (
                                    <>
                                        <label className="labelRed">Serienfertigung nicht geplant</label>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className="item-actions">
                        <div className="item-comment-label">
                            <label htmlFor={item.uuid + 'approved'}>Freigegeben: </label>

                            <input
                                type="checkbox"
                                id={item.uuid + 'approved'}
                                name="approved"
                                checked={approved}
                                onChange={(e) => onApprovedChange(e.target.checked)}
                            />
                        </div>

                        {userData.rights?.includes('deleted') && (
                            <>
                                <button className="page-button" onClick={onDownload}>
                                    Download
                                </button>
                            </>
                        )}
                    </div>
                </div>
            </div>
            {tasks &&
                tasks.length > 0 &&
                tasks.map((task, index) => (
                    <>
                        <FileTaskOfferAdmin key={task.uuid} position={`${position + 1}.${index + 1}`} task={task} updateTask={updateTask} />
                    </>
                ))}
        </>
    );
};

export default FileDetailsOfferAdmin;
