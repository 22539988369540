import React, { useState, useContext, useEffect } from 'react';
import Axios from 'axios';
import UserContext from '../../context/UserContext';
import ControlContext from '../../context/ControlContext';
import { useHistory } from 'react-router-dom';
import ErrorNotice from '../misc/ErrorNotice';
import download from 'downloadjs';
import { AgGridReact } from 'ag-grid-react';
import {
    BtnCellRenderer,
    BtnPDFCellRenderer,
    BtnDownloadCellRenderer,
    BtnViewCellRenderer,
    BtnAttentionCellRenderer,
    BtnCommentCellRenderer,
    BtnDeliveryCellRenderer,
} from '../admin/BtnCellRenderer';
import { columnTypes, defaultColDef, defaultColGroupDef, dateFormatter, dateTimeFormatter } from '../misc/ColumnTypes';
import DeliveryPage from './DeliveryPage';
import { checkAdmin } from '../account/AccountUtils';
import AutoButton from '../misc/AutoButton';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

const AdminOrders = () => {
    const { userData } = useContext(UserContext);
    const { control, setControl } = useContext(ControlContext);
    const history = useHistory();
    const [error, setError] = useState();
    const [orders, setOrders] = useState();
    const [showDelivery, setShowDelivery] = useState(false);

    const frameworkComponents = {
        btnCellRenderer: BtnCellRenderer,
        btnPDFCellRenderer: BtnPDFCellRenderer,
        btnDownloadCellRenderer: BtnDownloadCellRenderer,
        btnViewCellRenderer: BtnViewCellRenderer,
        btnAttentionCellRenderer: BtnAttentionCellRenderer,
        btnCommentCellRenderer: BtnCommentCellRenderer,
        btnDeliveryCellRenderer: BtnDeliveryCellRenderer,
    };

    const columnDefs = [
        {
            field: 'IDS',
            headerName: 'Bestellnummer',
            sortable: true,
            filter: true,
            type: 'numberColumn',
            suppressMenu: true,
            editable: false,
            onCellClicked: (params) => {
                viewOrder(params.data._id);
            },
        },
        {
            field: 'customerID.email',
            headerName: 'Account',
            sortable: true,
            filter: true,
            type: 'accountColumn',
            suppressMenu: true,
            editable: false,
        },
        {
            field: 'created',
            headerName: 'Erstellt am',
            sortable: true,
            filter: true,
            type: 'dateColumn',
            valueFormatter: dateTimeFormatter,
            sort: 'desc',
            suppressMenu: true,
            editable: false,
        },
        {
            field: 'estDelivery',
            headerName: 'Lieferdatum',
            sortable: true,
            filter: true,
            type: 'dateColumn',
            valueFormatter: dateFormatter,
            suppressMenu: true,
            editable: false,
        },
        {
            field: 'statusDisplayName',
            headerName: 'Status',
            sortable: true,
            filter: true,
            suppressMenu: true,
            editable: false,
        },
        {
            field: 'fromOffer.IDS',
            headerName: 'aus Angebot',
            sortable: true,
            filter: true,
            onCellClicked: (params) => {
                viewOffer(params.data.fromOffer?._id);
            },
            suppressMenu: true,
            editable: false,
        },
        {
            field: '_id',
            headerName: 'View',
            cellRenderer: 'btnViewCellRenderer',
            cellRendererParams: {
                clicked: function (field) {
                    viewOrder(field);
                },
                label: 'View',
            },
            type: 'iconColumn',
            editable: false,
            filter: false,
        },

        {
            field: '_id',
            headerName: 'Lieferschein',
            sortable: true,
            filter: false,
            valueGetter: () => {
                return 'Bearbeiten';
            },
            suppressMenu: true,
            editable: false,
            cellRenderer: 'btnDeliveryCellRenderer',
            cellRendererParams: {
                clicked: function (field) {
                    editDeliveryNote(field);
                },
                label: 'Download',
            },
            type: 'iconColumn',
        },
        {
            field: '_id',
            headerName: 'Files',
            cellRenderer: 'btnDownloadCellRenderer',
            cellRendererParams: {
                clicked: function (field) {
                    downloadFiles(field);
                },
                label: 'Download',
            },
            sortable: true,
            filter: false,
            valueGetter: () => {
                return 'Download';
            },
            suppressMenu: true,
            editable: false,
            type: 'iconColumn',
        },
        {
            field: '_id',
            headerName: 'PDF',
            cellRenderer: 'btnPDFCellRenderer',
            cellRendererParams: {
                clicked: function (field) {
                    downloadPDF(field);
                },
                label: 'Download',
            },
            sortable: true,
            filter: false,
            valueGetter: () => {
                return 'Download';
            },

            suppressMenu: true,
            editable: false,
            type: 'iconColumn',
        },
        {
            field: 'needsAdminAttention',
            headerName: 'Alert',
            cellRenderer: 'btnAttentionCellRenderer',
            cellRendererParams: {
                clicked: function (field) {
                    viewOrder(field);
                },
                label: 'View',
            },

            type: 'iconColumn',
            editable: false,
            filter: false,
        },
        {
            field: 'unansweredMessage',
            headerName: 'Message',
            cellRenderer: 'btnCommentCellRenderer',
            cellRendererParams: {
                clicked: function (field) {
                    viewOrder(field);
                },
                label: 'View',
            },

            type: 'iconColumn',
            editable: false,
            filter: false,
        },
    ];

    const viewOrder = async (_id) => {
        setControl({
            ...control,
            viewOrder: _id,
        });

        history.push('/viewOrder');
    };

    const editDeliveryNote = async (_id) => {
        setControl({
            ...control,
            viewOrder: _id,
        });
        setShowDelivery(true);
        //history.push('/delivery');
    };

    const viewOffer = async (_id) => {
        setControl({
            ...control,
            viewOffer: _id,
        });

        history.push('/viewOffer');
    };

    const downloadFiles = async (order) => {
        try {
            let token = localStorage.getItem('auth-token');
            const config = {
                responseType: 'blob',
                headers: { 'x-auth-token': token },
                params: { orderID: order._id },
            };

            Axios.get(`${process.env.REACT_APP_SERVER}/admin/downloadorderzip`, config).then((res) => {
                download(res.data, `Auftrag_${order.IDS}.zip`);
            });
        } catch (err) {
            console.log(err);
            err.response && err.response.data && err.response.data.msg && setError(err.response.data.msg);
        }
    };

    const downloadPDF = async (order) => {
        try {
            let token = localStorage.getItem('auth-token');
            const config = {
                responseType: 'blob',
                headers: { 'x-auth-token': token },
                params: { orderID: order._id },
            };

            Axios.get(`${process.env.REACT_APP_SERVER}/admin/downloadorderpdf`, config).then((res) => {
                download(res.data, `Auftrag_${order.IDS}.pdf`);
            });
        } catch (err) {
            console.log(err);
            err.response && err.response.data && err.response.data.msg && setError(err.response.data.msg);
        }
    };

    const init = async () => {
        let token = localStorage.getItem('auth-token');

        const res = await Axios.get(`${process.env.REACT_APP_SERVER}/admin/orders/`, { headers: { 'x-auth-token': token } });
        setOrders(res.data.orders);
    };

    const checkLoggedIn = async () => {
        try {
            checkAdmin(userData, history);
        } catch (err) {
            err.response.data.msg && setError(err.response.data.msg);
        }
    };

    useEffect(() => {
        checkLoggedIn();
        init();
    }, []);

    return (
        <div className="overview">
            <DeliveryPage showModal={showDelivery} setShowModal={setShowDelivery} />
            <h2>Admin - Alle Bestellungen</h2>
            {error && <ErrorNotice message={error} clearError={() => setError(undefined)} />}

            <div className="ag-theme-alpine">
                <AgGridReact
                    rowData={orders}
                    columnDefs={columnDefs}
                    frameworkComponents={frameworkComponents}
                    columnTypes={columnTypes}
                    defaultColDef={defaultColDef}
                    defaultColGroupDef={defaultColGroupDef}
                    pagination={false}
                    paginationAutoPageSize={false}
                    paginationPageSize={12}
                ></AgGridReact>
            </div>

            <AutoButton className="page-button-white" onClick={init} text="Aktualisieren" count={300} />
        </div>
    );
};

export default AdminOrders;
