import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import { confirmAlert } from 'react-confirm-alert';

const AdminSettings = ({ setError }) => {
    const [offerPrefix, setOfferPrefix] = useState();
    const [orderPrefix, setOrderPrefix] = useState();
    const [itemPrefix, setItemPrefix] = useState();
    const [deliveryPrefix, setDeliveryPrefix] = useState();

    const [email, setEmail] = useState();
    const [telefon, setTelefon] = useState();
    const [adr1, setAdr1] = useState();
    const [adr2, setAdr2] = useState();
    const [adr3, setAdr3] = useState();
    const [agb, setAgb] = useState();
    const [datenschutz, setDatenschutz] = useState();
    const [widerruf, setWiderruf] = useState();
    const [rebateEuro, setRebateEuro] = useState();
    const [quoteValidDays, setQuoteValidDays] = useState();
    const [dhlUser, setDhlUser] = useState();
    const [dhlPassword, setDhlPassword] = useState();
    const [dhlAccount, setDhlAccount] = useState();

    const saveChanges = async () => {
        let payload = {};
        payload.offerPrefix = offerPrefix;
        payload.orderPrefix = orderPrefix;
        payload.itemPrefix = itemPrefix;
        payload.deliveryPrefix = deliveryPrefix;
        payload.email = email;
        payload.telefon = telefon;
        payload.adr1 = adr1;
        payload.adr2 = adr2;
        payload.adr3 = adr3;
        payload.agb = agb;
        payload.datenschutz = datenschutz;
        payload.widerruf = widerruf;
        payload.rebateEuro = rebateEuro;
        payload.quoteValidDays = quoteValidDays;
        payload.dhlUser = dhlUser;
        payload.dhlPassword = dhlPassword;
        payload.dhlAccount = dhlAccount;
        let token = localStorage.getItem('auth-token');
        try {
            await Axios.post(`${process.env.REACT_APP_SERVER}/admin/savesettings`, payload, { headers: { 'x-auth-token': token } });
        } catch (err) {
            err.response.data && err.response.data.msg && setError(err.response.data.msg);
        }
    };

    const clearDB = async () => {
        confirmAlert({
            title: 'Löschen bestätigen',
            message: 'Klicken Sie nur ja, wenn Sie wissen was Sie tun.',
            buttons: [
                {
                    label: 'Ja',
                    onClick: async () => {
                        let token = localStorage.getItem('auth-token');
                        try {
                            await Axios.post(`${process.env.REACT_APP_SERVER}/admin/cleardb`, {}, { headers: { 'x-auth-token': token } });
                        } catch (err) {
                            err.response.data && err.response.data.msg && setError(err.response.data.msg);
                        }
                    },
                },
                {
                    label: 'Nein',
                    onClick: () => {},
                },
            ],
        });
    };

    const cleanUpload = async () => {
        confirmAlert({
            title: 'Löschen bestätigen',
            message: 'Klicken Sie nur ja, wenn Sie wissen was Sie tun.',
            buttons: [
                {
                    label: 'Ja',
                    onClick: async () => {
                        let token = localStorage.getItem('auth-token');
                        try {
                            await Axios.post(`${process.env.REACT_APP_SERVER}/admin/cleanupload`, {}, { headers: { 'x-auth-token': token } });
                        } catch (err) {
                            err.response.data && err.response.data.msg && setError(err.response.data.msg);
                        }
                    },
                },
                {
                    label: 'Nein',
                    onClick: () => {},
                },
            ],
        });
    };

    useEffect(() => {
        const initThis = async () => {
            try {
                let token = localStorage.getItem('auth-token');
                const res = await Axios.get(`${process.env.REACT_APP_SERVER}/admin/settings/`, { headers: { 'x-auth-token': token } });
                if (res) {
                    setOfferPrefix(res.data.offerPrefix);
                    setOrderPrefix(res.data.orderPrefix);
                    setItemPrefix(res.data.itemPrefix);
                    setDeliveryPrefix(res.data.deliveryPrefix);
                    setEmail(res.data.email);
                    setTelefon(res.data.telefon);
                    setAdr1(res.data.adr1);
                    setAdr2(res.data.adr2);
                    setAdr3(res.data.adr3);
                    setAgb(res.data.agb);
                    setDatenschutz(res.data.datenschutz);
                    setWiderruf(res.data.widerruf);
                    setRebateEuro(res.data.rebateEuro);
                    setQuoteValidDays(res.data.quoteValidDays);
                    setDhlUser(res.data.dhlUser);
                    setDhlPassword(res.data.dhlPassword);
                    setDhlAccount(res.data.dhlAccount);
                }
            } catch (err) {
                err.response.data.msg && setError(err.response.data.msg);
            }
        };

        initThis();
    }, []);

    return (
        <>
            <div className="admin-editor-page">
                <div className="admin-edit-column">
                    <h4>Settings</h4>
                    <h6>Prefix Angebot</h6>
                    <input type="text" value={offerPrefix} onChange={(e) => setOfferPrefix(e.target.value)} />
                    <br />
                    <h6>Prefix Bestellung</h6>
                    <input type="text" value={orderPrefix} onChange={(e) => setOrderPrefix(e.target.value)} />
                    <br />
                    <h6>Prefix Item</h6>
                    <input type="text" value={itemPrefix} onChange={(e) => setItemPrefix(e.target.value)} />
                    <h6>Prefix Lieferschein</h6>
                    <input type="text" value={deliveryPrefix} onChange={(e) => setDeliveryPrefix(e.target.value)} />
                    <h6>Email</h6>
                    <input type="text" value={email} onChange={(e) => setEmail(e.target.value)} />
                    <h6>Telefon</h6>
                    <input type="text" value={telefon} onChange={(e) => setTelefon(e.target.value)} />
                    <h6>Adresszeile 1</h6>
                    <input type="text" value={adr1} onChange={(e) => setAdr1(e.target.value)} />
                    <h6>Adresszeile 2</h6>
                    <input type="text" value={adr2} onChange={(e) => setAdr2(e.target.value)} />
                    <h6>Adresszeile 3</h6>
                    <input type="text" value={adr3} onChange={(e) => setAdr3(e.target.value)} />
                    <h6>Rabattpunkt pro x Euro</h6>
                    <input type="text" value={rebateEuro} onChange={(e) => setRebateEuro(e.target.value)} />
                    <h6>Angebotsgültigkeit Tage</h6>
                    <input type="text" value={quoteValidDays} onChange={(e) => setQuoteValidDays(e.target.value)} />
                    <h6>DHL User</h6>
                    <input type="text" value={dhlUser} onChange={(e) => setDhlUser(e.target.value)} />
                    <h6>DHL Password</h6>
                    <input type="text" value={dhlPassword} onChange={(e) => setDhlPassword(e.target.value)} />
                    <h6>DHL Account</h6>
                    <input type="text" value={dhlAccount} onChange={(e) => setDhlAccount(e.target.value)} />
                </div>
                <div className="admin-edit-column">
                    <h6>AGB</h6>
                    <textarea id="agb-input" rows="10" cols="100" onChange={(e) => setAgb(e.currentTarget.value)} value={agb}></textarea>
                    <h6>Datenschutz</h6>
                    <textarea
                        id="ds-input"
                        rows="10"
                        cols="100"
                        onChange={(e) => setDatenschutz(e.currentTarget.value)}
                        value={datenschutz}
                    ></textarea>
                    <h6>Widerrufsbelehrung</h6>
                    <textarea id="wr-input" rows="10" cols="100" onChange={(e) => setWiderruf(e.currentTarget.value)} value={widerruf}></textarea>
                </div>
            </div>
            <button className="page-button-action" onClick={() => saveChanges()}>
                Änderungen speichern
            </button>
            <br />
            <br />
            <br />
            <button className="page-button-action" onClick={() => cleanUpload()}>
                Clean Upload Dir
            </button>
            <button className="page-button-action" onClick={() => clearDB()}>
                Clear DB
            </button>
        </>
    );
};

export default AdminSettings;
