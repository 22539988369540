export const columnTypes = {
    numberColumn: {
        width: 130,
        filter: 'agTextColumnFilter',
    },
    nonEditableColumn: { editable: false },
    dateColumn: {
        filter: 'agDateColumnFilter',
        filterParams: {
            comparator: function (filterLocalDateAtMidnight, cellValue) {
                var dateParts = cellValue.split('.');
                var day = Number(dateParts[0]);
                var month = Number(dateParts[1]) - 1;
                var year = Number(dateParts[2]);
                var cellDate = new Date(year, month, day);
                if (cellDate < filterLocalDateAtMidnight) {
                    return -1;
                } else if (cellDate > filterLocalDateAtMidnight) {
                    return 1;
                } else {
                    return 0;
                }
            },
        },
    },
    accountColumn: {
        width: 230,
    },
    buttonColumn: {
        width: 100,
    },
    paramsColumn: {
        width: 600,
    },
    iconColumn: {
        width: 70,
    },
    cbColumn: {
        width: 120,
    },
};

export const defaultColDef = {
    width: 150,
    editable: true,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    resizable: true,
};
export const defaultColGroupDef = { marryChildren: true };

export const dateFormatter = (params) => {
    let date1 = new Date(params.value);
    let month = date1.getMonth() + 1; //months from 1-12
    let day = date1.getDate();
    let year = date1.getFullYear();
    return day + '.' + month + '.' + year;
};

export const dateTimeFormatter = (params) => {
    let date1 = new Date(params.value);
    let month = date1.getMonth() + 1; //months from 1-12
    let day = date1.getDate();
    let year = date1.getFullYear();
    let hour = date1.getHours().toString().padStart(2, '0');
    let minute = date1.getMinutes().toString().padStart(2, '0');
    return day + '.' + month + '.' + year + ' ' + hour + ':' + minute;
};

export const expiryFormatter = (params) => {
    let date1 = new Date(params.value);
    let date2 = new Date();
    //console.log(date1);
    //console.log(date2);
    const diffTime = date1 - date2;
    //console.log(diffTime);
    if (diffTime < 0) {
        return 'abgelaufen';
    }
    if (diffTime < 3600000) {
        const diffHours = Math.ceil(diffTime / (1000 * 60 * 60));
        return `in ${diffHours.toString()} Stunden`;
    }
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return `in ${diffDays.toString()} Tagen`;
};
