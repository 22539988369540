import React, { useEffect, useState, useRef } from 'react';

import * as THREE from 'three';
import { CameraHelper, Vector3 } from 'three';
import { OBB } from 'three/examples/jsm/math/OBB';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';

const PreviewWindow = ({ file, renderColor, requestPreview, setRequestPreview, useAABB }) => {
    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);
    const ref = useRef(null);

    useEffect(() => {
        setHeight(ref.current.clientHeight);
        setWidth(ref.current.clientWidth);

        //console.log(`${ref.current.clientWidth} ${ref.current.clientHeight}`);
    }, [setHeight, setWidth]);

    useEffect(() => {
        const addLine = (start, end, col, scene) => {
            const material = new THREE.LineBasicMaterial({
                color: col,
            });

            const points = [];
            points.push(start);
            points.push(end);

            const geometry = new THREE.BufferGeometry().setFromPoints(points);

            const line = new THREE.Line(geometry, material);
            scene.add(line);
        };

        if(file.fileType === 'stl') {

            const container = document.getElementById(file.uuid);
            // === THREE.JS CODE START ===
            let scene = new THREE.Scene();

            let renderer = new THREE.WebGLRenderer({ canvas: container, preserveDrawingBuffer: true });
            renderer.setSize(width, height);

            renderer.setClearColor(0xdfdfdf, 1);

            //container.innerHTML = '';
            //container.appendChild(renderer.domElement);

        
            var STLLoader = require('three-stl-loader')(THREE);

            const loader = new STLLoader();

            let reader = new FileReader();
            reader.readAsArrayBuffer(file);
        

            reader.onload = function () {
                let geometry = loader.parse(reader.result);
                geometry.computeVertexNormals();

                let material = new THREE.MeshPhongMaterial({
                    color: renderColor,
                });

                material.side = THREE.DoubleSide;
                let mesh = new THREE.Mesh(geometry, material);

                let bb = new THREE.Box3();
                bb.setFromObject(mesh);
                let cent = new Vector3();
                bb.getCenter(cent);
                mesh.position.x -= cent.x;
                mesh.position.y -= cent.y;
                mesh.position.z -= cent.z;
                scene.add(mesh);

                let ix = 0;
                let iy = 0;
                let iz = 0;

                let ax = 0;
                let ay = 0;
                let az = 0;

                const ba = new THREE.Box3();
                ba.setFromObject(mesh);

                //if (useAABB) {
                if (true) {
                    ix = ba.min.x;
                    iy = ba.min.y;
                    iz = ba.min.z;

                    ax = ba.max.x;
                    ay = ba.max.y;
                    az = ba.max.z;
                } else {
                    mesh.geometry.computeBoundingBox();
                    const obb = new OBB().fromBox3(mesh.geometry.boundingBox);
                    console.log(obb);
                    ix = obb.center.x - obb.halfSize.x;
                    iy = obb.center.y - obb.halfSize.y;
                    iz = obb.center.z - obb.halfSize.z;

                    ax = obb.center.x + obb.halfSize.x;
                    ay = obb.center.y + obb.halfSize.y;
                    az = obb.center.z + obb.halfSize.z;
                }

                const red = 0xff0000;
                const green = 0x00ff00;
                const blue = 0x0000ff;

                const lights = [];
                lights[0] = new THREE.PointLight(0xffffff, 1, 0);
                lights[1] = new THREE.PointLight(0xffffff, 1, 0);
                lights[2] = new THREE.PointLight(0xffffff, 1, 0);

                lights[0].position.set(-500, -300, -800);
                lights[1].position.set(300, 500, 800);
                lights[2].position.set(0, 0, -500);

                scene.add(lights[0]);
                scene.add(lights[1]);
                //scene.add(lights[2]);

                const camSize = parseInt((Math.max(Math.max(ax - ix, ay - iy), az - iz) / 2) * 1.2);
                let camera = new THREE.OrthographicCamera(-camSize, camSize, -camSize, camSize, 1000, -1000);

                let controls = new OrbitControls(camera, renderer.domElement);

                camera.up.set(0, -1, 0);
                camera.position.x = 200;
                camera.position.y = -100;
                camera.position.z = -200;
                camera.lookAt(new THREE.Vector3(0, 0, 0));

                const group = new THREE.Group();

                addLine(new Vector3(ix, iy, iz), new Vector3(ax, iy, iz), red, group);
                addLine(new Vector3(ix, iy, az), new Vector3(ax, iy, az), red, group);
                addLine(new Vector3(ix, ay, iz), new Vector3(ax, ay, iz), red, group);
                addLine(new Vector3(ix, ay, az), new Vector3(ax, ay, az), red, group);

                addLine(new Vector3(ix, iy, iz), new Vector3(ix, ay, iz), blue, group);
                addLine(new Vector3(ix, iy, az), new Vector3(ix, ay, az), blue, group);
                addLine(new Vector3(ax, iy, iz), new Vector3(ax, ay, iz), blue, group);
                addLine(new Vector3(ax, iy, az), new Vector3(ax, ay, az), blue, group);

                //addLine(new Vector3(ax - (ax - ix) * 0.25, iy, az), new Vector3(ax - (ax - ix) * 0.25, ay, az), blue, group);

                addLine(new Vector3(ix, iy, iz), new Vector3(ix, iy, az), green, group);
                addLine(new Vector3(ax, iy, iz), new Vector3(ax, iy, az), green, group);
                addLine(new Vector3(ix, ay, iz), new Vector3(ix, ay, az), green, group);
                addLine(new Vector3(ax, ay, iz), new Vector3(ax, ay, az), green, group);

                scene.add(group);

                let animate = function () {
                    requestAnimationFrame(animate);
                    scene.rotation.x += 0.001;
                    scene.rotation.y += 0.001;
                    scene.rotation.z += 0.001;

                    if (requestPreview === file.uuid && !file.preview) {
                        console.log(renderColor.toString(16));
                        scene.rotation.x = 0.0;
                        scene.rotation.y = 0.0;
                        scene.rotation.z = 0.0;

                        group.visible = false;
                        renderer.render(scene, camera);
                        const preview = renderer.domElement.toDataURL();
                        if (preview && preview.startsWith('data:image/png;base64') && preview.length > 100) {
                            group.visible = true;
                            file.preview = preview;
                            setRequestPreview(undefined);
                        }
                    } else {
                        renderer.render(scene, camera);
                    }
                    if (ref.current) {
                        if (height !== ref.current.clientHeight) {
                            setHeight(ref.current.clientHeight);
                        }
                        if (width !== ref.current.clientWidth) {
                            setWidth(ref.current.clientWidth);
                        }
                    }
                };
                animate();
            };
        }

        // === THREE.JS EXAMPLE CODE END ===
    }, [file, renderColor, requestPreview, setRequestPreview, width, height]);

    return (
        <div className="item-preview-window" ref={ref}>
            <canvas id={file.uuid} width="200" height="200" />
        </div>
    );
};

export default PreviewWindow;
