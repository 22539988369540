import React, { useState, useEffect } from 'react';
import Axios from 'axios';

import AdminEditFormula from '../admin/AdminEditFormula';

const AdminEditInfill = ({ setError }) => {
    const [select, setSelect] = useState('main');
    const [name, setName] = useState();
    const [displayName, setDisplayName] = useState();
    const [newName, setNewName] = useState();
    const [infills, setInfills] = useState([]);
    const [editObject, setEditObject] = useState();
    const [formula, setFormula] = useState();
    const [formulaTime, setFormulaTime] = useState();
    const [tooltip, setTooltip] = useState();

    const [showEditFormula, setShowEditFormula] = useState(false);
    const [showEditFormulaTime, setShowEditFormulaTime] = useState(false);
    const [inputVariables, setInputVariables] = useState({});

    const saveChanges = async () => {
        editObject.formula = formula;
        editObject.formulaTime = formulaTime;
        editObject.name = name;
        editObject.displayName = displayName;
        editObject.tooltip = tooltip;

        let token = localStorage.getItem('auth-token');
        try {
            await Axios.post(`${process.env.REACT_APP_SERVER}/admin/saveinfill`, editObject, { headers: { 'x-auth-token': token } });
        } catch (err) {
            err.response.data && err.response.data.msg && setError(err.response.data.msg);
        }
    };

    const AddInfill = async () => {
        if (newName) {
            let token = localStorage.getItem('auth-token');
            const payload = {
                name: newName,
            };
            try {
                const res = await Axios.post(`${process.env.REACT_APP_SERVER}/admin/addinfill`, payload, { headers: { 'x-auth-token': token } });
                if (res.data && res.data.name) {
                    setInfills([...infills, res.data]);
                    setEditObject(res.data);
                    setName(res.data.name);
                }
            } catch (err) {
                err.response.data.msg && setError(err.response.data.msg);
            }
        }
    };

    const deleteEntry = async () => {
        let token = localStorage.getItem('auth-token');
        try {
            await Axios.post(`${process.env.REACT_APP_SERVER}/admin/deleteinfill`, editObject, { headers: { 'x-auth-token': token } });
            const res2 = await Axios.get(`${process.env.REACT_APP_SERVER}/admin/infills/`, { headers: { 'x-auth-token': token } });
            if (res2) {
                setInfills(res2.data.infills);
                setEditObject(undefined);
            }
        } catch (err) {
            err.response.data && err.response.data.msg && setError(err.response.data.msg);
        }
    };

    useEffect(() => {
        const getInfills = async () => {
            try {
                let token = localStorage.getItem('auth-token');
                const res = await Axios.get(`${process.env.REACT_APP_SERVER}/admin/infills/`, { headers: { 'x-auth-token': token } });
                if (res) {
                    setInfills(res.data.infills);
                    setEditObject(undefined);
                }
            } catch (err) {
                err.response.data.msg && setError(err.response.data.msg);
            }
        };
        getInfills();
    }, [setError]);

    const editPrintFormula = () => {
        setInputVariables({
            x: 100,
            y: 100,
            z: 100,
            w: 100,
            h: 100,
            d: 100,
            volume: 1000,
            area: 600,
            amount: 1,
            discount: 0,
            Druckpreis: 100,
        });
        setShowEditFormula(true);
    };

    const editTimeFormula = () => {
        setInputVariables({
            x: 100,
            y: 100,
            z: 100,
            w: 100,
            h: 100,
            d: 100,
            volume: 1000,
            area: 600,
            amount: 1,
            discount: 0,
            printMaxDays: 0,
            procMaxDays: 0,
            infillMaxDays: 0,
            percentSpace: 0,
            Time: 60,
        });
        setShowEditFormulaTime(true);
    };

    return (
        <>
            <AdminEditFormula
                showModal={showEditFormula}
                setShowModal={setShowEditFormula}
                inputVariables={inputVariables}
                setInputVariables={setInputVariables}
                formula={formula}
                setFormula={setFormula}
            />
            <AdminEditFormula
                showModal={showEditFormulaTime}
                setShowModal={setShowEditFormulaTime}
                inputVariables={inputVariables}
                setInputVariables={setInputVariables}
                formula={formulaTime}
                setFormula={setFormulaTime}
            />

            <h1>Infill anlegen</h1>
            <div className="admin-editor-page">
                <div className="admin-edit-column">
                    <ul>
                        {infills &&
                            infills.length > 0 &&
                            infills.map((infill) => (
                                <li key={infill._id}>
                                    <button
                                        className={select === infill.name ? 'admin-nav-button-sel' : 'admin-nav-button'}
                                        onClick={() => {
                                            setEditObject(infill);
                                            setFormula(infill.formula || '');
                                            setFormulaTime(infill.formulaTime || '');
                                            setSelect(infill.name);
                                            setName(infill.name);
                                            setDisplayName(infill.displayName || '');
                                            setTooltip(infill.tooltip || '');
                                        }}
                                    >
                                        {infill.name}
                                    </button>
                                </li>
                            ))}
                    </ul>
                    <input type="text" value={newName} onChange={(e) => setNewName(e.target.value)} />
                    <br />
                    <button className="page-button-action" onClick={AddInfill}>
                        Infill hinzufügen
                    </button>
                </div>

                {editObject ? (
                    <>
                        <div className="admin-edit-column">
                            <input type="text" value={name} onChange={(e) => setName(e.target.value)} />
                            <h4>Anzeigename</h4>
                            <input type="text" value={displayName} onChange={(e) => setDisplayName(e.target.value)} />
                        </div>
                        <div className="admin-edit-column">
                            <br />
                            <h4>Formeln</h4>

                            <button className="page-button-action" onClick={() => editPrintFormula()}>
                                Edit
                            </button>
                            <h4>Produktionszeit</h4>
                            <button className="page-button-action" onClick={() => editTimeFormula()}>
                                Edit
                            </button>
                            <h4>Tooltip Text</h4>
                            <textarea
                                id="ttt-input"
                                rows="3"
                                cols="50"
                                onChange={(e) => setTooltip(e.currentTarget.value)}
                                value={tooltip}
                            ></textarea>
                            <br />
                            <button className="page-button-action" onClick={() => saveChanges()}>
                                Änderungen speichern
                            </button>
                            <button className="admin-delete-button" onClick={() => deleteEntry()}>
                                Löschen
                            </button>
                        </div>
                        <div className="admin-edit-column">
                            <h4>Variablen</h4>
                            <h5>Vom Modell</h5>
                            <label>x = w</label>
                            <br />
                            <label>y = h</label>
                            <br />
                            <label>z = d</label>
                            <br />
                            <label>volume</label>
                            <br />
                            <label>area</label>
                            <br />
                            <h5>Von Auswahl</h5>
                            <label>amount</label>
                            <br />
                            <h5>Von Nutzer</h5>
                            <label>discount</label>
                            <br />
                            <h5>Zu schreiben</h5>
                            <label>Einzelpreis</label>
                            <br />
                            <label>Druckpreis</label>
                            <br />
                        </div>
                    </>
                ) : null}
            </div>
        </>
    );
};

export default AdminEditInfill;
