import React, { useEffect, useState } from 'react';
import { NumericFormat } from 'react-number-format';;

const FileExtraTaskDelivery = ({ task, total, alreadyPacked, inHere, pindex, iindex, onSetAmount, readOnly }) => {
    const [params] = useState(task);
    const [packAmount, setPackAmount] = useState(inHere);
    useEffect(() => {}, []);

    const onSetPackAmount = (amount) => {
        setPackAmount(amount);
        onSetAmount(pindex, iindex, amount);
        /*
        setPackAmount(Math.min(total - alreadyPacked, amount));
        onSetAmount(pindex, iindex, Math.min(total - alreadyPacked, amount));
        */
    };

    return (
        <>
            <div className="delivery-item">
                <div className="row-item-name">
                    <div>
                        <h4>{params.name}</h4>
                    </div>
                </div>

                <div className="item-comment-textbox">
                    {params.description && (
                        <>
                            <p className="paragraph">Beschreibung:</p>
                            <textarea id="file-comment" name="filecomment" rows="15" cols="100" disabled value={params.description}></textarea>
                        </>
                    )}
                </div>
                <div className="vertical-divider"></div>
                <div className="item-selections">
                    <div>
                        {readOnly ? (
                            <b>{packAmount}</b>
                        ) : (
                            <input
                                className="input-projectID"
                                type="number"
                                value={packAmount}
                                onChange={(e) => onSetPackAmount(e.currentTarget.value)}
                            />
                        )}
                        /{total - alreadyPacked}
                    </div>
                </div>
            </div>
        </>
    );
};

export default FileExtraTaskDelivery;
