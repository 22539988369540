import React from 'react';
import ReactTooltip from 'react-tooltip';
import UserEdit from '../../img/user-edit.svg';
import UserDelete from '../../img/user-delete.svg';
import MainAddress from '../../img/main-address.svg';

const AdressItem = ({ adress, deleteAdress, editAdress, makeMain }) => {
    const onDelete = async () => {
        deleteAdress(adress._id);
    };

    const onEdit = async () => {
        editAdress(adress._id);
    };

    const onMakeMain = async () => {
        makeMain(adress._id);
    };

    return (
        <div className="adress-item">
            <div className="address-item-column-1">
            <label className="address-label"><strong>Name</strong></label>
                <br />
                {adress.faName}
                <br />
                {adress.dpt}
                <br />
                {adress.lastName}, {adress.firstName}
            </div>
            <div className="address-item-column-2">
            <label className="address-label"><strong>Adresse</strong></label>
                <br />
                {adress.street}
                <br />
                {adress.zip} {adress.city}
                <br />
                {adress.country?.name}
                <br />
            </div>
            <div className="address-item-column-3">
                {adress.isInvoiceAdr ? (
                    <>
                        Rechnungsadresse
                        <br />
                    </>
                ) : null}
                {adress.isDeliveryAdr ? (
                    <>
                        Lieferadresse
                        <br />
                    </>
                ) : null}
            </div>

            <div id="address-button-column">
                {!adress.isMainAdr ? (
                    <>
                    <div id="address-button"><img src={MainAddress} alt="Hauptadresse" width={35}
                    onClick={onMakeMain}
                    data-tip
                    data-for="buttonTip1"
                    ></img>
                        <ReactTooltip
                            id="buttonTip1"
                            place="top"
                            effect="solid"
                        >
                            Als Hauptadresse markieren.
                        </ReactTooltip>
                    </div>
                    </>
                ) : null}
                    <div id="address-button"><img src={UserEdit} alt="Bearbeiten" width={35}
                    onClick={onEdit}
                    data-tip
                    data-for="buttonTip2"
                    ></img>
                <ReactTooltip id="buttonTip2" place="top" effect="solid">
                    Bearbeiten
                </ReactTooltip>
                    </div>
                    <div id="address-button"><img src={UserDelete} alt="Löschen" width={35}
                    onClick={onDelete}
                    data-tip
                    data-for="buttonTip3"
                    ></img>
                <ReactTooltip id="buttonTip3" place="top" effect="solid">
                    L&ouml;schen
                </ReactTooltip>
                    </div>   
            </div>
        </div>
    );
};

export default AdressItem;
