import React, { useEffect, useState } from 'react';
import Axios from 'axios';

const FileDocument = ({ file, position, deleteFile }) => {
    const [params, setParams] = useState(file.params);

    useEffect(() => {
        const onUpload = async () => {
            const formData = new FormData();
            formData.append('file', file);
            formData.append('uuid', params.uuid);
            try {
                const res = await Axios.post(`${process.env.REACT_APP_SERVER}/files/uploadadditional`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
                file.params = res.data;
                console.log(res.data);
                setParams(res.data);
            } catch (err) {
                if (err?.response?.status === 500) {
                    console.log('Error 500');
                } else {
                    console.log(err?.response?.data?.msg);
                }
            }
        };

        if (params.fileName) {
            const _settings = {};
            Object.assign(_settings, file.settings);
            file.settings = _settings;
        } else {
            onUpload();
        }
    }, [file, file.uuid]);

    const onDelete = () => {
        deleteFile(params.uuid);
    };

    return (
        <div>
            <div className={`item-container`}>
                <div className="row-item-name">
                    <div>
                        <p className="round-border">{position + 1}</p>
                    </div>
                    <div>
                        <h4>{file.name}</h4>
                    </div>
                </div>

                <div className="item-actions">
                    <div>
                        <button className="position-remove-button" onClick={onDelete}>
                            Position entfernen
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FileDocument;
