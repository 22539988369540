import React, { useState, useEffect } from 'react';
import Axios from 'axios';

const AdminEditOrderStatus = ({ setError }) => {
    const [orderStati, setOrderStati] = useState([]);
    const [editObject, setEditObject] = useState();

    const AddOrderStatus = async () => {
        let token = localStorage.getItem('auth-token');

        const payload = {
            name: `os_new_${orderStati.length}`,
            displayName: 'new Status',
            listIndex: orderStati.length,
            requiresConfirm: false,
            followedBy: [],
        };
        try {
            const res = await Axios.post(`${process.env.REACT_APP_SERVER}/admin/addorderstatus`, payload, { headers: { 'x-auth-token': token } });
            if (res.data && res.data.name) {
                setOrderStati([...orderStati, res.data]);
                setEditObject(res.data);
            }
        } catch (err) {
            err.response.data && err.response.data.msg && setError(err.response.data.msg);
        }
    };

    const onSave = async () => {
        let token = localStorage.getItem('auth-token');

        const payload = {
            orderStati: orderStati,
        };
        try {
            const res = await Axios.post(`${process.env.REACT_APP_SERVER}/admin/saveorderstati`, payload, { headers: { 'x-auth-token': token } });
        } catch (err) {
            err.response.data && err.response.data.msg && setError(err.response.data.msg);
        }
    };

    const setName = (text, id) => {
        let tmp = [...orderStati];
        const index = tmp.findIndex((obj) => obj._id === id);
        tmp[index].name = text;
        setOrderStati(tmp);
    };

    const setDisplayName = (text, id) => {
        let tmp = [...orderStati];
        const index = tmp.findIndex((obj) => obj._id === id);
        tmp[index].displayName = text;
        setOrderStati(tmp);
    };

    const setRequiresConfirm = (value, id) => {
        let tmp = [...orderStati];
        const index = tmp.findIndex((obj) => obj._id === id);
        tmp[index].requiresConfirm = value;
        setOrderStati(tmp);
    };

    const setEmailTopicCustomer = (value, id) => {
        let tmp = [...orderStati];
        const index = tmp.findIndex((obj) => obj._id === id);
        tmp[index].emailTopicCustomer = value;
        setOrderStati(tmp);
    };
    const setEmailTextCustomer = (value, id) => {
        let tmp = [...orderStati];
        const index = tmp.findIndex((obj) => obj._id === id);
        tmp[index].emailTextCustomer = value;
        setOrderStati(tmp);
    };
    const setSendEmailCustomer = (value, id) => {
        let tmp = [...orderStati];
        const index = tmp.findIndex((obj) => obj._id === id);
        tmp[index].sendEmailCustomer = value;
        setOrderStati(tmp);
    };

    const setEmailTopicAdmin = (value, id) => {
        let tmp = [...orderStati];
        const index = tmp.findIndex((obj) => obj._id === id);
        tmp[index].emailTopicAdmin = value;
        setOrderStati(tmp);
    };
    const setEmailTextAdmin = (value, id) => {
        let tmp = [...orderStati];
        const index = tmp.findIndex((obj) => obj._id === id);
        tmp[index].emailTextAdmin = value;
        setOrderStati(tmp);
    };
    const setSendEmailAdmin = (value, id) => {
        let tmp = [...orderStati];
        const index = tmp.findIndex((obj) => obj._id === id);
        tmp[index].sendEmailAdmin = value;
        setOrderStati(tmp);
    };
    const setEmailAdrAdmin = (value, id) => {
        let tmp = [...orderStati];
        const index = tmp.findIndex((obj) => obj._id === id);
        tmp[index].emailAdrAdmin = value;
        setOrderStati(tmp);
    };

    const setMayFollow = (value, parentID, childID) => {
        let tmp = [...orderStati];
        const index = tmp.findIndex((obj) => obj._id === parentID);
        let followedBy = tmp[index].followedBy || [];
        if (value) {
            if (!followedBy.includes(childID)) {
                followedBy.push(childID);
            }
        } else {
            if (followedBy.includes(childID)) {
                let indx = followedBy.indexOf(childID);
                if (indx !== -1) {
                    followedBy.splice(indx, 1);
                }
            }
        }
        tmp[index].followedBy = followedBy;
        setOrderStati(tmp);
    };

    const onUp = () => {};

    const onDown = () => {};

    useEffect(() => {
        const getOrderStatuss = async () => {
            try {
                let token = localStorage.getItem('auth-token');
                const res = await Axios.get(`${process.env.REACT_APP_SERVER}/admin/orderstati/`, { headers: { 'x-auth-token': token } });
                if (res) {
                    let stati = res.data.OrderStati;
                    if (stati.length > 0) {
                        setOrderStati(stati);
                    }
                }
            } catch (err) {
                err?.response?.data?.msg && setError(err?.response?.data?.msg);
            }
        };

        getOrderStatuss();
    }, [setError]);

    return (
        <>
            
            <div>
                <h1>Orderstatus anlegen</h1>
                <ul>
                    {orderStati &&
                        orderStati.length > 0 &&
                        orderStati.map((OrderStatus, index) => (
                            <li key={OrderStatus._id}>
                                <div className="orderStatusContainer">
                                    <div className="orderStatusNames">
                                        <h3>Internal name</h3>
                                        <input type="text" value={OrderStatus.name} onChange={(e) => setName(e.target.value, OrderStatus._id)} />
                                        {/*OrderStatus.listIndex*/}
                                        <br />
                                        <h3>Display name</h3>
                                        <input
                                            type="text"
                                            value={OrderStatus.displayName}
                                            onChange={(e) => setDisplayName(e.target.value, OrderStatus._id)}
                                        />
                                        <br />
                                        <br />
                                        <label htmlFor={OrderStatus._id + 'cb'}>Requires confirmation: </label>

                                        <input
                                            type="checkbox"
                                            id={OrderStatus._id + 'cb'}
                                            checked={OrderStatus.requiresConfirm}
                                            onChange={(e) => setRequiresConfirm(e.target.checked, OrderStatus._id)}
                                        />
                                    </div>
                                    <div className="orderStatusPermissions">
                                        <h3>Erlaubt:</h3>
                                        {orderStati.map((OS) => (
                                            <>
                                                {OS._id !== OrderStatus._id ? (
                                                    <>
                                                        <input
                                                            type="checkbox"
                                                            id={OrderStatus._id + 'cbmf' + OS._id}
                                                            checked={OrderStatus.followedBy?.includes(OS._id)}
                                                            onChange={(e) => setMayFollow(e.target.checked, OrderStatus._id, OS._id)}
                                                        />
                                                        <label htmlFor={OrderStatus._id + 'cbmf' + OS._id}>{OS.name}: </label>
                                                        <br />
                                                    </>
                                                ) : null}
                                            </>
                                        ))}
                                    </div>
                                    <div className="orderStatusCustomer">
                                        <input
                                            className="admin-le-subject"
                                            type="text"
                                            value={OrderStatus.emailTopicCustomer}
                                            onChange={(e) => setEmailTopicCustomer(e.target.value, OrderStatus._id)}
                                        />
                                        <br />
                                        <textarea
                                            className="admin-le-subject"
                                            rows="10"
                                            
                                            onChange={(e) => setEmailTextCustomer(e.currentTarget.value, OrderStatus._id)}
                                            value={OrderStatus.emailTextCustomer}
                                        ></textarea>

                                        <br />
                                        <label htmlFor="emailCB1C">
                                            An Kunden
                                            <input
                                                className="admin-cb-email"
                                                type="checkbox"
                                                id="emailCB1C"
                                                checked={OrderStatus.sendEmailCustomer}
                                                onChange={(e) => {
                                                    setSendEmailCustomer(e.target.checked, OrderStatus._id);
                                                }}
                                            />
                                        </label>
                                    </div>
                                    <div className="orderStatusAdmin">
                                        <input
                                            className="admin-le-subject"
                                            type="text"
                                            value={OrderStatus.emailTopicAdmin}
                                            onChange={(e) => setEmailTopicAdmin(e.target.value, OrderStatus._id)}
                                        />
                                        <br />
                                        <textarea
                                            className="admin-le-subject"
                                            rows="10"
                                            
                                            onChange={(e) => setEmailTextAdmin(e.currentTarget.value, OrderStatus._id)}
                                            value={OrderStatus.emailTextAdmin}
                                        ></textarea>

                                        <br />
                                        <label htmlFor="emailCB1C">
                                            An Bearbeiter
                                            <input
                                                className="admin-cb-email"
                                                type="checkbox"
                                                id="emailCB1C"
                                                checked={OrderStatus.sendEmailAdmin}
                                                onChange={(e) => {
                                                    setSendEmailAdmin(e.target.checked, OrderStatus._id);
                                                }}
                                            />
                                        </label>
                                        <input
                                            className="admin-le-copyemail"
                                            type="text"
                                            value={OrderStatus.emailAdrAdmin}
                                            onChange={(e) => setEmailAdrAdmin(e.target.value, OrderStatus._id)}
                                        />
                                    </div>

                                    <div className="orderStatusButton">
                                        {index > 0 ? (
                                            <>
                                                <button className="page-button-action" onClick={onUp}>
                                                    ^
                                                </button>
                                                <br />
                                            </>
                                        ) : null}
                                        {index < orderStati.length - 1 ? (
                                            <>
                                                <button className="page-button-action" onClick={onDown}>
                                                    v
                                                </button>
                                                <br />
                                            </>
                                        ) : null}
                                    </div>
                                </div>
                            </li>
                        ))}
                </ul>

                <button className="page-button-action" onClick={AddOrderStatus}>
                    Status hinzufügen
                </button>
                <button className="page-button-action" onClick={onSave}>
                    Speichern
                </button>
            </div>
        </>
    );
};

export default AdminEditOrderStatus;
