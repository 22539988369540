import React, { useContext } from 'react';
import UserContext from '../../context/UserContext';
import ControlContext from '../../context/ControlContext';
import FileContext from '../../context/FileContext';

const AdditionalInfo = ({ readOnly, projectID, setProjectID, inquiryID, setInquiryID, status, updateIDs, isOrder }) => {
    const { userData } = useContext(UserContext);
    const { control, setControl } = useContext(ControlContext);
    const { files } = useContext(FileContext);

    const onProjectIDChange = (text) => {
        if (!readOnly) {
            setControl({
                ...control,
                projectID: text,
            });
        } else {
            setProjectID(text);
        }
    };

    const onOfferIDChange = (text) => {
        if (!readOnly) {
            setControl({
                ...control,
                inquiryID: text,
            });
        } else {
            setInquiryID(text);
        }
    };

    const onCommentChange = (text) => {
        if (!readOnly) {
            setControl({
                ...control,
                comment: text,
            });
        }
    };

    const onUpdateIDs = () => {
        updateIDs();
    };

    return (
        <div id="additional-info-container">
            <div className="additional-info">
                {(userData.user && files.length > 0) || readOnly ? (
                    <>
                        <div id="field-projectID">
                            <label>Projektbezeichnung:</label>

                            {readOnly ? (
                                <>
                                    <input
                                        className="input-projectID"
                                        type="text"
                                        value={projectID}
                                        onChange={(e) => onProjectIDChange(e.currentTarget.value)}
                                    />
                                    <br />
                                </>
                            ) : (
                                <input
                                    className="input-projectID"
                                    type="text"
                                    value={control.projectID}
                                    onChange={(e) => onProjectIDChange(e.currentTarget.value)}
                                />
                            )}
                        </div>
                        <div id="field-userOfferID">
                            {isOrder ? <label>Bestellnummer:</label> : <label>Anfragenummer:</label>}
                            {readOnly ? (
                                <>
                                    <input
                                        className="input-userOfferID"
                                        type="text"
                                        value={inquiryID}
                                        onChange={(e) => onOfferIDChange(e.currentTarget.value)}
                                    />
                                    <br />
                                </>
                            ) : (
                                <input
                                    className="input-userOfferID"
                                    type="text"
                                    value={control.inquiryID}
                                    onChange={(e) => onOfferIDChange(e.currentTarget.value)}
                                />
                            )}
                        </div>
                        {readOnly ? (
                            <button className="page-button-grey" onClick={onUpdateIDs}>
                                Speichern
                            </button>
                        ) : null}


                        {status && <div id="field-orderStatus">{readOnly ? <h4>Status: {status}</h4> : null}</div>}
                        
                    </>
                ) : null}
            </div>
        </div>
    );
};

export default AdditionalInfo;
