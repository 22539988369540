import React, { Component } from 'react';

class AutoButton extends Component {
    constructor(props) {
        super(props);
        this.changeHandler = this.changeHandler.bind(this);
        this.interval = null;
        this.intervalCountMax = props.count;
        this.intervalCount = this.intervalCountMax;
    }

    changeHandler(e) {
        //console.log(e);
    }

    componentDidMount() {
        this.interval = setInterval(() => {
            //console.log('This will run every second!');
            this.intervalCount = this.intervalCount - 1;
            if (this.intervalCount <= 0) {
                this.intervalCount = this.intervalCountMax;
                this.props.onClick();
            }
            this.forceUpdate();
        }, 1000);
    }

    componentWillUnmount() {
        // clearing the intervals
        if (this.interval) clearInterval(this.interval);
    }

    clickHandler() {
        this.intervalCount = this.intervalCountMax;
        this.props.onClick();
    }

    render() {
        return (
            <button className={this.props.className} onClick={() => this.clickHandler()}>
                {this.props.text} ({this.intervalCount})
            </button>
        );
    }
}

export default AutoButton;
