import React, { useState, useContext, useEffect } from 'react';
import Axios from 'axios';
import Modal from 'react-modal';
import { Link, useHistory } from 'react-router-dom';
import ErrorNotice from '../misc/ErrorNotice';
import ControlContext from '../../context/ControlContext';

export default function Register({ showModal, setShowModal, setShowLogin }) {
    const { control, setControl } = useContext(ControlContext);
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [passwordCheck, setPasswordCheck] = useState();
    const [firstName, setFirstName] = useState();
    const [lastName, setLastName] = useState();
    const [salut, setSalut] = useState('');
    const [error, setError] = useState();

    const [faName, setFaName] = useState();
    const [vatID, setVatID] = useState();
    const [street, setStreet] = useState();
    const [street2, setStreet2] = useState();
    const [additionalInfo, setAdditionalInfo] = useState();
    const [zip, setZip] = useState();
    const [city, setCity] = useState();
    const [country, setCountry] = useState();
    const [countries, setCountries] = useState([]);
    const [actionCode, setActionCode] = useState();

    const onCountryChange = (_country) => {
        setCountry(_country);
    };

    const modalClose = () => {
        setShowModal(false);
    };

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            position: 'absolute',
            transform: 'translate(-50%, -50%)',
            width: '90%',
            maxWidth: '700px',
            height: '60%',
        },
    };

    const history = useHistory();

    const submit = async (e) => {
        e.preventDefault();
        if (password.length < 8) {
            setError('Bitte wählen Sie ein Passwort mit mindestens 8 Zeichen');
            return;
        }
        if (password !== passwordCheck) {
            setError('Passworteingaben verschieden');
            return;
        }
        try {
            const newUser = {
                email,
                password,
                passwordCheck,
                faName,
                vatID,
                firstName,
                lastName,
                salut,
                street,
                street2,
                additionalInfo,
                zip,
                city,
                country,
                actionCode
            };
            await Axios.post(`${process.env.REACT_APP_SERVER}/users/register`, newUser);
            setShowModal(false);
            history.push('/registered');
        } catch (err) {
            err.response.data.msg && setError(err.response.data.msg);
        }
    };

    useEffect(() => {
        if (control.maintenance) {
            history.push('/maintenance');
        }
        const getCountries = async () => {
            try {
                let token = localStorage.getItem('auth-token');

                const cres = await Axios.get(`${process.env.REACT_APP_SERVER}/admin/countries/`, { headers: { 'x-auth-token': token } });
                if (cres) {
                    //console.log(cres.data.countries);
                    setCountries(cres.data.countries);
                    setCountry(cres.data.countries?.[0]?._id);
                }
            } catch (err) {
                console.log(err);
            }
        };
        getCountries();
    }, []);

    return (
        <Modal isOpen={showModal} onRequestClose={(e) => modalClose(e)} contentLabel={'modalLabel'} style={customStyles}>
            <div className="modalHeader">
                <div>
                    <h2>Registrierung</h2>
                </div>
                <div>
                    <button id="buttonX" onClick={() => modalClose()}>
                        &#10006;
                    </button>
                </div>
            </div>
            <div className="modalBody">
                {error && <ErrorNotice message={error} clearError={() => setError(undefined)} />}
                <form className="form" onSubmit={submit}>
                    <div className="form-div100">
                        <label htmlFor="register-email">Email*</label>
                        <br />
                        <input id="register-email" type="email" onChange={(e) => setEmail(e.target.value)} required />
                    </div>
                    <div className="form-div100">
                        <label htmlFor="register-password">Password*</label>
                        <br />
                        <input id="register-password" type="password" onChange={(e) => setPassword(e.target.value)} required />
                    </div>
                    <div className="form-div100">
                        <label htmlFor="verify-password">Verify Password*</label>
                        <input
                            type="password"
                            id="verify-password"
                            placeholder="Verify password"
                            onChange={(e) => setPasswordCheck(e.target.value)}
                            required
                        />
                    </div>
                    <div className="form-div100">
                        <label htmlFor="register-actionCode">Aktionscode</label>
                        <br />
                        <input id="register-actionCode" type="text" onChange={(e) => setActionCode(e.target.value)} />
                    </div>
                    <div className="form-div100">
                        <label className="adressLabel">Firmenname</label>
                        <br />
                        <input id="adr1" type="text" value={faName} onChange={(e) => setFaName(e.target.value)} placeholder="Firmenname" />
                    </div>
                    {faName?.length > 0 ? (
                        <div className="form-div80">
                            <label className="adressLabel">USt. ID*</label>
                            <br />
                            <input id="adr10" type="text" value={vatID} onChange={(e) => setVatID(e.target.value)} placeholder="USt. ID" required />
                        </div>
                    ) : null}

                    <div className="form-div100">
                        <label htmlFor="account-salut">Anrede</label>
                        <br />
                        <input id="adr8" type="text" value={salut} onChange={(e) => setSalut(e.target.value)} placeholder="Anrede" required />
                    </div>
                    <div className="form-div50">
                        <label htmlFor="account-name">Vorname*</label>
                        <br />
                        <input id="account-name" type="text" value={firstName} onChange={(e) => setFirstName(e.target.value)} required />
                    </div>
                    <div className="form-div50">
                        <label htmlFor="account-lastname">Name*</label>
                        <br />
                        <input id="account-lastname" type="text" value={lastName} onChange={(e) => setLastName(e.target.value)} required />
                    </div>
                    <div className="form-div80">
                        <label className="adressLabel">Straße*</label>
                        <br />
                        <input id="adr5" type="text" value={street} onChange={(e) => setStreet(e.target.value)} placeholder="Straße" required />
                    </div>
                    <div className="form-div20">
                        <label className="adressLabel">Hausnummer*</label>
                        <br />
                        <input
                            id="adr5b"
                            type="text"
                            value={street2}
                            onChange={(e) => setStreet2(e.target.value)}
                            placeholder="Hausnummer"
                            required
                        />
                    </div>
                    <div className="form-div100">
                        <label className="adressLabel">Adresszusatz</label>
                        <br />
                        <input
                            id="adr5a"
                            type="text"
                            value={additionalInfo}
                            onChange={(e) => setAdditionalInfo(e.target.value)}
                            placeholder="Adresszusatz"
                        />
                    </div>
                    <div className="form-div20">
                        <label className="adressLabel">Postleitzahl*</label>
                        <br />
                        <input id="adr6" type="text" value={zip} onChange={(e) => setZip(e.target.value)} placeholder="Postleitzahl" required />
                    </div>
                    <div className="form-div80">
                        <label className="adressLabel">Ort*</label>
                        <br />
                        <input id="adr7" type="text" value={city} onChange={(e) => setCity(e.target.value)} placeholder="Ort" required />
                    </div>
                    <div className="form-div100">
                        <label className="adressLabel">Land</label>
                        <br />
                        <select id="adr8" className="country-select" onChange={(e) => onCountryChange(e.currentTarget.value)} value={country}>
                            {countries.map((item) => (
                                <option key={item._id} value={item._id}>
                                    {item.name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <button className="page-button-grey" type="submit">
                        Registrieren
                    </button>
                </form>
                <div id="register-requestpw">
                    <br />
                    Sie haben bereits einen Account?&nbsp;
                    <Link
                        onClick={() => {
                            setShowModal(false);
                            setShowLogin(true);
                        }}
                        style={{ textDecoration: 'underline' }}
                    >
                        Hier
                    </Link>
                    &nbsp;klicken.
                </div>
            </div>
        </Modal>
    );
}
