import React, { useEffect, useState, useContext } from 'react';
import Axios from 'axios';
import UserContext from '../../context/UserContext';
import { NumericFormat } from 'react-number-format';;

const FileTaskOfferAdmin = ({ task, position, updateTask, paramsComplete }) => {
    const { userData } = useContext(UserContext);
    const [params, setParams] = useState(task);
    const [prices, setPrices] = useState(task.prices);
    const [approved, setApproved] = useState(task.approved);
    useEffect(() => {
        if (prices.length > 0) {
            params.selectedPrice = prices[0];
            onPriceSelect(prices[0], 0);
        }
    }, []);

    const onPriceChange = (e, index) => {
        let pr = prices[index];
        if (pr.price !== e.currentTarget.value) {
            pr.price = e.currentTarget.value;
            setPrices([...prices.slice(0, index), pr, ...prices.slice(index + 1)]);
        }
    };

    const onTimeChange = (e, index) => {
        let pr = prices[index];
        if (pr.time !== e.currentTarget.value) {
            pr.time = e.currentTarget.value;
            setPrices([...prices.slice(0, index), pr, ...prices.slice(index + 1)]);
        }
    };

    const onAmountChange = (e, index) => {
        let pr = prices[index];
        if (pr.amount !== e.currentTarget.value) {
            pr.amount = e.currentTarget.value;
            setPrices([...prices.slice(0, index), pr, ...prices.slice(index + 1)]);
        }
    };

    const onAmountDelete = (e, index) => {
        if (prices.length > 1) {
            setPrices([...prices.slice(0, index), ...prices.slice(index + 1)]);
        }
    };

    const onAmountAdd = (e) => {
        setPrices([...prices, { amount: 1, price: undefined }]);
    };

    useEffect(() => {
        setParams({ ...params, prices: [...prices] });
    }, [prices]);

    useEffect(() => {
        setParams({ ...params, approved: approved });
    }, [approved]);

    const onDescriptionChange = (e) => {
        setParams({ ...params, description: e });
    };

    const onPriceSelect = (value, index) => {
        let newPrices = [...prices];
        for (var i = 0; i < newPrices.length; ++i) {
            if (i === index) {
                newPrices[i].selected = true;
                setParams({ ...params, selectedPrice: newPrices[i] });
            } else {
                newPrices[i].selected = false;
            }
        }
        setPrices([...newPrices]);
    };

    useEffect(() => {
        if (updateTask) updateTask(params);
        if (paramsComplete) paramsComplete();
    }, [params]);

    return (
        <div id="item-element-wrapper">
            <div className={`item-container`}>
                <div className="item-preview-details-wrapper">
                        
                        <div className="item-position-number">
                                        {position}
                        </div>

                    <div className="item-details-row">    
                        
                        <div></div>
                        <div className="item-name">
                            {params.name}
                        </div>
                    

                        <div className="item-comment-textbox">
                            <p className="paragraph">Beschreibung:</p>
                            <textarea
                                id="file-comment"
                                name="filecomment"
                                rows="15"
                                cols="100"
                                onChange={(e) => onDescriptionChange(e.currentTarget.value)}
                                value={params.description}
                            ></textarea>
                            <p className="paragraph">
                                Bitte beschreiben Sie hier die gew&uuml;nschte Leistung. Sie k&ouml;nnen auch zus&auml;tzliche technische Dokumente im
                                PDF-Format hochladen.
                                <br /> Die Preise f&uuml;r diese Leistung k&ouml;nnen nicht automatisch ermittelt werden. Sie werden per Email benachrichtigt,
                                <br />
                                wenn ein Mitarbeiter Ihre Anfrage bearbeitet hat und k&ouml;nnen Ihr Angebot hier einsehen.
                            </p>
                        </div>
                    
                        <div>
                            <table id="item-prices-table">
                                <>
                                    {userData.rights?.includes('admin') ? (
                                        <tbody>
                                            <tr>
                                                <th />
                                                <th>Anzahl</th>
                                                <th>Einzelpreis</th>
                                                <th>Gesamtpreis</th>
                                                <th>Tage</th>
                                            </tr>

                                            {prices
                                                ? prices.map((pr, index) => (
                                                    <tr key={index}>
                                                        <td>
                                                            {prices.length > 1 && index > 0 ? (
                                                                <button className="delete-amount-button" onClick={(e) => onAmountDelete(e, index)}>
                                                                    -
                                                                </button>
                                                            ) : null}
                                                        </td>

                                                        <td>
                                                            <input
                                                                className="amount-input"
                                                                type="number"
                                                                value={pr.amount}
                                                                disabled={index === 0}
                                                                onChange={(e) => onAmountChange(e, index)}
                                                            />
                                                        </td>

                                                        <td>
                                                            <input
                                                                className="price-input"
                                                                type="number"
                                                                value={parseFloat(pr.price)}
                                                                onChange={(e) => onPriceChange(e, index)}
                                                            />
                                                        </td>
                                                        <td>
                                                            {' '}
                                                            <NumericFormat
                                                                value={parseFloat(pr.price) * parseInt(pr.amount)}
                                                                displayType={'text'}
                                                                prefix={''}
                                                                suffix={' €'}
                                                                decimalScale={2}
                                                                fixedDecimalScale={true}
                                                                thousandSeparator={'.'}
                                                                decimalSeparator={','}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                className="price-input"
                                                                type="number"
                                                                value={parseFloat(pr.time)}
                                                                onChange={(e) => onTimeChange(e, index)}
                                                            />
                                                        </td>
                                                    </tr>
                                                ))
                                                : null}
                                            <tr>
                                                <td>
                                                    <button className="add-amount-button" onClick={(e) => onAmountAdd(e)}>
                                                        +
                                                    </button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    ) : (
                                        <tbody>
                                            <tr>
                                                <th>Anzahl</th>
                                                <th>Einzelpreis</th>
                                                <th>Gesamtpreis</th>
                                            </tr>
                                            {prices
                                                ? prices.map((pr, index) => (
                                                    <tr key={index}>
                                                        <td>{pr.amount}</td>
                                                        <td>
                                                            <NumericFormat
                                                                value={parseFloat(pr.price)}
                                                                displayType={'text'}
                                                                prefix={''}
                                                                suffix={' €'}
                                                                decimalScale={2}
                                                                fixedDecimalScale={true}
                                                                thousandSeparator={'.'}
                                                                decimalSeparator={','}
                                                            />
                                                        </td>
                                                        <td>
                                                            <NumericFormat
                                                                value={parseFloat(pr.price) * parseInt(pr.amount)}
                                                                displayType={'text'}
                                                                prefix={''}
                                                                suffix={' €'}
                                                                decimalScale={2}
                                                                fixedDecimalScale={true}
                                                                thousandSeparator={'.'}
                                                                decimalSeparator={','}
                                                            />
                                                        </td>
                                                    </tr>
                                                ))
                                                : null}
                                        </tbody>
                                    )}
                                </>
                            </table>
                        </div>
                    </div>
                </div> 

                <div className="item-actions">
                    <div className="item-comment-label">
                        <label htmlFor={params.uuid + 'approved'}>Freigegeben: </label>

                        <input
                            type="checkbox"
                            id={params.uuid + 'approved'}
                            name="approved"
                            checked={approved}
                            onChange={(e) => setApproved(e.target.checked)}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FileTaskOfferAdmin;
