import React, { useState, useEffect } from 'react';
import Axios from 'axios';

import AdminEditFormula from '../admin/AdminEditFormula';

const AdminEditProcessing = ({ setError }) => {
    const [select, setSelect] = useState('main');
    const [name, setName] = useState();
    const [displayName, setDisplayName] = useState();
    const [newName, setNewName] = useState();
    const [processings, setProcessings] = useState([]);
    const [editObject, setEditObject] = useState();
    const [formula, setFormula] = useState();
    const [formulaTime, setFormulaTime] = useState();
    const [tooltip, setTooltip] = useState();
    const [minx, setMinx] = useState();
    const [miny, setMiny] = useState();
    const [minz, setMinz] = useState();
    const [maxx, setMaxx] = useState();
    const [maxy, setMaxy] = useState();
    const [maxz, setMaxz] = useState();

    const [showEditFormula, setShowEditFormula] = useState(false);
    const [showEditFormulaTime, setShowEditFormulaTime] = useState(false);
    const [inputVariables, setInputVariables] = useState({});

    const saveChanges = async () => {
        editObject.formula = formula;
        editObject.formulaTime = formulaTime;
        editObject.name = name;
        editObject.displayName = displayName;
        editObject.tooltip = tooltip;
        editObject.minx = minx;
        editObject.miny = miny;
        editObject.minz = minz;
        editObject.maxx = maxx;
        editObject.maxy = maxy;
        editObject.maxz = maxz;
        let token = localStorage.getItem('auth-token');
        try {
            await Axios.post(`${process.env.REACT_APP_SERVER}/admin/saveprocessing`, editObject, { headers: { 'x-auth-token': token } });
        } catch (err) {
            err.response.data && err.response.data.msg && setError(err.response.data.msg);
        }
    };

    const AddProcessing = async () => {
        if (newName) {
            let token = localStorage.getItem('auth-token');
            const payload = {
                name: newName,
            };
            try {
                const res = await Axios.post(`${process.env.REACT_APP_SERVER}/admin/addprocessing`, payload, { headers: { 'x-auth-token': token } });
                if (res.data && res.data.name) {
                    setProcessings([...processings, res.data]);
                    setEditObject(res.data);
                    setName(res.data.name);
                }
            } catch (err) {
                err.response.data && err.response.data.msg && setError(err.response.data.msg);
            }
        }
    };

    const deleteEntry = async () => {
        let token = localStorage.getItem('auth-token');
        try {
            await Axios.post(`${process.env.REACT_APP_SERVER}/admin/deleteprocessing`, editObject, { headers: { 'x-auth-token': token } });
            const res2 = await Axios.get(`${process.env.REACT_APP_SERVER}/admin/processings/`, { headers: { 'x-auth-token': token } });
            if (res2) {
                setProcessings(res2.data.processings);
                setEditObject(undefined);
            }
        } catch (err) {
            err.response.data && err.response.data.msg && setError(err.response.data.msg);
        }
    };

    useEffect(() => {
        const getProcessings = async () => {
            try {
                let token = localStorage.getItem('auth-token');
                const res = await Axios.get(`${process.env.REACT_APP_SERVER}/admin/processings/`, { headers: { 'x-auth-token': token } });
                if (res) {
                    setProcessings(res.data.processings);
                    setEditObject(undefined);
                }
            } catch (err) {
                err.response.data.msg && setError(err.response.data.msg);
            }
        };

        getProcessings();
    }, [setError]);

    const editPrintFormula = () => {
        setInputVariables({
            x: 100,
            y: 100,
            z: 100,
            w: 100,
            h: 100,
            d: 100,
            volume: 1000,
            area: 600,
            minx: minx,
            miny: miny,
            minz: minz,
            maxx: maxx,
            maxy: maxy,
            maxz: maxz,
            amount: 1,
            discount: 0,
        });
        setShowEditFormula(true);
    };

    const editTimeFormula = () => {
        setInputVariables({
            x: 100,
            y: 100,
            z: 100,
            w: 100,
            h: 100,
            d: 100,
            volume: 1000,
            area: 600,
            minx: minx,
            miny: miny,
            minz: minz,
            maxx: maxx,
            maxy: maxy,
            maxz: maxz,
            amount: 1,
            discount: 0,
            printMaxDays: 0,
            procMaxDays: 0,
            colorMaxDays: 0,
            percentSpace: 0,
        });
        setShowEditFormulaTime(true);
    };

    return (
        <>
            <AdminEditFormula
                showModal={showEditFormula}
                setShowModal={setShowEditFormula}
                inputVariables={inputVariables}
                setInputVariables={setInputVariables}
                formula={formula}
                setFormula={setFormula}
            />
            <AdminEditFormula
                showModal={showEditFormulaTime}
                setShowModal={setShowEditFormulaTime}
                inputVariables={inputVariables}
                setInputVariables={setInputVariables}
                formula={formulaTime}
                setFormula={setFormulaTime}
            />

            <h1>Nachbeaarbeitung anlegen</h1>
            <div className="admin-editor-page">
                <div className="admin-edit-column">
                    <ul>
                        {processings &&
                            processings.length > 0 &&
                            processings.map((processing) => (
                                <li key={processing._id}>
                                    <button
                                        className={select === processing.name ? 'admin-nav-button-sel' : 'admin-nav-button'}
                                        onClick={() => {
                                            setEditObject(processing);
                                            setFormula(processing.formula || '');
                                            setFormulaTime(processing.formulaTime || '');
                                            setSelect(processing.name);
                                            setName(processing.name);
                                            setDisplayName(processing.displayName || '');
                                            setTooltip(processing.tooltip || '');
                                            setMinx(processing.minx);
                                            setMiny(processing.miny);
                                            setMinz(processing.minz);
                                            setMaxx(processing.maxx);
                                            setMaxy(processing.maxy);
                                            setMaxz(processing.maxz);
                                        }}
                                    >
                                        {processing.name}
                                    </button>
                                </li>
                            ))}
                    </ul>

                    <input type="text" value={newName} onChange={(e) => setNewName(e.target.value)} />
                    <br />
                    <button className="page-button-action" onClick={AddProcessing}>
                        Nachbearbeitung hinzufügen
                    </button>
                </div>
                {editObject ? (
                    <>
                        <div className="admin-edit-column">
                            <input type="text" value={name} onChange={(e) => setName(e.target.value)} />
                            <h4>Anzeigename</h4>
                            <input type="text" value={displayName} onChange={(e) => setDisplayName(e.target.value)} />
                            <h4>Min</h4>
                            <input type="text" placeholder="Min X" value={minx} onChange={(e) => setMinx(e.target.value)} />
                            <input type="text" placeholder="Min Y" value={miny} onChange={(e) => setMiny(e.target.value)} />
                            <input type="text" placeholder="Min Z" value={minz} onChange={(e) => setMinz(e.target.value)} />
                            <br />
                            <h4>Max</h4>
                            <input type="text" placeholder="Max X" value={maxx} onChange={(e) => setMaxx(e.target.value)} />
                            <input type="text" placeholder="Max Y" value={maxy} onChange={(e) => setMaxy(e.target.value)} />
                            <input type="text" placeholder="Max Z" value={maxz} onChange={(e) => setMaxz(e.target.value)} />
                            <br />
                            <h4>Formeln</h4>
                            <button className="page-button-action" onClick={() => editPrintFormula()}>
                                Edit
                            </button>
                            <h4>Produktionszeit</h4>
                            <button className="page-button-action" onClick={() => editTimeFormula()}>
                                Edit
                            </button>
                            <h4>Tooltip Text</h4>
                            <textarea
                                id="ttt-input"
                                rows="3"
                                cols="50"
                                onChange={(e) => setTooltip(e.currentTarget.value)}
                                value={tooltip}
                            ></textarea>
                            <br />
                            <button className="page-button-action" onClick={() => saveChanges()}>
                                Änderungen speichern
                            </button>
                            <button className="admin-delete-button" onClick={() => deleteEntry()}>
                                Löschen
                            </button>
                        </div>
                        <div className="admin-edit-column">
                            <h4>Variablen</h4>
                            <h5>Vom Modell</h5>
                            <label>x = w</label>
                            <br />
                            <label>y = h</label>
                            <br />
                            <label>z = d</label>
                            <br />
                            <label>volume</label>
                            <br />
                            <label>area</label>
                            <br />
                            <h5>Von Nachbearbeitung</h5>
                            <label>minx</label>
                            <br />
                            <label>miny</label>
                            <br />
                            <label>minz</label>
                            <br />
                            <label>maxx</label>
                            <br />
                            <label>maxy</label>
                            <br />
                            <label>maxz</label>
                            <br />
                            <h5>Von Auswahl</h5>
                            <label>amount</label>
                            <br />
                            <h5>Von Nutzer</h5>
                            <label>discount</label>
                            <br />
                            <h5>Zu schreiben</h5>
                            <label>Procpreis</label>
                            <br />
                        </div>
                    </>
                ) : null}
            </div>
        </>
    );
};

export default AdminEditProcessing;
