import React, { useState, useContext, useEffect } from 'react';
import Axios from 'axios';
import download from 'downloadjs';
import UserContext from '../../context/UserContext';
import ControlContext from '../../context/ControlContext';
import FileContext from '../../context/FileContext';
import { useHistory } from 'react-router-dom';
import ErrorNotice from '../misc/ErrorNotice';

const Thanks = () => {
    const { userData, setUserData } = useContext(UserContext);
    const { control } = useContext(ControlContext);
    const { setFiles } = useContext(FileContext);
    const history = useHistory();
    const [error, setError] = useState();

    useEffect(() => {
        const checkLoggedIn = async () => {
            let token = localStorage.getItem('auth-token');
            if (token === null) {
                localStorage.setItem('auth-token', '');
                token = '';
            }

            const tokenRes = await Axios.post(`${process.env.REACT_APP_SERVER}/users/tokenIsValid`, null, { headers: { 'x-auth-token': token } });

            if (tokenRes.data) {
            } else {
                history.push('/login');
            }
        };
        checkLoggedIn();
    }, [history, setUserData]);

    const BackToMain = () => {
        setFiles([]);
        history.push('/');
    };
    const ToOrders = () => {
        setFiles([]);
        history.push('/orders');
    };

    const DownloadPDF = () => {
        if (control.lastOrder && control.lastOrderNumber) {
            try {
                let token = localStorage.getItem('auth-token');
                const config = {
                    responseType: 'blob',
                    headers: { 'x-auth-token': token },
                    params: { orderID: control.lastOrder },
                };

                Axios.get(`${process.env.REACT_APP_SERVER}/files/downloadorderpdf`, config).then((res) => {
                    download(res.data, `Auftrag_${control.lastOrderIDS}.pdf`);
                });
            } catch (err) {
                console.log(err);
                err.response && err.response.data && err.response.data.msg && setError(err.response.data.msg);
            }
        }
    };

    return (
        <>
            <div className="overlay-page-form">
                <div id="thanks-box">
                    <div className="page-title-left">
                        <h2>Vielen Dank für Ihren Auftrag</h2>
                        <p>Ihre Bestellung wird von uns bearbeitet und Sie erhalten in Kürze eine Bestellbestätigung per E-Mail.</p>
                        <br />
                    </div>
                    {control.paymentMethod?.isBefore && (
                        <>
                            <p>
                                Zahlungsart {control.paymentMethod.displayName}: {control.paymentMethod.text1}
                            </p>
                            <br />
                        </>
                    )}
                    <div id="button-container-even">
                        {error && <ErrorNotice message={error} clearError={() => setError(undefined)} />}
                        {control.lastOrder && control.lastOrderNumber && (
                            <button className="page-button-grey" onClick={DownloadPDF}>
                                Auftrag als PDF speichern
                            </button>
                        )}
                        <button className="page-button-grey" onClick={BackToMain}>
                            Weitere Anfrage
                        </button>
                        <button className="page-button-grey" onClick={ToOrders}>
                            Bestellübersicht
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Thanks;
