import React, { useEffect, useState } from 'react';
import { NumericFormat } from 'react-number-format';;

const FileExtraTask = ({ task, position, deleteTask, updateTask }) => {
    const [prices] = useState(task.prices);
    const [description, setDescription] = useState(task.description);
    useEffect(() => {}, []);

    const onDelete = () => {
        deleteTask(task);
    };

    const onDescriptionChange = (e) => {
        setDescription(e);
    };

    useEffect(() => {
        task.description = description;
        //if (updateTask) updateTask(task);
    }, [description]);

    return (
        <div id="item-element-wrapper">
            <div className={`item-container`}>
                <div className="item-preview-details-wrapper">

                        <div className="item-position-number">
                            {position}
                        </div>

                        <div className="item-details-row">
                            <div></div>

                            <div className="item-name">
                                {task.name}
                            </div>               
                        
                            <div className="item-comment-textbox">
                                <p className="paragraph">Beschreibung:</p>
                                <textarea
                                    id="file-comment"
                                    name="filecomment"
                                    rows="15"
                                    cols="80"
                                    maxLength="500"
                                    onChange={(e) => onDescriptionChange(e.currentTarget.value)}
                                    value={description}
                                ></textarea>
                                <p className="paragraph">
                                    Bitte beschreiben Sie hier die gew&uuml;nschte Leistung. <br />Sie k&ouml;nnen auch zus&auml;tzliche technische Dokumente im
                                    PDF-Format hochladen.<br /> 
                                    Die Preise f&uuml;r diese Leistung k&ouml;nnen nicht automatisch ermittelt werden. Sie werden per Email
                                    benachrichtigt, sobald ein Mitarbeiter Ihre Anfrage bearbeitet hat.<br /> <br /> 
                                </p>
                            </div>
                            

                            <div className="item-comment-textbox">
                                <p className="paragraph">Hier erscheint die Rabattstaffel, sobald ein Mitarbeiter die Anfrage beantwortet hat.</p>
                            </div>
                        </div>


                            <div className="remove-button-box">
                                        <div>
                                            {' '}
                                            <button className="delete-item-button" onClick={onDelete}>
                                                🗙
                                            </button>
                                        </div>
                            </div>
                </div>
            </div>
        </div>
    );
};

export default FileExtraTask;
