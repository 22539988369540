import React, { useEffect, useState } from 'react';
import { NumericFormat } from 'react-number-format';;

const FileTaskCreateOrder = ({ task, position, deleteTask, updateTask, isOrder, paramsComplete }) => {
    const [params, setParams] = useState(task);
    const [prices, setPrices] = useState(task.prices);
    useEffect(() => {
        if (prices.length > 0) {
            params.selectedPrice = prices[0];
            onPriceSelect(prices[0], 0);
        }
    }, []);

    const onDelete = () => {
        deleteTask(params);
    };

    useEffect(() => {
        setParams({ ...params, prices: [...prices] });
    }, [prices]);

    const onDescriptionChange = (e) => {
        setParams({ ...params, description: e });
    };

    const onPriceSelect = (value, index) => {
        let newPrices = [...prices];
        for (var i = 0; i < newPrices.length; ++i) {
            if (i === index) {
                newPrices[i].selected = true;
                setParams({ ...params, selectedPrice: newPrices[i] });
            } else {
                newPrices[i].selected = false;
            }
        }
        setPrices([...newPrices]);
    };

    useEffect(() => {
        if (updateTask) updateTask(params);
        if (paramsComplete) paramsComplete();
    }, [params]);

    return (
        <div id="item-element-wrapper">
            <div className={`item-container ${params.approved ? '' : 'item-container-notPrintable'}`}>
                <div className="item-preview-details-wrapper">
                        <div className="item-position-number">
                            {position}
                        </div>
                        <div className="item-name">
                            {params.name}
                        </div>
                

                    <div className="item-comment-textbox">
                        <p className="paragraph">Beschreibung:</p>
                        <textarea
                            id="file-comment"
                            name="filecomment"
                            rows="15"
                            cols="100"
                            onChange={(e) => onDescriptionChange(e.currentTarget.value)}
                            value={params.description}
                        ></textarea>
                        <p className="paragraph">
                            Bitte beschreiben Sie hier die gew&uuml;nschte Leistung. Sie k&ouml;nnen auch zus&auml;tzliche technische Dokumente im
                            PDF-Format hochladen.
                            <br /> Die Preise f&uuml;r diese Leistung k&ouml;nnen nicht automatisch ermittelt werden. Sie werden per Email benachrichtigt,
                            <br />
                            wenn ein Mitarbeiter Ihre Anfrage bearbeitet hat und k&ouml;nnen Ihr Angebot hier einsehen.
                        </p>
                    </div>
                 
                <div>
                    {params.approved ? (
                        <table id="item-prices-table">
                            <tbody>
                                <tr>
                                    <th>Anzahl</th>
                                    <th>Einzelpreis</th>
                                    <th>Gesamtpreis</th>
                                </tr>
                                {prices
                                    ? prices.map((pr, index) => (
                                          <tr key={index}>
                                              <td>{pr.amount}</td>
                                              <td>
                                                  <NumericFormat
                                                      value={parseFloat(pr.price)}
                                                      displayType={'text'}
                                                      prefix={''}
                                                      suffix={' €'}
                                                      decimalScale={2}
                                                      fixedDecimalScale={true}
                                                      thousandSeparator={'.'}
                                                      decimalSeparator={','}
                                                  />
                                              </td>
                                              <td>
                                                  <NumericFormat
                                                      value={parseFloat(pr.price) * parseInt(pr.amount)}
                                                      displayType={'text'}
                                                      prefix={''}
                                                      suffix={' €'}
                                                      decimalScale={2}
                                                      fixedDecimalScale={true}
                                                      thousandSeparator={'.'}
                                                      decimalSeparator={','}
                                                  />
                                              </td>

                                              <td>
                                                  <input
                                                      className="amount-radio"
                                                      type="radio"
                                                      name={pr.uuid}
                                                      checked={pr.selected ? true : false}
                                                      onChange={(e) => onPriceSelect(e, index)}
                                                  />
                                              </td>
                                          </tr>
                                      ))
                                    : null}
                            </tbody>
                        </table>
                    ) : (
                        <>
                            <p className="paragraph">
                                Diese Position wurde noch nicht durch einen Mitarbeiter bearbeitet und f&uuml;r
                                <br />
                                die Bestellung frei gegeben. Entfernen Sie diese Position, wenn Sie trotzdem
                                <br /> eine Bestellung ausl&ouml;sen wollen.
                            </p>
                        </>
                    )}
                    </div>
                                
                
                    	<div className="remove-button-box">
                                        <div>
                                            {' '}
                                            <button className="delete-item-button" onClick={onDelete}>
                                                🗙
                                            </button>
                                        </div>
                        </div>
                </div>
            </div>
        </div>
    );
};

export default FileTaskCreateOrder;
