import React, { useState, useEffect } from 'react';
import Axios from 'axios';

import AdminEditFormula from '../admin/AdminEditFormula';

const AdminEditMaterial = ({ setError }) => {
    const [select, setSelect] = useState('main');
    const [name, setName] = useState();
    const [displayName, setDisplayName] = useState();
    const [xmlName, setXmlName] = useState();
    const [newName, setNewName] = useState();
    const [density, setDensity] = useState();
    const [materials, setMaterials] = useState([]);
    const [colors, setColors] = useState([]);
    const [processings, setProcessings] = useState([]);
    const [infills, setInfills] = useState([]);
    const [editObject, setEditObject] = useState();
    const [allowedCols, setAllowedCols] = useState([]);
    const [allowedProcs, setAllowedProcs] = useState([]);
    const [allowedInfills, setAllowedInfills] = useState([]);
    const [formula, setFormula] = useState();
    const [tooltip, setTooltip] = useState();
    const [formulaTime, setFormulaTime] = useState();
    const [minx, setMinx] = useState();
    const [miny, setMiny] = useState();
    const [minz, setMinz] = useState();
    const [maxx, setMaxx] = useState();
    const [maxy, setMaxy] = useState();
    const [maxz, setMaxz] = useState();

    const [canHollow, setCanHollow] = useState();
    const [canOrient, setCanOrient] = useState();
    const [canSeries, setCanSeries] = useState();

    const [maxValue, setMaxValue] = useState();
    const [maxAmount, setMaxAmount] = useState();
    const [maxTotalValue, setMaxTotalValue] = useState();

    const [showEditFormula, setShowEditFormula] = useState(false);
    const [showEditFormulaTime, setShowEditFormulaTime] = useState(false);
    const [inputVariables, setInputVariables] = useState({});

    const saveChanges = async () => {
        editObject.colors = allowedCols;
        editObject.processings = allowedProcs;
        editObject.infills = allowedInfills;
        editObject.formula = formula;
        editObject.formulaTime = formulaTime;
        editObject.density = density;
        editObject.name = name;
        editObject.displayName = displayName;
        editObject.xmlName = xmlName;
        editObject.tooltip = tooltip;
        editObject.minx = minx;
        editObject.miny = miny;
        editObject.minz = minz;
        editObject.maxx = maxx;
        editObject.maxy = maxy;
        editObject.maxz = maxz;
        editObject.maxValue = maxValue;
        editObject.maxAmount = maxAmount;
        editObject.maxTotalValue = maxTotalValue;
        editObject.canHollow = canHollow;
        editObject.canOrient = canOrient;
        editObject.canSeries = canSeries;
        let token = localStorage.getItem('auth-token');
        try {
            await Axios.post(`${process.env.REACT_APP_SERVER}/admin/savematerial`, editObject, { headers: { 'x-auth-token': token } });
        } catch (err) {
            err.response.data && err.response.data.msg && setError(err.response.data.msg);
        }
    };

    const AddMaterial = async () => {
        if (newName) {
            let token = localStorage.getItem('auth-token');
            const payload = {
                name: newName,
            };
            try {
                const res = await Axios.post(`${process.env.REACT_APP_SERVER}/admin/addmaterial`, payload, { headers: { 'x-auth-token': token } });
                if (res.data && res.data.name) {
                    setMaterials([...materials, res.data]);
                    setEditObject(res.data);
                    setName(res.data.name);
                }
            } catch (err) {
                err.response.data.msg && setError(err.response.data.msg);
            }
        }
    };

    const changeMaterialColor = (checked, colorID) => {
        if (checked) {
            if (!allowedCols.some((e) => e._id === colorID)) {
                setAllowedCols([...allowedCols, { _id: colorID }]);
            }
        } else {
            setAllowedCols(
                allowedCols.filter(function (obj) {
                    return obj._id !== colorID;
                })
            );
        }
    };

    const changeMaterialProc = (checked, procID) => {
        if (checked) {
            if (!allowedProcs.some((e) => e._id === procID)) {
                setAllowedProcs([...allowedProcs, { _id: procID }]);
            }
        } else {
            setAllowedProcs(
                allowedProcs.filter(function (obj) {
                    return obj._id !== procID;
                })
            );
        }
    };

    const changeMaterialInfill = (checked, infillID) => {
        if (checked) {
            if (!allowedInfills.some((e) => e._id === infillID)) {
                setAllowedInfills([...allowedInfills, { _id: infillID }]);
            }
        } else {
            setAllowedInfills(
                allowedInfills.filter(function (obj) {
                    return obj._id !== infillID;
                })
            );
        }
    };

    const deleteEntry = async () => {
        let token = localStorage.getItem('auth-token');
        try {
            await Axios.post(`${process.env.REACT_APP_SERVER}/admin/deletematerial`, editObject, { headers: { 'x-auth-token': token } });
            const res2 = await Axios.get(`${process.env.REACT_APP_SERVER}/admin/materials/`, { headers: { 'x-auth-token': token } });
            if (res2) {
                setMaterials(res2.data.materials);
                setEditObject(undefined);
            }
        } catch (err) {
            err.response.data && err.response.data.msg && setError(err.response.data.msg);
        }
    };

    useEffect(() => {
        const getMaterials = async () => {
            try {
                let token = localStorage.getItem('auth-token');
                const res = await Axios.get(`${process.env.REACT_APP_SERVER}/admin/materials/`, { headers: { 'x-auth-token': token } });
                if (res) {
                    setMaterials(res.data.materials);
                }
            } catch (err) {
                err.response.data.msg && setError(err.response.data.msg);
            }
        };

        const getColors = async () => {
            try {
                let token = localStorage.getItem('auth-token');
                const res = await Axios.get(`${process.env.REACT_APP_SERVER}/admin/colors/`, { headers: { 'x-auth-token': token } });
                if (res) {
                    setColors(res.data.colors);
                    setEditObject(undefined);
                }
            } catch (err) {
                err.response.data.msg && setError(err.response.data.msg);
            }
        };

        const getProcessings = async () => {
            try {
                let token = localStorage.getItem('auth-token');
                const res = await Axios.get(`${process.env.REACT_APP_SERVER}/admin/processings/`, { headers: { 'x-auth-token': token } });
                if (res) {
                    setProcessings(res.data.processings);
                    setEditObject(undefined);
                }
            } catch (err) {
                err.response.data.msg && setError(err.response.data.msg);
            }
        };

        const getInfills = async () => {
            try {
                let token = localStorage.getItem('auth-token');
                const res = await Axios.get(`${process.env.REACT_APP_SERVER}/admin/infills/`, { headers: { 'x-auth-token': token } });
                if (res) {
                    setInfills(res.data.infills);
                    setEditObject(undefined);
                }
            } catch (err) {
                err.response.data.msg && setError(err.response.data.msg);
            }
        };

        getMaterials();
        getColors();
        getProcessings();
        getInfills();
    }, [setError]);

    const editPrintFormula = () => {
        setInputVariables({
            x: 100,
            y: 100,
            z: 100,
            w: 100,
            h: 100,
            d: 100,
            volume: 1000,
            area: 600,
            minx: minx,
            miny: miny,
            minz: minz,
            maxx: maxx,
            maxy: maxy,
            maxz: maxz,
            amount: 1,
            discount: 0,
        });
        setShowEditFormula(true);
    };

    const editTimeFormula = () => {
        setInputVariables({
            x: 100,
            y: 100,
            z: 100,
            w: 100,
            h: 100,
            d: 100,
            volume: 1000,
            area: 600,
            minx: minx,
            miny: miny,
            minz: minz,
            maxx: maxx,
            maxy: maxy,
            maxz: maxz,
            amount: 1,
            discount: 0,
            printMaxDays: 0,
            procMaxDays: 0,
            colorMaxDays: 0,
            percentSpace: 0,
        });
        setShowEditFormulaTime(true);
    };

    return (
        <>
            <AdminEditFormula
                showModal={showEditFormula}
                setShowModal={setShowEditFormula}
                inputVariables={inputVariables}
                setInputVariables={setInputVariables}
                formula={formula}
                setFormula={setFormula}
            />
            <AdminEditFormula
                showModal={showEditFormulaTime}
                setShowModal={setShowEditFormulaTime}
                inputVariables={inputVariables}
                setInputVariables={setInputVariables}
                formula={formulaTime}
                setFormula={setFormulaTime}
            />
            <h1>Material anlegen</h1>
            <div className="admin-editor-page">
                <div className="admin-edit-column">
                    <ul>
                        {materials &&
                            materials.length > 0 &&
                            materials.map((material) => (
                                <li key={material._id}>
                                    <button
                                        className={select === material.name ? 'admin-nav-button-sel' : 'admin-nav-button'}
                                        onClick={() => {
                                            setEditObject(material);
                                            setName(material.name);
                                            setDisplayName(material.displayName || '');
                                            setXmlName(material.xmlName || '');
                                            setFormula(material.formula || '');
                                            setAllowedCols(material.colors);
                                            setAllowedProcs(material.processings);
                                            setAllowedInfills(material.infills);
                                            setSelect(material.name);
                                            setDensity(material.density);
                                            setTooltip(material.tooltip || '');
                                            setFormulaTime(material.formulaTime || '');
                                            setMinx(material.minx);
                                            setMiny(material.miny);
                                            setMinz(material.minz);
                                            setMaxx(material.maxx);
                                            setMaxy(material.maxy);
                                            setMaxz(material.maxz);
                                            setMaxAmount(material.maxAmount || 0);
                                            setMaxValue(material.maxValue || 0);
                                            setMaxTotalValue(material.maxTotalValue || 0);
                                            setCanHollow(material.canHollow || false);
                                            setCanOrient(material.canOrient || false);
                                            setCanSeries(material.canSeries || false);
                                        }}
                                    >
                                        {material.name}
                                    </button>
                                </li>
                            ))}
                    </ul>
                    <h4>Neue Formel anlegen</h4>
                    <input type="text" value={newName} onChange={(e) => setNewName(e.target.value)} />
                    <br />
                    <button className="page-button-action" onClick={AddMaterial}>
                        Material hinzufügen
                    </button>
                </div>
                {editObject ? (
                    <>
                        <div className="admin-edit-column">
                            <h4>Formelname</h4>
                            <input type="text" value={name} onChange={(e) => setName(e.target.value)} />

                            <h4>Anzeigename</h4>
                            <input type="text" value={displayName} onChange={(e) => setDisplayName(e.target.value)} />
                            <h4>XML-Name</h4>
                            <input type="text" value={xmlName} onChange={(e) => setXmlName(e.target.value)} />
                            <h4>Dichte</h4>

                            <input type="text" placeholder="1.00" value={density} onChange={(e) => setDensity(e.target.value)} />
                            <br />
                            <br />
                            <label htmlFor="CBcanHollow">
                                <input
                                    type="checkbox"
                                    id="CBcanHollow"
                                    checked={canHollow}
                                    onChange={(e) => {
                                        setCanHollow(e.target.checked);
                                    }}
                                />
                                Aush&ouml;hlen erlauben
                            </label>
                            <br />
                            <label htmlFor="CBcanOrient">
                                <input
                                    type="checkbox"
                                    id="CBcanOrient"
                                    checked={canOrient}
                                    onChange={(e) => {
                                        setCanOrient(e.target.checked);
                                    }}
                                />
                                Orientierung beibehalten
                            </label>
                            <br />
                            <label htmlFor="CBcanSeries">
                                <input
                                    type="checkbox"
                                    id="CBcanSeries"
                                    checked={canSeries}
                                    onChange={(e) => {
                                        setCanSeries(e.target.checked);
                                    }}
                                />
                                Serienfertigung
                            </label>

                            <h4>Mögliche Färbungen</h4>
                            {colors &&
                                colors.length > 0 &&
                                colors.map((color) => (
                                    <div key={color._id}>
                                        <label htmlFor={color.ID}>
                                            <input
                                                type="checkbox"
                                                id={color.ID}
                                                checked={allowedCols.some((e) => e._id === color._id)}
                                                onChange={(e) => {
                                                    changeMaterialColor(e.target.checked, color._id);
                                                }}
                                            />
                                            {color.name}
                                        </label>
                                        <br />
                                    </div>
                                ))}
                            <h4>Mögliche Nachbearbeitungen</h4>
                            {processings &&
                                processings.length > 0 &&
                                processings.map((processing) => (
                                    <div key={processing._id}>
                                        <label htmlFor={processing.ID}>
                                            <input
                                                type="checkbox"
                                                id={processing.ID}
                                                checked={allowedProcs.some((e) => e._id === processing._id)}
                                                onChange={(e) => {
                                                    changeMaterialProc(e.target.checked, processing._id);
                                                }}
                                            />
                                            {processing.name}
                                        </label>
                                        <br />
                                    </div>
                                ))}
                            <h4>Mögliche Infills</h4>
                            {infills &&
                                infills.length > 0 &&
                                infills.map((infill) => (
                                    <div key={infill._id}>
                                        <label htmlFor={infill.ID}>
                                            <input
                                                type="checkbox"
                                                id={infill.ID}
                                                checked={allowedInfills.some((e) => e._id === infill._id)}
                                                onChange={(e) => {
                                                    changeMaterialInfill(e.target.checked, infill._id);
                                                }}
                                            />
                                            {infill.name}
                                        </label>
                                        <br />
                                    </div>
                                ))}
                            {allowedInfills &&
                                allowedInfills.length > 0 &&
                                allowedInfills.map((ai) => (
                                    <div>
                                        <label>#{infills.find((infi) => infi._id === ai._id).name}</label>
                                        <br />
                                    </div>
                                ))}
                        </div>
                        <div className="admin-edit-column">
                            <h4>Minimale Größe</h4>
                            <input type="text" placeholder="Min X" value={minx} onChange={(e) => setMinx(e.target.value)} />
                            <input type="text" placeholder="Min Y" value={miny} onChange={(e) => setMiny(e.target.value)} />
                            <input type="text" placeholder="Min Z" value={minz} onChange={(e) => setMinz(e.target.value)} />
                            <br />
                            <h4>Maximale Größe</h4>
                            <input type="text" placeholder="Max X" value={maxx} onChange={(e) => setMaxx(e.target.value)} />
                            <input type="text" placeholder="Max Y" value={maxy} onChange={(e) => setMaxy(e.target.value)} />
                            <input type="text" placeholder="Max Z" value={maxz} onChange={(e) => setMaxz(e.target.value)} />
                            <br />
                            <h4>Manuelle Preisberechnung ab</h4>
                            <label>Einzelwert </label>
                            <input type="text" placeholder="Wert" value={maxValue} onChange={(e) => setMaxValue(e.target.value)} />
                            <br />
                            <label>Stückzahl </label>
                            <input type="text" placeholder="St&uuml;ckzahl" value={maxAmount} onChange={(e) => setMaxAmount(e.target.value)} />
                            <br />
                            <label>Gesamtwert </label>
                            <input type="text" placeholder="Max Wert" value={maxTotalValue} onChange={(e) => setMaxTotalValue(e.target.value)} />
                            <br />
                            <h4>Formeln</h4>
                            <button className="page-button-action" onClick={() => editPrintFormula()}>
                                Edit
                            </button>
                            <h4>Produktionszeit</h4>
                            <button className="page-button-action" onClick={() => editTimeFormula()}>
                                Edit
                            </button>
                            <h4>Tooltip Text</h4>
                            <textarea
                                id="ttt-input"
                                rows="3"
                                cols="50"
                                onChange={(e) => setTooltip(e.currentTarget.value)}
                                value={tooltip}
                            ></textarea>
                            <br />
                            <button className="page-button-action" onClick={() => saveChanges()}>
                                Änderungen speichern
                            </button>
                            <button className="admin-delete-button" onClick={() => deleteEntry()}>
                                Löschen
                            </button>
                        </div>
                        <div className="admin-edit-column">
                            <h4>Variablen</h4>
                            <h5>Vom Modell</h5>
                            <label>x = w</label>
                            <br />
                            <label>y = h</label>
                            <br />
                            <label>z = d</label>
                            <br />
                            <label>volume</label>
                            <br />
                            <label>area</label>
                            <br />
                            <h5>Vom Material</h5>
                            <label>density</label>
                            <br />
                            <label>minx</label>
                            <br />
                            <label>miny</label>
                            <br />
                            <label>minz</label>
                            <br />
                            <label>maxx</label>
                            <br />
                            <label>maxy</label>
                            <br />
                            <label>maxz</label>
                            <br />
                            <h5>Von Auswahl</h5>
                            <label>amount</label>
                            <br />
                            <h5>Von Nutzer</h5>
                            <label>discount</label>
                            <br />
                            <h5>Zu schreiben</h5>
                            <label>Druckpreis</label>
                            <br />
                        </div>
                    </>
                ) : null}
            </div>
        </>
    );
};

export default AdminEditMaterial;
