import React, { Component } from 'react';

class OrderArrayRenderer extends Component {
    constructor(props) {
        super(props);
        this.btnClickedHandler = this.btnClickedHandler.bind(this);
    }
    btnClickedHandler(val) {
        this.props.clicked(val.target.value);
    }
    render() {
        if (!this.props?.value?.length) return <div>offen</div>;
        return (
            <>
                {this.props?.value?.map((el, index) => (
                    <>
                        <button onClick={this.btnClickedHandler} value={el._id} key={`bb${index}`}>
                            {el.IDS}
                        </button>
                        &nbsp;
                    </>
                ))}
            </>
        );
    }
}

export default OrderArrayRenderer;
